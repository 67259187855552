<template>
  <card
      card-type="support-measure"
  >
    <div class="div_status"  v-if="event.alert || event.status">
      <template v-if="event.status">
        <cl-status type="approved" class="status-ico-wrap" v-if="event.status == 'Одобрена'">
          {{ event.status }}
        </cl-status>
        <cl-status type="rejected" class="status-ico-wrap" v-else-if="event.status == 'Отклонена'">
          {{ event.status }}
        </cl-status>
        <cl-status type="new" class="status-ico-wrap" v-else-if="event.status == 'Новая'">
          {{ event.status }}
        </cl-status>
        <cl-status type="consideration" class="status-ico-wrap" v-else-if="event.status == 'В работе'">
          {{ event.status }}
        </cl-status>
        <cl-status type="draft" class="status-ico-wrap" v-else-if="event.status == 'Черновик'">
          {{ event.status }}
        </cl-status>
        <cl-status type="lock" class="status-ico-wrap" v-else-if="event.status == 'Завершена'">
          {{ event.status }}
        </cl-status>
        <cl-status v-else>{{ event.status }}</cl-status>
      </template>
      <Popper
          v-if="event.alert"
          content="Требуется действия"
          class="light2 popper_info"
          hover="true"
          arrow
      >
        <img src="/img/alert-circle.svg">
      </Popper>
<!--      <span v-if="event.result && event.resultCode" class="color_gray">{{ event.result }}</span>-->
    </div>
    <h4  v-if="event.outerParams?.['frontOutput:name_poject']" class="div_tit_h">{{ event.outerParams['frontOutput:name_poject'].value }}</h4>
    <div class="div_tit_flex">
      <div class="dt1" v-if="event.measureSupport?.image"><img :src="event.measureSupport.image" alt=""></div>
      <div class="dt2" v-if="event.name">
        <div class="div_tit_gray">Тип заявки:</div>
        <div>{{ event.name }}</div>
      </div>
    </div>
    <div class="div_number" v-if="event.code"><span class="sp_num">№{{ event.code }}</span>
      <template v-if="event.fio">
        <span class="color_gray">отправил:</span> {{ event.fio.split(' ')[0] }} {{ event.fio.split(' ')[1] ? event.fio.split(' ')[1].charAt(0) + '.' : '' }} {{ event.fio.split(' ')[2] ? event.fio.split(' ')[2].charAt(0) + '.' : '' }}
      </template>
      <template v-if="event.created">
      <span class="color_gray">Дата подачи:</span> {{ new Date(event.created).getYear() == new Date().getYear() ? $filters.ruDate1(new Date(event.created)) : $filters.ruDate3(new Date(event.created)) }}
      </template>
    </div>
    <div class="div_tit_flex2" v-if="event.outerParams">
      <div class="dt" v-if="event.outerParams['frontOutput:filming_date_from']">
        <div class="div_tit_gray">Дата съемки:</div>
        <div>{{ event.outerParams['frontOutput:filming_date_from'].value }} <template v-if="event.outerParams['frontOutput:filming_date_to'] && event.outerParams['frontOutput:filming_date_from'] != event.outerParams['frontOutput:filming_date_to']">- {{ event.outerParams['frontOutput:filming_date_to'].value }}</template></div>
      </div>
      <div class="dt" v-if="event.outerParams['frontOutput:name_address'] || event.outerParams['frontOutput:name_location']">
        <div class="div_tit_gray">Локация:</div>
        <div>{{ event.outerParams['frontOutput:name_location'].value }} <template v-if="event.outerParams['frontOutput:name_address'] && event.outerParams['frontOutput:name_location']"> / </template> {{ event.outerParams['frontOutput:name_address'].value }}</div>
      </div>

      <div class="dt" v-if="event.outerParams['frontOutput:name_route']">
        <div class="div_tit_gray">Маршут:</div>
        <div>{{ event.outerParams['frontOutput:name_route'].value }}</div>
      </div>
    </div>

    <div class="div_btn"><cl-button type="light" class="btn-middle" @click="$router.push(`/lk/my-applications/item/${event.guid}`)">Подробнее</cl-button></div>
  </card>
</template>

<script>
import ClButton from "@/components/library/ClButton.vue";

export default {
  name: "CardSupportMeasure",
  components: {ClButton},
  props: {
    event: {
      type: Object
    }
  },
}
</script>

<style lang="scss" scoped>

</style>