<template>
  <div class="card-document" :class="{'card-document-show': showCollapse || startFixHeight, 'not_active': !collapseActive}">
    <a :href="dataDoc.url" target="_blank" class="dt1"><img src="/img/ico/icoDoc.svg" alt=""></a>
    <div class="dt2">
      <div class="card-document-top-over" :class="{'collapse-start': collapseStart}">
        <div class="card-document-top" @click="collapseDoc"
             :class="{'show-collapse': showCollapse || startFixHeight, 'not_active': !collapseActive}"
             ref="cardDocumentTop">
          <div class="card-document-tit" v-if="dataDoc.name">
            {{ dataDoc.name }}
          </div>
          <div class="card-document-str" v-if="collapseActive">
            <img src="/img/ico/chevron-down-orange.svg" alt="">
          </div>
        </div>
      </div>      
      <div class="card-document-bot">
        <div class="card-document-format" v-if="dataDoc.size">
          {{ dataDoc.ext }}, {{ dataDoc.size.toFixed(2) }} MB
        </div>
        <div class="card-document-dow" v-if="dataDoc.url">
          <a :href="dataDoc.url" target="_blank" class="a-or2">Скачать</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
* dataDoc - данные документа (name/ext/size/url)
*
* */
export default {
  name: "cardDocument",
  props: {
    dataDoc: {
      type: Object,
      require: true
    },
  },
  data(){
    return {
      startFixHeight: true,
      showCollapse: false,
      collapseActive: false,
      collapseStart: true,
    }
  },
  methods: {
    collapseDoc(){
      if(this.collapseActive){
        this.showCollapse = !this.showCollapse;
      }
    }
  },
  mounted() {
    this.$refs.cardDocumentTop.style.height = this.$refs.cardDocumentTop.offsetHeight + 'px';
    if(this.$refs.cardDocumentTop.offsetHeight >= 52){
      this.collapseActive = true;
    }
    this.startFixHeight = false;
    setTimeout(()=>{
      this.collapseStart = false;
    }, 300);
  }
}
</script>

<style lang="scss" scoped>
.card-document {
  display: flex;
  opacity: 1;
  padding: 1.6rem;
  gap: 1.6rem;
  border-radius: .8rem;
  &:hover,
  &-show {
    background-color: #0C0C0E;
  }
  > .dt1 {
    width: 50px;
    flex: none;
  }
  > .dt2 {
  }
  &-top-over {
    margin-bottom: 8px;
    &.collapse-start {
      max-height: 48px;
      overflow: hidden;
      .card-document-tit {
        -webkit-line-clamp: inherit;
        line-clamp: inherit;
        height: auto;
      }
    }
  }
  &-top {

    display: flex;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    transition: height .3s ease;
    &.not_active {
      transition: none;
      cursor: default;
    }
    &:not(.show-collapse):not(.not_active) {
      height: 48px !important;
    }
    &.show-collapse {
      .card-document-tit {
        -webkit-line-clamp: inherit;
        line-clamp: inherit;
        height: auto;
      }
      .card-document-str {
        transform: rotate(180deg);
      }
    }
  }
  &-tit {
    font-size: 16px;
    line-height: 150%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &-str {
    flex: none;
    transition: all .3s ease;
    height: 24px;
  }
  &-bot {
    display: flex;
    font-size: 1.4rem;
    gap: 2.4rem;
  }
  &-format {
    color: #9096A2;
    letter-spacing: 0.21px;
    text-transform: uppercase;
    min-width: 10.5rem;
  }
  &-dow {}
}
</style>