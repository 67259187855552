<template>
  <div class="banner_premiere_cinema" v-if="dataInfo.bannerPremiereCinema">
    <container class="container3">
      <div class="block_banner_premiere" :class="{'left': imageLeft}">
        <div class="dt dt1">
          <div class="date" v-if="dataInfo.bannerPremiereCinema.dateBanner">{{ dataInfo.bannerPremiereCinema.dateBanner.value }}</div>
          <div class="uppertitle" v-if="dataInfo.bannerPremiereCinema.upperTitleBanner" v-html="dataInfo.bannerPremiereCinema.upperTitleBanner.value"></div>
          <div class="title" v-if="dataInfo.bannerPremiereCinema.titleBanner" v-html="dataInfo.bannerPremiereCinema.titleBanner.value"></div>
          <div class="desc" v-if="dataInfo.bannerPremiereCinema.descBanner">{{ dataInfo.bannerPremiereCinema.descBanner.value }}</div>
          <div class="dib_btn" v-if="dataInfo.bannerPremiereCinema.textBtn.value">
            <a targer="_blank" class="btn btn-light" :href="dataInfo.bannerPremiereCinema?.linkBtn?.value">{{ dataInfo.bannerPremiereCinema.textBtn.value }}</a>
          </div>
        </div>
        <div class="dt dt2" v-if="dataInfo.bannerPremiereCinema.imageBanner">
          <img :src="getImage(dataInfo.bannerPremiereCinema.imageBanner)" alt="">
        </div>
      </div>
    </container>
  </div>
</template>

<script>
export default {
  name: "bannerPremiereCinema",
  data(){
    return {
      imageLeft: false,
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  mounted(){
    if (this.dataInfo?.bannerPremiereCinema?.imageLeft){
      this.imageLeft = this.dataInfo?.bannerPremiereCinema?.imageLeft.value
    }
  }
}
</script>

<style lang="scss" scoped>

.banner_premiere_cinema{
  margin: 4rem 0;
  .container{
    max-width: 1440px;
  }
  @media(max-width: 1440px){
    .container{
      padding: 0;
    }
  }
  @media(max-width: 991px){
    .container{
      padding: 0 4rem;
    }
    .block_scheme{
      border-radius: 2.4rem;
    }
  }
  @media(max-width: 767px){
    .container{
      padding: 0;
    }
  }
}

.block_banner_premiere{
  padding: 120px;
  background: #17191C;
  display: flex;
  justify-content: space-between;
  gap: 48px;
  border-radius: 16px;
  .dt{
    display: flex;
    &.dt1{
      flex-direction: column;
      justify-content: center;
      width: 50%;
      .title, .uppertitle{
        font-size: 32px;
        line-height: 150%;
        margin-bottom: 8px;
      }
      .title{
        font-weight: 500;
      }
      .uppertitle{
        margin-bottom: 0;
        font-weight: 500;
      }
      .desc{
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 24px;
      }
      .date{
        color: #ED850A;
      }
    }
    &.dt2{
      width: 50%;
      img{
        border-radius: 16px;
      }
    }
  }
  &.left{
    flex-direction: row-reverse;
  }
  @media(max-width: 1440px){
    .dt.dt1 .uppertitle{
      font-size: 24px;
    }
  }
  @media(max-width: 1240px){
    padding: 120px 32px;
    gap: 24px;
  }
  @media(max-width: 991px){
    padding: 64px 16px;
    flex-direction: column-reverse;
    &.left{
      flex-direction: column-reverse;
    }
    .dt{
      &.dt1{
        width: 100%;
        .title{
          font-size: 24px;
        }
        .uppertitle{
          font-size: 18px;
        }
        .desc{
          font-size: 18px;
        }
        .btn{
          width: 100%;
          min-height: 48px;
        }
      }
      &.dt2{
        width: 100%;
        max-height: 310px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 16px;
        img{
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }
  @media(max-width: 767px){
    .dt{
      &.dt1{
        .title{
          font-size: 1.8rem;
        }
      }
    }
  }
}

</style>