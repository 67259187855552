<template>
  <div class="calendar_swiper" id="filterSwiper">
    <div class="dt d-none d-md-block"><div ref="prev" class="swiper-button swiper-button-prev"></div></div>
    <div class="dt dt_swiper">
      <div class="calendar_month" id="calendar_month">
        <div class="calendar_month_td"
             v-for="month in datesMonth"
             :key="month"
             :style="{ 'width': month.day * 68 + 'px' }"
        >
          <div class="calendar_month_tit">{{month.title}}</div>
        </div>
      </div>

      <swiper
          :modules="modules"
          :slides-per-view="'auto'"
          :slides-per-group="8"
          :spaceBetween="0"
          @realIndexChange="toggleCalendarBtn"
          :navigation="{
                    prevEl: prev,
                    nextEl: next,
                  }"
          @swiper="onSwiper"
          :observer="true"
      >
        <swiper-slide
            v-for="day in dates"
            :key="day"
            :class="activeDate(day)"
        >
          <!--              active -->
          <div
              class="calendar_day"
              :class="{'not-active': this.activeDates && !isActiveDay(day)}"
              @click="(this.activeDates && isActiveDay(day)) || !this.activeDates || this.period ? changeDay(day) : ''"
          >
            {{ new Date(day).getDate().toString().padStart(2, '0') }}
            <div class="div_text" :class="{'weekend_day': isWeekend(day) }">{{ new Date(day).toLocaleDateString('ru-RU', {weekday: 'short'}).slice(0, 2) }}</div>
          </div>
        </swiper-slide>
        <swiper-slide class="calendar_butn">
          <cl-calendar
              v-model="filter.filterDateCal"
              :attributes="calendarPoints"
              :btn-calendar="true"
              btnCalendarType="events"
              :min-date="minDate"
              :dateBtn="filter.filterDate?.start && new Date(filter.filterDate.start) > new Date(dates.at(-1))"
              :dateFilter="filter.filterDate"
              @clear="fromChangeCalendar"
              @change="fromChangeCalendar"
              :period="this.period"
              :position="'left'"
              :teleport="`#teleportCalendar${indexCalendar}`"
          />
        </swiper-slide>
      </swiper>
      <div :id="`teleportCalendar${indexCalendar}`" class="dt_swiper_tel pos_rel text-right"></div>
    </div>
    <div class="dt d-none d-md-block"><div ref="next" class="swiper-button swiper-button-next"></div></div>
  </div>
</template>

<script>
/*
* period {Boolean:true/false} - выбрать можно период или тока одну дату
* dataInfo {Array} - массив дат
* change {Emit} - { "start": "2024-05-16T07:31:11.064Z", "end": "2024-05-16T07:31:11.064Z" }
* activeDates {Array: Date[]} - массив активных дат, это значит что выбрать можно только их.
* chooseFirstActive {chooseFirstActive} - при монтировании выбрать первую даты входящую в списки активных(activeDates)
* firstActiveDate {boolean} - Начинать с первой активной даты.
* clear {Boolean:true/false} удаляем выбранные даты
* clear {Emit} возврашает когда удаляем выбранные даты
* */
import {ref} from 'vue';
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import {mapGetters} from "vuex";

export default {
  name: "calendarCarousel",
  components: {Swiper, SwiperSlide},
  props: {
    dataInfo: {
      type: Array,
      require: true
    },
    period: {
      type: Boolean,
      default: true
    },
    activeDates: {
      type: Array,
    },
    chooseFirstActive: {
      type: Boolean,
      default: false
    },
    firstActiveDate: {
      type: Boolean,
      default: false
    },
    clear: {
      type: Boolean,
    },
    indexCalendar: {
      type: String,
      default: "default"
    }
  },
  data(){
    return {
      //swiperWidth: 0,
      dates: [],
      datesMonth: [],
      showCalendar: false,
      minDate: new Date(),
      swiper: null,

      filter: {
        filterDate: null,
        filterDateCal: null,
        calPoint: {
          "records": [
            {
              "guid": "7e7c73ddffea4ddf9597a5882e4cdb48",
              "shortName": "«V FASHION»-митап «Путь к успеху лежит через потребителя»",
              "dateFrom": "2023-07-20T00:00:00",
              "dateTo": "2023-07-20T00:00:00",
              "place": "Москва, Космодамианская наб., 2",
              "shortInfo": "«V FASHION",
            },
          ]
        },
      }
    }
  },
  methods: {
    generateDates() {
      const dates = [];
      const datesMonth = [];
      let currentDate;
      if (this.firstActiveDate && this.activeDates?.length) {
        currentDate = new Date(this.activeDates[0].getTime());
      } else {
        currentDate = new Date();
      }
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth();

      for (let i = 0; i < 3; i++) { // 3 месяца включая текущий
        const lastDayOfMonth = new Date(year, month + 1, 0).getDate(); // последний день месяца
        const endOfMonth = new Date(year, month, lastDayOfMonth);
        datesMonth.push({title: endOfMonth.toLocaleDateString("ru", {
          month: 'long',
          formatMatcher: 'basic'
        }).split(', ').reverse().join(', '), day: 0});
        while (currentDate.setHours(0, 0, 0, 0) <= endOfMonth.setHours(0, 0, 0, 0)) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
          datesMonth[i].day = datesMonth[i].day + 1;
        }

        month++; // переходим к следующему месяцу
        if (month === 12) { // если это декабрь, переходим на следующий год
          month = 0;
          year++;
        }
      }
      this.dates = dates;
      this.datesMonth = datesMonth
    },
    toggleCalendarBtn(swiper){
      this.showCalendar = swiper.isEnd;
    },
    changeDay(day){
      const daySet = new Date(day).setHours(0, 0, 0, 0),
          dayStart = new Date(this.filter.filterDate?.start).setHours(0, 0, 0, 0),
          dayEnd = new Date(this.filter.filterDate?.end).setHours(0, 0, 0, 0);
      if (!this.filter.filterDate || !this.period) {
        // Выбор начальной даты
        this.filter.filterDate = {};
        this.filter.filterDate.start = day;
        this.filter.filterDate.end = day;
      } else {
        // Выбор конечной даты
        if (dayStart > daySet && dayStart == dayEnd){
          this.filter.filterDate.end = this.filter.filterDate.start;
          this.filter.filterDate.start = day;
        } else if(dayStart < daySet && dayStart == dayEnd) {
          this.filter.filterDate.end = day;
        } else if(dayStart == daySet && dayEnd == daySet) {
          this.filter.filterDate = null;
        } else {
          this.filter.filterDate.start = day;
          this.filter.filterDate.end = day;
        }
      }
      this.filter.filterDateCal = {...this.filter.filterDate};
      this.$emit("change", this.filter.filterDate);
    },
    fromChangeCalendar(){
      if (!this.period) {
        this.filter.filterDate = {};
        this.filter.filterDate.start = this.filter.filterDateCal;
        this.filter.filterDate.end = this.filter.filterDateCal;
      } else {
        this.filter.filterDate = {...this.filter.filterDateCal};
      }
      this.$emit("change", this.filter.filterDate);
      const position = this.dates.findIndex(date => new Date(date).setHours(0, 0, 0, 0) === new Date(this.filter.filterDate.start).setHours(0, 0, 0, 0));
      if (position !== -1) {
        this.handleSlideTo(position);
      }
      // обновляем карусель
      if(this.filter.filterDate?.start && new Date(this.filter.filterDate.start) > new Date(this.dates.at(-1))){
        this.$nextTick(() => {
          const parentElement = document.querySelector('.calendar_swiper .swiper-wrapper');
          const childElements = parentElement.querySelectorAll('.swiper-slide');
          let totalWidth = -parentElement.offsetWidth;
          childElements.forEach(childElement => {
            totalWidth += childElement.offsetWidth;
          });
          const newTransformValue = `translate3d(-${totalWidth}px, 0px, 0px)`;
          parentElement.style.transform = newTransformValue;
        });
      }
    },
    activeDate(day) {
      const daySet = new Date(day).setHours(0, 0, 0, 0),
          dayStart = new Date(this.filter.filterDate?.start).setHours(0, 0, 0, 0),
          dayEnd = new Date(this.filter.filterDate?.end).setHours(0, 0, 0, 0),
          classArray = [];
      if(dayStart == daySet && dayEnd == daySet){
        classArray.push('active');
      } else if(dayStart == daySet){
        classArray.push('active_start');
      } else if(dayEnd == daySet){
        classArray.push('active_end');
      } else if(dayStart < daySet && dayEnd > daySet){
        classArray.push('active_center');
      }
      return classArray;
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    handleSlideTo(index) {
      this.swiper.slideTo(index);
    },
    observerMonthScroll(){
      const el1 = document.querySelector('.calendar_swiper .swiper-wrapper');
      const el2 = document.querySelector('.calendar_month');

      const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'attributes') {
            const styleValue = el1.style.transform.match(/translate3d\((-?\d+)px, -?\d+px, -?\d+px\)/);
            if (styleValue) {
              const translateX = parseInt(styleValue[1]);
              //console.log(translateX)
              el2.scrollTo({
                left: Math.abs(translateX),
                // behavior: 'smooth'
              });
              //el2.scrollLeft = Math.abs(translateX);
            }
          }
        }
      });

      const config = { attributes: true, attributeFilter: ['style'], attributeOldValue: true };
      observer.observe(el1, config);
    },
    isWeekend(day) {
      const dayOfWeek = new Date(day).getDay();
      return dayOfWeek === 6 || dayOfWeek === 0;
    },
    isActiveDay(day) {
      if (this.activeDates) {
        const date = new Date(day);
        return this.activeDates.some(dateItem => {
          if (date.toISOString().split("T")[0] === dateItem.toISOString().split("T")[0]) {
            return true;
          }
        });
      } else {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
    calendarPoints() {
      //if (this.calPoint && this.calPoint.length > 0) {
        return [
          {
            key: 'today',
            highlight: true,
            dates: new Date(),
          },
          // ...this.eventsCalendar.map(event => ({
          //   dates: {start: new Date(event.dateFrom), end: new Date(event.dateTo)},
          //   dot: {
          //     color: 'red',
          //   },
          //   popover: {
          //     label: event.shortName,
          //   },
          //   customData: event.shortName,
          // })),
        ];
      //}
    },
  },
  watch: {
    clear: {
      deep: true,
      handler(newValue) {
        if(this.clear){
          this.filter.filterDate = {};
          this.filter.filterDateCal = {...this.filter.filterDate};
          this.$emit("change", this.filter.filterDate);
          this.$emit("clear", true);
          this.handleSlideTo(0);
        }
      },
    }
  },
  mounted() {
    if (this.activeDates) {
      this.activeDates.sort((a, b) => a - b);
    }
    this.generateDates();
    if (this.activeDates && this.chooseFirstActive && this.activeDates.length) {
      const dateNow = new Date().setHours(0, 0, 0, 0);
      for (let date of this.activeDates) {
        if (date.setHours(0, 0, 0, 0) >= dateNow) {
          this.changeDay(date);
          setTimeout(() => {
            const activeSlide = this.$el.querySelector(".swiper-slide.active");
            if (activeSlide) {
              const activeSlideIndex = Array.from(activeSlide.parentNode.children).indexOf(activeSlide);
              this.handleSlideTo(activeSlideIndex);
            }
          })
          break;
        }
      }
    }
    this.$nextTick(() => {
      this.observerMonthScroll();
    });
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);

    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
  emits: ['change']
}
</script>

<style lang="scss" scoped>
.calendar_swiper {
  display: flex;
  gap: 12px;
  .dt {
    position: relative;
    padding-top: 44px;
  }
  .dt_swiper_tel {
    height: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: flex;
    &.swiper-button-disabled {
      border: none;
    }
  }
  .swiper {
    //width: 1000px;
    width: calc(1632px - 56px - 56px - 24px);
    @media(max-width: 1632px){
      width: calc(100vw - 56px - 56px - 24px - 48px * 2);
    }
    @media(max-width: 1440px){
      width: calc(100vw - 56px - 56px - 24px - 32px * 2);
    }
    @media(max-width: 991px){
      width: calc(100vw - 56px - 56px - 24px - 24px * 2);
    }
  }
  .swiper-button {
    position: relative;
    left: auto;
    top: auto;
    height: 56px;
    width: 56px;
  }
  .swiper-slide {
    width: auto !important;
    //margin: 0 !important;
    padding: 0 6px;
    &.active,
    &.active_start,
    &.active_end {
      .calendar_day {
        background: #ED850A;
        border-color: #ED850A;
        color: #ffffff;
      }
    }
    &.active_start {
      background: linear-gradient(-90deg, rgba(237, 133, 10, 0.2) 50%, transparent 50%);
    }
    &.active_end {
      background: linear-gradient(90deg, rgba(237, 133, 10, 0.2) 50%, transparent 50%);
    }
    &.active_center {
      background: rgba(237, 133, 10, 0.2);
      .calendar_day {
        border-color: rgba(0, 0, 0, 0);
      }
    }
    &.active_center + .swiper-slide.calendar_butn{
      background: linear-gradient( to right, rgba(237, 133, 10, 0.2), rgba(237, 133, 10, 0.0));
    }
  }
  .calendar_day {
    border: 1px solid #ED850A;
    border-radius: 6px;
    padding: 8px 16px;
    width: 56px;
    height: 56px;
    font-weight: 500;
    font-size: 18px;
    line-height: 133%;
    letter-spacing: 0.02em;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    .weekend_day{
      color: #f43f5e;
    }
    &.active {
      color: #ffffff;
    }
    .div_text {
      font-size: 14px;
      line-height: 114%;
    }
    &.not-active {
      user-select: none;
      cursor: default;
      border-color: #6b7280;
    }
  }
  @media(max-width: 767px){
    flex-wrap: wrap;
    .swiper {
      width: 100vw !important;
      margin: -16px;
      padding: 16px;
    }
  }
}

.col-66 .swiper {
  width: calc(1632px / 100 * 66.66 - 56px - 56px - 24px - 4rem - 25.2rem - 40px);
  @media(max-width: 1632px){
    width: calc(100vw / 100 * 66.66 - 56px - 56px - 24px - 4rem - 25.2rem - 40px);
  }
  @media(max-width: 991px){
    width: calc(100vw  / 100 * 66.66 - 56px - 56px - 24px - 24px);
  }
  @media(max-width: 767px){
    width: calc(100vw - 56px - 56px - 16px - 16px);
  }
}

.calendar_month {
  display: flex;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 4px;
  font-size: 18px;
  &::-webkit-scrollbar {
    display: none;
  }

  .calendar_month_td {
    flex: none;
    display: flex;
  }
  .calendar_month_tit {
    position: sticky;
    left: 0;
    margin-right: 20px;
    margin-left: 5px;
  }
}

</style>