<template>
    <div class="block_wrap gdaib block_wrap_eng" v-if="dataInfo">
        <container>
            <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
            <div class="gdaib_text fs4n" v-if="dataInfo.text" v-html="dataInfo.text.value"></div>
            <div class="div_slide" v-if="dataInfo.gallery1 || dataInfo.gallery2 && dataInfo.gallery1.length > 0">

                <!--      @mouseenter="autoplayStart"-->
                <!--      @mouseleave="autoplayStop"-->
                <!--      :freeMode="true"-->
                <!--      pauseOnMouseEnter: true-->
                <h3 class="div_tit_swiper" v-if="dataInfo.text1?.value">{{ dataInfo.text1.value }}</h3>
                <swiper
                        v-if="dataInfo.gallery1 && dataInfo.gallery1.length > 0"
                        :modules="modules"
                        :slides-per-view="'auto'"
                        class="slide100h sample-slider arrow_center arrow_center2"
                        :loop="true"
                        :spaceBetween="24"
                        :speed="4000"
                        :autoplay="{
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: true,
          }"
                        :lazy="true"
                        :breakpoints="{
                  768: {
                    slidesPerView: 3,
                  },
                  300: {
                    slidesPerView: 1.45,
                  }
                }"
                >
                    <swiper-slide
                            v-for="(galleryItem1, index) in dataInfo.gallery1"
                            :key="galleryItem1.src"
                    >
                        <div class="gdaib_img" @click="showModalGalFun(dataInfo.gallery1, index)">
                            <img :src="getImage(galleryItem1, 'hd')" loading="lazy" alt="">
                        </div>
                    </swiper-slide>
                </swiper>
                <h3 class="div_tit_swiper" v-if="dataInfo.text2?.value">{{ dataInfo.text2.value }}</h3>
                <swiper
                        v-if="dataInfo.gallery2 && dataInfo.gallery2.length > 0"
                        :modules="modules"
                        :slides-per-view="'auto'"
                        class="slide100h sample-slider arrow_center arrow_center2"
                        :loop="true"
                        :spaceBetween="24"
                        :speed="4000"
                        :autoplay="{
            delay: 0,
            disableOnInteraction: false,
          }"
                        :lazy="true"
                        :breakpoints="{
                  768: {
                    slidesPerView: 3,
                  },
                  300: {
                     slidesPerView: 1.45,
                  }
                }"
                >
                    <swiper-slide
                            v-for="(galleryItem2, index) in dataInfo.gallery2"
                            :key="galleryItem2.src"
                    >
                        <div class="gdaib_img" @click="showModalGalFun(dataInfo.gallery2, index)">
                            <img :src="getImage(galleryItem2, 'big')" loading="lazy" alt="">
                        </div>
                    </swiper-slide>
                </swiper>

                <h3 class="div_tit_swiper" v-if="dataInfo.text3?.value">{{ dataInfo.text3.value }}</h3>
                <swiper
                    v-if="dataInfo.gallery3 && dataInfo.gallery3.length > 0"
                    :modules="modules"
                    :slides-per-view="'auto'"
                    class="slide100h sample-slider arrow_center arrow_center2"
                    :loop="true"
                    :spaceBetween="24"
                    :speed="4000"
                    :autoplay="{
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: true,
          }"
                    :lazy="true"
                    :breakpoints="{
                  768: {
                    slidesPerView: 3,
                  },
                  300: {
                     slidesPerView: 1.45,
                  }
                }"
                >
                    <swiper-slide
                        v-for="(gallery3, index) in dataInfo.gallery3"
                        :key="gallery3.src"
                    >
                        <div class="gdaib_img" @click="showModalGalFun(dataInfo.gallery3, index)">
                            <img :src="getImage(gallery3, 'hd')" loading="lazy" alt="">
                        </div>
                    </swiper-slide>
                </swiper>

                <modal-gallery :dataGallery="dataGallery" :initialSlide="initialSlide" v-modal="showModalGal" :modelValue="showModalGal"/>

            </div>
        </container>

    </div>

</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay, Mousewheel, Navigation} from "swiper";
import ModalGallery from "@/components/modalGallery.vue";

export default {
  name: "GallaryCaruselEn",
  components: {
    ModalGallery,
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      dataGallery: false,
      initialSlide: 0,
      showModalGal: false
    }
  },
  methods: {
    showModalGalFun(dataGallery, initialSlide){
      this.dataGallery = dataGallery;
      this.initialSlide = initialSlide;
      this.showModalGal = true;
    }
  },
  setup() {
    /*const autoplayStop = (swiper) => {
      swiper.target.swiper.autoplay.stop();
    };
    const autoplayStart = (swiper) => {
      swiper.target.swiper.params.autoplay.delay = 0;
      swiper.target.swiper.params.autoplay.disableOnInteraction = false;
      swiper.target.swiper.autoplay.start();

    };*/
    return {
      /* autoplayStop,
       autoplayStart,*/
      modules: [Autoplay, Mousewheel, Navigation],
    }
  },
}
</script>

<style lang="scss" scoped>
.gdaib {
  padding: 64px 0;
  &_text {
    margin-bottom: 48px;
  }

  .div_slide {
    margin-bottom: 48px;
    .swiper {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .div_info {
    .div_tit {
      margin-bottom: 16px;
    }

    .div_text {
      margin-bottom: 16px;
    }
  }
  :deep(.swiper-slide){
    width: 386px;
  }
  .gdaib_img {
    height: 248px;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.div_tit_swiper{
    margin-bottom: 1.6rem;
    text-transform: uppercase;
}

@media (max-width: 991px) {
  .arrow_center2{
    padding-top: 1.6rem;
    padding-bottom: 2.6rem;
  }
  h2 {
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .div_tit_swiper {
    font-size: 2.4rem;
    margin-bottom: 0;
  }
  .gdaib {
    padding-bottom: 4.6rem;
    .gdaib_img {
      height: 14.4rem;
    }
    .div_slide {
      margin-bottom: .8rem;
      .swiper {
        margin-bottom: .8rem;
      }
    }
  }
}
@media (max-width: 767px) {
  .arrow_center2{
    padding-top: 1.6rem;
  }
  h2 {
    font-size: 2.4rem;
  }
  .div_tit_swiper {
    font-size: 1.8rem;
  }
}
</style>