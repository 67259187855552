<template>
  <header class="site_header" ref="header" id="site_header" :class="{'is-hide': navbarHide }">
    <div id="site_header_container" class="site_header_container">
      <div class="bcgr_header_balck">
        <container>
          <div class="site_header_pos1">
            <router-link :to="dataLogo.url ? dataLogo.url.value : '/'" class="logo site_header_td1">
              <img v-if="dataLogo.src" :src="getImage(dataLogo.src, 'fullHd')" alt="">
              <div class="logo_text" v-if="dataLogo.name">{{dataLogo.name.value}}</div>
            </router-link>
            <div class="site_header_td2" id="site_header_td2"></div>
            <div class="site_header_td3" id="site_header_td3"></div>
          </div>
        </container>
      </div>
      <container>
        <div class="site_header_pos2" id="site_header_td4">

        </div>
      </container>
    </div>
  </header>
</template>

<script>

export default {
  name: "Header",
  props: {
    dataLogo: {
      type: Object,
      require: true
    },
  },
  data(){
    return {
      navbarHide: false,
    }
  },
  methods: {
    navbarHideHandler() {
      let scrollPrev = 1
      window.addEventListener("scroll", () => {
        this.pageYOffset = window.pageYOffset
        this.navbarHide = this.pageYOffset > scrollPrev && window.pageYOffset >= 1;
        scrollPrev = window.pageYOffset;
      })
    },
  },
  mounted() {
    this.navbarHideHandler();
  }
}
</script>

<style lang="scss" scoped>
.site_header {
  background-color: #000106;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  transition: transform .3s ease-in-out;
  &[fon="1"] {
    background: #000106;
    backdrop-filter: blur(6px);
  }
  &.not_menu {
    .site_header_pos2 {
      display: none;
    }
  }
  .site_header_container{
    display: flex;
    flex-direction: column;
  }
  .bcgr_header_balck{
    background-color: #000106;
    .site_header_pos1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
    }
  }
  .site_header_td1 {
    flex: none;
    margin-right: 50px;
    display: flex;
    align-items: center;
    img {
      height: 24px;
      flex: none;
      margin-right: 8px;
    }
  }
  &.is-hide {
    transform: translateY(-100%);
  }
  @media (max-width: 991px) {
    .site_header_td1 {
      margin-right: 20px;
    }
  }
}
.site_header_td2{
  width: 100%;
}
.site_header_pos2 {
  display: flex;
  justify-content: center;
}

.site_header_td3{
  display: flex;
  align-items: center;
  gap: 16px;
}

@media (max-width: 991px) {
  .logo_text{
    display: none;
  }
}
</style>