<template>
  <container>
    <div class="step_start" v-if="step == 0">
      <row>
        <column lg="6">
          <div class="dt1">
            <h2 class="fs2 step_tit" v-if="dataInfo.title?.value">{{ dataInfo.title.value }}</h2>
            <div class="step_desc fs4" v-if="dataInfo.description?.value" v-html="dataInfo.description.value"></div>
            <cl-button v-if="dataInfo.btnText?.value" @click="setSession">{{ dataInfo.btnText.value }}</cl-button>
          </div>
        </column>
        <column lg="6">
          <div class="dt2 step_dt2">
            <img :src="getImage(dataInfo.image)" alt="">
          </div>
        </column>
      </row>
    </div>
    <div class="step_two" v-else-if="step == 1">
      <h2 class="title">В качестве кого вы хотите пройти квиз?</h2>
      <row v-if="dataInfo.quiz?.difficults?.length > 0">
        <column md="6" v-for="item in dataInfo.quiz.difficults">
          <div class="div_btn" @click="setDifficult(item.id)">
            <div class="img">
              <img :src="item.image.url" alt="">
            </div>
            <div class="div_text fs4" v-if="item.difficult">
              {{ item.difficult }}
            </div>
          </div>
        </column>
      </row>
    </div>
    <div class="step_three" v-else-if="step == 2">
      <row>
        <column md="6">
          <div class="response_line">
              <span
                  v-for="item in question?.questionsCount"
                  class="line"
                  :class="item - 1 == question?.answerArray.length ? 'active' : item - 1 > question?.answerArray.length ? '' : question?.answerArray[item - 1] ? 'success' : 'error'"></span>
          </div>
          <h2 class="title fs3" v-if="question?.question">{{ question.question }}</h2>
          <div class="answer_options" v-if="question?.answer?.length > 0">
            <div v-for="item in question?.answer" class="option" @click='!this.questionParams.answer ? getQuestion(item.id) : ""' :class='getClassAnswer(item.id)'><div class="val">{{ item.answer }}</div> <div class="true"><img src="/img/ico/true-circle.svg" alt="">Верно!</div><div class="false"><img src="/img/ico/false-circle.svg" alt="">Не верно</div></div>
          </div>
          <div class="answer_info" v-if="this.questionParams.answer && this.nextQuestion?.description" v-html="this.nextQuestion.description"></div>
          <cl-button v-if="this.questionParams.answer" @click="nextQuestions">Следующий вопрос</cl-button>
        </column>
        <column md="6">
          <div class="dt2 step_dt2 mt12">
            <img v-if="question?.image" :src="question.image" alt="">
          </div>
        </column>
      </row>
    </div>
    <div class="step_four" v-else-if="step == 3 && this.question?.quizResult">
      <row>
        <column md="6">
          <div class="dt1">
            <h3 class="title" v-if="this.question?.quizResult?.header" v-html="this.question.quizResult.header"></h3>
            <div class="description" v-if="this.question?.quizResult?.description" v-html="this.question.quizResult.description"></div>
            <div class="div_buttons">
              <cl-button type="default" class="btn-middle" @click="resetParams">На главную</cl-button>
<!--              <cl-button type="light" class="sharer button btn-middle btn-ico btn-ico-right" img="/img/ico/share.svg" data-sharer="twitter" data-title="title" v-sharer>Поделиться</cl-button>-->
            </div>
          </div>
        </column>
        <column md="6">
          <div class="dt2 step_dt2" v-if="this.question?.quizResult?.image?.url">
            <img :src="this.question.quizResult.image.url" alt="">
          </div>
        </column>
      </row>
    </div>
  </container>
</template>

<script>

/**
 * step - этап на котором сейчас находится пользователь (0 - приветственный 1 - выбор сложности 2 - опросник 3 - вывод результата)
 * trueResponse - правильный ответ
 * questionParams - параметры передаваеваемые в api для получения вопросов (session - текущая сессия пользователя, difficult - сложность, answer - ответ пользователя, again - передать в параметр "1" при первой отправке запроса)
 * nameQuiz - наименование для cookie
 * nameDifficult - наименование для cookie
 * nameIdResponse - наименование для cookie
 * question - параметры текущего вопроса с ответами
 * nextQuestion - параметры следующего вопроса, с параметром правильного ответа на предыдущий
 */

export default {
  name: "quiz",
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  data() {
    return{
      step: 0,
      trueResponse: null,
      questionParams: {},
      nameQuiz: this.dataInfo.quiz?.header ? "quiz" + this.dataInfo.quiz.header : "quiz",
      nameDifficult: this.dataInfo.quiz?.header ? "difficult" + this.dataInfo.quiz.header : "difficult",
      nameIdResponse: this.dataInfo.quiz?.header ? "IdResponse" + this.dataInfo.quiz.header : "IdResponse",
      question: null,
      nextQuestion: null,
      isLoad: null,
    }
  },
  methods: {
    setSession(){
      let randomNum;
      randomNum = Math.random() * 1000000000000000000;
      var expires = (new Date(Date.now() + 24 * 60 * 60 * 1000)).toUTCString();
      document.cookie = `${this.nameQuiz}=${randomNum}; expires=${expires}; path=/;`;
      this.questionParams.session = randomNum;
      this.step++;
    },
    getCookie() {
      return document.cookie.split('; ').reduce((acc, item) => {
        const [name, value] = item.split('=')
        acc[name] = value
        return acc
      }, {})
    },
    setDifficult(guid){
      var expires = (new Date(Date.now() + 24 * 60 * 60 * 1000)).toUTCString();
      document.cookie = `${this.nameDifficult}=${guid}; expires=${expires}; path=/;`;
      this.questionParams.difficult = guid;
      this.step++;
      this.getQuestion();
    },
    setResponseId(id){
      var expires = (new Date(Date.now() + 24 * 60 * 60 * 1000)).toUTCString();
      document.cookie = `${this.nameIdResponse}=${id}; expires=${expires}; path=/;`;
    },
    getQuestion(answerId){
      if(this.nextQuestion?.description){
        this.nextQuestion.description = "";
      }
      if(answerId && !this.questionParams.answer){
        this.questionParams.answer = answerId;
        this.setResponseId(answerId);
      }
      if(!this.isLoad){
        this.questionParams.again = 1;
      }
      this.axios.get("/api/data/quiz", { params: this.questionParams })
          .then(response => {
            if (!this.question){
              this.question = response.data;
              this.questionParams.answer = null;
              this.trueResponse = null;
            } else {
              this.nextQuestion = response.data;
              if(response?.data?.rightAnswer){
                this.trueResponse = response?.data?.rightAnswer;
              }
            }
            if (!this.isLoad && this.question?.quizResult){
              this.step++;
            }
            delete this.questionParams.again;
            this.isLoad = true;
          });
    },
    nextQuestions(){
      this.questionParams.answer = null;
      this.trueResponse = null;
      this.question = this.nextQuestion;
      if(this.question?.quizResult){
        this.step++;
      }
    },
    resetParams(){
      this.deleteCookie(this.nameQuiz);
      this.deleteCookie(this.nameDifficult);
      this.deleteCookie(this.nameIdResponse);
      this.question = null;
      this.step = 0;
    },
    deleteCookie(name){
      var expires = (new Date(Date.now() - 100)).toUTCString();
      document.cookie = `${name}=0; expires=${expires}; path=/;`;
    },
    getClassAnswer(id){
      let classes = "";
      if(this.trueResponse && this.questionParams.answer){
        if(id == this.questionParams.answer){
          classes += "active"
        }
        if(id == this.trueResponse){
          classes += " success";
        } else {
          classes += " error";
        }
      }
      return classes;
    },
  },
  mounted() {
    if(document.cookie.indexOf(this.nameQuiz) != -1) {
      this.questionParams.session = this.getCookie()[this.nameQuiz];
      this.step++;
      if(document.cookie.indexOf(this.nameDifficult) != -1){
        this.questionParams.difficult = this.getCookie()[this.nameDifficult];
        this.step++;
        if(document.cookie.indexOf(this.nameIdResponse) != -1){
          this.getQuestion(this.getCookie()[this.nameIdResponse]);
        } else {
          this.getQuestion();
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.step_start{
  padding: 10rem 0;
  .dt1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 3.2rem;
    height: 100%;
  }
  .step_tit, .step_desc{
    margin-bottom: 1.6rem;
  }
  @media (max-width: 991px) {
    padding: 6.8rem 0;
  }
  @media (max-width: 767px) {
    padding: 4.4rem 0;
  }
}
.step_two{
  padding: 10rem 0;
  .div_btn{
    width: 100%;
    height: 100%;
    background-color: #17191C;
    border: 1px solid #2E3138;
    cursor: pointer;
    .img{
      height: 29rem;
      display: flex;
      justify-content: center;
      max-width: 100%;
      margin: 2rem 0;
      img{
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }
    .div_text{
      padding: 1.6rem;
    }
  }
}
.step_three{
  padding: 6.4rem 0;
  .response_line{
    display: flex;
    width: 100%;
    gap: 1.6rem;
    padding: 3.2rem 0 1.6rem;
    .line{
      display: inline-block;
      height: 0.8rem;
      width: 100%;
      background-color: #2E3138;
      border-radius: 0.8rem;
      &.error{
        background-color: #F43F5E;
      }
      &.success{
        background-color: #10B981;
      }
      &.active{
        background-color: #FFF;
      }
      &.common{
        background-color: #ED850A;
      }
    }
  }
  .title{
    margin-bottom: 1.6rem;

  }
  .answer_options{
    margin-bottom: 1.6rem;
    .option{
      display: flex;
      align-items: center;
      gap: 1.2rem;
      padding: 0.8rem 1.2rem;
      cursor: pointer;
      &:not(:last-child){
        border-bottom: 1px solid #000106;
      }
      &:before{
        content: '';
        width: 2rem;
        height: 2rem;
        min-width: 2rem;
        border-radius: 50%;
        border: 1px solid #ED850A;
      }
      &.active{
        &:before{
          background-color: #fff;
          border-width: 0.5rem;
        }
      }
      .true{
        display: none;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;
        letter-spacing: 0.036rem;
        color: #10B981;
        align-items: center;
        gap: 1.2rem;
      }
      .false{
        display: none;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;
        letter-spacing: 0.036rem;
        color: #F43F5E;
        align-items: center;
        gap: 1.2rem;
      }
      .true, .false{
        white-space: nowrap;
        min-width: fit-content;
      }
      &.success{
        background-color: #10B9811A;
        .true{
          display: flex;
        }
      }
      &.error{
        background-color: #F43F5E1A;
        color: #F43F5E;
        &.active{
          .false{
            display: flex;
          }
        }
      }
      .val{
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;
        letter-spacing: 0.036rem;
        flex-grow: 1;
      }
      @media (max-width: 991px){
        .val, .true, .false{
          font-size: 2rem;
          line-height: 120%;
        }
      }
      @media (max-width: 767px) {
        .val, .true, .false{
          font-size: 1.6rem;
        }
      }
    }
  }
}
.answer_info{
  color: #ACB0B9;
  margin-bottom: 1.6rem;
}

.step_four{
  padding: 15rem 0;
  .dt1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .title{
    margin-bottom: 2.4rem;
    text-transform: uppercase;
  }
  .description{
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%;
    letter-spacing: 0.036rem;
    margin-bottom: 2.4rem;
    @media (max-width: 991px){
      font-size: 2rem;
      line-height: 120%;
    }
    @media (max-width: 767px) {
      font-size: 1.6rem;
    }
  }
  .div_buttons{
    display: flex;
    gap: 1.6rem;
  }
  @media (max-width: 991px) {
    padding: 10rem 0;
  }
  @media (max-width: 767px) {
    padding: 4.4rem 0;
  }
}

.step_dt2{
  height: 42rem;
  max-width: 100%;
  .mt12{
    margin-top: 12rem;
  }
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  @media (max-width: 991px){
    height: auto;
    max-height: 42rem;
  }
}
</style>