import {mapGetters} from "vuex";

export default {
    methods: {
        addProductCart(item) {
            this.$store.commit('updateShoppingCart', [...this.shoppingCart, item])
        },
        //allDelite - удалить все, при удаление 1 false
        removeProductCart(item, allDelite = true) {
            let deliteItem = false;
            this.$store.commit('updateShoppingCart', [...this.shoppingCart].filter(el => {
                if(allDelite){
                    return el.guid != item.guid;
                } else {
                    if((el.guid != item.guid) || deliteItem){
                        return true
                    } else {
                        deliteItem = true;
                        return false;
                    }
                }

            }))
        },
        // проверка на присутствие в коризне и вывод количества если есть
        btnAddShow(item) {
            //return [...this.shoppingCart].indexOf(item) != -1;
            const shoppingCartFilter = [...this.shoppingCart].filter(el => el.guid == item.guid);
            if (shoppingCartFilter.length <= 0) {
                return false
            } else {
                return shoppingCartFilter.length;
            }
        },
        // добавление
    },
    watch: {
        shoppingCart() {
            localStorage.setItem('shoppingCart', JSON.stringify([...this.shoppingCart]));
        }
    },
    computed: {
        ...mapGetters([
            'shoppingCart'
        ])
    }
}