<template>
  <div class="block_wrap list_cards_develop">
    <container>
      <row v-if="dataInfo.listCard && dataInfo.listCard.length > 0">
        <column md="4"
                v-for="(item, index) in dataInfo.listCard"
                :key="item"
        >
          <h2 v-if="dataInfo.title && index == 1">{{ dataInfo.title.value }}</h2>
          <div class="card_develop"
               :style="{background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 54.16%, #000 83.85%), linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${getImage(item.image, 'fullHd')}), lightgray 50% / cover no-repeat`}"
          >
            <div class="div_period fs21" v-if="item.period">{{ item.period.value }}</div>
            <h3 class="div_tit" v-if="item.title">{{ item.title.value }}</h3>
            <div class="div_text" v-if="item.text" v-html="item.text.value"></div>
          </div>
        </column>
      </row>

    </container>
  </div>
</template>

<script>
export default {
  name: "listCardsDevelop",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.list_cards_develop {
  padding: 113px 0 134px 0;
  h2 {
    margin-bottom: 24px;
    text-align: center;
  }
}
.card_develop {
  display: flex;
  flex-direction: column;
  height: 744px;
  padding: 8px;
  justify-content: flex-end;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center top;
  > * {
    transition: all .3s ease;
  }
  &:hover {
    //padding-bottom: 64px;
    > * {
      transform: translateY(-56px);
    }
    .div_text {
      height: auto;
      opacity: 1;
    }
  }
  .div_period {
    color: #ED850A;
    font-weight: 500;
  }
  .div_tit {
    margin-bottom: 0;
  }
  .div_text {
    padding-top: 13px;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: all .3s ease;
  }
}
</style>