<template>
  <div class="excursions-cinema">
    <container>
      <div class="div_cont">
        <h2 class="div_tit" v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
        <div class="div_tit_img" v-if="dataInfo.titleImg || dataInfo.titleImg || dataInfo.title2">
          <img :src="getImage(dataInfo.titleImg)" alt="" v-if="dataInfo.titleImg">
          <template v-else-if="dataInfo.title2">{{ dataInfo.title2.value }}</template>
        </div>
        <div class="div_text" v-if="dataInfo.text" v-html="dataInfo.text.value"></div>
        <div class="div_btn" v-if="dataInfo.btnUrl && dataInfo.btnText">
          <cl-button link :href="dataInfo.btnUrl.value" target="_blank">{{ dataInfo.btnText.value }}</cl-button>
        </div>
        <div class="div_img" v-if="dataInfo.img"><img :src="getImage(dataInfo.img)" alt=""></div>
      </div>
    </container>
  </div>
</template>

<script>
export default {
  name: "excursionsCinema",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.excursions-cinema {
  padding: 11.5rem 0;
  overflow: hidden;
  .div_cont {
    max-width: 74rem;
    position: relative;
  }
  .div_tit {
    font-size: 6.4rem;
    font-weight: 500;
    line-height: 7.2rem;
    letter-spacing: 0.02em;
    margin-bottom: .8rem;
  }
  .div_tit_img {
    margin-bottom: 1.6rem;
    color: #ED850A;
    font-size: 7rem;
    font-weight: 500;
    img {
      max-width: 100%;
    }
  }
  .div_text {
    margin-bottom: 2.4rem;
    :deep(ul:not([class])) {
      li {
        margin: 0;
        &:before {
          left: 12px;
          width: 3px;
          height: 3px;
          background-color: #fff;
          border-radius: 100%;
        }
      }
    }
  }
  .div_btn {}
  .div_img {
    position: absolute;
    right: -2.4rem;
    top: 0;
    transform: translateX(100%);
    img {
      max-width: 86.1rem;
    }
    @media (max-width: 1100px) {
      right: -6.4rem;
    }
  }
  @media (max-width: 991px) {
    padding: 30px 0;
    .div_cont {
      max-width: 100%;
      display: flex;
      flex-direction: column;
    }
    .div_tit {
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
    }
    .div_tit_img {
      font-size: 50px;
    }
    .div_text {
      font-size: 18px;
    }
    .div_img {
      order: -1;
      position: relative;
      right: auto;
      top: 0;
      transform: none;
      max-width: 771px;
      img {
        max-width:100%;
      }
    }
  }
  @media (max-width: 767px) {
    .div_tit_img {
      img {
        height: 54px;
      }
    }
  }
}
</style>