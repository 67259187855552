export default {
    methods: {
        getImage(logo, size="reference"){
            //logo можно передавать массивом, 1- десктоп 2- планшет 3- мобила
            if(logo && logo.length > 0){
                if(window.innerWidth < 768 && logo[2]?.value){
                    logo = logo[2];
                    size = "small";
                } else if (window.innerWidth >= 768 && window.innerWidth < 991 && logo[1]?.value) {
                    logo = logo[1];
                    size = "middle";
                } else if(logo[0]?.value) {
                    logo = logo[0];
                }
            }
            if(logo?.value){
                return typeof logo.value.src == 'string' ?
                    logo.value?.src :
                    logo.value?.src?.[size] ?
                        logo.value?.src?.[size]?.url:
                        undefined;
            }
            if(logo?.src){
                return typeof logo.src == 'string' ?
                    logo.src :
                    logo.src?.[size] ?
                        logo.src?.[size]?.url:
                        undefined;
            }
        },
        getBkgrImage(logo, size="reference"){
            //logo можно передавать массивом, 1- десктоп 2- планшет 3- мобила
            if(logo && logo.length > 0){
                if(window.innerWidth < 768 && logo[2]?.value){
                    logo = logo[2];
                    size = "small";
                } else if (window.innerWidth >= 768 && window.innerWidth < 991 && logo[1]?.value) {
                    logo = logo[1];
                    size = "middle";
                } else if(logo[0]?.value) {
                    logo = logo[0];
                }
            }
            if(logo?.value){
                return typeof logo.value.src == 'string' ?
                    { backgroundImage: `url(${logo.value?.src})` } :
                    logo.value?.src?.[size] ?
                        { backgroundImage: `url(${logo.value?.src?.[size]?.url})` } :
                        '';
            }else{
                return '';
            }
        }
    }
}