<template>
    <teleport to="#site_header_container" v-if="!isLoadingFilter">
        <div class="pos_rel">
            <div class="filter_header">
                <container>
                    <loader
                            v-if="isLoadingFilter"
                            :animation-duration="3000"
                            :size="60"
                    ></loader>
                    <div
                            v-else
                            class="filter_header_flex">

                        <div class="td1"
                             :class="{'td_lite': showInput && widthSite > 991, 'td_lite2': (Object.keys(getParams).length - (filter.selectSelectedFilmType != '' ? 1 : 0) - (filter.nameSearch != '' ? 1 : 0) - (getParams.page ? 1 : 0)) > 0 }"
                             v-if="filter.selectOptionsFilmType.length > 0">
                            <div ref="prev" class="swiper-button-prev"></div>
                            <div ref="next" class="swiper-button-next"></div>
                            <swiper
                                    :modules="modules"
                                    :slides-per-view="'auto'"
                                    :spaceBetween="12"
                                    :navigation="{
                    prevEl: prev,
                    nextEl: next,
                  }"
                                    class="filter_header_slide slide_auto fs16 fw500"
                            >
                                <!--                :loop="filter.selectOptionsFilmType.length > 4 ? true : false"-->
                                <swiper-slide
                                        v-for="filmTypeItem in filter.selectOptionsFilmType"
                                        :key="filmTypeItem.id">
                                    <div class="filter_header_slide_item"
                                         @click="filmTypeFun(filmTypeItem)"
                                         :class="{'active': filter.selectSelectedFilmType == filmTypeItem}">
                                        <div class="div_img">
                                            <img :src="filmTypeItem.image ? filmTypeItem.image : '/img/ico/ico24_filmTypeItem.svg'" alt="">
                                        </div>
                                        <div class="div_tit" v-if="filmTypeItem.name">{{ filmTypeItem.name }}</div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <div class="td2">
                            <input-search
                                    v-model="filter.nameSearch"
                                    @search="findByText"
                                    @showInputFun="showInputFun"
                                    placeholder="Название"
                                    view="top"
                                    id="topInputFilter"
                            />
                        </div>
                        <div class="td3">
                            <cl-button type="light3" class="btn-middle btn-ico-right"
                                       :class="{'btn-text-none': widthSite <= 991}"
                                       img="/img/ico/sliders-horizontal.svg"
                                       @click="btnShowFilter">Фильтры
                                <template v-slot:after>
                                    <div class="circle_or"
                                         v-if="(Object.keys(getParams).length - (filter.selectSelectedFilmType != '' ? 1 : 0) - (filter.nameSearch != '' ? 1 : 0) - (getParams.page ? 1 : 0)) > 0">
                                        {{
                                        Object.keys(getParams).length - (filter.selectSelectedFilmType != '' ? 1 : 0) - (filter.nameSearch != '' ? 1 : 0) - (getParams.page ? 1 : 0)
                                        }}
                                    </div>
                                </template>
                            </cl-button>
                        </div>
                    </div>
                </container>
            </div>
        </div>
    </teleport>

    <cl-modal v-model="modalFilterShow" modal-type="center" class="filter_modal modal-scroll">
        <template v-slot:header>
            <div class="text-center">Фильтры</div>
        </template>
        <template v-slot:body>
            <perfect-scrollbar id="perfectUpdate">
                <div class="filter_cont" v-if="filter.filterAll">
                    <row class="filter_cont_row2">
                        <column md="12" class="filter_cont_col1">
                            <div class="filter_tit fs4">Уровень образования</div>
                            <row class="filter_cont_row2">
                                <column md="6" class="align-self-center fw500"
                                        v-for="itemCheckbox in filter.selectOptionsLevel"
                                >
                                    <div class='fake_checkbox cursor-pointer'
                                         @click="selectChangeParams(itemCheckbox.id, 'selectSelectedLevel', 'level')"
                                         :class="{'checked': filter.selectSelectedLevel.indexOf(itemCheckbox.id) != -1}"
                                    >{{ itemCheckbox.name }}
                                    </div>
                                </column>
                            </row>
                            <cl-button class="btn-lite"
                                       @click="chooseAll(filter.selectOptionsLevel, 'selectSelectedLevel', 'level')"
                                       v-if="btnChooseAll(filter.selectOptionsLevel, filter.selectSelectedLevel)"
                            >Выбрать все
                            </cl-button>
                            <cl-button type="lite" class="btn-light2"
                                       @click="cancelAll('selectSelectedLevel', 'level')"
                                       v-else
                            >Отменить все
                            </cl-button>
                        </column>
                    </row>
                    <row class="filter_cont_row2">
                        <column md="12" class="filter_cont_col1">
                            <div class="filter_tit fs4">Форма обучения</div>
                            <row class="filter_cont_row2">
                                <column md="6" class="align-self-center fw500"
                                        v-for="itemCheckbox in filter.selectOptionsForm"
                                >
                                    <div class='fake_checkbox cursor-pointer'
                                         @click="selectChangeParams(itemCheckbox.id, 'selectSelectedForm', 'forms')"
                                         :class="{'checked': filter.selectSelectedForm.indexOf(itemCheckbox.id) != -1}"
                                    >{{ itemCheckbox.name }}
                                    </div>
                                </column>
                            </row>
                            <cl-button class="btn-lite"
                                       @click="chooseAll(filter.selectOptionsForm, 'selectSelectedForm', 'forms')"
                                       v-if="btnChooseAll(filter.selectOptionsForm, filter.selectSelectedForm)"
                            >Выбрать все
                            </cl-button>
                            <cl-button type="lite" class="btn-light2"
                                       @click="cancelAll('selectSelectedForm', 'forms')"
                                       v-else
                            >Отменить все
                            </cl-button>
                        </column>
                    </row>
                    <row class="filter_cont_row1">
                        <column md="12" class="filter_cont_col1">
                            <div class="filter_tit fs4">Образовательное учреждение</div>
                            <row class="filter_cont_row2">
                                <column md="6" class="align-self-center fw500"
                                        v-for="itemCheckbox in filter.selectOptionsCinemaSchool.records"
                                >
                                    <div class='fake_checkbox cursor-pointer'
                                         @click="selectChangeParams(itemCheckbox.guid, 'selectSelectedCinemaSchool', 'guidSchool')"
                                         :class="{'checked': filter.selectSelectedCinemaSchool.indexOf(itemCheckbox.guid) != -1}"
                                    >{{ itemCheckbox.fullName }}
                                    </div>
                                </column>
                            </row>
                            <cl-button class="btn-lite"
                                       @click="chooseAll(filter.selectOptionsCinemaSchool.records, 'selectSelectedCinemaSchool', 'guidSchool', 'guid')"
                                       v-if="btnChooseAll(filter.selectOptionsCinemaSchool.records, filter.selectSelectedCinemaSchool)"
                            >Выбрать все
                            </cl-button>
                            <cl-button type="lite" class="btn-light2"
                                       @click="cancelAll('selectSelectedCinemaSchool', 'guidSchool')"
                                       v-else
                            >Отменить все
                            </cl-button>
                        </column>
                    </row>
                </div>
            </perfect-scrollbar>
        </template>
        <template v-slot:footer>
            <cl-button type="light" class="btn-middle color_or" @click="deleteParams">Очистить <span
                    class="d-none d-md-inline">все</span></cl-button>
            <cl-button class="btn-middle" @click="searchRent">Показать <span class="d-none d-md-inline">программы</span>
            </cl-button>
        </template>
    </cl-modal>


</template>

<script>
/*
* deleteParamsStatus - сбрасывает фильтры, нужно для активации сброса вне компонента
* */
import translates from '@/assets/js/resources/translate/rent';
import {ref} from 'vue';
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import {mapGetters} from "vuex";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "mosSchoolTopFilter",
  components: {Swiper, SwiperSlide},
  props: {
    deleteParamsStatus: {
      type: Boolean
    },
  },
  data() {
    return {
      showInput: false,
      modalFilterShow: false,

      getParams: {},
      getParamsFilter: {
        type: 'pavilionsAll'
      },
      controller: null,

      ps: null,

      isLoadingFilter: true,
      filter: {
        filterAll: false,

        areaFilter: [],
        selectOptionsCategory: [],
        selectSelectedCinemaSchool: [],
        selectOptionsCinemaSchool: [],
        selectSelectedLevel: [],
        selectOptionsLevel: [],
        selectSelectedForm: [],
        selectOptionsForm: [],

        nameSearch: "",
        selectSelectedFilmType: "",
        selectOptionsFilmType: [],

        ceilingHeight: [],
        ceilingHeightTrusses: [],
        length: [],
        width: [],
        powerElectricity: [],

        technical: [],
        service: [],
        comfort: [],
        checkboxAll: [],
      },
    }
  },
  methods: {
    //передаем параметры на страницу со списком
    emitParamsList() {
      this.$emit("searchFun", this.getParams);
    },
    getFilter() {
      this.isLoadingFilter = true;
      this.getParamsFilter.pageSize = 99;
      return this.axios
        .get(`/api/data/filters/cinemaSchool`, {params: this.getParamsFilter, signal: this.controller.signal})
        .then(response => {
          this.filter.filterAll = {...response.data};
          delete this.filter.filterAll['districtFilter'];

          this.filter.selectOptionsCinemaSchool = response.data.cinemaSchool;
          this.filter.selectOptionsLevel = response.data.level;
          this.filter.selectOptionsForm = response.data.form;

          //делаем поиск если в адресе query
          if (Object.keys(this.$route.query).length > 0) {
            for (const [key, value] of Object.entries(this.$route.query)) {
              if (key == 'level') {
                this.filter.selectSelectedLevel = value.split(',');
                this.getParams.level = value;
              } else if (key == 'direction') {
                const typesItem = [...this.filter.selectOptionsFilmType].filter(item => item.id == value)[0];
                if (typesItem) {
                  this.filmTypeFun(typesItem, false);
                }
              } else if (key == 'name') {
                this.filter.nameSearch = value;
                this.findByText(value, false);
              } else if (key == 'guidSchool') {
                this.filter.selectSelectedCinemaSchool = value.split(',');
                this.getParams.guidSchool = value;
              }
              else if (key == 'forms') {
                this.filter.selectSelectedForm = value.split(',');
                this.getParams.forms = value;
              }
            }
            this.$nextTick(() => {
              setTimeout(() => {
                this.emitParamsList();
              }, 100);
            });
          }

          this.isLoadingFilter = false;
        })
        .catch(error => {
          this.showError(error);
        });
    },
    getFilterType() {
      return this.axios
        .get(`/api/data/cinemaschool-type`, {signal: this.controller.signal})
        .then(response => {
          this.filter.selectOptionsFilmType = response.data;
          this.getFilter();
        })
        .catch(error => {
          this.showError(error);
        });
    },
    translateWord(word) {
      return translates[word] ? translates[word] : 'Значение не найдено';
    },
    //поиск
    searchRent() {
      this.modalFilterShow = false;
      this.emitParamsList();
    },
    // очистка фильтров в модалке
    deleteParams(category = false, startSearch = true) {
      /*if (Object.keys(this.$route.query).length > 0) {
        history.pushState({}, '', this.$route.path);
      }*/

      this.getParams = {};
      this.filter.selectSelectedCinemaSchool = [];
      this.filter.selectSelectedLevel = [];
      this.filter.selectSelectedForm = [];

      this.filter.nameSearch = "";
      this.filter.selectSelectedFilmType = "";

      if(startSearch) {
        this.modalFilterShow = false;
        this.emitParamsList();
      }
    },
    // поле типов
    filmTypeFun(item, startSearch = true) {
      if (this.filter.selectSelectedFilmType != item) {
        this.filter.selectSelectedFilmType = item;
        this.getParams.direction = item.id;
      } else {
        this.filter.selectSelectedFilmType = "";
        delete this.getParams.direction;
      }
      if (startSearch) {
        this.emitParamsList();
      }
    },
    // поле поиска
    findByText(text, startSearch = true) {
      if (text != "") {
        this.getParams.name = text;
      } else {
        delete this.getParams.name;
      }
      if (startSearch) {
        this.emitParamsList();
      }
    },
    // разкрытие/закрытие поиска
    showInputFun(status) {
      this.showInput = status;
    },
    // метод выбора checkBox
    selectChangeParams(val, selected, param, eventType = 'click') {
      if (eventType == 'click') {
        if (this.filter[selected].indexOf(val) != -1) {
          this.filter[selected] = [...this.filter[selected]].filter(item => item != val);
        } else {
          this.filter[selected] = [...this.filter[selected], val];
        }
      }
      if (this.filter[selected].length < 1) {
        delete this.getParams[param];
      } else {
        this.getParams[param] = [...this.filter[selected]].join();
      }
    },
    // модалка табы
    changeTabEvent(key) {
      if (this.filter[key].length > 0) {
        if (key == "areaFilter") {
          this.getParams.newArea = this.filter[key].join();
        } else {
          this.getParams[key] = this.filter[key].join();
        }
      } else {
        if (key == "areaFilter") {
          delete this.getParams.newArea;
        } else {
          delete this.getParams[key];
        }
      }
    },
    // модалка табы кнопка не выбрано
    clearTab(key) {
      this.filter[key] = [];
      delete this.getParams[key];
      if (key == "areaFilter") {
        delete this.getParams.newArea;
      }
    },
    // модалка чекбоксы
    changeCheckbox(value) {
      if (this.filter.checkboxAll.indexOf(value) != -1) {
        this.filter.checkboxAll = [...this.filter.checkboxAll].filter(item => item != value);
      } else {
        this.filter.checkboxAll = [...this.filter.checkboxAll, value];
      }
      if (this.filter.checkboxAll.length < 1) {
        delete this.getParams.checkbox
      } else {
        this.getParams.checkbox = [...this.filter.checkboxAll].join();
      }
    },
    // модалка выбрать все
    chooseAll(options, selected, getParam, param = "id") {
        this.filter[selected] = [...options].map(item => item[param])
        this.selectChangeParams(null, selected, getParam, "input");
    },
    // отменить все
    cancelAll(selected, getParam) {
        this.filter[selected] = [];
        this.selectChangeParams(null, selected, getParam, "input");
    },
    // показывать кнопку выбрать все
    btnChooseAll(options, selected) {
        return options.length !== selected.length;
    },
    btnShowFilter(){
      this.modalFilterShow = true;
      setTimeout(() => {
        this.ps = new PerfectScrollbar(document.querySelector('#perfectUpdate'));
        const resizeObserver = new ResizeObserver(() => {
          this.ps.update();
        });
        resizeObserver.observe(document.querySelector('#perfectUpdate').querySelector(".filter_cont"))
      }, 200);
    },
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    document.querySelector('#site_header').setAttribute('fon', '1');
    this.getFilterType();
    //this.getFilter();
    //this.emitParamsList();
  },
  beforeUnmount() {
    document.querySelector('#site_header').setAttribute('fon', '');
    this.controller.abort();
    this.controller = null;
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  },
  watch: {
    deleteParamsStatus() {
      this.deleteParamsStatus ? this.deleteParams() : '';
    }
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
}
</script>

<style lang="scss">

</style>