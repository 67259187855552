<template>
  <template v-if="widthSite > widthMobEnd || widthSite < widthMobStart">
    <div>
      <slot :name="index - 1" v-for="index in Object.keys(this.$slots).length" :key="index"></slot>
    </div>
  </template>
  <template v-else>
    <swiper
        class="slide_m slide_pagination slide100h"
        :modules="modules"
        :slideToClickedSlide="true"
        :spaceBetween="20"
        :slidesPerView="slidesPerView"
        :pagination="{ clickable: true }"
    >
      <swiper-slide v-for="index in Object.keys(this.$slots).length" :key="index">
        <slot :name="index - 1"></slot>
      </swiper-slide>
    </swiper>
  </template>
</template>

<script>
import {mapGetters} from "vuex";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";


/**
* widthMob максимальное разрешение с какого скрывать галерею
*/
export default {
  name: "SliderMob",
  components: {
    Swiper, SwiperSlide
  },
  props: {
    widthMobStart: {
      type: String,
      default: 0
    },
    widthMobEnd: {
      type: String,
      required: true
    },
    slidesPerView: {
      type: String,
      default: 1,
    }
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
  },
  mounted() {
  }
}
</script>

<style lang="scss">

</style>