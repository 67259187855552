<template>
  <container class="events-top">
    <h1 class="fs2" v-if="dataInfo.title">{{ dataInfo.title.value }}</h1>
    <div class="events_filter">
      <filter-tabs
          v-if="filter.platforms"
          :tabActive="filter.platformsActive"
          :tabList="filter.platforms"
          @changeTab="changeTabEvent('platformsActive', 'platform')"
          label="name"
          tabKey="id"
          :tabSample="1"
          class="filter_tabs_48 filter_tabs_bor"
      ></filter-tabs>

      <calendar-carousel :clear="clearCalendar" @clear="clearEmit" @change="changeDay" :activeDates='getDays(dataListDay)'/>

      <div class="select_category">
        <multiselect
            v-if="filter.categories"
            class="orange_filter multiselect48"
            :class="{'select_active': filter.categoriesActive}"
            v-model="filter.categoriesActive"
            track-by="id"
            label="name"
            placeholder="Категории"
            :options="filter.categories"
            @select="selectChangeCategory"
            :searchable="false"
            :allow-empty="false"
        >
          <template v-slot:clear>
            <span v-if="filter.categoriesActive"
                  @click="clearCategory"
                  class="multiselect__clear img_wh"
            ><img src="/img/ico/close.svg"></span>
          </template>
        </multiselect>
      </div>


    </div>
    
    <div class="events_list">
      <h3>
        <template v-if="dataInfo.titleList && filter.platformsActive.length == 0">{{ dataInfo.titleList.value }}{{ ' ' }}</template>
        <template v-if="filter.platformsActive.length > 0">Мероприятия
          <template v-for="platformEl in [...filter.platforms].filter(item => filter.platformsActive.some(otherItem => otherItem === item.id))"
                    :key="platformEl.id"
          >
            {{ platformEl.code }}{{ ' ' }}
          </template>
        </template>
        <template v-if="filter.filterDate">{{ dateTitle }}</template>
      </h3>
      <loader
          v-if="isLoading"
          :animation-duration="3000"
          :size="60"
      ></loader>
      <not-info
          v-if="!isLoading && dataList?.length == 0"
          :title="!Object.keys(getParams).length == 0 ? `К сожалению, под ваши критерии не подходит ни одно мероприятие на выбранный период` : `Ничего не нашлось`"
          :text="!Object.keys(getParams).length == 0 ? `` : `Нет результатов, удовлетворяющих критериям поиска. Попробуйте сбросить фильтры`"
      >
        <template v-slot:btn v-if="Object.keys(getParams).length > 0">
          <div class="div_btn">
            <cl-button @click="deleteParams">Сбросить фильтры</cl-button>
          </div>
        </template>
      </not-info>
      <row v-if="!isLoading && dataList?.length > 0">
        <template v-for="(item, index) in dataList"
                  :key='item'
        >
          <column
              lg="3"
              md="6">
            <card card-type="event" :route-link="dataInfo.url?.value + item.guid">
              <div class="div_img" :class="{'not_img': !item.logo?.imageUrl}">
                <div class="div_tag">
                  <div class="div_tag_el div_tag_el_min" v-if="item.ageLimit || item.ageLimit === 0">{{ item.ageLimit }}+</div>
                  <div class="div_tag_el" v-if="item.isRegistrationInRpp">Требуется регистрация</div>
                </div>
                <img v-if="item.logo?.imageUrl" :src="item.logo.imageUrl" alt="">
              </div>
              <div class="div_info">
                <div class="div_tag2" v-if="item.types && item.types.length > 0"><span v-for="tag in item.types">{{ tag.name }}</span></div>
                <div class="div_tit fs5" v-if="item.title">{{ item.title }}</div>
                <div class="div_dat">
                  <div class="dt1">{{$filters.ruDate2(new Date(item.eventStartDate))}} <template v-if="item.startTime">{{ item.startTime.split(':')[0] + ':' + item.startTime.split(':')[1] }}</template></div>
                  <template v-if="item.regEventVenues && item.regEventVenues.length == 1">
                      <div class="dt2">
                        {{ item.regEventVenues[0].name }}
                      </div>
                  </template>
                  <template v-else>
                    <div class="dt2" v-if="item.platform?.name">{{ item.platform.name }}</div>
                  </template>
                </div>
                <div class="div_proj" v-if="item.regEventVenues">
                  <div class="dt"></div>
                  <div class="dt">
                    <template v-if="item.regEventVenues.length == 1">
                        {{ item.regEventVenues[0].address }}
                    </template>
                    <template v-else>
                      {{ item.regEventVenues.length }} {{ getPlural(item.regEventVenues.length, 'площадка', 'площадки', 'площадок') }}
                    </template>
                  </div>
                </div>
              </div>

            </card>
          </column>
          <column
              v-if="index == 4 && measureGuid"
              lg="3"
              md="6">
            <card card-type="event-form">
              <div class="div_img" v-if="dataInfo.bannersImage">
                <img :src="getImage(dataInfo.bannersImage)" alt="">
              </div>
              <div class="div_info">
                <div class="div_tit" v-if="dataInfo.bannersTitle">{{ dataInfo.bannersTitle.value }}</div>
                <div class="div_text" v-if="dataInfo.bannersText">{{ dataInfo.bannersText.value }}</div>
                <div class="div_btn"><cl-button class="btn-lite" v-if="dataInfo.bannerstextButton" @click="formShow = true">{{ dataInfo.bannerstextButton.value }}</cl-button></div>
              </div>
            </card>
          </column>
        </template>
      </row>
      <div v-if="(data?.page < data?.pages || isLoadingMore) && !isLoading" class="show_more">
        <loader
            v-if="isLoadingMore"
            :animation-duration="1500"
            :size="55"
            :color="'#ED850A'"
        ></loader>
        <cl-button v-else @click="loadMore" type="light sm-w-100">Показать еще</cl-button>
      </div>
    </div>

    <cl-modal v-model="formShow" class="modal_form center" :closeFonActive="false">
      <h4 class="modal-header" v-if="formTitle">
        {{ formTitle }}
      </h4>
      <measure-form-component
          v-if="formShowParams"
          :measureGuid="measureGuid"
          :showMode="showMode"
          :formParams="measureFormParams"
          :form-type="1"
          @formTitle="formTitleFun"
      />
    </cl-modal>
  </container>
</template>

<script>
import CalendarCarousel from "@/components/calendarCarousel.vue";
import MeasureFormComponent from "@/components/measureForm.vue";
import Column from "@/components/library/Column.vue";
import {clear} from "core-js/internals/task";

export default {
  name: "eventsList",
  components: {Column, MeasureFormComponent, CalendarCarousel},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return {
      isLoading: false,
      isLoadingMore: false,

      data: false,
      dataList: false,
      dataListDay: false,

      getParams: {
      },
      controller: null,
      
      filter: {
        platformsActive: [],
        platforms: false,
        categoriesActive: "",
        categories: false,
      },
      clearCalendar: false,


      formTitle: null,
      formShow: false,
      formShowParams: true,
      showMode: 'inline',
      measureFormParams: {
        preSetParams: {},
        //'appendCss': '/override/modal_wh.css',
      },
      measureGuid: this.dataInfo.bannersGuid?.value,
    }
  },
  methods: {
    getFilter(type, url) {
      return this.axios
          .get(url, {signal: this.controller.signal})
          .then(response => {
            this.filter[type] = response.data;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    getListDay() {
      let getParams = {
        dateFrom: this.dateFormat(new Date())
      };
      if(this.getParams.types){
        getParams.types = this.getParams.types;
      }
      if(this.getParams.platform){
        getParams.platform = this.getParams.platform;
      }
      return this.axios
          .get(`/api/data/cinema-events/calendars`, {params: getParams, signal: this.controller.signal})
          .then(response => {
            this.dataListDay = response.data;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    getEvents(isLoadMore) {
      // если есть query при первой загрузке дублировался поиск
      // if (Object.keys(this.$route.query).length > 0) {
      //   for (const [key, value] of Object.entries(this.$route.query)) {
      //     if (key == 'company' && !this.getParams.company) {
      //       return false;
      //     }
      //   }
      // }
      if (!isLoadMore) {
        delete this.getParams.page;
        this.isLoading = true;
      }

      return this.axios
          .get(`/api/data/cinema-events`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            // dateFrom

            if (isLoadMore) {
              this.dataList = [...this.dataList, ...response.data.records];
            } else {
              this.dataList = response.data.records;
            }
            this.data = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    loadMore() {
      this.isLoadingMore = true;
      this.getParams.page = ++this.data.page;
      this.$nextTick(() => {
        this.getEvents(true).then(() => {
          this.isLoadingMore = false
        })
      });
    },
    //возврашаем заголовок формы
    formTitleFun(name) {
      this.formTitle = name;
    },
    deleteParams(){
      this.getParams = {};
      this.filter.platformsActive = [];
      delete this.filter.filterDate;
      this.filter.categoriesActive = "";


      this.clearCalendar = true;
      this.getListDay();
      this.getEvents();
    },
    changeTabEvent(key, params){
      if (this.filter[key].length > 0) {
        this.getParams[params] = this.filter[key].join();
      } else {
        delete this.getParams[params];
      }
      this.getListDay();
      this.getEvents();
    },
    selectChangeCategory(){
      if(!this.filter.categoriesActive) {
        delete this.getParams.types
      } else {
        this.getParams.types = this.filter.categoriesActive.id;
      }
      this.getListDay();
      this.getEvents();
    },
    clearCategory(){
      this.filter.categoriesActive = '';
      this.selectChangeCategory();
    },
    // даные фильтра календаря
    changeDay(period){
      if(period && period.start){
        this.filter.filterDate = period;
        this.getParams.dateFrom = this.dateFormat(new Date(this.filter.filterDate.start));
        this.getParams.dateTo = this.dateFormat(new Date(this.filter.filterDate.end.toISOString()));
        this.getEvents();
      }else {
        if (this.getParams.dateFrom) {
          delete this.getParams.dateFrom;
        }
        if (this.getParams.dateTo) {
          delete this.getParams.dateTo;
        }
        if (this.filter.filterDate) {
          delete this.filter.filterDate;
        }
        this.getEvents();
      }
    },
    dateFormat(date){
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    getDays(daysArr) {
      const days = [];
      if (daysArr) {
        for (let key in daysArr) {
          days.push(new Date(daysArr[key]));
        }
      }
      return days;
    },
    clearEmit(data){
      if(data){
        this.clearCalendar = false;
      }
    },
    getPlural(number, one, two, many) {
      if (number % 10 === 1 && number % 100 !== 11) {
        return one;
      } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
        return two;
      } else {
        return many;
      }
    },
  },
  computed: {
    dateTitle(){
      let text = '';
      const dataStart = new Date(this.filter.filterDate.start),
            dataEnd = new Date(this.filter.filterDate.end),
            dataСurrent = new Date();
      dataStart.setHours(0, 0, 0, 0);
      dataEnd.setHours(0, 0, 0, 0);
      dataСurrent.setHours(0, 0, 0, 0);
      if(dataStart.getTime() == dataEnd.getTime()){
        text = this.$filters.ruDate4(dataStart)
      } else {
        if(dataStart.getFullYear() == dataСurrent.getFullYear() && dataEnd.getFullYear() == dataСurrent.getFullYear()){
          // if(dataStart.getMonth() == dataСurrent.getMonth() && dataEnd.getMonth() == dataСurrent.getMonth()){
          //   text = 'с ' + dataStart.getDate() + ' по ' + dataEnd.getDate() + ' ' + dataStart.toLocaleDateString("ru", {
          //     month: 'long',
          //     formatMatcher: 'basic'
          //   }).split(', ').reverse().join(', ');
          // } else {
          //   text = 'с ' + this.$filters.ruDate2(dataStart) + ' по ' + this.$filters.ruDate2(dataEnd)
          // }
          text = 'с ' + this.$filters.ruDate2(dataStart) + ' по ' + this.$filters.ruDate2(dataEnd)
        } else {
          text = 'с ' + this.$filters.ruDate3(dataStart) + ' по ' + this.$filters.ruDate3(dataEnd)
        }
      }
      return text;
    },
  },
  mounted() {
    this.getListDay();
    this.getFilter("platforms", "/api/data/poster-platforms");
    this.getFilter("categories", "/api/data/poster-event-categories");
    this.getEvents();
  },
  created() {
    this.controller = new AbortController();
  },
  beforeUnmount() {
    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style lang="scss" scoped>
.events-top {
  padding-top: 122px;
  h1 {
    margin-bottom: 24px;
  }
  .filter_tabs {
    gap: 1.2rem;
  }
}
.events_filter {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}
.events_list {
  h3 {
    margin-bottom: 24px;
    text-transform: uppercase;
  }
}
@media (min-width: 768px) {
  .select_category {
    max-width: 230px;
  }
}
.card-event-form {
  border: 1px solid #ED850A;
  text-align: center;
  background: none;
  padding: 0;
  backdrop-filter: none;
  height: 100%;
  .div_img {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .div_info {
    padding: 1.2rem;
  }
  .div_tit {
    margin-bottom: .8rem;
    font-size: 2.1rem;
  }
  .div_text {
    font-size: 1.6rem;
    margin-bottom: .8rem;
  }
  .btn {
    background: linear-gradient(90deg, #ED850A 2.48%, #FF6767 100%);
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

.calendar_swiper :deep(.calendar_day.not-active) {
  cursor: pointer;
}

</style>