<template>
    <teleport to="#toButtonFooter" v-if="!(getUser.currentCompany && dataInfo.hide?.value)">
            <cl-button
                v-if="dataInfo.file?.value && dataInfo.text?.value && dataInfo.icon?.value"
                :link="true"
                :href="dataInfo.file.value.src"
                :img="getImage(dataInfo.icon)"
                :type="'light'"
                class="btn-ico"
                target="_blank">
                {{ dataInfo.text.value }}
            </cl-button>
    </teleport>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    props:{
        dataInfo: {
            type: Object,
            require: true,
        }
    },
    computed: {
      ...mapGetters([
        'getUser'
      ]),
    },
};
</script>

<style lang="scss" scoped>

</style>