<template>
  <!-- ошибка v-model : v-model="modelValue" -->
  <multiselect
      v-if="options"
      class="selectH1"

      :modelValue="modelValue"
      @update:modelValue="modelValue = $event"

      track-by="name"
      label="name"
      placeholder="Выберите"
      :options="options"
      :searchable="false"
      :allow-empty="false"
      @close="changeOption"
      @select="scrollTop"
      :preselect-first="true"
  >
    <template v-slot:singleLabel="{ option }">
      <h1><span>{{ option.name }}</span></h1>
    </template>
  </multiselect>
</template>

<script>
export default {
  name: "HSelect",
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    changeOption() {
      this.$emit('update:modelValue', this.modelValue);
    },
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="scss" scoped>
.selectH1 {
  display: inline-block;
  margin-bottom: 50px;
  width: auto;
  cursor: pointer;

  .multiselect__tags {
    display: inline-block;
    height: auto;
    background: none;
    border: none;
    padding: 0;
  }

  .multiselect__select {
    display: none;
  }

  .multiselect__single {
    color: #ffffff;
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
  }

  h1 {
    position: relative;
    vertical-align: middle;
    margin: 0;

    span {
      vertical-align: middle;
      padding-right: 30px;
    }

    &:after {
      content: "";
      background: url(../../assets/img/st/chevron-down.svg) no-repeat center center;
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
  }

  .multiselect__content-wrapper {
    min-width: 50vw;
  }
}

@media (max-width: 991px) {
  .selectH1 {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .selectH1 {
    margin-bottom: 25px;

    h1 span {
      padding-right: 10px;
    }
  }
}
</style>