<template>
  <teleport to="#addListCard">
    <not-info
        v-if="dataInfo.listCard?.length == 0"
        :title="`Здесь пока ничего нет`"
    >
    </not-info>
    <row v-if="dataInfo.listCard.length > 0">
      <column :key='item.id' v-for='item in dataInfo.listCard' md="4">
        <card card-type="rent" class="card-equipment1">
          <div class="div_img div_img_prop" :class="{'not_img': !item.gallery}">
            <div class="div_img_wrapp">
              <img v-if="item.gallery?.galleryItems" :src="item.gallery?.galleryItems[0].url" alt="">
            </div>
          </div>
          <div class="div_tit fs4n" v-if="item.name"> {{ item.name }}</div>
          <div class="div_text fs16" v-if="item.description" v-html="item.description"></div>
<!--          <cl-button class="btn-middle w-100" :router-link=`${$route.fullPath}/list/${item.id}` @click="$router.push({path: `${$route.fullPath}/list/${item.id}`})">-->
          <cl-button class="btn-middle w-100" :router-link="`${$route.fullPath}/list/${item.id}`">
            Перейти
          </cl-button>
        </card>
      </column>
    </row>
  </teleport>
</template>

<script>
export default {
  name: "equipmentCategories",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>

</style>