<template>
  <container>
    <div v-if="dataInfo" style="padding: 20px; background: red; margin-bottom: 50px;">{{dataInfo.text?.value}}</div>
    <div v-else>нету данных</div>
  </container>
</template>

<script>

export default {
  name: "textOne",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  }
}
</script>

<style lang="scss" scoped>

</style>