<template>
    <div class="content_form_area">
        <div v-html="btest" v-if="btest"></div>
        <div v-html="data.content" v-if="data" :class="{'op_form': opacityShow}"></div>
        <loader
            v-if="!data && opacityShow"
            :animation-duration="3000"
            :size="60"
        ></loader>
        <div id="loader-measure" class="d-none loader-measure">
            <loader
                :animation-duration="3000"
                :size="60"
            ></loader>
        </div>
    </div>
    <cl-modal v-model="modalview" class="d-none" :id="modal_id">
        <div :id="modal_content_id"></div>
    </cl-modal>
    <div :id="'form_type' + measureServiceGuid + '_' + subRequestGuid" :data-type="formType" :data-text="successText" :data-text2="successText2" :data-button="buttonText" :data-link="buttonLink" :data-reload="reload"></div>
</template>


<script>
/*
* formType - вид вывода сообщения о успешной отправке
* successText - текст сообшения о успешной отправке
* successText2 - текст под первым successText
* buttonText - текст кнопки о успешной отправке
* buttonLink - ссылка кнопки о успешной отправке
* reload - запрет на перезагрузку формы, сама форма удаляется со страницы
* */
let current_measure_guid = null;
let iframe_id = null;


import {Base64} from 'js-base64';

export default {
    name: "MeasureSubRequestFormComponent",
    props: {
        measureServiceGuid: {
            type: String,
            required: true
        },
        subRequestGuid: {
            type: String,
            required: true
        },
        formParams: {
            type: Array,
        },
        formType:{
          type: String,
          default: "subrequestForm",
        },
        successText:{
          type: String,
          default: "ВАШ ОТВЕТ ОТПРАВЛЕН"
        },
        successText2:{
          type: String,
          default: "Можете вернуться на главную страницу"
        },
        buttonText:{
          type: String,
          default: "Закрыть"
        },
        buttonLink:{
          type: String,
          default: ""
        },
        reload: {
          type: Boolean,
          default: true
        }
    },
    data() {
        return {
            data: null,
            btest: null,
            opacityShow: true,
            modalview: true,
            modal_id: null,
            modal_content_id: null
        }
    },
    methods: {
        getFormItem(id) {
            this.axios
                .post(
                    `/api/get/form/measure-subrequest/${id}`,
                    null,
                    {
                        params: {
                            'formparams': Base64.encode(JSON.stringify(this.formParams)),
                        }

                    }
                )
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    this.showError(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.opacityShow = false;
                    }, 500)
                });
        }
    },
    watch: {
        data() {


            this.$nextTick(() => {
                let element = document.getElementById(this.data.iframe_id);

                current_measure_guid = this.data.measure_id;
                iframe_id = this.data.iframe_id;

                var timerId = setInterval(function () {
                    if (!element || !element.contentWindow || !element.contentWindow.document || !element.contentWindow.document.body) {
                        return;
                    }
                    element.height = element.contentWindow.document.body.scrollHeight + 30;
                }, 200);

            });
        }
    },
    mounted() {
        this.getFormItem(this.measureServiceGuid + '_' + this.subRequestGuid);
        this.modal_id = 'measure_ctx_modal_' + this.measureServiceGuid + '_' + this.subRequestGuid;
        this.modal_content_id = 'measure_ctx_modal_content_' + this.measureServiceGuid + '_' + this.subRequestGuid;
    }
}
</script>

<style scoped lang="scss">
.content_form_area {
    min-height: 100px;
}

.loader-measure {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.support_measure_form_tit0 {
    margin-bottom: 40px;
}

.support_measure_form_tit1 {
    max-width: 872px;
    margin-bottom: 50px;
}
</style>
