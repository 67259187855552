import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import library from "@/components/library"
import directives from "@/directives"
import axios from "axios";
import VueAxios from "vue-axios";
import VCalendar from 'v-calendar';
import vueMultiselectEsm from "vue-multiselect";
import VueWebpImage from 'vue-webp-image';
import VueClickAway from "vue3-click-away";
import Popper from "vue3-popper";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import ImageMixin from "@/mixin/imageMixin";
import yandexMetrikaMixin from "@/mixin/yandexMetrikaMixin";
import SplitArray from "@/mixin/splitArray";
import { notify } from "@kyvg/vue3-notification";
import Notifications from '@kyvg/vue3-notification';
import notificationWrap from "@/mixin/notificationWrap";
import Multigroup from "@/components/forms/Multigroup.vue";

//css
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.min.css'
import 'v-calendar/dist/style.css';
import "vue-multiselect/dist/vue-multiselect.css";
import "@/assets/scss/animate.scss";
import "@/assets/scss/style.scss";
import YandexMetrikaMixin from "@/mixin/yandexMetrikaMixin";

// проверка авторизации
axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if (error.response?.status === 401 || error.response?.status === 403) {
            if (store.state.user) {
                notify({
                    type: 'error',
                    title: `Ошибка`,
                    text: `Сессия просрочена, пожалуйста, авторизуйтесь снова`,
                    speed: 500,
                    duration: 5000,
                });
                // обнуление пользователя
                store.commit('updateUser', null);
                setTimeout(() => {
                    // редирект
                    document.location.href = `https://${document.location.host}/auth/sudir/out`;
                }, 5000)
            }
        }
        return Promise.reject(error);
    }
);

(async () => {
    if(window.innerWidth < 375){
        document.getElementsByTagName('meta')['viewport'].content='width=375px';
    }

    await store.dispatch('fetchUser');

    const app = createApp(App);


// регистрируем компоненты
    library.forEach(component => {
        app.component(component.name, component)
    })

// регистрируем директивы
    directives.forEach(directive => {
        app.directive(directive.name, directive);
    })

// миксин для иконок(в случае если не знаешь будет svg или растровая иконка)
    app.mixin(ImageMixin);
    app.mixin(SplitArray);
    app.mixin(YandexMetrikaMixin);

    app.config.productionTip = false;

// добавляем фильтры
    app.config.globalProperties.$filters = {
        // пример даты на выходе - 5 августа, вс
        ruDate(value) {
            return value.toLocaleDateString("ru", {
                month: 'long',
                day: 'numeric',
                weekday: 'short',
                formatMatcher: 'basic'
            }).split(', ').reverse().join(', ');
        },
        // пример 12:00
        time(value) {
            return ((value.getHours() < 10)? "0" :"") + value.getHours() +":"+ ((value.getMinutes() < 10)?"0":"") + value.getMinutes();
        },
        // пример даты на выходе - 17 мая в 11:00
        ruDate1(value) {
            return value.toLocaleDateString("ru", {
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                formatMatcher: 'basic'
            }).split(', ').join(' в ');
        },
        // пример даты на выходе - 5 августа
        ruDate2(value) {
            return value.toLocaleDateString("ru", {
                month: 'long',
                day: 'numeric',
                formatMatcher: 'basic'
            }).split(', ').reverse().join(', ');
        },
        // пример даты на выходе - 1 июля 2024 г.
        ruDate3(value) {
            return value.toLocaleDateString("ru", {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                formatMatcher: 'basic'
            }).split(', ').reverse().join(', ');
        },
        // пример даты на выходе - 1 июля 2024
        ruDate4(value, replace = ' г.') {
            return value.toLocaleDateString("ru", {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                formatMatcher: 'basic'
            }).split(', ').reverse().join(', ').replace(replace, '');
        },
        // пример даты на выходе - 1 июля 2024
        ruDate5(value, replace = ' г.') {
            return value.toLocaleDateString("ru", {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                formatMatcher: 'basic'
            }).split(', ').reverse().join(', ').replace(replace, '');
        },
        // склонение
        numWord(value, words){
            value = Math.abs(value) % 100;
            let num = value % 10;
            if(value > 10 && value < 20) return words[2];
            if(num > 1 && num < 5) return words[1];
            if(num == 1) return words[0];
            else return words[2];
        },
        // удаление тэгов html из строки
        striptags: function(string) {
            return string.replace(/<\/?[^>]+>/ig, " ");
        }
    }

    app
        .component('multiselect', vueMultiselectEsm)
        .component("Popper", Popper)
        .component("multigroup", Multigroup)
        .use(PerfectScrollbar)
        .use(VueClickAway)
        .use(VueWebpImage)
        .use(VCalendar, {
            masks: {
                weekdays: 'WW',
            }
        })
        .use(store)
        .use(router)
        .use(VueAxios, axios)
        .use(Notifications)
        .mixin(notificationWrap)
        .mount('#app')

})()

/**** для плагина форм ***/

window.measureFunctions = {
    showLoader: function () {
        document.getElementById('loader-measure').classList.remove('d-none');
        document.getElementById('loader-measure').classList.add('loader-window-global');
    },
    hideLoader: function () {
        //document.getElementById('loader-measure').classList.add('d-none');
        document.querySelectorAll('.loader-measure').forEach(el =>{
            el.classList.add('d-none');
            el.classList.remove('loader-window-global');
        })
    },
}

window.measurebundleInterface = {
    showSuccess: function (message, params)
    {
        let success = document.getElementById('success_message_' + params.guid);
        success.innerText = success.innerText.replace('%%content%%', message);
        let iframe = document.getElementById(params.iframe_id);
        if(document.getElementById("formContainer" + params.guid)){
            document.getElementById("formContainer" + params.guid).classList.add('d-none')
        }
        if(document.getElementById("formResult" + params.guid)){
            document.getElementById("formResult" + params.guid).classList.remove('d-none')
        }

        if(document.getElementById("form_type" + params.guid)){
            const dataEl = document.getElementById("form_type" + params.guid)
            const successEl = document.getElementById("success_message_" + params.guid);
            if(dataEl.getAttribute("data-type") == "1"){
                successEl.innerHTML = `<div class='success_block'><img src='/img/ico/check-circle-2.svg' alt=''><p>${dataEl.getAttribute('data-text')}</p></div>`;
                iframe.remove();
                if(document.querySelector(".subscribe_form .dt1")){
                    document.querySelector(".subscribe_form .dt1").classList.add("d-none");
                }
            } else{
                const modalSuccess = document.getElementById("modal-form-success");
                successEl.innerHTML = "";
                modalSuccess.querySelector(".modal-body").innerHTML = `<div class='success_block'><img src='/img/ico/check-circle-2.svg' alt=''><p>${dataEl.getAttribute('data-text')}</p></div><div class="success_block_dop">${dataEl.getAttribute('data-text2')}</div>`;

                if(document.getElementById('measureNumberSp') && document.getElementById('measureNumber')){
                    modalSuccess.querySelector(".modal-body").querySelector('.success_block_dop').innerHTML = 'За прогрессом можно следить в личном кабинете';
                    document.getElementById('measureNumberSp').innerHTML = document.getElementById('measureNumber').innerHTML;
                }

                modalSuccess.classList.remove("d-none");
                document.getElementById("button-close").textContent = dataEl.getAttribute('data-button');
                if(dataEl.getAttribute('data-link') != ""){
                    document.getElementById("button-close").addEventListener("click", () => {
                       // window.location.href = window.location.origin + dataEl.getAttribute('data-link');
                        router.push({path: dataEl.getAttribute('data-link')});
                    });
                }
                document.getElementsByClassName('site_body')[0].classList.add("modal_open");
            }
            if(document.querySelector(`[id='${params.iframe_id}']`) && dataEl.getAttribute("data-reload") == "true"){
                document.querySelector(`[id='${params.iframe_id}']`).contentWindow.location.reload(true);
            }
            if(document.querySelector(`[id='${params.iframe_id}']`) && dataEl.getAttribute("data-reload") == "false"){
                document.querySelector(`[id='${params.iframe_id}']`).remove();
            }
        }else{
            iframe.remove();
        }
        success.classList.remove('d-none');
        window.measureFunctions.hideLoader();
        // window.scrollTo(0, 0);
        //Вызываем событие что форма успешно отправлена (если в компоненте страницы нужно будет отловить событие, нужно создать submittedForm + гуид меры)
        var event = new Event("submittedForm" + params.guid);
        document.dispatchEvent(event);

    },
    showError: function (message, params)
    {
        var modal = document.getElementById('measure_ctx_modal_'+params.guid);
        document.getElementById('measure_ctx_modal_content_'+params.guid).innerHTML = message;
        modal.classList.remove('d-none');
    }
}

/**** /для плагина форм ***/


function adaptiveTransform() {
    const baseWidth = 1728,
        baseSize = 10,
        htmlTag = document.querySelector('html'),
        ww = htmlTag.clientWidth,
        desktop_size = ww/baseWidth*baseSize;

    if (window.outerWidth > 991 && window.outerWidth < 1728) {
        htmlTag.style.fontSize = desktop_size+'px';
    } else {
        htmlTag.style.fontSize = '';
    }
}
window.addEventListener("DOMContentLoaded", adaptiveTransform);
window.addEventListener('resize', adaptiveTransform);

// ;(() => {
//     if(process.env.NODE_ENV === "production"){
//
//         const element  = document.createElement("script");
//         element.innerHTML = "            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n" +
//             "                m[i].l=1*new Date();\n" +
//             "                for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n" +
//             "                k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n" +
//             "            (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n" +
//             "\n" +
//             "            ym(94757334, \"init\", {\n" +
//             "                clickmap:true,\n" +
//             "                trackLinks:true,\n" +
//             "                accurateTrackBounce:true,\n" +
//             "                webvisor:true\n" +
//             "            });\n";
//         const element2 = document.createElement("noscript");
//         const elementDiv = document.createElement('div');
//         const elementImage = document.createElement('img');
//         elementImage.setAttribute('src', 'https://mc.yandex.ru/watch/94757334');
//         elementImage.setAttribute('style', 'position:absolute; left:-9999px;');
//         elementImage.setAttribute('alt', '');
//         elementDiv.appendChild(elementImage);
//         element2.appendChild(elementDiv);
//         document.head.appendChild(element);
//         document.head.appendChild(element2);
//     }
// })()

/**
 * Функция для генерации guid
 * @param Hyphens
 * @returns {string|string}
 */
export function guidHandler(Hyphens = true) {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
    }
    let guid = s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
    return (Hyphens ? guid : guid.replace(/\-/gi, ''))
}

export function clearAttr (el) {
    if (el.attributes && Array.from(el.attributes).length) {
        Array.from(el.attributes).forEach(attr => {
            el.removeAttribute(attr.name)
        })
    }
    if (Array.from(el.childNodes).length) {
        Array.from(el.childNodes).forEach(subEl => clearAttr(subEl))
    }
}

window.renderForm = params => {

}
window.measureSignFileList = new Proxy({}, {
    get(target, prop) {
        if (prop in target) {
            return target[prop]
        }
    },
    set(target, prop, value) {
        target[prop] = value
        return true
    },
    deleteProperty(target, prop) {
        if (prop in target) {
            delete target[prop]
        }
        return true
    }
})
window.measureSignFileInfoList = new Proxy({}, {
    get(target, prop) {
        if (prop in target) {
            return target[prop]
        }
    },
    set(target, prop, value) {
        target[prop] = value
        return true
    },
    deleteProperty(target, prop) {
        if (prop in target) {
            delete target[prop]
        }
        return true
    }
})

window.showLoader = () => {
    const loader = document.querySelector("#loader-measure-ms-form");
    if(loader){
        loader.classList.remove("d-none");
    }
}
window.hideLoader = () => {
    const loader = document.querySelector("#loader-measure-ms-form");
    if(loader){
        loader.classList.add("d-none");
    }
}
window.scrollUp = () => {
    window.dispatchEvent(new Event("scroll-up"))
}
window.showErrorMessage = message => {
    if (process.env.NODE_ENV === "development") {
        console.log("error message")
    }
    window.dispatchEvent(new CustomEvent("error-message", {
        detail: {message}
    }))
    return message
}
window.showSuccessMessage = message => {
    if (process.env.NODE_ENV === "development") {
        console.log("success message")
    }
    window.dispatchEvent(new CustomEvent("success-message", {
        detail: {message}
    }))
    return message.messageUser
}
window.successPostSendEvent = resp => {
    window.dispatchEvent(new CustomEvent("measureBundle.SuccessPostSendEvent", {
        detail: {resp}
    }))
}

/*window.showLoader = () => {
  window.dispatchEvent(new Event("show-loader"))
}
window.hideLoader = () => {
  window.dispatchEvent(new Event("hide-loader"))
}
window.scrollUp = () => {
  window.dispatchEvent(new Event("scroll-up"))
}
window.showErrorMessage = message => {
  window.dispatchEvent(new CustomEvent("error-message", {
    detail: {message},
    bubbles: true
  }))
}
window.showSuccessMessage = message => {
  window.dispatchEvent(new CustomEvent("success-message", {
    detail: {message},
    bubbles: true
  }))
}*/

if (process.env.NODE_ENV === "development") {
    const measurebundle = {
        routes: {
            fieldsUrl: process.env.VUE_APP_BASE_API_URL + "/form",
            submitUrl: process.env.VUE_APP_BASE_API_URL + "/submit",
            validateUrl: process.env.VUE_APP_BASE_API_URL + "/validate",
            saveDraftUrl: process.env.VUE_APP_BASE_API_URL + "/drafts",
            buildMeasureFilesUrl: process.env.VUE_APP_BASE_API_URL + "/build",
            getMeasureRequestXmlUrl: "./testsign.xml",
            getMeasureRequestPdfUrl: "./testsign.pdf",
            signXmlUrl: process.env.VUE_APP_BASE_API_URL + "/sign-xml",
            signPdfUrl: process.env.VUE_APP_BASE_API_URL + "/sign-pdf",
            fileUploadUrl: "https://rpp.mos.ru/services/upload/"
        },
        settings: {
            isDraftmode: false,
            showDraftButton: false
        }
    }
    window.renderForm(measurebundle)
}

String.prototype.getStatusNumber = function() {
    let num
    switch (this.toLowerCase()) {
        case "черновик":
            num = 0
            break
        case "на модерации":
            num = 1
            break
        case "подтверждено":
            num = 2
            break
        case "отклонено":
            num = 3
            break
        case "на удалении":
            num = 4
            break
        case "удалено":
            num = 5
            break;
        default:
            num = 777
    }
    return num.toString()
}
String.prototype.priceFormat = function() {
    return this.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}



window.helperApp = {
    // функция для добавления хлебных крошек и дополнительного класса
    addBreadcrumbsLink: (text, className) => {
        const last = document.getElementById("breadcrumbs_last");
        const breadcrumbs = document.querySelector(".breadcrumbs");

        if (last && text) {
            last.querySelector(".breadcrumbs_link").innerHTML = text;
            last.classList.remove("d-none");
        }

        if (className && breadcrumbs) {
            breadcrumbs.classList.add(className);
        }
    }
}
