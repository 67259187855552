<template>
    <template v-if="type === 'circleFix'">
      <div class="loader-fixed">
        <div class="spring-spinner" :style="spinnerStyle" :class="classEl">
          <div class="spring-spinner-part top" :style="spinnerPartStyle">
            <div class="spring-spinner-rotator" :style="rotatorStyle"></div>
          </div>
          <div class="spring-spinner-part bottom" :style="spinnerPartStyle">
            <div class="spring-spinner-rotator" :style="rotatorStyle"></div>
          </div>
        </div>
      </div>
    </template>
  <template  v-if="type === 'circle'">
      <div class="spring-spinner" :style="spinnerStyle" :class="classEl">
            <div class="spring-spinner-part top" :style="spinnerPartStyle">
                <div class="spring-spinner-rotator" :style="rotatorStyle"></div>
            </div>
            <div class="spring-spinner-part bottom" :style="spinnerPartStyle">
                <div class="spring-spinner-rotator" :style="rotatorStyle"></div>
            </div>
        </div>
    </template>
    <template  v-if="type === 'points'">
        <div class="snippet" :class="classEl">
            <div class="stage">
                <div class="dot-carousel" :style="{ backgroundColor: color, color  }"></div>
            </div>
        </div>
    </template>
</template>

<script>

export default {
    name: 'Loader',
    props: {
        animationDuration: {
            type: Number,
            default: 3000
        },
        size: {
            type: Number,
            default: 70
        },
        color: {
            type: String,
            default: '#ED850A'
        },
        type: {
            type: String,
            default: 'circle'
        },
        classEl: {
          type: String,
        }
    },
    data () {
        return {
            animationName: `spring-spinner-animation-${Date.now()}`
        }
    },
    computed: {
        spinnerStyle () {
            return {
                height: `${this.size}px`,
                width: `${this.size}px`
            }
        },
        spinnerPartStyle () {
            return {
                height: `${this.size / 2}px`,
                width: `${this.size}px`
            }
        },
        rotatorStyle () {
            return {
                height: `${this.size}px`,
                width: `${this.size}px`,
                borderRightColor: this.color,
                borderTopColor: this.color,
                borderWidth: `${this.size / 7}px`,
                animationDuration: `${this.animationDuration}ms`,
                animationName: this.animationName
            }
        }
    },
    watch: {
        size: {
            handler: 'updateAnimation',
            immediate: true
        },
        color: {
            handler: 'updateAnimation',
            immediate: true
        }
    },
    mounted () {
        this.updateAnimation()
    },
    beforeDestroy () {
        this.removeKeyframes(this.animationName)
    },
    methods: {
        updateAnimation () {
            this.removeKeyframes(this.animationName)
            this.appendKeyframes(this.animationName, this.generateKeyframes())
        },
        generateKeyframes () {
            return `
          0% {
            border-width: ${this.size / 7}px;
          }
          25% {
            border-width: ${this.size / 23.33}px;
          }
          50% {
            transform: rotate(115deg);
            border-width: ${this.size / 7}px;
          }
          75% {
            border-width: ${this.size / 23.33}px;
           }
          100% {
           border-width: ${this.size / 7}px;
          }`
        },
        appendKeyframes (name, frames) {
            const sheet = document.createElement('style')
            if (!sheet) {
                return
            }
            sheet.setAttribute('id', name)
            sheet.innerHTML = `@keyframes ${name} {${frames}}`
            document.body.appendChild(sheet)
        },
        removeKeyframes (name) {
            const sheet = document.getElementById(name)
            if (!sheet) {
                return
            }
            const sheetParent = sheet.parentNode
            sheetParent.removeChild(sheet)
        }
    }
}
</script>

<style scoped>
.spring-spinner, .spring-spinner * {
    box-sizing: border-box;
}
.spring-spinner {
    height: 60px;
    width: 60px;
    margin: auto;
}
.spring-spinner .spring-spinner-part {
    overflow: hidden;
    height: calc(60px / 2);
    width: 60px;
}
.spring-spinner  .spring-spinner-part.bottom {
    transform: rotate(180deg) scale(-1, 1);
}
.spring-spinner .spring-spinner-rotator {
    width: 60px;
    height: 60px;
    border: calc(60px / 7) solid transparent;
    border-right-color: #ED850A;
    border-top-color: #ED850A;
    border-radius: 50%;
    box-sizing: border-box;
    animation: spring-spinner-animation 3s ease-in-out infinite;
    transform: rotate(-200deg);
}
/* NOTE Keyframes here serve as reference. They don't do anything. */
@keyframes spring-spinner-animation {
    0% {
        border-width: calc(60px / 7);
    }
    25% {
        border-width: calc(60px / 23.33);
    }
    50% {
        transform: rotate(115deg);
        border-width: calc(60px / 7);
    }
    75% {
        border-width: calc(60px / 23.33);
    }
    100% {
        border-width: calc(60px / 7);
    }
}
.snippet {
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.dot-carousel {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    box-shadow: 9984px 0 0 0 #ED850A, 9999px 0 0 0 #ED850A, 10014px 0 0 0 #ED850A;
    animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
    0% {
        box-shadow: 9984px 0 0 -1px #ED850A, 9999px 0 0 1px #ED850A, 10014px 0 0 -1px #ED850A;
    }
    50% {
        box-shadow: 10014px 0 0 -1px #ED850A, 9984px 0 0 -1px #ED850A, 9999px 0 0 1px #ED850A;
    }
    100% {
        box-shadow: 9999px 0 0 1px #ED850A, 10014px 0 0 -1px #ED850A, 9984px 0 0 -1px #ED850A;
    }
}

.spring-spinner-margin {
  margin: 20px auto 50px auto;
}

.loader-fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
  background: rgba(0, 0,0, .4);
}
.loader-fixed .spring-spinner {
  transform: scale(1.2);
}

.loader_grant{
  padding: 8rem 0;
}
</style>