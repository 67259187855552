<template>
  <component
      v-if="currentTabShow"
      :dataInfo="dataInfo"
      :is="currentTab"
  />
</template>

<script>
import rentItem from "@/components/rentItem.vue";
import rentList from "@/components/rentList.vue";
export default {
  name: "rentChoice",
  components: {rentList, rentItem},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return {
      currentTab: "rentItem",
      currentTabShow: false,
    }
  },
  methods: {
    selectComponent(){
      if(this.$route.query?.guid) {
        this.currentTab =  "rentItem";
      } else {
        this.currentTab =  "rentList";
      }
      this.currentTabShow = true;
    }
  },
  mounted() {
    this.selectComponent();
    //console.log(this.dataInfo)
  },
  watch: {
    $route() {
      this.currentTabShow = false;
      this.selectComponent();
    },
  },
}
</script>

<style lang="scss" scoped>

</style>