<template>
  <teleport :to="widthWindow > 991 ? '#site_header_td4' : '#site_header_td3'">
    <div class="burger" :class="burgerActive ? 'active' : ''" @click="burgerActive = !burgerActive"></div>
    <nav v-if="dataInfo && dataInfo.listSection && dataInfo.listSection.length > 0" class="menu" :class="burgerActive ? 'active' : ''">
      <div v-for="item in dataInfo.listSection">
        <div class="sub_menu" v-if="item.name && item.listLinks && item.listLinks?.length > 0 && widthWindow > 991">
          <div
              @click="toggelActive(item)"
              :class="{'active': activeItem == item}"
              class="title_menu cursor-pointer"
          >{{ item.name.value }} <img src="/img/ico/chevron-down.svg" class="title_menu_chevron img_wh" alt=""></div>
          <div class="list_sub_menu" v-if="activeItem" v-click-away="activeHide">
              <router-link
                      v-for="linkItem in item.listLinks"
                      :to="linkItem.link?.value"
                      :class="[
                                {'router-link-exact-active': $route.path == linkItem.link?.value},
                                {'no-active-link': !linkItem.link?.value},
                            ]"
              >{{ linkItem.name?.value }}</router-link>
          </div>
        </div>
        <template v-else-if="item.name && item.listLinks && item.listLinks?.length > 0 && widthWindow < 992">
          <router-link
              v-for="linkItem in item.listLinks"
              :to="linkItem.link?.value"
              :class="[
                                {'router-link-exact-active': $route.path == linkItem.link?.value},
                                {'no-active-link': !linkItem.link?.value},
                            ]"
          >{{ linkItem.name?.value }}</router-link>
<!--          <cl-collapse v-else-if="item.name && item.listLinks && item.listLinks?.length > 0 && widthWindow < 992" class="header_collapse">-->
<!--            <template v-slot:name>-->
<!--              <div-->
<!--                  class="title_menu cursor-pointer"-->
<!--              >{{ item.name.value }} <img src="/img/ico/chevron-down.svg" class="title_menu_chevron img_wh" alt=""></div>-->
<!--            </template>-->
<!--            <template v-slot:card>-->
<!--              <router-link-->
<!--                  v-for="linkItem in item.listLinks"-->
<!--                  :to="linkItem.link?.value"-->
<!--                  :class="[-->
<!--                                {'router-link-exact-active': $route.path == linkItem.link?.value},-->
<!--                                {'no-active-link': !linkItem.link?.value},-->
<!--                            ]"-->
<!--              >{{ linkItem.name?.value }}</router-link>-->
<!--            </template>-->
<!--          </cl-collapse>-->
        </template>
        <router-link
            v-else-if="item.link && item.name"
            :to="item.link?.value"
            :class="{'router-link-exact-active': $route.path == item.link?.value}"
        >{{ item.name?.value }}</router-link>
        <span
            v-else-if="item.name"
            class="no-active-link"
        >{{ item.name?.value }}</span>
      </div>
    </nav>
  </teleport>
</template>

<script>
export default {
  name: "menuGlobal",
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      activeItem: false,
      widthWindow: 1920,
      burgerActive: false,
    }
  },
  methods: {
    activeHide(event) {
      if (!event.target.classList.contains("title_menu") && !event.target.classList.contains("title_menu_chevron")) {
        this.activeItem = false;
      }
    },
    toggelActive(item) {
      if (this.activeItem == item) {
        this.activeItem = false;
      } else {
        this.activeItem = item;
      }
    }
  },
  mounted() {
    this.widthWindow = window.innerWidth;
    window.addEventListener("resize", () => {
      this.widthWindow = window.innerWidth;
    });
  },
  watch:{
    burgerActive(){
      if(this.burgerActive){
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  height: 48px;
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  z-index: 1;
  span, a {
    display: inline-block;
    padding: 12px 16px;
  }
  a:hover {
    background-color: rgba(237, 135, 10, 0.10);
  }
}

.title_menu {
  display: flex;
  align-items: center;
  padding: 12px 16px;

  & img {
    flex: none;
    margin-left: 4px;
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
  }

  &:hover {
    background-color: rgba(237, 135, 10, 0.10);
  }
}

.menu{
  .active {
    background-color: #ED850A !important;

    & ~ .list_sub_menu {
      display: flex;
    }

    & img {
      transform: rotate(-180deg);
    }
  }
}

.list_sub_menu {
  position: absolute;
  display: none;
  flex-direction: column;
  background-color: white;
  margin-top: 8px;
  border-radius: 6px;

  a {
    padding: 12px;
    color: black;

    &:not([class="no-active-link"]):hover {
      color: #ED850A;
    }
  }

  .router-link-exact-active {
    border: none;
    color: #ED850A;
    background-color: rgba(237, 133, 10, 0.25);
  }

  .no-active-link {
    color: black !important;
  }
}

.no-active-link {
  opacity: 0.4;
  cursor: default;
}

.router-link-exact-active {
  border-bottom: 1px solid #ED850A;
  cursor: default;
}

.burger{
  display: none;
}

@media (max-width: 991px) {
  .menu{
    display: none;
    position: absolute;
    top: 52px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    height: calc(100vh - 48px);
    .title_menu{
      justify-content: space-between;
    }
    .list_sub_menu{
      display: flex !important;
      position: relative;
      height: 0;
      margin-top: 0;
      border-radius: 0;
      transition: all 0.5s ease;
      a{
        width: 100%;
        margin: 0;
        padding: 12px 16px;
      }
      .router-link-active, .router-link-exact-active,
      .router-link-exact-active{
        color: #ED850A;
        background-color: rgba(237, 133, 10, 0.25);
      }
    }
    .active.title_menu + .list_sub_menu{
      height: 100%;
      flex: auto;
    }
    a{
      padding: 12px 0;
      margin: 0 16px;
      display: inline-block;
      width: calc(100% - 32px);
    }
    &.active{
      display: block;
    }
  }

  .burger {
    cursor: pointer;
    display: block;
    position: relative;
    border: none;
    background: transparent;
    width: 28px;
    height: 16px;
    &:before,
    &:after {
      content: '';
      left: 0;
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      background: #fff;
    }
    &:before {
      top: 0;
      box-shadow: 0 7px 0 #fff;
      transition: box-shadow .3s .15s, top .3s .15s, transform .3s;
    }
    &:after {
      bottom: 0;
      transition: bottom .3s .15s, transform .3s;
    }
    &.active::before {
      top: 7px;
      transform: rotate(45deg);
      box-shadow: 0 6px 0 rgba(0,0,0,0);
      transition: box-shadow .15s, top .3s, transform .3s .15s;
    }
    &.active::after {
      bottom: 7px;
      transform: rotate(-45deg);
      transition: bottom .3s, transform .3s .15s;
    }
  }
  @media(max-width: 767px){
    .burger {
      width: 36px;
      border-left: 9px solid rgba(0, 0, 0, 0);
      border-right: 9px solid rgba(0, 0, 0, 0);
    }
  }
}

</style>

<style lang="scss">
.header_collapse{
  .collapse_btn{
    padding: 0 !important;
    .collapse_text{
      width: 100%;
      .title_menu{
        //padding: 12px 0;
        //margin: 0 16px;
        background-color: transparent;
        &:hover{
          background-color: transparent;
        }
      }
    }
    &:after{
      display: none;
    }
    &.collapsed{
      .collapse_text{
        img{
          transform: rotate(-180deg);
        }
        .title_menu{
          border-bottom: 1px solid #ED850A;
        }
      }
    }
  }
  .collapse{
    .collapse_card{
      padding: 0 !important;
      background-color: #fff;
      a{
        display: block;
        color: #000106;
      }
      a{
        width: 100%;
        margin: 0;
        padding: 12px 16px;
      }
      .router-link-active, .router-link-exact-active,
      .router-link-exact-active{
        color: #ED850A;
        background-color: rgba(237, 133, 10, 0.25);
        border: none;
      }
    }
  }
}
</style>