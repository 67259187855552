<template>
  <div class="block_wrap tour_video">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>

      <div class="div_text fs4n" v-if="dataInfo.subtitle" v-html="dataInfo.subtitle.value"></div>

      <div class="div_video">
        <div class="button_play">
          <img v-if="dataInfo.preview" :src="getImage(dataInfo.preview)" :alt="dataInfo.preview.value.alt">
          <div class="play">
            <div class="play_img" @click="modalShow = true">
              <img src="/img/ico/play-circle.svg" alt="">
            </div>
          </div>
        </div>
        <div class="button_botton">
          <div class="share button_ico">
            <img src="/img/ico/share-2.svg" alt="" @click="copyLink">
          </div>
          <div class="fullScreen button_ico">
            <img src="/img/ico/maximize.svg" alt="" @click="openfullScreen">
          </div>
        </div>
        <!--          <iframe src="/3Dtour/index.html" frameborder="0"></iframe>-->
      </div>
<!--      <div class="message_block" id="msBlock">-->
<!--        <div class="ms_close" @click="closeMs"></div>-->
<!--        <div class="ms_text">Ссылка скопированна</div>-->
<!--      </div>-->
      <cl-modal v-model="modalShow" class="modalTour" id="modalTour">
        <template v-slot:body>
          <iframe src="3Dtour/index.html" id="modal_frame"></iframe>
        </template>
        <template v-slot:footer>
          <div class="button_botton">
            <div class="share button_ico" @click="copyLink('скопировал')">
              <img src="/img/ico/share-2.svg" alt="">
            </div>
            <div class="fullScreen button_ico" @click="fullScreen" id="fullScreen">
              <img src="/img/ico/maximize.svg" alt="">
            </div>
            <div class="exitfullScreen button_ico d-none" @click="exitFullScreen" id="exitfullScreen">
              <img src="/img/ico/minimize.svg" alt="">
            </div>
          </div>
        </template>
      </cl-modal>
      <div class="tour_video_survey"
           v-if="theme == 'survey'"
      >
        <teleport to="#test" :disabled="widthSite > 767" v-if="isMounted">
        <div class="dt1 fs3" v-if="dataInfo.text" v-html="dataInfo.text.value"></div>
        </teleport>
        <div class="dt2" v-if="dataInfo.question && dataInfo.question.answerVotes?.length > 0">
          <div v-if="dataInfo.question?.question" class="dt2_tit">{{ dataInfo.question?.question[0].value }}</div>
          <div id="test" v-if="widthSite <= 767"></div>
          <multiselect
              v-model="selectSelected"
              track-by="id"
              label="value"
              placeholder="Выберите ваш вариант"
              :options="dataInfo.question.answerVotes"
              :searchable="false"
              :allow-empty="false"
              class="multiselect_gray"
          ></multiselect>
          <cl-button @click="changeResult" class="btn-middle btn-light" :class="selectSelected?.value ? '' : 'btn-disabled'">Проголосовать</cl-button>
        </div>
      </div>
      <div class="subscribe_answer"
           v-else
      >
        <div class="div_img"><img src="/img/ico/check-circle.svg" alt=""></div>
        <div class="div_text">Спасибо! Будем держать вас в курсе событий!</div>
      </div>

    </container>

  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "tourVideo",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      modalShow: false,
      theme: 'survey',
      selectSelected: [],
      questionResult: false,
      questionParams: {},
      isMounted: false
    }
  },
  methods: {
    openfullScreen() {
      this.modalShow = true;
      setTimeout(() => {
        document.querySelector(".modalTour").classList.add("fullscreen");
        document.querySelector("#fullScreen").classList.add("d-none");
        document.querySelector("#exitfullScreen").classList.remove("d-none");
      }, 10);
    },
    fullScreen() {
      document.querySelector(".modalTour").classList.add("fullscreen");
      document.querySelector("#fullScreen").classList.add("d-none");
      document.querySelector("#exitfullScreen").classList.remove("d-none");
    },
    exitFullScreen() {
      document.querySelector(".modalTour").classList.remove("fullscreen");
      document.querySelector("#fullScreen").classList.remove("d-none");
      document.querySelector("#exitfullScreen").classList.add("d-none");
    },
    copyLink() {
      const element = document.querySelector(".tour_video");
      const location = window.location;
      var text;
      if (element.hasAttribute("id")) {
        text = location.origin + this.$route.fullPath + "#" + element.getAttribute("id");
      } else {
        text = location.origin + this.$route.fullPath ;
      }
      navigator.clipboard.writeText(text);
      this.showSuccess('', 'Ссылка скопирована');
      // const closeEl = document.getElementById("msBlock");
      // if (closeEl.style.display != "block") {
      //   setTimeout(() => {
      //     closeEl.style.display = "none";
      //   }, 3000);
      // }
      // closeEl.style.display = "block";
    },
    // closeMs() {
    //   const closeEl = document.getElementById("msBlock");
    //   if (closeEl.style.display == "block") {
    //     closeEl.style.display = "none";
    //   }
    // },
    changeResult() {
      let randomNum;
      if(document.cookie.indexOf('sessionSubscribe') != -1) {
          randomNum = this.getCookie().sessionSubscribe;
      } else {
          randomNum = Math.random() * 1000000000000000000;
          var expires = (new Date(Date.now() + 24 * 60 * 60 * 1000)).toUTCString();
          document.cookie = `sessionSubscribe=${randomNum}; expires=${expires}; path=/;`;
      }
      //document.cookie = "mainSurveyRentRecv=true; max-age=0"

      this.questionParams.id = this.dataInfo.question.question[0].id;
      this.questionParams.answer = this.selectSelected.id;
      this.questionParams.session = randomNum;
      this.axios
          .post(`/api/data/vote`, null, {params: this.questionParams})
          .then(response => {
            this.questionResult = response.data.data;
            this.isLoading = false;
            this.theme = 'result';
          })
          .catch(error => {

          })
    },
    getCookie() {
        return document.cookie.split('; ').reduce((acc, item) => {
            const [name, value] = item.split('=')
            acc[name] = value
            return acc
        }, {})
    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
  },
  mounted() {
    if(document.cookie.indexOf('sessionSubscribe') != -1){
      this.changeResult();
    }
    this.$nextTick(() => {
      this.isMounted = true;
    });
  }
}
</script>

<style lang="scss" scoped>
.tour_video {
  padding: 60px 0 0 0;

  h2 {
    margin-bottom: 24px;
  }

  .div_text {
    margin-bottom: 37px;
  }

  .div_video {
    position: relative;
    border-radius: 32px;
    background: linear-gradient(0deg, rgba(0, 1, 6, 0.60) 0%, rgba(0, 1, 6, 0.60) 100%), lightgray 50% / cover no-repeat;
    height: 785px;
    margin-bottom: 48px;
    overflow: hidden;

    .button_play {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .play {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00010699;

        .play_img {
          width: 124px;
          height: 124px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .button_botton {
      position: absolute;
      bottom: 32px;
      right: 32px;
      display: flex;
      gap: 24px;

      .button_ico {
        width: 48px;
        height: 48px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .div_video {
      height: 550px;
    }
  }
  @media (max-width: 767px) {
    .div_video {
      height: 350px;
      .button_play{
        .play{
          .play_img{
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
}

.tour_video_survey {
  display: flex;

  > .dt1 {
    flex: none;
    width: 50%;
    padding-right: 40px;
  }

  > .dt2 {
    width: 50%;
  }

  .dt2_tit {
    font-weight: 500;
    margin-bottom: 2px;
  }

  .multiselect {
    margin-bottom: 24px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    .dt1, .dt2{
      width: 100%;
    }
    .dt1{
      margin-bottom: 8px;
    }
  }
}

.message_block {
  position: fixed;
  display: none;
  bottom: 16px;
  right: 16px;
  z-index: 100000;
  padding: 16px 38px 16px 16px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #F1F2F3;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.15);

  .ms_close {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 18px;
    height: 17px;
    transition: .2s linear;
    cursor: pointer;

    &:hover {
      opacity: .5;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &::before, &::after {
      position: absolute;
      left: 8px;
      content: '';
      height: 17px;
      width: 2px;
      background-color: #000000;
    }
  }
}
</style>