<template>
  <div class="block_wrap block_top renting_oborud"
       :style="[ getBkgrImage(dataInfo.image, 'fullHd') ]"
  >
    <container>
      <div class="renting_oborud_info">
        <h1 class="" v-if="dataInfo.title.value" v-html="dataInfo.title.value"></h1>
        <div class="div_text fs2" v-if="dataInfo.subtitle" v-html="dataInfo.subtitle.value"></div>
        <row v-if="dataInfo.list && dataInfo.list.length > 0">
          <column md="4"
                  v-for="(item, index) in dataInfo.list"
                  :key="item.text?.value"
          >
            <div class="block_number_text">
              <div class="dt dt1 fs2">{{ index + 1 }}<span class="dotted">.</span></div>
              <div class="dt dt2 fs4n" v-if="item.text" v-html="item.text.value"></div>
            </div>
          </column>
        </row>
      </div>
      <div id="addListCard"></div>
    </container>
  </div>
</template>

<script>
export default {
  name: 'rentOborudovanie',
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
};
</script>

<style lang="scss" scoped>
.renting_oborud {
  padding: 305px 0 0 0;
  background-size: 100% auto;
  background-position: center top;
  &.block_topCinemaGrant{
    background-image: unset!important;
    padding: 168px 0 104px 0;
    .renting_oborud_info{
      h1{
        margin-bottom: .8rem;
      }
      .div_text{
        margin-bottom: 3.2rem;
        max-width: 140rem;
      }
      .block_number_text{
        padding: 2.4rem;
        border-radius: 1.2rem;
        flex-direction: column;
        background: unset;
        border: 1px solid #ED850A;
        align-items: baseline;
        .dt1{
          margin-right: 0;
          padding: 0 1.75rem;
          border-radius: 100%;
          border: 1px solid #2E3138;
          width: 6.4rem;
          height: 6.4rem;
          display: flex;
          justify-content: center;
          margin-bottom: 2.4rem;
          .dotted{
            display: none;
          }
        }
      }
    }
  }
}
.renting_oborud_info {
  padding-bottom: 66px;
  .div_text {
    margin-bottom: 135px;
  }
  h1 {
    margin-bottom: 48px;
  }
}
@media (max-width: 1440px) {

  .renting_oborud{

    .renting_oborud_info{

      .block_number_text{

        .dt2 {
          font-size: 24px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {

  .renting_oborud{
    &.block_topCinemaGrant{

    .renting_oborud_info{

      h1 {
        font-size: 80px;
      }

      .div_text {
        font-size: 32px;
      }

      .block_number_text{

        .dt2 {
          font-size: 21px;
        }
      }
    }
  }
  }
}

@media (max-width: 991px) {
  .renting_oborud {
    padding: 180px 0 0px 0;

    &.block_topCinemaGrant {
      .renting_oborud_info {
        h1 {
          margin-bottom: 8px;
          font-size: 60px;
        }

        .div_text {
          font-size: 24px;
        }

        .block_number_text{

          padding: 2.4rem 1.2rem;

          .dt1 {
            font-size: 32px;
            align-items: center;
          }

          .dt2 {
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .renting_oborud{


    &.block_topCinemaGrant {
      .renting_oborud_info {
        h1 {
          font-size: 24px;
        }

        .div_text {
          font-size: 16px;
        }

        .block_number_text{
          display: flex;
          flex-direction: row;
          gap: 12px;

          .dt1 {
            margin-bottom: 0;
          }

          .dt2 {
            align-self: center;
          }
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .renting_oborud_info{
    h1{
      font-size: 30px;
    }
  }
}
</style>