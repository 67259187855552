export default {
	methods:{
		splitArray(array ,count){
			const newArray = [];
			let temp;
			for(let i = 0; i < array.length; i++){
				if(i % count == 0){
					temp = [];
					newArray.push(temp);
				}
				temp.push(array[i]);
			}
			return newArray;
		}
	}
}