<template>
  <div class="multigroup" :data-grouphash="group.options && group.options['group_hash']">
    <template v-for="(group, index) of formattedGroup" :key="group.options.groupGuid">
      <div class="multiholder"
            :class="group.options && group.options.parent?.length === 0 ? 'parentalGroup' : 'childGroup'"
            :data-multiplace="groupKey"
            :data-typehash="group.options ? group.options['type_hash'] : null"
            :id="`multi_${groupKey}`"
            data-ismulti=1
            :data-label="group.options ? group.options['entry_label'] : null"
            :data-holdrm="`${group.options['type_hash']}_${group.options.parent}`"
            :data-parentid="group.options.parent.length > 0 ? group.options.parent : 0"
            :data-bn="group.options['button_label']"
      >
        <legend class="col-form-label">{{group.options["entry_label"]}}</legend>
        <button class="btn btn-light btn-remove-clone" type="button" v-if="group.options.isClone" @click.prevent="removeHandler(formattedGroup, index)">Удалить</button>
        <div class="form-group">
          <template v-for="(prop, key) in group.properties" :key="key">
            <template v-if="prop.type === 'measure_collection_group'">
              <multigroup :loaded="loaded"
                          @new-group="$emit('new-group', $event)"
                          @save-draft="$emit('save-draft', $event)"
                          :draft-saved="draftSaved"
                          @modal-open="$emit('modal-open', $event)"
                          @product-update="$emit('product-update', $emit)"
                          :hidden-label="hiddenLabel"
                          :sign-step="signStep"
                          :group-key="key"
                          :group="prop"
                          :parent-proto="prop.options.parentProto"
                          @form-item-value="formValue"
                          @captcha-blocked="$emit('captcha-blocked', $event)"
              />
            </template>
            <template v-else-if="prop.type === 'measure_group'">
              <group :loaded="loaded"
                     @new-group="$emit('new-group', $event)"
                     @save-draft="$emit('save-draft', $event)"
                     :draft-saved="draftSaved"
                     @modal-open="$emit('modal-open', $event)"
                     @product-update="$emit('product-update', $emit)"
                     :hidden-label="hiddenLabel"
                     :sign-step="signStep"
                     :group-prop="prop"
                     :group-key="key"
                     :parent-proto="prop.options.parentProto"
                     @form-item-value="formValue"
                     @captcha-blocked="$emit('captcha-blocked', $event)"
              />
            </template>
            <template v-else>
              <form-item :loaded="loaded"
                         :draft-saved="draftSaved"
                         @modal-open="$emit('modal-open', $event)"
                         @product-update="$emit('product-update', $emit)"
                         :hidden-label="hiddenLabel" :sign-step="signStep"
                         :item="prop"
                         :propkey="key"
                         :required="group.required && group.required.includes(key)"
                         @form-item-value="formValue"
                         @captcha-blocked="$emit('captcha-blocked', $event)"
              />
            </template>
          </template>
        </div>
      </div>
    </template>
    <div class="add-more-place" v-show="!group.options['hide_add_button']" v-if="group.options.hasAddButton" :data-multiplace="groupKey" :data-pn="group.options.protoname">
      <button :data-hash="group.options['type_hash']" data-counter="1" type="button" class="btn btn-light" @click="addMoreHandler">
        <svg viewBox="0 0 12 12" width="12" height="12" fill="none"><path d="M6 0V12" stroke-width="2"></path><path d="M12 6L-3.57628e-07 6" stroke-width="2"></path></svg> {{group.options['button_label']}}
      </button>
      <a role="button" @click="$emit('save-draft', true)" class="save-draft" v-if="params.settings?.showDraftButton">Сохранить черновик</a>
    </div>
  </div>
</template>

<script>
/**
 * Компонент мультигруппы (не влезай, убьет)
 * group - мультигруппа
 * groupKey - имя свойства мультигруппы
 * parentProto - для дочерних элементов, если в родословной мультигруппы была еще одна мультигруппа (необходимо для клонирования)
 */
import FormItem from "@/components/forms/FormItem";
import cloneDeep from "lodash.clonedeep"
import Group from "@/components/forms/Group";
import {guidHandler} from "@/main";

export default {
  name: "Multigroup",
  components: {Group, FormItem},
  props: {
    group: {
      type: Object,
      required: true
    },
    groupKey: {
      type: String,
      required: true
    },
    parentProto: Array,
    signStep: Boolean,
    hiddenLabel: Boolean,
    draftSaved: Boolean,
    loaded: Boolean
  },
  data() {
    return {
      index: 0,
      formattedGroup: [cloneDeep(this.group)],
    }
  },
  mounted() {
    this.setFormattedGroup()
  },
  watch: {
    parentProto() {
      this.setFormattedGroup()
    }
  },
  methods: {
    setFormattedGroup() {
      this.formattedGroup.forEach(group => {
        this.setProtoIndex(group)
      })
    },
    formValue(value, item, state) {
      this.$emit('form-item-value', value, item, state)
    },
    setProtoIndex(group) {
      for (let item in group.properties) {
        switch (group.properties[item].type) {
          case "measure_group" || "measure_collection_group":
            if (!this.parentProto) {
              group.properties[item].options.parentProto = [[group.options.protoname, this.index]]
            } else {
              group.properties[item].options.parentProto = [[group.options.protoname, this.index], ...this.parentProto]
            }
            break
          default:
            if (this.parentProto) {
              this.parentProto.forEach(item => {
                group.properties[item]["full_name"] = group.properties[item]["full_name"].replace(item[0], item[1])
              })
            } else {
              group.properties[item]["full_name"] = group.properties[item]["full_name"].replace(group.options.protoname, this.index)
            }
        }
      }
    },
    addMoreHandler() {
      const obj = cloneDeep(this.group)
      const clearObj = object => {
        for (let item in object.properties) {
          if (item !== "groupId" && item !== "groupTemplate") {
            if (object.properties[item].type === "iasupload") {
              if (object.properties[item].options && !object.properties[item].options.readonly) {
                if (object.properties[item].options.fileUrl) {
                  object.properties[item].options.fileUrl = ""
                }
                for (let input in object.properties[item].properties) {
                  object.properties[item].properties[input].value = ""
                }
              }
            } else if (object.properties[item].type === "measure_collection_group" || object.properties[item].type === "measure_group") {
              clearObj(object.properties[item])
            } else if (item === "groupGuid") {
              object.properties[item].value = guidHandler(false)
            } else {
              if (object.properties[item].options && !object.properties[item].options.readonly) {
                object.properties[item].value = null
              }
            }
          }
        }
      }
      clearObj(obj)
      obj.options.isClone = true
      obj.options.groupGuid = guidHandler(false)
      this.index++
      this.setProtoIndex(obj)
      this.formattedGroup.push(obj)
      setTimeout(() => this.$emit("new-group", true), 300)
    },
    removeHandler(arr, index) {
      arr = arr.splice(index, 1)
      this.index--
    }
  }
}
</script>

<style scoped>

</style>