<template>
  <div class="block_gallery_partners">
    <container class="container3">
      <h1>НАШИ ПАРТНЕРЫ</h1>
        <div class="gallery_partners" v-if="dataInfo.imagesList">
          <template v-for="item of dataInfo?.imagesList">
            <div class="partners__item">
              <img :src="getImage(item.imageItem, 'reference')" alt="">
            </div>
          </template>
        </div>
    </container>
  </div>
</template>

<script>
export default {
  name: "partnersImagesList",
  data(){
    return {}
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.block_gallery_partners{
  margin: 4rem 0;
}
h1{
  font-size: 48px;
  text-align: center;
}
.gallery_partners{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 24px 12px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  .partners__item{
    position: relative;
    min-height: 196px;
    width: calc(33.333% - 12px);
    min-width: calc(33.333% - 12px);
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child):before {
      content: "";
      height: 100%;
      border-left: 1px solid white;
      position: absolute;
      right: -6px;
    }
    &:nth-child(3n):before{
      content: unset;
    }
  }
  @media(max-width: 1100px){
    margin: 0 60px;
    .partners__item{
      min-height: 176px;
    }
  }
  @media(max-width: 991px){
    flex-wrap: wrap;
    margin: 0;
  }
  @media(max-width: 767px){
    flex-direction: column;
    margin: 40px 0;
    .partners__item{
      width: 100%;
      &:nth-child(3n):before{
        content: "";
      }
      &:not(:last-child):before{
        right: 0;
        border-left: 0;
        bottom: -12px;
        border-bottom: 1px solid white;
        width: 100%;
      }

    }
  }
}
</style>