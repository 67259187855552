<template>
  <div class="placement-issue">
    <div class="rec rec-1"></div>
    <div class="rec rec-2"></div>
    <container class="indexUp block_min">
      <div class="person">
        <div class="person__image">
          <img :src="getImage(dataInfo.image, 'hd')" alt="">
        </div>
        <div class="person__name" v-if="dataInfo.fio">
          {{ dataInfo.fio.value }}
        </div>
        <div class="person__post" v-if="dataInfo.post">
          {{ dataInfo.post.value }}
        </div>
      </div>
      <div class="personInfo">
        <div class="personInfo__title" v-if="dataInfo.title">
          {{ dataInfo.title.value }}
        </div>
        <div class="personInfo__subTitle" v-if="dataInfo.subTitle">
          {{ dataInfo.subTitle.value }}
        </div>
        <div class="personInfo__contact">
          <cl-button link type="light" v-if="dataInfo.phone" img="/img/ico/phone.svg"
                     :href="'tel:' + dataInfo.phone.value">{{ dataInfo.phone.value }}
          </cl-button>
          <cl-button link type="light" v-if="dataInfo.mail" img="/img/ico/mail.svg"
                     :href="'mailto:' + dataInfo.mail.value">{{ dataInfo.mail.value }}
          </cl-button>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";
import ClButton from "@/components/library/ClButton.vue";

export default {
  name: "placement-issue",
  components: {ClButton, Container},
  props: {
    dataInfo: {
      type: Object,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
.placement-issue {
  position: relative;
  padding: 36px 0 37px;
  min-height: 229px;
  background-color: white;

  .rec {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    clip-path: polygon(0 0, 0 100%, 100% 0, 100% 0);

    &.rec-1 {
      height: 229px;
      background-color: #ED850A;
    }

    &.rec-2 {
      height: 197px;
      background-color: #000106;
    }
  }

  .indexUp {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 64px;
  }

  .person {
    max-width: 331px;

    &__image {
      width: 331px;
      height: 331px;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 24px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__name {
      color: #000106;
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 125%;
      letter-spacing: 0.64px;
      padding-bottom: 8px;
    }

    &__post {
      color: #6B7280;
      font-size: 21px;
      font-weight: 400;
      line-height: 133.333%;
      letter-spacing: 0.315px;
    }
  }

  .personInfo {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    &__title {
      color: #000106;
      font-size: 48px;
      font-weight: 500;
      line-height: 133.333%;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }

    &__subTitle {
      color: #6B7280;
      font-size: 21px;
      font-weight: 400;
      line-height: 133.333%;
      letter-spacing: 0.315px;
    }

    &__contact {
      display: flex;
      gap: 24px;
    }
  }

  @media (max-width: 991px) {
    .indexUp {
      gap: 24px;
    }
    .person {
      max-width: 224px;

      &__image {
        width: 224px;
        height: 224px;
      }

      &__name {
        font-size: 21px;
      }

      &__post {
        font-size: 16px;
      }

      &Info {
        gap: 12px;
        &__title {
          font-size: 24px;
        }
        &__subTitle {
          font-size: 16px;
        }
        .btn {
          padding-left: 24px;
          padding-right: 24px;
          white-space: nowrap;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .indexUp {
      gap: 24px;
      flex-direction: column;
      padding-top: 50px;
    }
    .person {
      max-width: 100%;
      display: grid;
      grid-template-areas: "img name" "img post";
      gap: 8px 16px;

      &__image {
        grid-area: img;
        width: 164px;
        height: 164px;
        margin: 0;
      }

      &__name {
        grid-area: name;
        font-size: 16px;
        align-self: flex-end;
      }

      &__post {
        grid-area: post;
        font-size: 12px;
      }

      &Info {

        &__title {
          font-size: 18px;
        }
        &__subTitle {
          font-size: 14px;
        }
        &__contact {
          flex-direction: column;
          gap: 16px;
        }
        .btn {
          width: 100%;
        }
      }
    }
  }
}

</style>