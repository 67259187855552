<template>
  <footer class="block_wrap questions_suggestions site_footer site_footerEngland">
    <div class="div_flex" v-if="dataInfo.image" :style="getBkgrImage(dataInfo.image,'fullHd')">
      <container class="div_flex">
        <h2 v-if="dataInfo.title && widthSite <= 991">{{dataInfo.title?.value}}</h2>
        <div class="dt1">
          <h2 v-if="dataInfo.title && widthSite > 991">{{dataInfo.title?.value}}</h2>
          <div class="row2">
            <a v-if="dataInfo.linkNamePol && dataInfo.file"
               :href="dataInfo.file?.value?.src"
               class="link clOr">{{ dataInfo.linkNamePol?.value }}</a>
            <a v-else-if="dataInfo.linkNamePol && dataInfo.linkPol"
               :href="dataInfo.linkPol?.value"
               class="link clOr">{{ dataInfo.linkNamePol?.value }}</a>
            <div v-if="dataInfo.textRight" class="text">
              <a v-if="dataInfo.textRightFile"
                 :href="dataInfo.textRightFile?.value?.src"
                 class="link clOr">{{  dataInfo.textRight?.value }}</a>
              <template v-else>
                {{ dataInfo.textRight?.value }}
              </template>
            </div>
          </div>
        </div>
        <div class="dt2">
          <measure-form-component
              v-if="dataInfo.form"
              :measureGuid="dataInfo.form.value"
              :showMode="showMode"
              :formParams="formParams"
              class="loader_ab"
          />
        </div>
      </container>
    </div>
  </footer>
</template>

<script>

import MeasureFormComponent from "@/components/measureForm.vue";
import {mapGetters} from "vuex";

export default {
  name: "footerForm",
  components: {MeasureFormComponent},
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      showMode: 'inline',
      formParams: {
        preSetParams: {},
      },
    }
  },
  methods: {
    hrefUrl(url) {
      if (url.includes("https")) {
        location.href = url;
      } else if (url.includes("/") && !url.includes("http")) {
        this.$router.push({path: url});
      }
    }
  },
  mounted() {
    this.formParams.preSetParams["widget"] = 'personalConfirm';
    this.formParams.preSetParams["linkpart"] = 'согласие';
    this.formParams.preSetParams["filelink"] = this.dataInfo.file?.value?.src;
  },
  computed:{
    ...mapGetters([
      'widthSite'
    ])
  },
};
</script>

<style lang="scss" scoped>
.site_footerEngland{
  margin-top: auto;
  .dt1{
    padding: 6.4rem 0 6.4rem 4.8rem;
    display: flex;
    flex-direction: column;
    h2{
      font-weight: 500;
      font-size: 6.5rem;
      line-height: 147.962%;
      text-transform: uppercase;
      max-width: 66.6rem;
      margin: auto 0;
    }
    .row2{
      padding-top: 0;
      margin-top: auto;
    }
  }
  .dt2{
    padding-left: 4.4rem;
  }
  @media(max-width: 991px) {
    .dt1{
      padding: 2.4rem 0;
      h2{
        font-size: 4.8rem;
        text-align: center;
        line-height: 133.3%;
        max-width: unset;
      }
    }
  }
}
.questions_suggestions {
  padding-top: 5rem;
  h2 {
    margin-bottom: 32px;
  }

  .div_flex {
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .dt1 {
    width: 57%;
    max-height: 1000px;
    @media (max-width: 1400px){
      width: 81%;
    }
    //max-height: 780px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .dt2 {
    width: 43%;
    padding: 64px 64px 24px;
    @media (max-width: 1400px){
      padding: 24px;
    }
  }
}

.site_footer {
  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .info {
      display: flex;
      flex-direction: column;
      gap: 48px;
      width: 100%;

      .dt_info {
        display: flex;
        justify-content: center;
        gap: 8px;
        width: 100%;

        .r_info {
          display: flex;
          gap: 24px;

          .image {
            width: 32px;
            height: 32px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .text {
            font: {
              size: 24px;
              weight: 500;
            }
            line-height: 133.33%;
            color: #ED850A;
          }
        }
      }
    }
  }

  .row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 32px;

    .link, .text {
      color: #B2B5BB;
      font-size: 16px;
      font-weight: 400;
      line-height: 125%;

      &.clOr {
        color: #ED850A;
        line-height: 125%;

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: #ED850A;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .questions_suggestions{
    .div_flex{
      flex-direction: column;
      > div{
        position: relative;
        order: 0;
      }
      .dt1{
        order: 1;
        padding: 2.4rem;
        padding-top: 0;
        .row2 {
          .link {
            font-size: 1.6rem;
          }

          .text {
            font-size: 1.6rem;
          }
        }
      }
      .dt2{
        padding-bottom: 0;
      }
      h2{
        font-weight: 500;
        text-align: center;
        margin: 0 auto;
        font-size: 4.8rem;
        line-height: 133.333% ;
        text-transform: uppercase;
      }
      .dt1, .dt2{
        width: 100%;
      }
    }
  }
  .site_footer .row2 .link, .site_footer .row2 .text{
    font-size: 10px;
  }
}
@media(max-width: 767px){
  .questions_suggestions{
    .div_flex{
      background-position: bottom;
      .dt1 .row2 {
          .link {
            font-size: 1.2rem;
          }
          .text {
            font-size: 1.2rem;
          }
        }
      h2{
        font-size: 3rem;
        line-height: 150% ;
      }
    }
  }
}
</style>