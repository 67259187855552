<template>
  <div class="block_wrap survey_main"
       :style="[ imageResize ]">
    <container>
      <div class="block_min">
        <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
        <div v-if="dataInfo.question?.question && theme == 'survey'" class="div_subtitle fs4">{{ dataInfo.question?.question[0].value }}</div>
        <loader
            v-if="isLoading"
            :animation-duration="3000"
            :size="60"
        ></loader>
        <template v-else-if="theme == 'survey'">
          <div class="survey_list fs4n"
               v-if="dataInfo.question && dataInfo.question.answerVotes?.length > 0"
          >
            <div class="fake_radio fake_radio2"
                 v-for="answerVotesItem in dataInfo.question.answerVotes"
                 :key="answerVotesItem.id"
                 :class="{'checked': answerVotesItem == checkedEl}"
                 @click="checkedEl = answerVotesItem"
            >
              {{ answerVotesItem.value }}
            </div>
          </div>
          <div class="div_btn">
            <cl-button @click="changeResult">Проголосовать</cl-button>
          </div>
        </template>
        <template v-else>
          <div class="div_text">Спасибо за ваш голос, нам очень важно ваше мнение!</div>
          <div class="survey_result"
               v-if="questionResult && questionResult.length > 0"
          >
            <div class="survey_result_line"
                 v-for="answerVotesItem in questionResult"
                 :key="answerVotesItem.description"
            >
              <div class="dt1">
                {{ Math.round(answerVotesItem.percent) }}%
                <div><img src="/img/ico/check-circle2.svg" alt=""></div>
              </div>
              <div class="dt2">
                <div class="div_text">{{ answerVotesItem.description }}</div>
                <div class="div_line">
                  <div class="div_line_inner" :style="{width: answerVotesItem.percent + '%'}"></div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </container>
  </div>
</template>

<script>

export default {
  name: "surveyMain",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      checkedEl: false,
      theme: 'survey',
      isLoading: false,
      questionResult: false,
      questionParams: {},
      imageResize: null,
    }
  },
  methods: {
    changeResult() {
      this.isLoading = true;
      let randomNum;
      if(document.cookie.indexOf('mainSurvey') != -1) {
          randomNum = this.getCookie().mainSurvey;
      } else {
          randomNum = Math.random() * 1000000000000000000;
          var expires = (new Date(Date.now() + 24 * 60 * 60 * 1000)).toUTCString();
          document.cookie = `mainSurvey=${randomNum}; expires=${expires}; path=/;`;
      }
      //document.cookie = "mainSurvey=true; max-age=0"

      this.questionParams.id = this.dataInfo.question.question[0].id;
      this.questionParams.answer = this.checkedEl.id;
      this.questionParams.session = randomNum;
      this.axios
          .post(`/api/data/vote`,null,{params: this.questionParams})
          .then(response => {
            this.questionResult = response.data.data;
            this.isLoading = false;
            this.theme = 'result';
          })
          .catch(error => {

          })
    },
    getCookie() {
      return document.cookie.split('; ').reduce((acc, item) => {
          const [name, value] = item.split('=')
          acc[name] = value
          return acc
      }, {})
    },
    getImageMob(){
      if(window.innerWidth > 991 || !this.dataInfo.imageMob){
        this.imageResize = this.getBkgrImage(this.dataInfo.image, 'fullHd');
      } else{
        this.imageResize = this.getBkgrImage(this.dataInfo.imageMob, 'hd');
      }
    },
  },
  mounted() {
    //document.cookie.indexOf("mainSurvey") == 0
    if(document.cookie.indexOf('mainSurvey') != -1){
      this.changeResult();
    }
    this.getImageMob();
    window.addEventListener("resize", () => {
      this.getImageMob();
    });
  }
}
</script>

<style lang="scss" scoped>
.survey_main {
  padding: 111px 0 143px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  h2 {
    margin-bottom: 16px;
    max-width: 800px;
  }
  @media (max-width: 1400px) {
    padding: 55px 0 70px 0;
    //h2{
    //  font-size: 3.2rem;
    //}
  }

  .div_subtitle {
    margin-bottom: 24px;
  }

  .div_text {
    margin-bottom: 24px;
  }
  .survey_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
  }
}
.survey_result {
  display: flex;
  flex-direction: column;
  gap: 21px 0;
  .survey_result_line {
    display: flex;
    .dt1 {
      flex: none;
      text-align: right;
      width: 36px;
      margin-right: 12px;
    }
    .div_text {
      font-weight: 500;
      margin-bottom: 5px;
    }
    .div_line {
      border-radius: 100px;
      height: 6px;
      max-width: 300px;
      background: #17191C;
      overflow: hidden;
      .div_line_inner {
        background-color: #ED850A;
        height: 100%;
        border-radius: 100px;
      }
    }

  }
}

@media (max-width: 991px) {
  .survey_main{
    padding-top: 54%;
    padding-bottom: 32px;
    background-position: center top;
    .fake_radio::before{
      min-width: 21px;
      width: 21px;
      height: 21px;
    }
  }
}
</style>