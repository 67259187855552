<template>
  <div class="pos_rel" :id="id + 'wrap'">
    <datepicker locale="ru"
                :format-locale="formatLocale"
                format="dd.MM.yyyy"
                :name="name"
                @change="$emit('datetime', date)"
                v-model="date"
                select-text=""
                cancel-text=""
                auto-apply
                :id="id"
                :uid="id"
                :teleport="'#' + id + 'wrap'"
                :alt-position="() => ({ top: heightPole, left: 0 })"
    />
  </div>
</template>

<script>
/**
 * Компонент поля даты
 * name - имя для сериализации формы
 * value - базовое значение
 */
import Datepicker from '@vuepic/vue-datepicker';
import { ru } from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "Datetime",
  components: { Datepicker },
  props: {
    name: String,
    value: String,
    id: String,
    uid: String,
  },
  data() {
    return {
      date: new Date(this.value.split(".")[2], this.value.split(".")[1] - 1, this.value.split(".")[0], ) ?? null,
      formatLocale: ru,
      heightPole: 0
    }
  },
  mounted() {
    this.heightPole = document.getElementById('dp-input-' + this.id).offsetHeight + 10 + 'px'
  }
}
</script>
