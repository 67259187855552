<template>
  <how-to-get v-if='blockComponent == "howToGet"' :dataInfo="dataInfo" :id="id"/>
</template>

<script>
import howToGet from "@/components/howToGet.vue";

export default {
  name: "templateDynamic4",
  components: {
    howToGet
  },
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
    id: {
      type: String,
    },
    blockComponent: {
      type: String,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>