<template>
  <div class="block_wrap coming_platform">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
    </container>
      <div class="coming_platform_wrap"
           v-if="dataInfo.listCard && dataInfo.listCard.length > 0"
      >
        <swiper
            :modules="modules"
            :slides-per-view="'auto'"
            class="coming_platform_slide slide100h sample-slider"
            :loop="true"
            :spaceBetween="20"
            :speed="4000"
            :freeMode="true"
            :autoplay="true"
            :breakpoints="{991: {
              spaceBetween: 64,
              speed: 2000,
            }}"
            @mouseenter="autoplayStart"
            @mouseleave="autoplayStop"
        >
          <swiper-slide
              v-for="listCardItem in dataInfo.listCard"
              :key="listCardItem.title"
          >
            <div class="coming_platform_el">
              <div class="div_img_hover">
                <div class="div_img" :class="{'not_img': !listCardItem.image?.value?.src?.fullHd?.url}"><img
                    v-if="listCardItem.image?.value" :src="getImage(listCardItem.image, 'fullHd')">
                </div>
                <div class="div_text">
                  <div class="div_text1 fs40">{{ listCardItem.title.value }}</div>
                  <div class="div_text2 fs4n" v-if="listCardItem.description"
                       v-html="listCardItem.description.value"></div>
                </div>
              </div>
              <div class="div_tit fs40">{{ listCardItem.title.value }}</div>
            </div>
          </swiper-slide>
        </swiper>


      </div>

<!--
      <h3>вседа прокрутка остановка по наведению, сбоку обалсти продолжения движения, но элемент уже не наводится</h3>
      <div class="coming_platform_wrap"
           v-if="dataInfo.listCard && dataInfo.listCard.length > 0"
           style="position:relative;"
      >
        <div style="position:absolute; left: -48px;top: 0;bottom: 0;width: 300px;background-color: red; opacity: .1; z-index: 10;"></div>
        <div style="position:absolute; right: -48px;top: 0;bottom: 0;width: 300px;background-color: red; opacity: .1; z-index: 10;"></div>
        <swiper
            :modules="modules"
            :slides-per-view="'auto'"
            class="coming_platform_slide slide100h sample-slider"
            :loop="true"
            :spaceBetween="64"
            :speed="2000"
            :freeMode="true"
            :autoplay="{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }"
        >
          <swiper-slide
              v-for="listCardItem in dataInfo.listCard"
              :key="listCardItem.title"
          >
            <div class="coming_platform_el">
              <div class="div_img_hover">
                <div class="div_img" :class="{'not_img': !listCardItem.image?.value?.src?.fullHd?.url}"><img
                    v-if="listCardItem.image?.value" :src="getImage(listCardItem.image, 'fullHd')">
                </div>
                <div class="div_text">
                  <div class="div_text1 fs40">{{ listCardItem.title.value }}</div>
                  <div class="div_text2 fs4n" v-if="listCardItem.description"
                       v-html="listCardItem.description.value"></div>
                </div>
              </div>
              <div class="div_tit fs40">{{ listCardItem.title.value }}</div>
            </div>
          </swiper-slide>
        </swiper>


      </div>
-->





      <subscribe-email v-if="dataInfo.form"
                             :dataGuid="dataInfo.form.value"
                             :titleForm="dataInfo.textForm?.value"
                             class="coming_platform_subscribe"/>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay, Mousewheel, Navigation} from "swiper";
import SubscribeEmail from "@/components/subscribeEmail.vue";
import {mapGetters} from "vuex";

export default {
  name: "comingOnPlatform",
  components: {
    SubscribeEmail,
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {}
  },
  setup() {
    const autoplayStop = (swiper) => {
      swiper.target.swiper.autoplay.stop();
    };
    const autoplayStart = (swiper) => {
      swiper.target.swiper.params.autoplay.delay = 0;
      swiper.target.swiper.params.autoplay.disableOnInteraction = false;
      swiper.target.swiper.autoplay.start();

    };
    return {
      autoplayStop,
      autoplayStart,
      modules: [Autoplay, Mousewheel, Navigation],
    }
  },
  methods: {},
  mounted() {
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  }
}
</script>

<style lang="scss">
.coming_platform {
  padding: 82px 0 110px 0;

  h2 {
    margin-bottom: 0;
  }
}

.coming_platform_slide {
  //display: flex;
  //gap: 0 64px;
  //margin: 0 -48px;
  padding: 0 48px;
  padding-top: 60px;
  .swiper-slide {
    width: 390px;
  }
}

.coming_platform_el {
  width: 390px;
  flex: none;

  &:nth-child(1),
  &:nth-child(5),
  &:nth-child(10) {
    .div_text {
      right: 0;
      bottom: 0;
    }

    &:hover {
      .div_text {
        transform: translate(39px, 50px);
      }
    }
  }

  &:nth-child(2),
  &:nth-child(6),
  &:nth-child(11) {
    .div_text {
      left: 0;
      top: 0;
    }

    &:hover {
      .div_text {
        transform: translate(-39px, -50px);
      }
    }
  }

  &:nth-child(3),
  &:nth-child(7),
  &:nth-child(12) {
    .div_text {
      left: 0;
      bottom: 0;
    }

    &:hover {
      .div_text {
        transform: translate(-39px, 50px);
      }
    }
  }

  &:nth-child(4),
  &:nth-child(8),
  &:nth-child(13) {
    .div_text {
      right: 0;
      top: 0;
    }

    &:hover {
      .div_text {
        transform: translate(39px, -50px);
      }
    }
  }

  &:hover {
    z-index: 10;
    .div_img {
      transform: scale(1.2);
    }

    .div_tit {
      opacity: 0;
    }

    .div_text {
      opacity: 1;
    }
  }

  .div_img_hover {
    margin-bottom: 12px;
    position: relative;
  }

  .div_img {
    border-radius: 8px;
    overflow: hidden;
    height: 500px;
    transition: transform .3s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .div_tit {
    font-weight: 500;
    letter-spacing: 0.8px;
  }

  .div_text {
    border-radius: 8px 0px 0px 8px;
    background: rgba(255, 255, 255, 0.55);
    backdrop-filter: blur(13px);
    padding: 24px;
    color: #000106;
    opacity: 0;
    transition: all .3s ease;
    position: absolute;
  }

  .div_text1 {
    margin-bottom: 16px;
    font-weight: 500;
  }

  .div_text2 {

  }
}

@media (max-width: 991px) {
  .coming_platform {
    padding: 32px 0;
    .coming_platform_slide{
      padding: 24px 24px 0;
      .swiper-slide {
        width: 240px;
        .coming_platform_el{
          width: 240px;
          .div_img{
            height: 350px;
          }
          .fs40 {
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 133.333%;
            letter-spacing: 0.36px;
          }
          .div_text{
            padding: 16px;
            transform: translate(12px, 17px);
          }
          &:hover {
            .div_img {
              transform: scale(1);
            }

            .div_tit {
              opacity: 1;
            }

            .div_text {
              opacity: 0;
            }
          }
        }
      }
    }
  }
  .subscribe_form_wrap.coming_platform_subscribe{
    padding-top: 36px !important;
  }
  .swiper-slide-active{
    .coming_platform_el{
      z-index: 10;
      .div_img {
        transform: scale(1.1);
      }

      .div_tit {
        opacity: 0;
      }

      .div_text {
        opacity: 1;
      }
    }
  }
  .subscribe_form{
    flex-direction: column;
  }
}
</style>