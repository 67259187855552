<template>
  <div class="grid-block-wrapper" :class="blockInfo.isWhiteBg && 'white-bg'" v-if="dataInfo">
    <container>
      <h2>{{blockInfo.title}}</h2>
      <div class="grid-block">
        <div v-for="item of grid" :key="grid" class="grid-block-item" :class="item.isHorizontal && 'is-horizontal'">
          <div class="img"><img :src="item.img?.value?.src?.reference?.url" alt=""></div>
          <div class="text-content">
            <h3 class="title">{{item.title.value}}</h3>
            <div class="text" v-html="item.text.value"></div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    blockInfo() {
      let temp = this.dataInfo.blockInfo
      for (let item in temp) {
        temp[item] = temp[item].value
      }
      return temp
    },
    grid() {
      return this.dataInfo.grid
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-block {
  display: grid;
  grid-template-rows: 1fr 1.57fr;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "item1 item2" "item1 item3";
  grid-gap: 24px;
  &-wrapper {
    padding: 68px 0;
    &.white-bg {
      background-color: #fff;
      color: #000106;
    }
  }
  &-item {
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.03),
    0 4px 9px 0 rgba(0, 0, 0, 0.03),
    0 17px 17px 0 rgba(0, 0, 0, 0.03),
    0 37px 22px 0 rgba(0, 0, 0, 0.02),
    0 66px 27px 0 rgba(0, 0, 0, 0.00),
    0 104px 29px 0 rgba(0, 0, 0, 0.00);
    &.is-horizontal {
      display: flex;
      gap: 24px;
      .img {
        width: 50%;
      }
    }
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        grid-area: item+$i;
      }
    }
    .img {
      margin-bottom: 24px;
      grid-area: img;
      img {
        width: 100%;
      }
    }
    .title {
      margin-bottom: 24px;
      grid-area: title;
    }
    .text {
      grid-area: text;
      font-size: 24px;
      line-height: 1.33;
    }
  }
  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;//286px 286px
    &-item {
      padding: 16px;
      gap: 0 !important;
      &:nth-child(1) {
        .img {
          height: 358px;
        }
      }
      &.is-horizontal {
        flex-direction: column;
      }
      .img {
        width: 100% !important;
        margin-bottom: 24px;
        height: 126px;
        border-radius: 8px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .title {
        font-size: 16px;
        margin-bottom: 8px;
        font-weight: 500;
      }
      .text {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
</style>