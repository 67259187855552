<template>
  <div class="bg">
    <container>
      <div class="bg_er_fon"><img src="../assets/img/st/bg-error.svg" alt=""></div>
      <div class="cont">
        <template v-if="errorUrlType == '403'">
          <div class="contTit">
            {{errorUrlType}}
          </div>
          <div class="contDesc">
            Кажется что-то пошло не так!
          </div>
          <cl-button @click="$router.push(`/`)">на главную</cl-button>
        </template>
        <template v-else>
          <div class="contTit">
            404
          </div>
          <h4 class="contSubtit">
            Страница не найдена
          </h4>
          <div class="contDesc">
            Кажется что-то пошло не так! Неправильно набран адрес или такой страницы не существует
          </div>
          <cl-button @click="$router.push(`/`)">на главную</cl-button>
        </template>

      </div>
    </container>
  </div>
</template>

<script>
export default {
  name: 'notFound',
  props: {
    errorUrlType: {
      type: Number,
    },
  },
}
</script>

<style lang="scss" scoped>
.bg {
  flex: 1;
}
.container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
}
.bg_er_fon {
  position: absolute;
  left: calc(50% + 129px);
  top: calc(50% - 192px);
}

.cont {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  height: 100vh;
  position: relative;

  &Tit {
    font-weight: 500;
    font-size: 200px;
    line-height: 1;
    margin-bottom: 25px;
  }

  &Subtit {
    margin-bottom: 30px;
  }

  &Desc {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .cont {
    &Tit {
      font-size: 150px;
    }
  }
  .bg_er_fon {
    transform: rotate(-15deg);
    left: calc(50% + 142px);
    top: calc(50% - 219px);
  }
}

@media (max-width: 767px) {
  .bg_er_fon {
    transform: rotate(-15deg);
    left: calc(50% + 50px);
    top: calc(50% - 235px);
  }
  .cont {
    &Tit {
      font-size: 120px;
      margin-bottom: 20px;
    }
    &Subtit {
      margin-bottom: 30px;
    }
    &Desc {
      margin-bottom: 20px;
    }
  }
}
</style>