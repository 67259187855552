<template>
  <container>
    <h1 class="hasSubtit hasSubtitProf">Мои заявки</h1>

    <div class="filter_block filter_block2" v-if="filterShow">
      <div class="dt w252">
        <multiselect
            v-model="filter.selectSelectedStatus"
            :options="filter.selectOptionsStatus"
            placeholder="Статус"
            track-by="idStatus"
            label="title"
            class="light"
            @select="selectChangeStatus"
            :searchable="false"
            :allow-empty="false"
        ></multiselect>
      </div>
      <div class="dt">
        <multiselect
            v-model="filter.selectSelectedFinansial"
            :options="filter.selectOptionsFinansial"
            placeholder="Тип меры"
            track-by="value"
            label="name"
            class="light"
            @select="selectChangeFinansial"
            :searchable="false"
            :allow-empty="false"
        ></multiselect>
      </div>
    </div>

    <loader
        v-if="isLoading"
        :animation-duration="3000"
        :size="60"
    ></loader>
    <not-info v-else-if="applications && applications.length == 0 && (Object.keys(getParams).length > 1 || (Object.keys(getParams).length == 1 && getParams.measureSupports != measureSupportStart))"
              :title="Object.keys(getParams).length == 0 ? `Здесь пока ничего нет` : `Ничего не нашлось`"
              :text="Object.keys(getParams).length == 0 ? `В данный момент заявки отсутствуют` : `Нет результатов, удовлетворяющих критериям поиска. Попробуйте сбросить фильтры`"
    >
      <template v-slot:btn v-if="Object.keys(getParams).length > 0">
        <div class="div_btn">
          <cl-button @click="this.deleteParams">Сбросить фильтры</cl-button>
        </div>
      </template>
    </not-info>
    <template v-if="!isLoading && applications && applications.length > 0">
      <row class="row_mb">
        <transition-group name="fade-base2">
          <column
              v-for="(event, index) in applications"
              :key="event.id"
          >
            <card-support-measure :event="event"/>
          </column>
        </transition-group>
      </row>
      <div v-if="data?.page < data?.pages" class="show_more">
        <loader
            v-if="isLoadingMore"
            :animation-duration="1500"
            :size="55"
        ></loader>
        <cl-button v-else @click="loadMore" type="light sm-w-100">показать еще</cl-button>
      </div>
    </template>

    <div v-if="!isLoading && (applications && applications.length == 0) && Object.keys(getParams).length == 1 && getParams.measureSupports == measureSupportStart" class="fs4n text_not_info">В данный момент заявки отсутствуют </div>


  </container>

</template>

<script>
import {mapGetters} from "vuex";
import cardSupportMeasure from "@/components/CardSupportMeasure.vue";
import {nextTick} from "vue";

export default {
  name: "MyApplications",
  components: {cardSupportMeasure},
  data() {
    return {
      data: false,
      applications: null,

      filterShow: false,

      isLoadingMore: false,
      isLoading: true,
      getParams: {},
      measureSupportStart: '',

      filter: {
        selectSelectedStatus: [],
        selectOptionsStatus: [
          {
            id: 0,
            idStatus: 1,
            title: "новая",
          },
          {
            id: 1,
            idStatus: 2,
            title: "в работе",
          },
          {
            id: 2,
            idStatus: 3,
            title: "завершена",
          },
          {
            id: 3,
            idStatus: 4,
            title: "одобрена",
          },
          {
            id: 4,
            idStatus: 5,
            title: "отклонена",
          },
          {
            id: 5,
            idStatus: 6,
            title: "черновик",
          },
        ],
        selectSelectedFinansial: [],
        selectOptionsFinansial: [],
      },


      controller: null
    }
  },

  methods: {
    getMeasureSupportList(isLoadMore) {
      if (!isLoadMore) {
        delete this.getParams.page;
        this.isLoading = true;
      }
      return this.axios
          .get(`/api/get/measure-services`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            if (isLoadMore) {
              this.applications = [...this.applications, ...response.data.records];
            } else {
              this.applications = response.data.records;
              this.showLoader = false;
            }
            this.data = response.data;
            this.isLoading = false;
            if(!this.filterShow && this.applications && this.applications.length > 0){
              this.filterShow = true;
            }
          })
          .catch(error => {
            this.showError(error);
          });
    },
    loadMore() {
      this.isLoadingMore = true;
      this.getParams.page = ++this.data.page;
      this.$nextTick(() => {
        this.getMeasureSupportList(true).then(() => {
          this.isLoadingMore = false
        })
      });
    },
    // получение мер
    getFinansial() {
      return this.axios
          .get(`/api/data/applications-all`, {signal: this.controller.signal})
          .then(response => {
            this.filter.selectOptionsFinansial = response.data;
            let idList = [];
            [...this.filter.selectOptionsFinansial].map(item => {
              idList.push(item.value);
            });
            this.$nextTick(() => {
              this.measureSupportStart = idList.join();
              this.getParams.measureSupports = this.measureSupportStart;
              this.filter.selectOptionsFinansial.unshift({
                "name": "Все меры",
                "value": this.measureSupportStart
              });

              this.getMeasureSupportList();
            });
          })
          .catch(error => {
            this.showError(error);
          });
    },
    // Тип меры
    selectChangeFinansial() {
      if (this.filter.selectSelectedFinansial.length < 1) {
        delete this.getParams.measureSupports
      } else {
        this.getParams.measureSupports = this.filter.selectSelectedFinansial.value;
      }
      this.getMeasureSupportList();
    },
    //Статус
    selectChangeStatus() {
      if (this.filter.selectSelectedStatus.length < 1) {
        delete this.getParams.claimStatus
      } else {
        this.getParams.claimStatus = this.filter.selectSelectedStatus.idStatus;
      }
      this.getMeasureSupportList();
    },
    deleteParams() {
      this.filter.selectSelectedStatus = [];
      this.filter.selectSelectedFinansial = []
      this.getParams = {};
      this.getParams.measureSupports = this.measureSupportStart;
      this.getMeasureSupportList();
    },
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    //this.getMeasureSupportList();
    this.getFinansial();
  },
  beforeUnmount() {
    // отмена запросов
    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style scoped lang="scss">
.text_not_info {
  color: #C7CAD1;
}
.w252 {
  @media (min-width: 600px) {
    width: 252px;
  }
}
.filter_block2 {
  margin-bottom: 24px;
}
</style>