<template>
  <div class="blog_list blog_list_block">
    <container class="gray_fon">
      <h2>{{ dataInfo.dataBlock.titleBlock.value }}</h2>
      <div class="blog_list_cont">
      <template v-for="(blog, index) in dataInfo.SelectedBlogs">
        <router-link :to="'/blog/single_blog/' + blog.blogsList.id " class="blog_list_elem">
          <div class="img_wrap">
            <template v-if="blog.blogsList.logo && blog.blogsList.logo.url !== null">
              <img draggable="false" :src="blog.blogsList.logo.url" alt="">
            </template>
            <template v-else>
              <img draggable="false" class="noneImage" src="" alt="">
            </template>
          </div>
          <div class="block_authr block_auth2">
            <div class="auth_desc">
              <div class="logo_authr">
                <template v-if="blog.blogsList.author.logo !== null && blog.blogsList.author.logo.url !== null">
                  <img :src="blog.blogsList.author.logo.url" alt="">
                </template>
                <template v-else>
                  <div class="mask_logo">
                    {{ blog.blogsList.author.name.split(" ").map((word) => word[0]).join('') }}
                  </div>
                </template>
              </div>
              <div class="auth_stat">
                <div class="auth_name" v-if="blog.blogsList.author.name && blog.blogsList.author">
                  {{ blog.blogsList.author.name }}
                </div>
                <div class="auth_sub">
                  <div class="auth_date" v-if="blog.blogsList.datePublication">
                    {{ $filters.ruDate4(new Date(blog.blogsList.datePublication)) }}
                  </div>
                  <div class="auth_watch" v-if="blog.blogsList.viewsCount !== null">
                    <img src="/img/ico/eye.svg" alt="">
                    {{ blog.blogsList.viewsCount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="text_content" v-if="blog.blogsList.name && blog.blogsList.name !== null">
              {{ blog.blogsList.name }}
            </div>
          </div>
        </router-link>
      </template>
    </div>
    </container>
  </div>
</template>

<script>
export default {
  name: "cardsBlog",
  data(){
    return{}
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.container{
  max-width: 144rem;
  &.gray_fon{
    padding: 12rem;
    background: #0C0C0E;
    border-radius: 2.4rem;
  }
}
.blog_list{
  margin-top: 140px;
  margin-bottom: 200px;
  h2{
    text-align: center;
  }
  &.blog_list_block{
    margin: 30px 0;
  }
  .blog_list_cont {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }
  &.block_auth2{

  }
  .blog_list_elem{
    width: calc(33.333% - 16px);
    border-radius: 8px;
    overflow: hidden;
    background: #17191C;
    .img_wrap{
      height: 250px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .noneImage{
        background: #d9d9d9;
      }
    }
    .text_content{
      margin-bottom: 16px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 1.8rem;
      p, ul, h1, h2, h3, li{
        margin-bottom: 0;
      }
      img{
        display: none;
      }
    }
    .logo_authr, .mask_logo{
      border-radius: 50%;
      overflow: hidden;
      width: 36px;
      height: 36px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .mask_logo{
      background: #ED850A;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media(max-width: 1025px){
    margin-top: 120px;
    .blog_list_elem{
      width: calc(33% - 16px);
    }
  }
  @media(max-width: 991px){
    margin-bottom: 80px;
    .blog_list_elem{
      width: calc(50% - 16px);
    }
  }
  @media(max-width: 767px){
    .blog_list_elem{
      width: calc(100%);
    }
  }
}

@media(max-width: 991px){
  h2{
    font-size: 3.2rem;
  }
  .blog_list{
    &.blog_list_block{
      margin: 3rem 4rem;
      .container{
        padding: 6rem 2.4rem;
      }
      .blog_list_elem{
        width: 100%;
        .img_wrap{
          height: 33.4rem;
        }
      }
    }
  }
}

@media(max-width: 767px){
  .blog_list {
    &.blog_list_block {
      margin: 3rem 0;
      .blog_list_elem{
        width: 100%;
        .img_wrap{
          height: 26rem;
        }
      }
    }
  }
}

</style>