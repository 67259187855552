<template>
  <documents-and-agreements v-if='blockComponent == "documentsAndAgreements"' :dataInfo="dataInfo" :id="id"/>
  <banner-form v-else-if='blockComponent == "bannerForm"' :dataInfo="dataInfo" :id="id"/>
  <events-list v-else-if='blockComponent == "eventsList"' :dataInfo="dataInfo" :id="id"/>
  <excursions-cinema v-else-if='blockComponent == "excursionsCinema"' :dataInfo="dataInfo" :id="id"/>
</template>

<script>/**
 * dataInfo - данные приходящие в блок
 * id - якорь блока
 * blockComponent - название компонента (которое прописывается в админке)
 */

import DocumentsAndAgreements from "@/components/documentsAndAgreements.vue";
import BannerForm from "@/components/library/bannerForm.vue";
import EventsList from "@/components/eventsList.vue";
import ExcursionsCinema from "@/components/excursionsCinema.vue";

export default {
  name: "templateDynamic2",
  components: {ExcursionsCinema, EventsList, BannerForm, DocumentsAndAgreements},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
    id: {
      type: String,
    },
    blockComponent: {
      type: String,
    }
  }
}
</script>