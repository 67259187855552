<template>
  <footer class="block_wrap questions_suggestions site_footer site_footerEngland">
    <div class="div_flex" v-if="dataInfo.image" :style="getBkgrImage(dataInfo.image,'fullHd')">
      <container class="div_flex">
<!--        <h2 v-if="dataInfo.title && widthSite <= 991">{{dataInfo.title?.value}}</h2>-->
        <div class="dt1">
          <h2 v-if="dataInfo.title">{{dataInfo.title?.value}}</h2>
          <div class="content">
          <div class="row1">
            <div class="info">
              <div class="dt_info">
                <div v-if="dataInfo.email" class="r_info">
                  <div class="image">
                    <img src="/img/ico/mail.svg" alt="" class="img_wh">
                  </div>
                  <div class="text">{{ dataInfo.email?.value }}</div>
                </div>
              </div>

            </div>


          </div>
          <div class="row2">
            <a v-if="dataInfo.linkNamePol && dataInfo.file"
               :href="dataInfo.file?.value?.src"
               class="link clOr">{{ dataInfo.linkNamePol?.value }}</a>
            <a v-else-if="dataInfo.linkNamePol && dataInfo.linkPol"
               :href="dataInfo.linkPol?.value"
               class="link clOr">{{ dataInfo.linkNamePol?.value }}</a>
            <div v-if="dataInfo.textRight" class="text">
              <a v-if="dataInfo.textRightFile"
                 :href="dataInfo.textRightFile?.value?.src"
                 class="link clOr">{{  dataInfo.textRight?.value }}</a>
              <template v-else>
                {{ dataInfo.textRight?.value }}
              </template>
            </div>
          </div>
        </div>
        </div>
        <div class="dt2">
          <h3 class="color-or">Форма обратной связи</h3>
          <measure-form-component
              v-if="dataInfo.form"
              :measureGuid="dataInfo.form.value"
              :showMode="showMode"
              :formParams="formParams"
              class="loader_ab"
          />
        </div>
      </container>
    </div>
  </footer>
</template>

<script>

import MeasureFormComponent from "@/components/measureForm.vue";
import Container from "@/components/library/Container";
import {mapGetters} from "vuex";

export default {
  name: "footerForm",
  components: {Container, MeasureFormComponent},
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      showMode: 'inline',
      formParams: {
        preSetParams: {},
        'appendCss': '/override/footer_form.css',
      },
    }
  },
  methods: {
    hrefUrl(url) {
      if (url.includes("https")) {
        location.href = url;
      } else if (url.includes("/") && !url.includes("http")) {
        this.$router.push({path: url});
      }
    }
  },
  mounted() {
      this.formParams.preSetParams["widget"] = 'personalConfirm';
      this.formParams.preSetParams["linkpart"] = 'согласие';
      this.formParams.preSetParams["filelink"] = this.dataInfo.file?.value?.src;
  },
  computed:{
    ...mapGetters([
      'widthSite'
    ])
  },
};
</script>

<style lang="scss" scoped>
.questions_suggestions {
  padding-top: 5rem;

  h2 {
    margin-bottom: 3.2rem;
  }

  .div_flex {
    display: flex;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    gap: 50px;
  }

  .dt1 {
    padding: 3.2rem 0 6.6rem 0;
    display: flex;
    flex-direction: column;
    //width: 50%;
    max-width: 942px;
    width: 100%;
    //max-width: 68.4rem;
    max-height: 1000px;

    h2 {
      font-weight: 500;
      font-size: 6.5rem;
      line-height: 147.962%;
      text-transform: uppercase;
      max-width: 88.2rem;
      margin: auto 0;
    }


    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .dt2 {
    flex: none;
    width: 532px;
    //width: calc(50% - 17.5rem);
    margin-left: auto;
    padding: 3.2rem 0;

    h3 {
      margin-bottom: 2.4rem;
      text-transform: uppercase;
    }
  }
  @media (max-width: 1440px) {
    .dt1 {
      width: 49.74%;
      max-width: 100%;
    }

    .dt2 {
      width: 39.7%;
    }
  }
}

.site_footer {
  margin-top: auto;
  .content {
    display: flex;
    flex-direction: column;
    gap: 4.2rem 2.4rem;
    @media (max-width: 1024px) {
      gap: 40px 24px;
    }
  }

  .row1 {
    display: flex;
    justify-content: space-between;
    align-self: baseline;

    .info {
      display: flex;
      flex-direction: column;
      gap: 4.8rem;
      width: 100%;

      .dt_info {
        display: flex;
        justify-content: center;
        gap: 8px;
        width: 100%;

        .r_info {
          display: flex;
          gap: 2.4rem;

          .image {
            width: 3.2rem;
            height: 3.2rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .text {
            font: {
              size: 2.4rem;
              weight: 500;
            }
            line-height: 133.33%;
            color: #ED850A;
            align-self: center;
          }
        }
      }
    }
    @media (max-width: 1024px) {
      .info .dt_info .r_info .image {
        width: 32px;
        height: 32px;
      }
      .info .dt_info .r_info .text {
        font-size: 24px;
      }
    }

  }

  .row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 0;

    .link, .text {
      color: #B2B5BB;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 125%;

      &.clOr {
        color: #ED850A;
        line-height: 125%;

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: .1rem;
          background-color: #ED850A;
        }
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      .link,
      .text {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 991px) {
  .questions_suggestions {
    .div_flex {

      .dt1 {
        width: 40%;
        padding-bottom: 4.5rem;

        h2 {
          font-size: 2.4rem;
          text-align: left;
          line-height: 133.33%;
          letter-spacing: 0.048rem;
          max-width: 28rem;
        }
      }

      .dt2 {
        width: 49%;
        padding-bottom: 0;

        h3 {
          font-size: 2.4rem;
          //margin-bottom: 1.6rem;
        }
      }
    }
  }
  .site_footer {
    .content {
      gap: 4rem;
    }

    .row1 .info .dt_info .r_info {
      .text {
        font-size: 1.8rem;
      }
    }

    .row2 {
      flex-direction: column;
      align-items: baseline;
      gap: 1.2rem;

      .link {
        font-size: 1.4rem;
      }

      .text {
        font-size: 1.6rem;
      }
    }
  }
}

@media(max-width: 767px) {
  .questions_suggestions {
    .div_flex {
      background-position: bottom;
      flex-direction: column-reverse;
      gap: 0;
      .dt1 {
        padding-top: 2.4rem;
        width: 100%;

        h2 {
          max-width: 32rem;
          text-align: center;
          margin: 0 auto .8rem auto;
        }
      }

      .dt2 {
        width: 100%;

        h3 {
          text-align: center;
        }
      }

    }
  }
  .site_footer {
    .content {
      gap: 4rem;
      align-self: center;
    }

    .row1 .info .dt_info .r_info {
      .text {
        font-size: 1.8rem;
      }
    }

    .row2 {
      align-items: center;
    }
  }
}
</style>