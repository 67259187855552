<template>
	<container>
		<h1 class="hasSubtit hasSubtitProf">Проекты</h1>

		<row class="trusted_persone">
			<div id="loader-measure-ms-form" class="loader-trusted" v-if="loaderProjects">
				<loader
					:animation-duration="3000"
					:size="60"
					:color="'#ED850A'"
				></loader>
			</div>
			<column lg="3" md="6">
				<card card-type="trusted" class="trusted_add" @click="modalProjectsAddShow = !modalProjectsAddShow">
					<div class="div_icon">
						<img src="/img/ico/user-plus.svg" alt="">
					</div>
					<div class="div_tit">
						Добавить проект
					</div>
				</card>
			</column>

			<column lg="3" md="6" v-for="item in projects">
				<card card-type="trusted">
					<div class="trusted_row1">
						<div class="div_tag">Проект</div>
						<div class="div_buttons">
							<div class="btn_edit btn_img" @click="modalProjectsEditShow = !modalProjectsEditShow; activeProjects = {'guid': item.guid, 'name': item.name}">
								<img src="/img/ico/pencil.svg" alt="">
							</div>
							<div class="btn_clear btn_img" @click="modalProjectsDeleteShow = !modalProjectsDeleteShow; activeProjects = {'guid': item.guid, 'name': item.name}">
								<img src="/img/ico/trashOrg.svg" alt="">
							</div>
						</div>
					</div>
					<div class="trusted_row2">
						{{ item.name }}
					</div>
<!--					<div class="trusted_row3">-->
<!--						ID заявки: 112233445-->
<!--					</div>-->
				</card>
			</column>
		</row>
		<cl-button type="default" class="btn-to-location" :routerLink="'/arenda_kinopomecheniy/pavilony'">Перейти к выбору локации</cl-button>

		<cl-modal v-model="modalProjectsAddShow" class="modal-trusted" :closeFonActive="false" :closeNone="true">
			<ms-form :urlData="'/api/add/film/projects/'" :submitName="'Добавить'" :btnCloseModal="true" @closeModal="modalProjectsAddShow = false" @ok="okProjects" :title="'Создание нового проекта'"/>
		</cl-modal>
		<cl-modal v-model="modalProjectsEditShow" class="modal-trusted" :closeFonActive="false" :closeNone="true">
			<ms-form :urlData="`/api/update/film/projects/${activeProjects.guid}`" :submitName="'Сохранить'" :btnCloseModal="true" @closeModal="modalProjectsEditShow = false" @ok="okProjects" :title="'Редактирование проекта'"/>
		</cl-modal>
		<cl-modal v-model="modalProjectsDeleteShow" modal-type="center" class="modal-trusted" :closeFonActive="false" :closeNone="true">
			<div class="delete_person">
				<div class="delete_person_img">
					<img src="/img/ico/trashOrg.svg" alt="">
				</div>
				<div class="delete_person_tit">Вы точно хотите удалить проект "{{ activeProjects.name }}"?</div>
				<div class="delete_person_buttons">
					<cl-button class="btn-light btn-middle sp_img btn-ico" img="/img/ico/cancel.svg" @click="modalProjectsDeleteShow = false">Отменить</cl-button>
					<cl-button class="btn-middle  sp_img btn-ico" img="/img/ico/checkWhite.svg" @click="removeProjects">Подтвердить</cl-button>
				</div>
			</div>
		</cl-modal>

    <cl-modal v-model="modalProjectsOk" modal-type="center">
      <template v-slot:header>
        <div class="success_block">
          <img src="/img/ico/check-circle-2.svg" alt="">
          <div>{{ `Проект ${modalProjectsName} успешно сохранен!`}}</div>
        </div>
        <div class="success_block_dop">Теперь он доступен для выбора в новых заявках</div>
      </template>
      <template v-slot:footer>
       <router-link to="/" class="btn btn-default m-0-auto">На главную</router-link>
      </template>
    </cl-modal>


	</container>
</template>

<script>

import Card from "@/components/library/Card.vue";
import MsForm from "@/components/forms/MsForm.vue";
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";

export default {
	name: "filmProjects",
	components: {ClButton, ClModal, MsForm, Card},
	props: {
		dataInfo: {
			type: Object,
			require: true
		},
	},
	data(){
		return{
			projects: null,
			modalProjectsAddShow: false,
			modalProjectsEditShow: false,
			modalProjectsDeleteShow: false,
			activeProjects: null,
			loaderProjects: true,
      modalProjectsOk: false,
      modalProjectsName: null
		}
	},
	computed: {
		...mapGetters([
			'getUser'
		]),
	},
	methods: {
		getProjects(){
			this.loaderProjects = true;
			this.axios
				.get(`/api/get/list/film/projects/`)
				.then(response => {
					this.projects = response.data.records;
				})
				.catch(error => {
					this.showError(error);
				})
				.finally(() => {
					this.loaderProjects = false;
				});
		},
		removeProjects(){
			this.modalProjectsDeleteShow = false;
			this.loaderProjects = true;
			this.axios
				.post(`/api/delete/film/projects/${this.activeProjects.guid}`)
				.then(response => {
					this.getProjects();
				})
				.catch(error => {
					this.showError(error);
				})
				.finally(() => {
					this.loaderProjects = false;
				});
		},
    okProjects(){
      this.modalProjectsName = document.getElementById('film_projects_name').value;
      this.modalProjectsOk = true;
      this.getProjects();
    }
	},
	mounted(){
		this.getProjects();
	}
}
</script>

<style lang="scss" scoped>
.trusted_advantages{
	margin-top: 2.4rem;
	img{
		width: 64px;
		height: 64px;
		object-fit: contain;
	}
}
.trusted_persone{
	margin-top: 3.6rem;
	position: relative;
	.modal_bkcg{
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 1, 6, 0.80);
	}
	.modal_info{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 32px 24px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		border-radius: 6px;
		background: #17191C;
		max-width: 800px;
	}
	.mInfo_row2{
		display: flex;
		gap: 12px;
		height: 64px;
		.mInfo_info{
			display: flex;
			gap: 12px;
			align-items: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 131.25%;
			letter-spacing: 0.24px;
		}
		img{
			height: 100%;
			width: auto;
			object-fit: contain;
		}
	}
}
.delete_person{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2.4rem;

	.delete_person_tit{
		text-align: center;
		font-size: 2.4rem;
		font-style: normal;
		font-weight: 500;
		line-height: 133.333%;
		letter-spacing: 0.048rem;
		color: #fff;
	}

	.delete_person_img{
		width: 64px;
		height: 64px;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
.delete_person_buttons{
	display: flex;
	gap: 1.6rem;
	align-items: center;
}
.loader-trusted{
	position: absolute;
	width: 100%;
	height: 100%;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 10;
}
</style>