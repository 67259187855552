<template>
  <div>
    <measure-form-component
        :measureGuid="measureGuid"
        :showMode="showMode"
        :formParams="formParams"
    />
  </div>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm.vue";

export default {
  name: "FormValidationTest",
  components: {MeasureFormComponent},
  data() {
    return {
      measureGuid: 'cf0181b61a8b425abb84c3d21dfc8c7a',
      showMode: 'inline',
      formParams: {
        'appendCss': '/override/oneline.css',
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>