<template>
  <div class="calendar_wrap"
       :class="{'calendar_mob calendar_wh': btnCalendar && !btnCalendarType, 'calendar_mob calendar_bl': btnCalendar && btnCalendarType, 'show': btnCalendar && showCalendar, 'filter_yes': modelValue, 'calendar_right': positionRight}"
       v-click-away="hideCalendarAway" ref="datackerEl">
    <template v-if="btnCalendar">
      <cl-button
          v-if="btnCalendarType"
          class="btn-ico-right wh_nowrap"
          :img="showCalendar ? '/img/ico/chevron-up.svg' : '/img/ico/chevron-down-orange.svg'"
          :class="{'btn-light': !showCalendar, 'img_wh': showCalendar}"
          @click="showCalendar = !showCalendar">
        <template v-if="!dateBtn">Другая дата</template>
        <template v-else-if="modelValue.start">{{ btnDateText }}</template>
      </cl-button>
      <cl-button
          v-else
          type="calendar btn-light btn-ico"  @click="showCalendar = !showCalendar">
        <div class="dot_red"></div>
      </cl-button>
    </template>
    <teleport :to="teleport" :disabled="!teleport" v-if="isMounted">
      <div :class="{'show': btnCalendar && showCalendar && teleport, 'calendar_mob calendar_bl': btnCalendar && btnCalendarType && teleport, 'calendar_teleport': teleport, 'calendar_right': positionRight && teleport}">
        <div class="calendar_cont">
          <div class="calendar_btn_tit d-md-none">
            <div class="cbt_dt1">Календарь</div>
            <div class="cbt_dt2 close_ico" @click="hideCalendar"></div>
          </div>
          <!-- ошибка v-model : v-model="modelValue" :value="modelValue" -->
          <v-date-picker
              class="datepicker-inline"

              :modelValue="modelValue"
              @update:modelValue="modelValue = $event"

              :attributes="attributes"
              :select-attribute='selectAttribute'
              :drag-attribute='dragAttribute'
              :is-range="period"
              color="orange"
              is-dark
              is-expanded
              title-position="left"
              :min-date="minDate"
              @update:from-page="fromPageCalendar"
          >
            <template v-slot:day-popover="{ format, day, dayTitle, attributes }">
              <div class="popover_cal">
                <div class="text-xs text-gray-300 font-semibold text-center">
                  {{ dayTitle }}
                </div>
                <ul>
                  <template
                      v-for="{key, customData} in attributes"
                      :key="key"
                  >
                    <li
                        v-if="key <= 5"
                    >
                      {{ customData }}
                    </li>
                    <li v-else-if="key == 6" class="popover_li_other">..........</li>
                  </template>
                </ul>
              </div>
            </template>
            <template v-slot:footer v-if="btnCalendar">
              <div class="v-btn-bot">
                <template v-if="btnCalendarType && period">
                  <cl-button
                      @click="mobSelected"
                      v-if="modelValue && JSON.stringify(dateFilter) != JSON.stringify(modelValue)"
                      class="w-100 btn-middle"
                  >Выбрать {{ dateTitle }}
                  </cl-button>
                  <cl-button
                      @click="clearSelectedDate"
                      type="wh"
                      class="w-100 btn-middle"
                      v-if="(modelValue && JSON.stringify(dateFilter) == JSON.stringify(modelValue))"
                  >Отменить
                  </cl-button>
                </template>
                <template v-else>
                  <cl-button
                      @click="mobSelected"
                      :disabled="!modelValue"
                  >применить
                  </cl-button>
                  <cl-button
                      @click="clearSelectedDate"
                      type="light"
                  >сбросить
                  </cl-button>
                </template>
              </div>
            </template>
          </v-date-picker>
          <transition name="fade-base">
            <div class="datepicker-btn"
                 v-if="modelValue && !btnCalendar"
            >
              <cl-button
                  @click="clearSelectedDate"
                  type="light w-100"
              >сбросить период
              </cl-button>
            </div>
          </transition>
        </div>
        <div class="shadow_over" v-if="btnCalendar && showCalendar" @click="hideCalendar"></div>
      </div>

    </teleport>
  </div>

</template>

<script>
/**
 * dateBtn показывать даты или текст в кнопке
 * dateFilter даты используемые для фильтрации, если выборка из календаря применяется тока по кнопке
 * teleport - нужен ли teleport и куда
 *
 * */

export default {
  name: "ClCalendar",
  components: {},

  props: {
    attributes: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Array,
      default: ""
    },
    minDate: {
      type: String
    },
    dateBtn: {
      type: Boolean
    },
    dateFilter: {
      type: Object
    },
    btnCalendar: {
      type: Boolean,
      default: true
    },
    btnCalendarType: {
      type: String
    },
    teleport: {
      type: String
    },
    period: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      dragAttribute: {
        highlight: {
          color: 'orange',
          fillMode: 'outline',
          class: 'highlight_num',
        }
      },
      selectAttribute: {
        highlight: {
          color: 'orange',
          fillMode: 'outline',
          class: 'highlight_num',
        }
      },
      showCalendar: false,
      positionRight: false,
      btnCalendarShow: false,
      isMounted: false,
      btnDateText: ''
    }
  },
  watch: {
    modelValue() {
      if(!this.btnCalendar){
        this.$emit('update:modelValue', this.modelValue);
        this.$emit('change', this.modelValue);
      }
    },
    showCalendar() {
      if(window.innerWidth <= 500 && this.btnCalendar) {
        if (this.showCalendar) {
          document.getElementsByClassName('site_body')[0].classList.add('overflow-hidden');
        } else {
          document.getElementsByClassName('site_body')[0].classList.remove('overflow-hidden');
        }
      }
      if(document.getElementsByClassName('filter_mob_ser')[0] && window.innerWidth > 500 && window.innerWidth <= 991 && this.btnCalendar) {
        if (this.showCalendar) {
          document.getElementsByClassName('filter_mob_ser')[0].classList.add('overflow-visible');
        } else {
          document.getElementsByClassName('filter_mob_ser')[0].classList.remove('overflow-visible');
        }
      }
    }
  },
  methods: {
    fromPageCalendar(item) {
      this.$emit('fromPageCalendar', item);
    },
    clearSelectedDate() {
      this.$emit('update:modelValue', null);
      this.$emit('clear', null);
      if(this.btnCalendar){
        this.showCalendar = false
      }
      this.btnCalendarShow = false;
    },
    hideCalendarAway(el){
      if(!el.target.closest('.calendar_teleport')){
        this.hideCalendar();
      }
    },
    hideCalendar(){
      this.showCalendar = false
    },
    mobSelected(){
      this.$emit('update:modelValue', this.modelValue);
      this.$emit('change', this.modelValue);
      this.btnDateTextFun();
      this.showCalendar = false;
      this.btnCalendarShow = true;
    },
    btnDateTextFun(){
      let text = '';
      const dataStart = new Date({...this.modelValue}.start),
          dataEnd = new Date({...this.modelValue}.end);
      if(dataStart.setHours(0, 0, 0, 0) == dataEnd.setHours(0, 0, 0, 0)){
        text = this.$filters.ruDate4(dataStart);
      } else {
        if(dataStart.getFullYear() == dataEnd.getFullYear()){
          if(dataStart.getMonth() == dataEnd.getMonth()){
            text = 'с ' + dataStart.getDate() + ' по ' + dataEnd.getDate() + ' ' + dataStart.toLocaleDateString("ru", {
              month: 'long',
              formatMatcher: 'basic'
            }).split(', ').reverse().join(', ');
          } else {
            text = 'с ' + this.$filters.ruDate2(dataStart) + ' по ' + this.$filters.ruDate2(dataEnd)
          }
        } else {
          text = 'с ' + this.$filters.ruDate4(dataStart) + ' по ' + this.$filters.ruDate4(dataEnd)
        }
      }
      this.btnDateText = text
      //return text;
    }
  },
  computed: {
    dateTitle(){
      let text = '';
      const dataStart = new Date(this.modelValue.start),
          dataEnd = new Date(this.modelValue.end),
          dataСurrent = new Date();
      dataStart.setHours(0, 0, 0, 0);
      dataEnd.setHours(0, 0, 0, 0);
      dataСurrent.setHours(0, 0, 0, 0);
      if(dataStart.getTime() == dataEnd.getTime()){
        text = this.$filters.ruDate2(dataStart)
      } else {
        if(dataStart.getFullYear() == dataСurrent.getFullYear() && dataEnd.getFullYear() == dataСurrent.getFullYear()){
          if(dataStart.getMonth() == dataСurrent.getMonth() && dataEnd.getMonth() == dataСurrent.getMonth()){
            text = dataStart.getDate() + ' - ' + dataEnd.getDate() + ' ' + dataStart.toLocaleDateString("ru", {
              month: 'long',
              formatMatcher: 'basic'
            }).split(', ').reverse().join(', ');
          } else {
            text = this.$filters.ruDate2(dataStart) + ' - ' + this.$filters.ruDate2(dataEnd)
          }
          //text = this.$filters.ruDate2(dataStart) + ' - ' + this.$filters.ruDate2(dataEnd)
        } else {
          text = this.$filters.ruDate3(dataStart) + ' - ' + this.$filters.ruDate3(dataEnd)
        }
      }
      return text;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if(this.btnCalendar && ((window.innerWidth - this.$refs.datackerEl.getBoundingClientRect().left) < 300) || this.teleport){
        this.positionRight = true;
      }
    });
    this.btnCalendarShow = this.modelValue;
    this.$nextTick(() => {
      this.isMounted = true;
    });
  }
}
</script>

<style scoped lang="scss">
.calendar_mob {
  position: relative;
  display: inline-block;
  //vc-container
  .calendar_btn_tit {
    padding: 15px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }
  .calendar_cont {
    background: #ffffff;
    position: absolute;
    display: none;
    top: 70px;
    left: -10px;
    z-index: 3;
    border-radius: 7px;
    &:before {
      content: "";
      border: 5px solid transparent;
      border-bottom: 5px solid #ffffff;
      position: absolute;
      left: 33px;
      top: -10px;
    }
    .v-btn-bot {
      display: flex;
      justify-content: space-between;
    }
    @media (min-width: 700px) {
      min-width: 390px;
    }
  }
  &.calendar_right {
    .calendar_cont {
      left: auto;
      right: -10px;
      &:before {
        left: auto;
        right: 33px;
      }
    }
  }
  .dot_red {
    position: absolute;
    top: -15px;
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #E74362;
  }
  &.show {
    .btn-calendar {
      position: relative;
    }
    .calendar_cont {
      display: block;
    }
  }
  @media (max-width: 500px) {
    &.calendar_wh .calendar_cont {
      position: fixed;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0;

      display: block;
      transform: translateY(100%);
      transition: transform .3s ease, z-index .3s ease;
      z-index: -1;

      .vc-container {
        padding: 15px 20px 20px 20px;
        border-radius: 0;
      }
      &:before {
        display: none;
      }
    }
    &.show {
      .calendar_cont {
        transform: translateY(0);
        z-index: 9999;
      }
    }
    .shadow_over {
      display: block;
      z-index: 999;
    }

    .calendar_top_tit {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      display: flex;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

}
.calendar_bl.calendar_mob .calendar_cont {
  background: #000000;
  padding: 32px;
  &:before {
    border-bottom: 5px solid #000000;
  }
  .datepicker-inline .vc-weekday {
    border: none;
    margin: 0;
  }
  .datepicker-inline .vc-header {
    margin-bottom: 15px;
  }

  @media (max-width: 767px) {
    padding: 16px;
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    &:before {
      display: none;
    }
    .calendar_btn_tit {
      color: #fff;
      padding: 0 12px;
    }
    .close_ico {
      background: url(../../assets/img/ico/close.svg) no-repeat center center;
      background-size: contain;
      filter: brightness(0) invert(1);
      width: 24px;
      height: 24px;
    }
  }
}
.calendar_teleport {
  .calendar_cont {
    top: auto;
  }
}

</style>