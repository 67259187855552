<template>
    <div class="rutube-video block_wrap_eng">
        <container>
            <h2 class="dt_tit fs4" v-if="dataInfo.title?.value">{{ dataInfo.title.value }}</h2>
            <div class="rutube-content">
                <div class="rutube-loader" v-if="isLoadingVideo">
                    <loader
                        v-if="isLoadingVideo"
                        :animation-duration="3000"
                        :size="60"
                    ></loader>
                </div>
                <swiper
                    v-else
                    :spaceBetween="24"
                    :navigation="true"
                    :threshold='10'
                    :modules="modules"
                    :loop="widthSite > 992 ? dataInfo.list.length > 3 : dataInfo.list.length > 2"
                    :slidesPerView="'auto'"
                    class="slide100h arrow_center arrow_center2 arrow_center_op arrowForeignLanguage"
                    :lazy="true"
                    :breakpoints="{
                  992: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 2.5,
                  },
                  300: {
                    slidesPerView: 1.4,
                  }
                }"
                >
                    <swiper-slide v-for="item in dataInfo.list" @click="infoVideo.name = item.name.value; infoVideo.iframe = item.iframe; showModal = !showModal">
                        <div class="card-video">
                            <div class="card-preview" v-if="item.preview">
                                <img :src="item.preview">
                            </div>
                            <div class="card-content">
                                <div class="div_name" v-if="item.name?.value">{{ item.name.value }}</div>
                                <div class="card-channel">
                                    <div class="icon-channel" v-if="item.imgChannel">
                                        <img :src="getImage(item.imgChannel)" alt="">
                                    </div>
                                    <div class="info-channel">
                                        <div class="channel-name" v-if="item.nameChannel?.value">{{ item.nameChannel.value }}</div>
                                        <div class="hits" v-if="item.hits">{{ item.hits }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </container>
        <cl-modal modal-type="center" class="modal-rutube" v-model="showModal">
<!--            <template v-slot:header>-->
<!--                <div class="div_name" v-if="infoVideo.name">{{ infoVideo.name }}</div>-->
<!--            </template>-->
            <template v-slot:body>
                <div v-if="infoVideo.iframe" v-html="infoVideo.iframe"></div>
            </template>
        </cl-modal>
    </div>
</template>

<script>
import Container from "@/components/library/Container.vue";
import Column from "@/components/library/Column.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";

export default {
    name: "video-rutube",
    components: {
        ClModal,
        Swiper,
        SwiperSlide,
    },
    props: {
        dataInfo: {
            type: Object,
            require: true,
        }
    },
    data() {
        return {
            infoVideo: {
                name: null,
                iframe: null,
            },
            showModal: false,
            isLoadingVideo: true,
            langViews: {
              ru: ' тыс. просмотров',
              en: 'K Views'
            }
        }
    },
    setup() {
        return {
            modules: [Navigation, Pagination],
            videos: null,
        }
    },
    computed:{
        ...mapGetters([
            'widthSite'
        ])
    },
    methods:{
        getVideos(){
            this.isLoadingVideo = true;
            if(this.dataInfo.list?.length > 0){
                let promises = [];
                this.dataInfo.list.forEach(item => {
                    if(item.guid?.value){
                      const promis = this.axios.get("/api/data/rutube", {
                            params:{
                                id: item.guid.value,
                            }
                        })
                        .then(response => {
                            item.preview = response.data.thumbnailUrl;
                            item.iframe = response.data.html;
                            if(item.showViews){
                              if (response.data.hits > 1000){
                                item.hits = Math.trunc(response.data.hits / 1000) + this.langViewsFun()
                              } else {
                                item.hits = "";
                              }
                            }
                            promises.push(promis);
                        })
                    }
                });
                Promise.all(promises)
                    .then(() => {
                      this.isLoadingVideo = false;
                    });
            }
        },
        langViewsFun(){
            let text = this.langViews[this.$route.params.id];
            if(!text){
              text = this.langViews['ru'];
            }
            return text;
        }
    },
    mounted() {
        this.getVideos();
    }
};
</script>

<style lang="scss" scoped>
.rutube-loader{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rutube-video{
    padding: 4.8rem 0;
    .dt_tit{
        text-transform: uppercase;
        padding: 1.2rem 0;
    }
    .rutube-content{
        position: relative;
        min-height: 10rem;
    }
    .card-preview{
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        border-radius: 1.6rem;
        overflow: hidden;
        margin-bottom: 1.2rem;
        img{
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .div_name{
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 600;
        line-height: 133.333%;
        letter-spacing: 0.048rem;
        margin-bottom: 0.4rem;
    }
    .card-channel{
        display: flex;
        gap: 1.2rem;
    }
    .icon-channel{
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 0.8rem;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .info-channel{
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 131.25%;
        letter-spacing: 0.024rem;
        color: #606060;
    }
}
@media(max-width: 991px){
  .block_wrap_eng {
    .dt_tit {
      font-size: 2.4rem;
    }
  }
  .rutube-video{
    .card-video{
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .card-preview{
      margin-bottom: .8rem;
      border-radius: .8rem;
    }
    .card-content{
      display: flex;
      flex-direction: column;
      height: 100%;
      .div_name{
        font-size: 1.8rem;
        letter-spacing: 0.036rem;
      }
      .card-channel{
        gap: .76rem;
        margin-top: auto;
        .icon-channel{
          width: 3rem;
          height: 3rem;
        }
        .info-channel{
          .channel-name{
            font-size: 1.4rem;
            line-height: 142.85%;
            letter-spacing: 0.021rem;
          }
          .hits{
            font-size: 1.4rem;
            line-height: 142.85%;
            letter-spacing: 0.021rem;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .rutube-video{
    .card-video{
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 1.6rem;
    }
    .card-preview{
      margin-bottom: .626rem;
    }
    .card-content{
      .card-channel{
        gap: .626rem;
        .icon-channel{
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
  }
}
</style>