<template>
  <cl-modal v-model="modalShow" class="modal_competition">
    <div class="contet_modal_comp">
      <div class="div_content">
        <div class="div_tit" v-if="dataInfo.title?.value">
          {{ dataInfo.title.value }}
        </div>
        <div class="div_desc" v-if="dataInfo.description?.value" v-html="dataInfo.description.value"></div>
        <cl-button type="default" class="btn-middle" v-if="dataInfo.link?.value && dataInfo.linkText?.value" :routerLink="dataInfo.link.value">
          {{ dataInfo.linkText.value }}
        </cl-button>
      </div>
      <div class="div_img">
        <img :src="getImage(dataInfo.image)" alt="" v-if="widthSite > 767 || !dataInfo.imageMob">
        <img :src="getImage(dataInfo.imageMob)" alt="" v-else>
      </div>
    </div>
  </cl-modal>
</template>

<script>
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";
import {mapGetters} from "vuex";

export default {
  components: {ClButton, ClModal},
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      modalShow: true,
    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  },
};
</script>

<style lang="scss" scoped>

</style>