<template>
  <div class="subscribe_form_wrap" v-if="dataGuid || dataInfo?.form">
    <container>
      <form action="" class="subscribe_form">
        <div class="dt dt1 fs4" v-if="dataInfo?.text || titleForm" v-html="dataInfo?.text ? dataInfo.text.value : titleForm"></div>
        <div class="dt dt2">
          <measure-form-component
              :measureGuid="dataGuid ? dataGuid : dataInfo?.form.value"
              :showMode="showMode"
              :formParams="formParams"
              :formType="formType"
              :successText="successText"
              class="loader_ab emailForm"
          />

<!--          <base-input-->
<!--              class="input_line"-->
<!--              :placeholder="'Ваш e-mail'"-->
<!--          />-->
        </div>
<!--        <div class="dt dt3">-->
<!--          <cl-button>Отправить</cl-button>-->
<!--        </div>-->
      </form>

<!--      <div class="subscribe_answer">-->
<!--        <div class="div_img"><img src="/img/ico/check-circle.svg" alt=""></div>-->
<!--        <div class="div_text">Спасибо! Будем держать вас в курсе событий!</div>-->
<!--      </div>-->
    </container>

  </div>
</template>

<script>
/*
* dataGuid - гуид формы
* titleForm - заголовок формы
* dataInfo - модуль в админке содержит гуид формы dataInfo?.form.value / заголовок формы dataInfo.text.value
* */
import MeasureFormComponent from "@/components/measureForm.vue";

export default {
  name: "subscribeEmail",
  components: {MeasureFormComponent},
  props: {
    dataInfo: {
      type: Object
    },
    dataGuid: {
      type: String
    },
    titleForm: {
      type: String
    }
  },
  data() {
    return {
      showMode: 'inline',
      formParams: {
        'appendCss': '/override/flex_input_btn.css',
      },
      formType: "1",
      successText: "Спасибо! Будем держать вас в курсе событий!"
    }
  },
}
</script>

<style lang="scss" scoped>
.subscribe_form_wrap {
  background: #000;
  padding: 90px 0 100px 0;
  &.coming_platform_subscribe {
    padding: 120px 0 0 0;
  }
  @media (max-width: 767px) {
    padding: 32px 0 35px;
  }
}

.subscribe_form {
  display: flex;
  gap:24px;
  position: relative;
  justify-content: center;
  //align-items: center;
  .dt1 {
    flex: none;
    max-width: fit-content;
    @media (min-width: 992px) {
      height: 56px;
      align-items: center;
      display: inline-flex;
    }
  }
  .dt2 {
    width: 100%;
    max-width: 824px;
    //:deep(.content_form_area) {
    //  min-height: 10px;
    //}
    //:deep(iframe) {
    //  height: 56px;
    //}
  }
  .btn {
    min-height: 64px;
  }
}
.loader_ab{
  min-height: 66px;
  height: 66px;
}
@media (max-width: 767px) {
  .loader_ab{
    min-height: 100px;
    height: auto;
    //max-height: 128px;
  }
}
</style>