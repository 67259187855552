<template>
  <div class="block_wrap block_wh moschino_services fs4">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
      <div class="moschino_services_shem" v-if="dataInfo.listCards && dataInfo.listCards.length > 0">
        <div class="div_el"
             :class="'div_el' + index"
             v-for="(item, index) in dataInfo.listCards"
             :key="item.text?.value">
          <div class="div_tit" v-if="item.title" v-html="item.title.value"></div>
        </div>
        <div class="div_center">
          <h3 class="div_center_tit fs3" v-if="dataInfo.subTitle">{{ dataInfo.subTitle.value }}</h3>
          <div class="div_center_text fs21" v-if="dataInfo.text">{{ dataInfo.text.value }}</div>
        </div>
      </div>
      <div class="div_bottom" v-if="dataInfo.text1" v-html="dataInfo.text1.value"></div>
    </container>
  </div>
</template>

<script>
export default {
  name: "moschinoServices",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.moschino_services {
  padding: 68px 0 76px 0;
  overflow: hidden;
  h2 {
    margin-bottom: 70px;
  }
  .div_bottom {
    color: #9096A2;
    text-align: center;
  }
}
.moschino_services_shem {
  height: 70rem;
  margin-bottom: 12rem;
  background:  url(@/assets/img/metmash_services_shem.svg) no-repeat center 2rem;
  background-size: contain;
  position: relative;
  .div_center {
    position: absolute;
    top: 37rem;
    width: 20rem;
    left: 50%;
    transform: translateX(-50%);
    color: #FFFFFF;
    text-align: center;
    .div_center_tit {
      margin-bottom: 0;
    }
  }
  .div_el {
    width: 45rem;
    text-align: center;
    position: absolute;
    .div_tit {
      margin-bottom: 0;
    }
  }
  //574
  //287
  .div_el0 {
    top: -4rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .div_el1 {
    top: 10rem;
    left: 50%;
    transform: translateX(24rem);
  }
  .div_el2 {
    top: 28rem;
    left: 50%;
    transform: translateX(35rem);
  }
  .div_el3 {
    top: 53rem;
    left: 50%;
    transform: translateX(24.5rem);
  }
  .div_el4 {
    bottom: -4rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .div_el5 {
    top: 49rem;
    right: 50%;
    transform: translateX(-24rem);
  }
  .div_el6 {
    top: 27rem;
    right: 50%;
    transform: translateX(-27rem);
  }
  .div_el7 {
    top: 13rem;
    right: 50%;
    transform: translateX(-18rem);
  }
  @media (max-width: 991px) {
    margin-bottom: 5rem;
    background:  none;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    height: auto;
    gap: 20px;
    .div_center {
      display: none;
    }
    .div_el {
      width: calc(50% - 10px);
      transform: none;
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      .div_tit {
        text-align: left;
        font-size: 18px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 7px;
        font-weight: 500;
        &:before {
          content: "";
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          border: 2px solid #000000;
          transform: rotate(314deg);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        }
      }
      .div_text {
        text-align: left;
      }
    }
  }
  @media (max-width: 767px) {
    .div_el {
      width: 100%;
      br {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
@media (max-width: 991px) {
  .moschino_services_shem{
    .div_el {
      width: 100%;
      br {
        display: none;
      }
    }
  }
}
</style>