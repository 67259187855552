<template>
  <div class="block_wrap block_info_light">
    <container>
      <h2 v-if="dataInfo.title?.value" class="div_tit">{{ dataInfo.title.value }}</h2>
      <div v-if="dataInfo.text?.value" class="div_text"
           v-html="dataInfo.text.value"></div>
      <div v-if="dataInfo.image" class="div_img">
        <img :src="getImage(dataInfo.image, 'fullHd')" alt="">
      </div>
    </container>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";

export default {
  name: "titleImage",
  components: {Container},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.block_info_light {
  margin-top: 5.2rem;
  .div_tit {}
  .div_text {
    margin-bottom: 40px;
  }
  .div_img {
    img {
      max-width: 100%;
    }
  }
}
</style>