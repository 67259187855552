<template>
  <div class="block_wrap block_wh famous_people"
       v-if="dataInfo.list && dataInfo.list.length > 0"
  >
    <container>
      <swiper
          :navigation="true"
          :modules="modules"
          class="str_vertical"
          :direction="'horizontal'"
          :breakpoints="{800: {
            direction: 'vertical'
          }}"
          :loop="true"
      >
        <swiper-slide
            v-for="item in dataInfo.list"
            :key="item"
        >
          <div class="famous_people_el  block_min">
            <div class="dt dt1" v-if="item.image1"><img :src="getImage(item.image1, 'hd')" alt=""></div>
            <div class="dt2">
              <div class="div_text fs3n" v-if="item.text" v-html="item.text.value"></div>
              <div class="div_flex">
                <div class="div_name fs3">
                  <template v-if="item.name">{{ item.name.value }}&nbsp;</template>
                  <template v-if="item.otchestvo"> {{ item.otchestvo.value }}</template>
                  <br>
                  <template v-if="item.familiya">{{ item.familiya.value }}</template>
                </div>
                <div class="div_img" v-if="item.image2"><img :src="getImage(item.image2, 'hd')" alt=""></div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </container>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation} from "swiper";

export default {
  name: "famousPeople",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  setup() {
    return {
      modules: [Navigation]
    }
  },
}
</script>

<style lang="scss" scoped>
.famous_people {
  padding: 171px 0;
  .str_vertical {
    height: 830px;
    @media (max-width: 1400px) {
      height: 603px;
    }
  }
}
.fs3{
  font-weight: 700;
}

.famous_people_el {
  display: flex;
  .dt1 {
    width: 596px;
    margin-right: 64px;
    margin-top: 5px;
    @media (max-width: 1400px) {
      width: 422px;
      margin-right: 48px;
    }
    flex: none;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.07), 3px 3px 9px 0px rgba(0, 0, 0, 0.07), 13px 11px 17px 0px rgba(0, 0, 0, 0.06), 30px 25px 23px 0px rgba(0, 0, 0, 0.04), 53px 44px 28px 0px rgba(0, 0, 0, 0.01), 83px 69px 30px 0px rgba(0, 0, 0, 0.00);
    max-height: 775px;
    @media (max-width: 767px) {
      box-shadow: none;
    }
  }

  .dt2 {
    align-self: center;
    padding-right: 40px;
    @media (max-width: 1400px) {
      padding-right: 35px;
    }
  }

  .div_text {
    margin-bottom: 24px;
  }

  .div_flex {
    display: flex;
    align-items: center;
  }

  .div_name {
    margin-right: 43px;
    flex: none;
  }

  .div_img {
    width: 144px;
    height: 149px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 800px) {
  .famous_people{
    padding: 48px 0 68px;
    .famous_people_el{
      flex-direction: column;
    }
    .str_vertical{
      height: 100%;
      .dt1{
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 86.2%;
        img{
          width: 100%;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          object-fit: cover;
        }
        margin-bottom: 24px;
      }
      .dt2{
        padding-right: 0;
        padding-bottom: 45px;
        .fs3n{
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.21px;
        }
        .fs3{
          font-size: 1.8rem;
          font-weight: 500 !important;
          line-height: 133.333%;
          letter-spacing: 0.36px;
        }
      }
    }
  }
}
</style>