<template>
    <teleport to="#site_header_container">
        <div class="button_kinokomis">
            <div class="lenta_kino">
                <span class="item_lenta"></span>
                <span class="item_lenta"></span>
                <span class="item_lenta"></span>
            </div>
            <div class="kinokomis_content">
                <div class="kin_tit" v-if="dataInfo.text && widthSite > 768">{{ dataInfo.text?.value }}</div>
                <div class="kin_button btn btn-light2" v-if="dataInfo.textButton && widthSite > 768" @click="showForm = !showForm">{{ dataInfo.textButton?.value }}</div>
                <div class="kin_button_mob" v-else-if="dataInfo.textMobileButton" @click="showForm = !showForm">{{ dataInfo.textMobileButton?.value }} <img src="/img/ico/move-right.svg" alt=""> </div>
            </div>
            <div class="lenta_kino lenta_kino_right">
                <span class="item_lenta"></span>
                <span class="item_lenta"></span>
                <span class="item_lenta"></span>
            </div>
        </div>
    </teleport>
    <cl-modal modalType="helper" v-model="showForm" :closeFonActive="false" class=" modal_form">
      <div class="title" v-if="dataInfo.title?.value">{{ dataInfo.title.value }}</div>
      <div class="desc" v-if="!getUser">
        Для доступа к данных типам помощи, пожалуйста <a href="/connect/mosru">авторизуйтесь</a> на Киноплатформе!
      </div>
      <div class="desc" v-else-if="!getUser?.currentCompany">
        Некоторые типы помощи доступны только компаниям (юридическим лицам).<br>
        Авторизуйте компанию в <a href="/lk">личном кабинете</a> для доступа ко всем типам помощи
      </div>
      <row>
        <template v-for="item in dataInfo.list">
          <column md="6" v-if="item.isNotAuth?.value && !getUser">
            <card cardType="helper">
              <div class="ico" v-if="item.image">
                <img :src="getImage(item.image)" alt="">
              </div>
              <div class="card_title" v-if="item.title?.value">{{ item.title.value }}</div>
              <cl-button type="light" class="btn-middle" :link="true" :href="item.link?.value" target="_blank">Выбрать</cl-button>
            </card>
          </column>
          <column md="6" v-else-if="item.link?.value && !getUser">
            <card cardType="helper">
              <div class="ico" v-if="item.imageDisable">
                <img :src="getImage(item.imageDisable)" alt="">
              </div>
              <div class="card_title" v-if="item.title?.value">{{ item.title.value }}</div>
              <cl-button type="disable-background" class="btn-middle" >Выбрать</cl-button>
            </card>
          </column>
          <column md="6" v-else-if="item.link?.value && !getUser?.currentCompany && item.isCompany?.value">
            <card cardType="helper">
              <div class="ico" v-if="item.imageDisable">
                <img :src="getImage(item.imageDisable)" alt="">
              </div>
              <div class="card_title" v-if="item.title?.value">{{ item.title.value }}</div>
              <cl-button type="disable-background" class="btn-middle" >Выбрать</cl-button>
            </card>
          </column>
          <column md="6" v-else>
            <card cardType="helper">
              <div class="ico" v-if="item.image">
                <img :src="getImage(item.image)" alt="">
              </div>
              <div class="card_title" v-if="item.title?.value">{{ item.title.value }}</div>
              <cl-button type="light" class="btn-middle" :link="true" :href="item.link?.value" target="_blank">Выбрать</cl-button>
            </card>
          </column>
        </template>
      </row>
    </cl-modal>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm.vue";
import {mapGetters} from "vuex";
import Column from "@/components/library/Column.vue";
import ClButton from "@/components/library/ClButton.vue";
import {list} from "quill/ui/icons";

export default {
    name: "blockButtonKinokomis",
    components: {ClButton, Column, MeasureFormComponent},
    props: {
        dataInfo: {
            type: Object,
            require: true
        },
    },
    data(){
        return{
            showForm: false,
            showMode: 'inline',
            formParams: {
                'appendCss': '/override/measure.css',
            },
        }
    },
    computed: {
      list() {
        return list
      },
      ...mapGetters([
          'widthSite',
          'getUser'
      ])
    },
    mounted() {
      document.querySelector('body').classList.add('button_kinomiks')
    }
};
</script>

<style lang="scss" scoped>
.button_kinokomis{
    position: relative;
    display: flex;
    justify-content: center;
    padding: 8px 48px;
    background: #ED850A;
    order: 10;
    &:before{
        content: "";
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        background: linear-gradient( 120deg , transparent, rgba(255, 255, 255, 0.3) , transparent );
        animation: shine 5s ease-in-out infinite;
    }
    .kinokomis_content{
        display: flex;
        align-items: center;
        gap: 4.8rem;
    }
    .lenta_kino{
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        padding: 2px 8px;
        &_right{
            left: auto;
            right: 0;
        }
        .item_lenta{
            display: block;
            width: 20px;
            height: 12px;
            background-color: #000106;
            border-radius: 2px;
        }
    }
}

@keyframes shine {
    0% {
        left: -100%;
        transition-property: left;
    }
    45%, 100% {
        left: 100%;
        transition-property: left;
    }
}

.kin_button{
    color: white;
    border-color: white;
    padding: 9px 24px;
    line-height: 112.5%;
    min-height: initial;
    &:hover{
        border-color: white;
        background-color: rgba(255, 255, 255, 0.16);
    }
    &:active{
        background-color: white;
        color: #ED850A;
    }
    &:focus{
        border: white;
        color: white;
    }
}
@media (max-width: 991px){
    .button_kinokomis{
        .kinokomis_content{
            .kin_tit{
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 500;
                line-height: 131.25%;
                letter-spacing: 0.24px;
            }
        }
    }
}
@media (max-width: 767px){
    .button_kinokomis{
        padding: 6px 16px;
        .kinokomis_content{
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 133.333%;
            letter-spacing: 0.18px;
            .kin_button_mob{
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
        .lenta_kino{
            padding: 2px 3px;
            .item_lenta{
                width: 10px;
                height: 6px;
            }
        }
    }
}
</style>