<template>
    <div class="h_list">
        <div class="h_list_tit fs1" @click="isShowModal = true">
            <template v-if="modelValue"><span>{{ modelValue.name }}</span></template>
            <template v-else-if="selectedAll"><span>{{ selectedAllText }}</span></template>
        </div>
        <cl-modal v-model="isShowModal" modal-type="select" class="modal1200">
            <div class="div_item">
                <div class="dt1">
                    <h2 class="div_tit" v-html="title"></h2>
                    <div class="div_text" v-html="text"></div>
                </div>
                <div class="dt2">
                    <div class="div_a" v-if="selectedAll">
                        <a class="a_color2 fs4" @click="changeOption('')">{{ selectedAllText }}</a>
                    </div>
                    <div class="div_a"
                         v-for="item in options"
                         :key="item.id"
                    >
                        <a class="a_color2 fs4" @click="changeOption(item)">{{ item.name }}</a>
                    </div>
                </div>
            </div>
        </cl-modal>
    </div>
</template>

<script>
/*
* props
* title заголовок слева
* text текст под заголовкам слева
* modelValue выбранный элемент
* options список
* selectedAll - false, если не нужен пункт все
* selectedAllText текст заместо "все"
* urlType - true если у нас на странице смена адреса при сортировке
* */
export default {
    name: "ModalSelect",
    components: {},
    data() {
        return {
            isShowModal: false,
        }
    },
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        },
        title: {
            type: String
        },
        text: {
            type: String
        },
        selectedAll: {
            type: Boolean,
            default: true
        },
        selectedAllText: {
            type: String,
            default: "Все"
        },
        urlType: {
            type: String
        }
    },
    methods: {
        changeOption(item) {
            this.isShowModal = false;
            this.$emit('update:modelValue', item);
            this.$emit('change', item);
        },
        scrollTop() {
            window.scrollTo(0, 0);
        }
    },
    created() {
        if (!this.selectedAll && this.modelValue == "" && !this.urlType || (!this.selectedAll && this.modelValue == "" && this.urlType && isNaN(parseInt(new URLSearchParams(location.search).get(this.urlType))))) {
            this.$emit('update:modelValue', this.options[0]);
        }
    }
}
</script>

<style lang="scss">
.h_list {
    margin-bottom: 50px;

    .h_list_tit {
        position: relative;
        vertical-align: middle;
        display: inline-block;
        cursor: pointer;
        margin: 0;

        span {
            vertical-align: middle;
            padding-right: 30px;
        }

        &:after {
            content: "";
            background: url(../../assets/img/st/chevron-down.svg) no-repeat center center;
            display: inline-block;
            width: 20px;
            height: 20px;
            vertical-align: middle;
        }
        @media (max-width: 767px) {
            font-size: 30px;
            &:after {
              width: 14px;
              height: 14px;
            }
        }
    }
}

.modal-select {
    .div_item {
        display: flex;

        .dt1 {
            width: 50%;
            padding-right: 40px;
        }

        .dt2 {
            width: 50%;
        }
    }

    .div_tit {
        margin-bottom: 40px;
    }

    .div_text {
    }

    .div_a {
        margin-bottom: 26px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 991px) {
    .h_list {
        margin-bottom: 30px;
    }
    .modal-select {
        .div_item {
            display: block;

            .dt1 {
                width: 100%;
            }

            .dt2 {
                width: 100%;
            }
        }

        .div_tit {
            margin-bottom: 20px;
        }

        .div_text {
            margin-bottom: 30px;
        }

        .div_a {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .h_list {
        margin-bottom: 20px;

        .h_list_tit span {
            padding-right: 10px;
        }
    }
}
</style>