<template>
  <div class="measure-group" :data-grouphash="groupProp.options && groupProp.options['group_hash']" :class="{'onerow': groupProp.options['show_style'] === 'onerow'}">
    <h3 v-if="groupProp.options['show_title']">{{groupProp.title}}</h3>
    <template v-for="(prop, key) in formattedData.properties" :key="prop.propertyOrder">
      <template v-if="prop.type !== 'measure_collection_group' && prop.type !== 'measure_group'">
        <form-item :loaded="loaded"
                   :draft-saved="draftSaved"
                   @modal-open="$emit('modal-open', $event)"
                   @product-update="$emit('product-update', $event)"
                   :hidden-label="hiddenLabel" :sign-step="signStep"
                   :item="prop"
                   :propkey="key"
                   :required="groupProp.required && groupProp.required.includes(key)"
                   @form-item-value="formValue"
                   @captcha-blocked="$emit('captcha-blocked', $event)"
        />
      </template>
      <template v-else-if="prop.type === 'measure_collection_group'">
        <multigroup :loaded="loaded"
                    @new-group="$emit('new-group', $event)"
                    @save-draft="$emit('save-draft', $event)"
                    :draft-saved="draftSaved"
                    @modal-open="$emit('modal-open', $event)"
                    @product-update="$emit('product-update', $emit)"
                    :hidden-label="hiddenLabel"
                    :sign-step="signStep"
                    :group="prop"
                    :group-key="key"
                    @form-item-value="formValue"
                    :parent-proto="parentProto"
                    @captcha-blocked="$emit('captcha-blocked', $event)"
        />
      </template>
      <template v-else-if="prop.type === 'measure_group'">
        <group :loaded="loaded"
               @new-group="$emit('new-group', $event)"
               @save-draft="$emit('save-draft', $event)"
               :draft-saved="draftSaved"
               @modal-open="$emit('modal-open', $event)"
               @product-update="$emit('product-update', $emit)"
               :hidden-label="hiddenLabel"
               :sign-step="signStep"
               :group-prop="prop"
               :group-key="key"
               @form-item-value="formValue"
               :parent-proto="parentProto"
               @captcha-blocked="$emit('captcha-blocked', $event)"
        />
      </template>
    </template>
  </div>
</template>

<script>
/**
 * Компонент группы
 * groupProp - группа
 * groupKey - имя свойства группы
 * parentProto - параметр для дочерних компонентов (необходим для клонирования мультигрупп)
 */
import FormItem from "@/components/forms/FormItem";
import cloneDeep from "lodash.clonedeep"

export default {
  name: "Group",
  components: {FormItem},
  props: {
    groupProp: {
      type: Object,
      required: true
    },
    groupKey: {
      type: String
    },
    parentProto: Array,
    signStep: Boolean,
    hiddenLabel: Boolean,
    draftSaved: Boolean,
    loaded: Boolean
  },
  data() {
    return {
      formattedData: cloneDeep(this.groupProp)
    }
  },
  mounted() {
    this.setFormattedGroup()
  },
  watch: {
    parentProto() {
      this.setFormattedGroup()
    }
  },
  methods: {
    formValue(value, item, state) {
      this.$emit('form-item-value', value, item, state)
    },
    setFormattedGroup() {
      for (let prop in this.formattedData.properties) {
        if (this.parentProto && (this.formattedData.properties[prop].type !== "measure_group" && this.formattedData.properties[prop].type !== "measure_collection_group")) {
          this.parentProto.forEach(item => {
            if (this.formattedData.properties[prop].type === "iasupload") {
              const iasUpload = this.formattedData.properties[prop]
              iasUpload["full_name"] = iasUpload["full_name"].replace(item[0], item[1])
              for (let subProp in iasUpload.properties) {
                iasUpload.properties[subProp]["full_name"] = iasUpload.properties[subProp]["full_name"].replace(item[0], item[1])
              }
            } else {
              this.formattedData.properties[prop]["full_name"] = this.formattedData.properties[prop]["full_name"].replace(item[0], item[1])
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>