<template>
    <mos-school-top-filter @searchFun="searchFunPath"/>
    <container>
        <div class="banner_mos_school">
            <div class="banner_img">
                <img :src="getImage(dataInfo.image)" alt="">
            </div>
            <div class="banner_tit" v-if="dataInfo.title?.value" v-html="dataInfo.title.value"></div>
            <div class="banner_desk" v-if="dataInfo.subtitle?.value" v-html="dataInfo.subtitle.value"></div>
            <div class="banner_listCard" v-if="dataInfo.list?.length > 0">
                <row>
                    <column md="3" v-for="(item, key) in dataInfo.list">
                        <div class="banner_item">
                            <div class="item_number">{{ key + 1 }}</div>
                            <div class="item_text" v-if="item.text?.value" v-html="item.text.value"></div>
                        </div>
                    </column>
                </row>
            </div>
        </div>
    </container>
</template>

<script>

import Column from "@/components/library/Column.vue";
import Row from "@/components/library/Row.vue";
import MosSchoolTopFilter from "@/components/mosSchoolTopFilter.vue";

export default {
    name: "banner-mos-school",
    components: {MosSchoolTopFilter, Row, Column},
    props:{
        dataInfo: {
            type: Object,
            require: true
        }
    },
    methods: {
        // переход на страницу со списком
        searchFunPath(params){
            this.$nextTick(() => {
                if (Object.keys(params).length > 0) {
                    this.$router.push({path: `/kinoschools/programs`, query: JSON.parse(JSON.stringify(params))})
                } else {
                    this.$router.push({path: `/kinoschools/programs`})
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .banner_mos_school{
        position: relative;
        padding-top: 208px;
        .banner_tit{
            font-size: 14rem;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.28rem;
            text-transform: uppercase;
        }
        .banner_desk{
            font-size: 2.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: 133.333%;
            letter-spacing: 0.036rem;
            margin-bottom: 6.4rem;
            max-width: 95rem;
        }
        .banner_img{
            position: absolute;
            top: 220px;
            right: 0;
            width: 40%;
        }
        .banner_item{
            padding: 2.4rem;
            border-radius: 1.2rem;
            border: 1px solid #ED850A;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 2.4rem;
            .item_number{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 6.4rem;
                height: 6.4rem;
                border-radius: 50%;
                border: 2px solid #17191C;
                font-size: 4.8rem;
                font-style: normal;
                font-weight: 500;
                line-height: 133.333%;
                letter-spacing: 0.096rem;
                text-transform: uppercase;
            }
        }
        @media (max-width: 991px){
          .banner_tit{
            font-size: 9.6rem;
            line-height: 125%;
            letter-spacing: 0.192rem;
          }
          .banner_desk{
            font-size: 1.6rem;
            line-height: 131.25%;
            letter-spacing: 0.024rem;
            width: 55%;
          }
          .banner_img{
            top: 336px;
            width: 40%;
          }
          .banner_item{
            padding: 1.2rem;
            gap: 0.8rem;
            min-height: 11.6rem;
            font-size: 1.6rem;
            line-height: 112.5%;
            letter-spacing: 0.032rem;
            .item_number{
              width: 4.8rem;
              height: 4.8rem;
              font-size: 3.2rem;
              line-height: 150%;
              letter-spacing: 0.064rem;
              margin-bottom: 0;
            }
          }
        }
        @media (max-width: 767px){
          padding-top: 240px;
          .banner_tit{
            font-size: 4.8rem;
            line-height: 133.333%;
            letter-spacing: 0.096rem;
          }
          .banner_desk{
            margin-bottom: 1.8rem;
            width: 100%;
          }
          .banner_img{
            position: relative;
            top: 0;
            width: 100%;
            margin-bottom: 1.6rem;
          }
        }
    }
</style>