<template>
  <div :key="keyUpdate" class="form-wrapper" :class="{'form-wrapper--steps': hasSteps}">
    <div class="form-container">
      <h2 v-if="title">{{title}}</h2>
      <div class="errors_global" v-if="globalError"><img src="/img/ico/info.svg"><span v-html="globalError"></span></div>
      <form :id="formData['full_name']" :data-title="formData.title" class="measure">
        <div id="loader-measure-ms-form" class="d-none loader-measure">
            <loader
                    :animation-duration="3000"
                    :size="60"
                    :color="'#ED850A'"
            ></loader>
        </div>
        <template v-if="hasSteps !== null">
          <template v-if="!hasSteps">
            <template v-for="(prop, key) in formData.properties" :key="prop.propertyOrder">
              <template v-if="prop.type === 'measure_collection_group'">
                <multigroup
                  @modal-open="saveDraft"
                  @product-update="saveDraft"
                  :hidden-label="formData.options['hide_fields_labels']"
                  :group="prop"
                  :group-key="key"
                  :draft-saved="draftSaved"
                  @new-group="rulesDistribution"
                  @save-draft="saveDraft"
                  @form-item-value="formValue"
                  :loaded="loaded"
                  @captcha-blocked="captchaBlocked = $event"
                />
              </template>
              <template v-else-if="prop.type === 'measure_group'">
                <group
                  @modal-open="saveDraft"
                  @product-update="saveDraft"
                  :hidden-label="formData.options['hide_fields_labels']"
                  :group-prop="prop"
                  :group-key="key"
                  :draft-saved="draftSaved"
                  @new-group="rulesDistribution"
                  @save-draft="saveDraft"
                  :loaded="loaded"
                  @form-item-value="formValue"
                  @captcha-blocked="captchaBlocked = $event"
                />
              </template>
              <template v-else>
                <form-item
                  @modal-open="saveDraft"
                  @product-update="saveDraft"
                  :hidden-label="formData.options['hide_fields_labels']"
                  :item="prop"
                  :propkey="key"
                  :required="formData.required && formData.required.includes(key) || prop.required"
                  @form-item-value="formValue"
                  :draft-saved="draftSaved"
                  :loaded="loaded"
                  @captcha-blocked="captchaBlocked = $event"
                  v-if="!prop.isHidden"
                />
              </template>
            </template>
            <div class="buttons_bottom">
              <button class="btn btn-light btn-middle" type="button" @click="this.$emit('closeModal', '')">Отменить</button>
              <button class="btn btn-default btn-middle" @click="sendData">{{ submitName }}</button>
            </div>
<!--              <template v-else>-->
<!--                <button class="btn btn-primary" type="button" v-if="showBuildButton" @click.prevent="buildMeasureFiles">Сформировать заявку</button>-->
<!--                <button class="btn btn-primary" type="button" v-if="showSignButton" @click.prevent="signRequest">Подписать</button>-->
<!--                <div class="bottom-buttons">-->
<!--                  <button class="btn btn-primary" type="button" v-if="showSendButton" @click.prevent="sendData">Отправить</button>-->
<!--                  <button class="btn btn-primary" type="button" v-if="showEditButton" @click.prevent="editForm">Редактировать</button>-->
<!--                </div>-->
<!--              </template>-->
          </template>
          <template v-else>
            <template v-for="prop in formData.properties" :key="prop.propertyOrder">
              <template v-if="prop.title !== 'markStaged'">
                <div class="form-step" :class="{
                [`form-step--${prop.attr.stepmode}`]: hasSteps,
                'active': prop.propertyOrder === step
              }">
                  <div class="form-step--title">
                    <h3>{{prop.title}}</h3>
                  </div>
                  <div class="form-content">
                    <template v-for="(subProp, key) in prop.properties" :key="subProp.propertyOrder">
                      <template v-if="subProp.type === 'measure_group'">
                        <group
                          @modal-open="saveDraft"
                          @product-update="saveDraft"
                          :hidden-label="formData.options['hide_fields_labels']"
                          :sign-step="formData.options['need_sign_step']"
                          :group-prop="subProp"
                          :group-key="key"
                          :draft-saved="draftSaved"
                          @new-group="rulesDistribution"
                          @save-draft="saveDraft"
                          @captcha-blocked="captchaBlocked = $event"
                          @form-item-value="formValue" />
                      </template>
                      <template v-else-if="subProp.type === 'measure_collection_group'">
                        <multigroup
                          @modal-open="saveDraft"
                          @product-update="saveDraft"
                          :hidden-label="formData.options['hide_fields_labels']"
                          :sign-step="formData.options['need_sign_step']"
                          :group="subProp"
                          :group-key="key"
                          :draft-saved="draftSaved"
                          @new-group="rulesDistribution"
                          @save-draft="saveDraft"
                          @captcha-blocked="captchaBlocked = $event"
                          @form-item-value="formValue" />
                      </template>
                      <template v-else>
                        <form-item
                          @modal-open="saveDraft"
                          @product-update="saveDraft"
                          :hidden-label="formData.options['hide_fields_labels']"
                          :sign-step="formData.options['need_sign_step']"
                          :item="subProp"
                          :propkey="key"
                          :required="prop.required && prop.required.includes(key) || subProp.required"
                          @form-item-value="formValue"
                          :draft-saved="draftSaved"
                          @captcha-blocked="captchaBlocked = $event"
                          v-if="!subProp.isHidden" />
                      </template>
                    </template>
                  </div>
                </div>
              </template>
            </template>
            <template v-if="formData.options && formData.options['need_sign_step']">
              <div class="form-step" :class="{
                'active': step === stepsData.length
              }">
                <div class="form-step--title">
                  <h3>Проверка и подписание документов</h3>
                </div>
                <div class="doc-area-podpis">
                  <div class="dap-text">
                    Подпишите прикрепленные к заявке файлы с помощью электронной подписи
                  </div>
                  <div>
                    <button class="btn btn-primary" @click="signFileList" ref="measureSignByList" :disabled="Object.keys(filesToSign).length === 0" type="button">Подписать документы</button>
                  </div>
                  <div ref="measureSignHolder" id="measureSignHolder"></div>
                </div>
                <div class="filelist-for-sign">
                  <template v-for="(prop, key) in filesToSignInfo" :key="key">
                    <div class="file-item">
                      <div class="file-item-fieldname">{{prop.fieldName}}</div>
                      <div class="file-item-filename">Файл: {{prop.name}}</div>
                      <div class="signItemFileInfo"></div>
                      <span class="file_st_green status-hold signHold">Документ {{ prop.isSigned ? "подписан" : "загружен"}}</span>
                    </div>
                  </template>
                  <div id="signListUploadBtn" v-show="false" ref="signListUploadBtn"></div>
                  <div id="signListUploadContainer" v-show="false" ref="signListUploadContainer"></div>
                </div>
              </div>
            </template>
          </template>
        </template>
      </form>
      <div class="form-signfiles" v-if="showSignButton">
        <h4>Заявка сформирована, ее необходимо подписать</h4>
        <div v-if="signXmlLink"><a :href="signXmlLink" download>Скачать xml заявки</a></div>
        <div v-if="signPdfLink"><a :href="signPdfLink" target="_blank">Скачать pdf заявки</a></div>
        <div ref="formSignHolder" id="formSignHolder"></div>
        <div id="formSignContainer" ref="formSignContainer" v-show="false"></div>
        <div id="formSignButton" ref="formSignButton" v-show="false"></div>
      </div>
      <div class="form-sidebar" v-if="hasSteps">
        <div class="form-nav">
          <ul class="form-nav-tabs" role="tablist">
            <template v-for="item in stepsData">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': step === item.stepNum, 'complete': item.isComplete}" @click.prevent="saveDraft($event, undefined, step - 1, item.stepNum)">
                  <span class="radio_input">{{formatStepNumber(item.stepNum)}}</span>
                  <span>{{item.title}}</span>
                </a>
              </li>
            </template>
          </ul>
          <div class="buttons-block">
            <div class="step-buttons">
              <button class="btn btn-light" type="button" @click="this.$emit('closeModal', '')">Отменить</button>
              <button class="btn btn-light" type="button" v-if="step !== 1" @click.prevent="changeStep('back')">Назад</button>
              <button class="btn btn-primary" type="button" v-if="step !== Object.keys(stepsData).length" @click.prevent="saveDraft($event, true)">Далее</button>
<!--              <template v-else-if="formData.options['form_sign'].pdf || formData.options['form_sign'].xml">-->
<!--                <button class="btn btn-primary" type="button" v-if="showBuildButton && Object.keys(filesToSign).length === 0" @click.prevent="buildMeasureFiles">Сформировать заявку</button>-->
<!--                <button class="btn btn-primary" type="button" v-if="showSignButton" @click.prevent="signRequest">Подписать</button>-->
<!--                <button class="btn btn-primary" type="button" v-if="showSendButton" :disabled="blockButtons" @click.prevent="sendData">Отправить</button>-->
<!--                <button class="btn btn-primary" type="button" v-if="showEditButton" :disabled="blockButtons" @click.prevent="editForm">Редактировать</button>-->
<!--              </template>-->
              <button class="btn btn-default btn-middle" type="button" v-else @click="sendData" :disabled="blockButtons">{{ submitName }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
* urlData - url для получения формы и для отправки
* submitName - Текст кнопки отправки формы (стандартное значение "Отправить")
* btnCloseModal - props типа Boolean, определят будет ли кнопка закрытия
* title - Заголовок формы
* paramsGetForm - query параметры которые будут передваться в api
* */

import FormItem from "@/components/forms/FormItem";
import Group from "@/components/forms/Group";
import {SignPluginBlitz} from "@/plugin/SignPluginBlitz";
import plupload from "plupload";
import {guidHandler} from "@/main";
import Formula from "fparser";

export default {
  name: "MsForm",
  components: {Group, FormItem},
  props: {
    urlData: {
      type: String,
      require: true,
    },
    submitName:{
      type: String,
      default: "Отправить"
    },
    btnCloseModal:{
      type: Boolean,
      default: false,
    },
    title: {
      type: String
    },
    paramsGetForm:{
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      formData: {},
      step: 1,
      hasSteps: null,
      stepsData: [],
      formTitle: "",
      hideTitle: null,
      filesToSign: window.measureSignFileList,
      filesToSignInfo: window.measureSignFileInfoList,
      docUploadSign: null,
      signGuid: null,
      showBuildButton: true,
      showSignButton: false,
      showSendButton: false,
      showEditButton: false,
      signPdfLink: null,
      signXmlLink: null,
      keyUpdate: 0,
      loaded: false,
      draftSaved: false,
      blockButtons: false,
      xmlSign: false,
      pdfSign: false,
      captchaBlocked: false,
			globalError: '',
    }
  },
  mounted() {
    this.fetchData()
    this.signGuid = guidHandler(false)
    // this.fileListSignUploader()
  },
  methods: {
    /**
     * Функция для вывода порядкового номера шага в формате 0n, если номер меньше 10
     */
    formatStepNumber(num) {
      if (num < 10 && num > 0) {
        return `0${num}`
      }
      return num
    },
    /**
     * Функция для получения данных
     */
    async fetchData() {
      window.showLoader()
      this.loaded = false
      for (let prop in window.measureSignFileList) {
        delete window.measureSignFileList[prop]
      }
      for (let prop in window.measureSignFileInfoList) {
        delete window.measureSignFileInfoList[prop]
      }
      const resp = await fetch(this.urlData + "?" + new URLSearchParams(this.paramsGetForm), {
        method: 'get',
        headers: {
          'Accept': 'application/json;charset=utf-8'
        }
      })
      if (resp.ok) {
        try {
          const data = await resp.json()
          new Promise(resolve => {
            this.formData = data
            if(!this.formData.options && typeof this.formData.options == "undefined"){
              this.formData.options = {
                hide_fields_labels: false,
                hide_form_label: false,
                label_as_placeholders:false,
                measure_title:"",
                need_sign_step: false
              }
            }
            this.formTitle = typeof data.options !== "undefined" ? data.options["measure_title"] : ""
            this.hideTitle = typeof data.options !== "undefined" ? data.options["hide_form_label"] : ""
            if (this.hasSteps === null) {
              this.stepsData = []
              for (let item in data.properties) {
                if (item !== "markStaged") {
                  this.stepsData.push({
                    title: data.properties[item].title,
                    stepNum: data.properties[item].propertyOrder
                  })
                }
              }
              if (data.options && data.options['need_sign_step']) {
                this.stepsData.push({
                  title: "Проверка и подписание документов",
                  stepNum: this.stepsData.length + 1
                })
              }
            }
            this.hasSteps = false
            if (!this.hasSteps) {
              this.formatData(this.formData)
            } else {
              for (let item in this.formData.properties) {
                if (item !== "markStaged") {
                  this.formatData(this.formData.properties[item])
                }
              }
            }
            this.filesToSign = window.measureSignFileList
            this.filesToSignInfo = window.measureSignFileInfoList
            this.keyUpdate++
            resolve()
          }).then(() => {
            this.rulesDistribution()
            this.loaded = true


            // временный хак для отслеживания полей и показывания поля другое
            const animPoleToggle = (obj, show = true) => {
              obj.classList.add('animate_pole_textarea');
              if (show) {
                obj.classList.remove('d-none');
                setTimeout(() => {
                  obj.classList.add('animate_show');
                })
              } else {
                obj.classList.add('animate_show');
                setTimeout(() => {
                  obj.classList.remove('animate_show');
                });
                setTimeout(() => {
                  obj.classList.add('d-none');
                  if(obj.querySelector('textarea')){
                    obj.querySelector('textarea').value = '';
                  }
                  if(obj.querySelector('.textarea-counter')){
                    obj.querySelector('.textarea-counter').innerHTML = '';
                  }
                }, 350);
              }
            }
            //setTimeout(()=>{
            const pole1 = document.getElementById('film_projects_typeAudio')?.parentNode,
                pole1child = document.getElementById('film_projects_typeAudioComment')?.closest('.form-group'),
                pole2 = document.getElementById('film_projects_formatAudio')?.parentNode,
                pole2child = document.getElementById('film_projects_formatAudioComment')?.closest('.form-group'),
                pole3 = document.getElementById('film_projects_typeRental')?.parentNode,
                pole3child = document.getElementById('film_projects_typeRentalComment')?.closest('.form-group'),
                pole4 = document.getElementById('film_projects_fondiSupports'),
                pole4child = document.getElementById('film_projects_supportComment')?.closest('.form-group'),
                pole5 = document.getElementById('film_projects_nationalFilm'),
                pole5child = document.getElementById('film_projects_fileNational_file')?.closest('.form-group');

            if(pole1 && pole2 && pole3 && pole4 && pole1child && pole2child && pole3child && pole4child){
              const observer1 = new MutationObserver((mutations) => {
                if(pole1.getAttribute("data-value") && pole1.getAttribute("data-value") == "1318804"){
                  animPoleToggle(pole1child, true);
                } else {
                  animPoleToggle(pole1child, false);
                }
              });
              if(pole1.getAttribute("data-value") && pole1.getAttribute("data-value") == "1318804"){
                pole1child.classList.add('animate_show', 'animate_pole_textarea');
              } else {
                pole1child.classList.add('d-none', 'animate_pole_textarea');
              }
              observer1.observe(pole1, {attributes: true});

              const observer2 = new MutationObserver((mutations) => {
                if(pole2.getAttribute("data-value") && pole2.getAttribute("data-value") == "1318906"){
                  animPoleToggle(pole2child, true);
                } else {
                  animPoleToggle(pole2child, false);
                }
              });
              if(pole2.getAttribute("data-value") && pole2.getAttribute("data-value") == "1318906"){
                pole2child.classList.add('animate_show', 'animate_pole_textarea');
              } else {
                pole2child.classList.add('d-none', 'animate_pole_textarea');
              }
              observer2.observe(pole2, {attributes: true});

              const observer3 = new MutationObserver((mutations) => {
                if(pole3.getAttribute("data-value") && pole3.getAttribute("data-value") == "1319005"){
                  animPoleToggle(pole3child, true);
                } else {
                  animPoleToggle(pole3child, false);
                }
              });
              if(pole3.getAttribute("data-value") && pole3.getAttribute("data-value") == "1319005"){
                pole3child.classList.add('animate_show', 'animate_pole_textarea');
              } else {
                pole3child.classList.add('d-none', 'animate_pole_textarea');
              }
              observer3.observe(pole3, {attributes: true});

              async function processArray(array, type) {
                let pole4optionsSelected = []
                const promises = array.map(function (option) {
                  if(option.selected){
                    pole4optionsSelected.push(option.value)
                  }
                });
                await Promise.all(promises);

                if(type == 'start'){
                  if(pole4optionsSelected.includes("1319106")){
                    pole4child.classList.add('animate_show', 'animate_pole_textarea');
                  } else {
                    pole4child.classList.add('d-none', 'animate_pole_textarea');
                  }
                } else {
                  if(pole4optionsSelected.includes("1319106")){
                    animPoleToggle(pole4child, true);
                  } else {
                    animPoleToggle(pole4child, false);
                  }
                }
              }
              const observer4 = new MutationObserver((mutations) => {
                processArray(Array.from(pole4.options));
              });
              processArray(Array.from(pole4.options), 'start');
              observer4.observe(pole4.parentNode, {subtree: true, childList: true, characterData: true});
            }

            if(pole5 && pole5child){
              if(pole5.value){
                pole5child.classList.add('animate_show', 'animate_pole_textarea');
              } else {
                pole5child.classList.add('d-none', 'animate_pole_textarea');
              }
              pole5.addEventListener('input', function() {
                if(pole5.value){
                  animPoleToggle(pole5child, true);
                } else {
                  animPoleToggle(pole5child, false);
                }
              });
            }
            //},1000)
            // /временный хак для отслеживания полей и показывания поля другое


            window.hideLoader()
          })
        } catch (e) {
          window.showErrorMessage("При загрузке формы произошла ошибка")
        }

      } else if (resp.status >= 400 && resp.status <= 499) {
        const error = await resp.json()
        window.showErrorMessage(error)
      } else {
        window.showErrorMessage("Непредвиденная ошибка, попробуйте позже")
      }
    },
    /**
     * Функция форматирования данных
     * @param formData - контейнер с данными
     */
    formatData(formData) {
      let dataProperties = formData.properties // объект с полями для вывода
      if (!!dataProperties && Object.keys(dataProperties).length > 0) { // если данные существуют
        // цикл для задания базовых значений полей
        let groupHash = ""
        let lastItemOfMultigroup = ""
        const formatMeasCollection = (data, item) => {
          if (groupHash.length === 0) {
            groupHash = data[item].options["type_hash"]
          } else if (groupHash === data[lastItemOfMultigroup].options["type_hash"]) {
            data[lastItemOfMultigroup].options.hasAddButton = true
          } else if (data[item].options["type_hash"] !== groupHash) {
            data[lastItemOfMultigroup].options.hasAddButton = true
          }
          for (let subItem in data[item].properties) {
            switch (data[item].properties[subItem].type) {
              case "measure_collection_group":
                groupHash = ""
                lastItemOfMultigroup = ""
                formatMeasCollection(data[item].properties, subItem)
                break
              case "measure_group":
                groupHash = ""
                lastItemOfMultigroup = ""
                for (let subItem1 in data[item].properties[subItem].properties) {
                  if (data[item].properties[subItem].properties[subItem1].type === "measure_collection_group") {
                    formatMeasCollection(data[item].properties[subItem].properties, subItem1)
                  }
                }
            }
          }
          groupHash = data[item].options["type_hash"]
          lastItemOfMultigroup = item
          if (data[item].propertyOrder === Object.keys(data).length) {
            data[item].options.hasAddButton = true
          }
        }
        const labelAsPlaceholder = (data, item) => {
          if (data[item].attr && data[item].attr.placeholder) {
            delete data[item].attr.placeholder
          } else {
            for (let subItem in data[item].properties) {
              labelAsPlaceholder(data[item].properties, subItem)
            }
          }
        }
        for (let item in dataProperties) {
          if (this.formData.options["label_as_placeholders"]) {
            labelAsPlaceholder(dataProperties, item)
          }
          if (dataProperties[item].type === 'measure_collection_group') {
            formatMeasCollection(dataProperties, item)
          } else {
            if (dataProperties[lastItemOfMultigroup]) {
              formatMeasCollection(dataProperties, lastItemOfMultigroup)
            }
          }
          if (!dataProperties[item].hasOwnProperty("value")) { // если нет value
            dataProperties[item].value = null                    //  задаем его
          }
        }
      }
    },
    /**
     * Функция для события, всплывающего из элемента формы
     * @param value - значение элемента
     * @param prop - сам элемент
     * @param state - состояние элемента (для правил)
     */
    formValue(value, prop, state) {
      if (prop.type !== "iasupload") {
        prop.value = value
        prop.state = state
      } else {
        for (let item in prop.properties) {
          prop.properties[item].value = value[item]
        }
      }
    },
    rulesDistribution(event) {
      const dataRules = this.formData.options?.rules
      if(dataRules){
        const masterFields = new Set(dataRules.map(item => item.paramValueId))

        const actions = {
          show(slaveItem) {
            slaveItem.style.display = ""
            slaveItem.querySelectorAll(".form-control").forEach(item => item.removeAttribute("disabled"))
          },
          hide(slaveItem) {
            slaveItem.style.display = "none";
            slaveItem.querySelectorAll(".form-control").forEach(item => {
              item.value = ""
              item.dispatchEvent(new Event("change"))
              item.dispatchEvent(new Event("input"))
              item.setAttribute("disabled", true)
            })
          },
          required(slaveItem) {
            slaveItem.querySelectorAll(".form-control").forEach(item => item.setAttribute("required", "required"))
            if (slaveItem.querySelector(".form-label")) {
              slaveItem.querySelector(".form-label").classList.add("required")
            } else if (slaveItem.querySelector(".col-form-label")) {
              slaveItem.querySelector(".col-form-label").classList.add("required")
            }
          },
          notRequired(slaveItem) {
            slaveItem.querySelectorAll(".form-control").forEach(item => item.removeAttribute("required"))
            if (slaveItem.querySelector(".form-label")) {
              slaveItem.querySelector(".form-label").classList.remove("required")
            } else if (slaveItem.querySelector(".col-form-label")) {
              slaveItem.querySelector(".col-form-label").classList.remove("required")
            }
          },
          calculation(masterItem, condition) {
            let cond = condition
            const fieldsArray = condition.replace(/[\+|\-|\*|\/|\(|\)]/g, " ").replace("  ", " ").trim().split(" ")
            fieldsArray.forEach(item => {
              if (isNaN(parseFloat(item))) {
                cond = cond.replace(item, `[${item}]`)
              }
            })
            const formula = new Formula(cond.split(" ").join(""))
            fieldsArray.forEach(field => {
              if (isNaN(parseFloat(field))) {
                const targetField = masterItem.closest(".multiholder") ? masterItem.closest(".multiholder").querySelector(`[id*="${field}"]`) : document.querySelector(`[id$="${field}"]`)
                targetField.addEventListener("input", () => {
                  const vars = {}
                  fieldsArray.forEach(item => {
                    if (isNaN(parseFloat(item))) {
                      const targetItem = masterItem.closest(".multiholder") ? masterItem.closest(".multiholder").querySelector(`[id*="${item}"]`) : document.querySelector(`[id$="${item}"]`)
                      vars[item] = Number(targetItem.value.replace(",", ".")) || 0
                    }
                  })
                  if (!isNaN(formula.evaluate(vars)) && isFinite(formula.evaluate(vars))) {
                    masterItem.value = formula.evaluate(vars)
                    masterItem.value = Number(masterItem.value).toFixed(2).replace(".", ",")
                    masterItem.dispatchEvent(new Event("input"))
                  }
                })
              }
            })
          }
        }
        const checkInList = (rule, value) => {
          let conclusion
          if (Array.isArray(value)) {
            [...value].forEach(item => {
              if (rule.value.split(",").includes(item)) {
                conclusion = true
                return 0
              }
              conclusion = false
            })
          } else {
            conclusion = rule.value.split(",").includes(value)
          }
          return rule.state === "inlist" ? conclusion : !conclusion
        }
        const checkChosen = (rule, checked) => {
          let conclusion
          if (rule.state === "chosen" || rule.state === "notchosen") {
            conclusion = rule.state === "chosen"
          } else {
            conclusion = !Boolean(rule.state)
          }
          return checked === conclusion
        }
        const firstInit = (master, event) => master.dispatchEvent(event)

        masterFields.forEach(fieldName => {
          if (document.querySelector(`[id*="${fieldName}"]`)) {
            const masterCollection = document.querySelectorAll(`[id*="${fieldName}"]`)
            const slavesFields = dataRules.filter(item => item.paramValueId === fieldName && item.ruleType === "forField")
            const slaveGroups = dataRules.filter(item => item.paramValueId === fieldName && item.ruleType === "forGroup")
            let managedId
            if (dataRules.findIndex(item => item.paramValueId === fieldName && item.condition) !== -1) {
              managedId = dataRules[dataRules.findIndex(item => item.paramValueId === fieldName && item.condition)].managedParamValueId
            }
            const slaveHandler = (slave, rule, valid) => {
              if (!slave.classList.contains("under-rules")) {
                slave.classList.add("under-rules")
              }
              if (valid) {
                if (rule.action === "show") {
                  actions.show(slave)
                } else if (rule.action === "required") {
                  actions.required(slave)
                }
              } else {
                if (rule.action === "hide") {
                  actions.hide(slave)
                } else if (rule.action === "notrequired") {
                  actions.notRequired(slave)
                }
              }
              if (rule.action === "formulaСalculation") {
                [...document.querySelectorAll(`[id*="${managedId}"]`)].forEach(item => {
                  if (typeof item !== "undefined") {
                    actions.calculation(item, rule.condition)
                  }
                })
              }
            }

            [...masterCollection].forEach(master => {
              if (master.nodeName === "SELECT") {
                const isSelectValid = (rule, value) => {
                  let isValid
                  switch (rule.action) {
                    case "show":
                    case "required":
                      isValid = checkInList(rule, value)
                      break
                    case "hide":
                    case "notrequired":
                      isValid = !checkInList(rule, value)
                      break
                  }
                  return isValid
                }
                master.addEventListener("select", () => {
                  if (slavesFields.length) {
                    if (master.closest(".multiholder")) {
                      slavesFields.forEach(rule => {
                        slaveHandler(master.closest(".multiholder").querySelector(`[data-singleid="${rule.managedParamValueId}"]`), rule, isSelectValid(rule, master.value))
                      })
                    } else {
                      slavesFields.forEach(rule => {
                        slaveHandler(document.querySelector(`[id$="${rule.managedParamValueId}"]`), rule, isSelectValid(rule, master.value))
                      })
                    }
                  }
                  if (slaveGroups.length) {
                    if (master.closest(".multiholder")) {
                      slaveGroups.forEach(rule => slaveHandler(master.closest(".multiholder").querySelector(`[data-grouphash*="${rule.managedGroup}"]`), rule, isSelectValid(rule, master.value)))
                    } else {
                      slaveGroups.forEach(rule => slaveHandler(document.querySelector(`[data-grouphash="${rule.managedGroup}"]`), rule, isSelectValid(rule, master.value)))
                    }
                  }
                })
                if (!this.loaded || event) {
                  firstInit(master, new Event("select"))
                }
              } else {
                master.addEventListener("change", () => {
                  if (slavesFields.length) {
                    if (master.closest(".multiholder")) {
                      slavesFields.forEach(rule => slaveHandler(master.closest(".multiholder").querySelector(`[id*="${rule.managedParamValueId}"]`), rule, checkChosen(rule, master.checked)))
                    } else {
                      slavesFields.forEach(rule => slaveHandler(document.querySelector(`[id$="${rule.managedParamValueId}"]`), rule, checkChosen(rule, master.checked)))
                    }
                  }
                  if (slaveGroups.length) {
                    if (master.closest(".multiholder")) {
                      slaveGroups.forEach(rule => slaveHandler(master.closest(".multiholder").querySelector(`[data-grouphash*="${rule.managedGroup}"]`), rule, checkChosen(rule, master.checked)))
                    } else {
                      slaveGroups.forEach(rule => slaveHandler(document.querySelector(`[data-grouphash="${rule.managedGroup}"]`), rule, checkChosen(rule, master.checked)))
                    }
                  }
                })
                if (!this.loaded || event) {
                  firstInit(master, new Event("change"))
                }
              }
            })
          }
        })
      }
    },
    /**
     * Функция для смены шага
     * @param dir - направление (forward или back)
     */
    changeStep(dir) {
      // если текущее значение шагов не нулевое и меньше номера последнего шага
      if (this.step > 0 && this.step <= this.stepsData.length) {
        this.step += dir === "forward" ? 1 : -1 // отнимаем или прибавляем единицу в зависимости от направления
      }
      window.scrollUp()
    },
    /**
     * Функция для обработки полей валидации
     */
    dataValidationHandler(obj) {
      document.querySelectorAll(".validation-error").forEach(item => {
        item.innerHTML = ""
        item.closest(".form-group").classList.remove("error")
      })
      if (Object.keys(obj.fields).length > 0) {
        for (let field in obj.fields) {
          if (document.getElementById(field)) {
            document.getElementById(field).closest(".form-group").classList.add("error")
            
            if (document.getElementById(field).closest(".form-group").classList.contains("captcha")) {
              const validationObj = JSON.parse(obj.fields[field])
              document.getElementById(field).closest(".form-group").querySelector(".validation-error").innerHTML = `<span>${validationObj.message}</span>`
              document.getElementById(field).dispatchEvent(new CustomEvent("validate-captcha", {detail: validationObj.captchaData}))
            } else {
              document.getElementById(field).closest(".form-group").querySelector(".validation-error").innerHTML = `<span>${obj.fields[field]}</span>`
            }
          }
        }
        if (this.hasSteps) {
          const initFieldStep = document.getElementById(Object.keys(obj.fields)[0]).dataset?.name?.replace(/]/g, "").split("[")[1]
          this.step = this.formData.properties[initFieldStep].propertyOrder
        }
      }
      if (obj.global?.length > 0) {
				this.globalError = '';
        for (let err of obj.global) {
          this.globalError += `<p>${err}</p>`
        }
      }
    },
    /**
     * Функция для обработки перехода на следующий шаг (валидация и сохранение черновика)
     */
    async saveDraft(event, move, step, stepNum) {
      new Promise(resolve => {
        this.blockButtons = true
        window.showLoader()
        const interval = setInterval(() => {
          if (!this.captchaBlocked) {
            clearInterval(interval)
            resolve()
          }
        }, 1000)
      }).then(async () => {
          if (this.hasSteps) {
            document.querySelectorAll(".form-step")[this.step - 1].querySelectorAll(".validation-error").forEach(item => item.innerHTML = "")
          }
          // сериализация формы
          let stepName
          const data = new FormData(document.forms[this.formData["full_name"]])
          // урл запроса
          if (this.hasSteps) {
            if (event.type === "modal-open") {
              if (event.valid) {
                stepName = Object.keys(this.formData.properties)[this.step - 1]
              } else {
                if (Object.keys(this.formData.properties)[this.step - 2]) {
                  stepName = Object.keys(this.formData.properties)[this.step - 2]
                }
              }
            } else {
              stepName = Object.keys(this.formData.properties)[this.step - 1] === "markStaged" ? Object.keys(this.formData.properties)[this.step - 2] : Object.keys(this.formData.properties)[step !== undefined ? step : this.step - 1]
            }
            const url = this.params.routes.validateUrl.replace("__step__", stepName)
            const invalidFields = [...document.forms[this.formData["full_name"]].elements].filter(item => item.id.indexOf(stepName) > -1 && item.required && item.value?.length === 0 && item.classList.contains("under-rules"))
            // запрос
            
            if (typeof url !== "undefined") {
              if (invalidFields.length === 0) {
                const response = await fetch(url, {
                  method: "post",
                  body: data
                })
                // проверка статуса запроса
                const json = await response.json()
                // валидация полей
                if (response.status >= 400 && response.status < 500) {
                  this.dataValidationHandler(json)
                  this.blockButtons = false
                  window.hideLoader()
                  window.scrollUp()
                } else if (response.ok) {
                  if (this.params.settings.isDraftmode) {
                    Promise.resolve().then(async () => {
                      const draftResp = await fetch(this.params.routes.saveDraftUrl, {
                        method: "post",
                        body: data
                      })
                      if (draftResp.ok) {
                        if (event.type === "modal-open") {
                          this.draftSaved = true
                          setTimeout(() => {
                            this.draftSaved = false
                          }, 4000)
                        } else {
                          return new Promise(resolve => {
                            resolve(this.fetchData())
                          }).then(() => {
                            this.stepsData[this.step - 1].isComplete = true
                            if (move) {
                              this.changeStep("forward")
                            }
                          })
                        }
                      }
                      else if (draftResp.status >= 400 && draftResp.status < 500) {
                        const draftJson = await draftResp.json()
                        if (draftJson.global?.length > 0) {
                          let msg = ""
                          if (Array.isArray(draftJson.global)) {
                            draftJson.global.forEach(i => {
                              msg += `<p>${i}</p>`
                            })
                          } else {
                            msg = `<p>${draftJson.global}</p>`
                          }
                          window.showErrorMessage(msg)
                        }
                        window.scrollUp()
                      } else {
                        window.showErrorMessage("Непредвиденная ошибка!")
                      }
                    }).finally(() => {
                      this.blockButtons = false
                      if (stepNum) {
                        this.step = stepNum
                      }
                      window.hideLoader()
                    })
                  } else {
                    this.stepsData[this.step - 1].isComplete = true
                    this.blockButtons = false
                    if (stepNum) {
                      this.step = stepNum
                    }
                    window.hideLoader()
                    if (move) {
                      this.changeStep("forward")
                    }
                  }
                } else {
                  window.showErrorMessage("Непредвиденная ошибка!")
                  window.hideLoader()
                }
              } else {
                invalidFields.forEach(item => {
                  window.scrollUp()
                  const msg = document.createElement("span")
                  msg.textContent = "Укажите значение"
                  item.closest(".form-group").querySelector(".validation-error").appendChild(msg);
                })
                window.hideLoader()
              }
            } else {
              if (event.type === "modal-open") {
                this.draftSaved = true
                setTimeout(() => {
                  this.draftSaved = false
                }, 4000)
              }
            }
          } else {
            Promise.resolve().then(async () => {
              const draftResp = await fetch(this.params.routes.saveDraftUrl, {
                method: "post",
                body: data
              })
              if (draftResp.ok) {
                if (event.type === "modal-open") {
                  this.draftSaved = true
                  setTimeout(() => {
                    this.draftSaved = false
                  }, 4000)
                } else {
                  await this.fetchData()
                }
              } else if (draftResp.status >= 400 && draftResp.status < 500) {
                const draftJson = await draftResp.json()
                if (draftJson.global?.length > 0) {
                  let msg = ""
                  if (Array.isArray(draftJson.global)) {
                    draftJson.global.forEach(i => {
                      msg += `<p>${i}</p>`
                    })
                  } else {
                    msg = `<p>${draftJson.global}</p>`
                  }
                  window.showErrorMessage(msg)
                }
              } else {
                window.showErrorMessage("Непредвиденная ошибка!")
              }
            }).finally(() => {
              this.blockButtons = false
              window.hideLoader()
            })
          }
        })
    },
    /**
     * Функция для отправки формы
     */
    async sendData(event) {
      event.preventDefault()
      new Promise(resolve => {
        this.blockButtons = true
        window.showLoader()
        const interval = setInterval(() => {
          if (!this.captchaBlocked) {
            clearInterval(interval)
            resolve()
          }
        }, 1000)
      }).then(async () => {
        const data = new FormData(document.getElementById(this.formData["full_name"]))
        const response = await fetch(this.urlData + "?" + new URLSearchParams(this.paramsGetForm), {
          method: "post",
          body: data
        })
        if (response.ok) {
          const json = await response.json()
          window.successPostSendEvent(json)
          window.showSuccessMessage(json)
          this.$emit('ok', '')
          this.$emit('closeModal', '')
        } else if (response.status >= 400 && response.status < 500) {
          const json = await response.json()
          this.dataValidationHandler(json)
        } else if (response.status > 500) {
          try {
            const json = await response.json()
            window.showErrorMessage(json.message)
          } catch (e) {
            window.showErrorMessage("При обработке ответа произошла ошибка")
          }
        }
        this.blockButtons = false
        window.hideLoader()
      })
    },
    // /**
    //  * Функция для загрузчика цифровой подписи файлов на последнем шаге
    //  */
    // fileListSignUploader() {
    //   this.docUploadSign = new plupload.Uploader({
    //     runtimes: 'html5,flash,silverlight,html4',
    //     browse_button: document.getElementById("signListUploadBtn"),
    //     container: document.getElementById("signListUploadContainer"),
    //     multi_selection: false,
    //     chunk_size: '128kb',
    //     url: `${this.params.routes.fileUploadUrl}?guid=${this.signGuid}`,
    //     flash_swf_url: '../assets/common/moxie.swf',
    //     silverlight_xap_url: '../assets/common/moxie.xap',
    //     init: {
    //       FilesAdded: (upload, files) => {
    //         upload.start();
    //         upload.refresh();
    //       },
    //       Error: (upload, error) => {
    //         alert('Ошибка загрузки подписи: ' + error.message)
    //       },
    //       FileUploaded: (upload, file, response) => {
    //         if (response.response === 'success') {
    //           const fileInfo = file.getNative()
    //           if (document.querySelector(`.measureUploadedGuid[value="${fileInfo.id}"]`)) {
    //             document.querySelector(`.measureUploadedGuid[value="${fileInfo.id}"]`).closest(".form-group-file-line").querySelector(".file_st_green > span").innerText = "Документ подписан"
    //             document.querySelector(`.measureUploadedGuid[value="${fileInfo.id}"]`).closest(".form-group-file-line").querySelector(".localSignGuid").value = this.signGuid
    //           } else {
    //             document.querySelector(`.measureUploadedIasDbGuid[value="${fileInfo.id}"]`).closest(".form-group-file-line").querySelector(".file_st_green > span").innerText = "Документ подписан"
    //             document.querySelector(`.measureUploadedIasDbGuid[value="${fileInfo.id}"]`).closest(".form-group-file-line").querySelector(".localSignGuid").value = this.signGuid
    //           }
    //           this.signGuid = guidHandler(false)
    //           upload.setOption('url', `${this.params.routes.fileUploadUrl}?guid=${this.signGuid}`);
    //         }
    //       }
    //     }
    //   })
    //   this.docUploadSign.init()
    // },
    /**
     * Функция для подписания файлов формы
     */
    signFileList() {
      const measureSignHolder = document.getElementById("measureSignHolder")
      if (Object.keys(this.filesToSign).length > 0) {
        // this.fileListSignUploader()
        const blitz = new SignPluginBlitz(measureSignHolder, {...this.filesToSign}, result => {
          for (let guid in result.signatures)
          {
            let signature = result['signatures'][guid]
              .replace(/\r?\n/g, "")
              .replace("-----BEGIN CMS-----", "")
              .replace("-----END CMS-----", "");
            let signBlob = new Blob([signature], {type: 'text/plain'});
            signBlob.id = guid
            this.docUploadSign.addFile(signBlob);
            this.filesToSignInfo[guid].isSigned = true
            delete window.measureSignFileList[guid];
          }
        }, () => {
          window.alert("Не удалось подписать файл")
        })
        blitz.init()
      } else {
        measureSignHolder.innerHTML = "<h4 style='margin-top: 30px;margin-bottom: 0;'>Нет файлов, требующих подписания</h4>"
        setTimeout(() => {
          measureSignHolder.innerHTML = ""
        }, 3000)
      }
    },
    /**
     * Функция для формирования файлов для подписания формы
     */
    async buildMeasureFiles() {
      // window.showLoader()
      // const data = new FormData(document.getElementById(this.formData["full_name"]))
      // const response = await fetch(this.params.routes.buildMeasureFilesUrl, {
      //   method: "POST",
      //   body: data
      // })
      // const json = response.json()
      // if (response.ok) {
      //   document.getElementById(this.formData["full_name"]).classList.add('blocked')
      //   if (this.formData.options["form_sign"].pdf) this.signPdfLink = this.params.routes.getMeasureRequestPdfUrl
      //   if (this.formData.options["form_sign"].xml) this.signXmlLink = this.params.routes.getMeasureRequestXmlUrl
      //   this.showBuildButton = false
      //   this.showSignButton = true
      // } else if (response.status >= 400 && response.status < 500) {
      //   for (let field in json.fields) {
      //     document.getElementById(field).nextElementSibling.innerHTML = `<span>${json.fields[field]}</span>`
      //   }
      // } else {
      //   window.showErrorMessage(json.message)
      // }
      // window.hideLoader()
    },
    /**
     * Функция с загрузчиком подписи формы
     */
    formSignUploader(url, type) {
      const uploader = new plupload.Uploader({
        runtimes: 'html5,flash,silverlight,html4',
        browse_button: document.getElementById("formSignButton"),
        container: document.getElementById("formSignContainer"),
        multi_selection: false,
        chunk_size: '128kb',
        url: url,
        flash_swf_url: '../assets/common/moxie.swf',
        silverlight_xap_url: '../assets/common/moxie.xap',
        init: {
          FilesAdded: (upload, files) => {
            upload.setOption("url", url)
            upload.start();
            upload.refresh();
          },
          Error: (upload, error) => {
            window.showErrorMessage(error.message)
          },
          FileUploaded: (upload, file, response) => {
            this[type] = true
          }
        }
      })
      uploader.init()
      return uploader
    },
    /**
     * Функция для подписания формы
     */
    async signRequest() {
      const formSignHolder = document.getElementById("formSignHolder")
      const filesToSign = {
        xml: this.signXmlLink,
        pdf: this.signPdfLink
      }
      const uploaders = [
        this.formSignUploader(this.params.routes.signXmlUrl, "xmlSign"),
        this.formSignUploader(this.params.routes.signPdfUrl, "pdfSign")
      ]
      let blitz = new SignPluginBlitz(formSignHolder, filesToSign, result => {
        let i = 0
        for (let sign in result.signatures)
        {
          let signature = result['signatures'][sign]
            .replace(/\r?\n/g, "")
            .replace("-----BEGIN CMS-----", "")
            .replace("-----END CMS-----", "");
          let signBlob = new Blob([signature], {type: 'text/plain'});
          uploaders[i].addFile(signBlob);
          i++
        }
        this.showSignButton = false
        this.showSendButton = true
        this.showEditButton = true
        this.blockButtons = true
        const interval = setInterval(() => {
          if (this.xmlSign && this.pdfSign) {
            this.blockButtons = false
            clearInterval(interval)
          }
        }, 1000)
      }, () => {
        window.alert("Не удалось подписать файл")
      })
      blitz.init()
    },
    /**
     * Функция редактирования формы после подписания
     */
    editForm() {
      document.getElementById(this.formData["full_name"]).classList.remove('blocked')
      this.showSendButton = false
      this.showEditButton = false
      this.showBuildButton = true
      this.xmlSign = false
      this.pdfSign = false
    },
  }
}
</script>

<style scoped>
.buttons_bottom{
    display: flex;
    gap: 2.4rem;
}
.measure{
    position: relative;
    min-height: 100px;
}
.modal .loader-measure{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.loader-measure{
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
}
.errors_global{
    color: #ACB0B9;
    display: flex;
    gap: 1rem;
    align-items: start;
    margin-bottom: 2.4rem;
}
.errors_global :deep(a){
    color: #ED850A;
}
.errors_global :deep(a:hover){
    text-decoration: underline;
}
</style>