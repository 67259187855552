<script>
export default {
  name: "Captcha",
  props: {
    captchaInfo: {
      type: Object,
      required: true
    },
    fullName: String,
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    chosenCaptcha: "",
    timeoutStart: false,
    captchaData: null
  }),
  methods: {
    captchaRefreshHandler() {
      if (!this.timeoutStart) {
        this.timeoutStart = true
        fetch(this.captchaData.refreshUrl)
          .then(r=>r.json())
          .then(data => {
            this.captchaData = data
          })
          .finally(() => setTimeout(() => {
            this.timeoutStart = false
          }, this.captchaData.refreshTimeOut * 1000))
      }
    }
  },
  mounted() {
    this.captchaData = this.captchaInfo
  },
  watch: {
    captchaData() {
      setTimeout(() => {
        document.getElementById(this.id).addEventListener("validate-captcha", event => this.captchaData = event.detail)
      }, 100)
    },
    timeoutStart(value) {
      this.$emit("captcha-blocked", value)
    }
  }
}
</script>

<template>
  <div class="form-captcha" :id="id" v-if="captchaData">
    <input type="hidden" :name="`${fullName}[${captchaData.uidField}]`" :value="captchaData.uid">
    <template v-if="captchaData.type === 'cubical'">
      <input type="hidden" :name="`${fullName}[${captchaData.answerField}]`" v-model="chosenCaptcha">
      <div class="captcha-img-list">
        <div class="captcha-img-holder"
             v-for="(img, guid) in captchaData.images"
             :class="{'active': chosenCaptcha === guid}"
             @click="chosenCaptcha = guid"
             :key="guid"
        >
          <img :src="img" alt="">
        </div>
      </div>
    </template>
    <template v-else-if="captchaData.type === 'math'">
      <div class="captcha-math-img" v-for="(img, guid) in captchaData.images" :key="guid">
        <img :src="img" alt="">
      </div>
      <input type="text" :name="`${fullName}[${captchaData.answerField}]`" v-model="chosenCaptcha" placeholder="Введите ответ" class="form-control">
    </template>
    <div class="form-captcha-refresh">
      <button :disabled="timeoutStart" @click.prevent="captchaRefreshHandler"></button>
    </div>
  </div>
</template>

<style scoped>

</style>