<template>
  <teleport to="#site_header_td3">
  <div class="lang_top" v-if="dataInfo && dataInfo.listCard?.length > 0">
    <div class="lang_top_ico" :class="{'active': showMenuLang}" @click="showMenuLang = !showMenuLang">
      <img src="/img/ico/language.svg">
    </div>
    <transition>
      <div class="lang_top_list" v-if="showMenuLang" v-click-away="hideMenuLang">
        <template v-for="itemLang in dataInfo.listCard">
          <router-link v-if="itemLang.link?.value" class="div_el"
               :key="itemLang.type"
               :class="{'active': itemLang == elMenuActive}"
               @click="clickElLang(itemLang)"
               :to="itemLang.link.value">
            <div class="dt1">{{ itemLang.type.value }}</div>
            <div class="dt2">{{ itemLang.name.value }}</div>
          </router-link>
        </template>
      </div>
    </transition>
  </div>
  </teleport>
</template>

<script>
export default {
  name: "langTop",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return {
      showMenuLang: false,
      elMenuActive: this.dataInfo.listCard[0],
    }
  },
  methods: {
    clickElLang(item){
      this.elMenuActive = item;
      this.hideMenuLang();
    },
    hideMenuLang() {
      this.showMenuLang = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.lang_top {
  position: relative;
  font-weight: 500;
  color: #000106 !important;
  a{
    color: #000106;
    &.router-link-active{
      color: #ED850A;
    }
  }
  .lang_top_ico {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 48px;
    width: 48px;
    border-radius: 6px;
    &.router-link-active {
      background-color: rgba(255, 255, 255, 0.10);
    }
  }
  .lang_top_list {
    width: 250px;
    overflow: hidden;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
    position: absolute;
    top: 68px;
    right: 0;
    z-index: 10;
  }
  .div_el {
    padding: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:nth-child(odd) {
      background: #F7F5F3;
    }
    &:hover {
      background: rgba(237, 133, 10, 0.25);
      cursor: pointer;
    }
    &.router-link-active {
      cursor: default;
      //border-radius: 6px;
      background: rgba(237, 133, 10, 0.25);
    }
    > .dt1 {
      flex: none;
      margin-right: 24px;
      width: 34px;
    }
  }
}

</style>