<template>
  <div class="header_notification" v-click-away="showInfoAway">
    <div class="div_ico" :class="{ active: isActive}" @click="showInfo = !showInfo"></div>
    <div class="div_info" v-if="showInfo">
      <div class="div_close" @click="showInfoAway">
        <div class="close_ico"></div>
      </div>
      <div class="div_top">
        <div class="div_tit">
          <span class="fs4">Уведомления</span>
          <multiselect
              v-if="categories"
              class="select_span"
              v-model="selectInfo"
              track-by="name"
              label="name"
              :options="categories"
              :searchable="false"
              :allow-empty="false"
              @close="changeOption"
              @select="selectCategory"
              :preselect-first="true"
          >
            <template v-slot:singleLabel="{ option }">
                            <span class="select_tit sselect_tit_black color_red fs4">
                                <span>{{ option.name }}</span>
                            </span>
            </template>
          </multiselect>
        </div>
        <div class="div_btn" v-if="notifications && notifications.length">
          <span @click="readAll" class="cursor-pointer">отметить все как прочитанные</span>
        </div>
      </div>
      <div class="div_list" v-if="notifications">
        <div
            class="div_item"
            v-if="viewedNotifications.length"
            v-for="notice in viewedNotifications"
            :key="notice.id"
            :class="{active: !notice.read}"
            @click="readNotice(notice)"
        >
          <template v-if="notice.objectLink">
            <div class="dt1">
              <h5 class="div_tit">{{ notice.objectNotificationTitle }}</h5>
              <div class="div_text">{{
                  new Date(notice.dateCreate).toLocaleDateString("ru", {}) + " " + $filters.time(new Date(notice.dateCreate))
                }}
              </div>
            </div>
            <div class="dt2">
              <img src="/build/img/arrow-right.svg" alt="">
            </div>
            <div class="dt3">
              <cl-tag type="black">{{ notice.objectNotification }}</cl-tag>
            </div>
          </template>
          <template v-else>
            <div class="dt0">
              <h5 class="div_tit">{{ notice.notificationText }}</h5>
              <div class="div_text">{{
                  new Date(notice.dateCreate).toLocaleDateString("ru", {}) + " " + $filters.time(new Date(notice.dateCreate))
                }}
              </div>
            </div>
          </template>
        </div>
        <div v-else class="div_noitems">
          <h5>Здесь пока что ничего нет</h5>
        </div>
      </div>
    </div>
    <div class="shadow_over" v-if="showInfo"></div>
  </div>
</template>

<script>

export default {
  name: "notificationsHeaders",
  data() {
    return {
      showInfo: false,
      selectInfo: "",
      notifications: null,
      viewedNotifications: [],
      categories: [],
      isActive: false
    }
  },
  methods: {
    changeOption(item) {
    },
    showInfoAway() {
      this.showInfo = false;
    },
    selectCategory(value) {
      if (value.id === -1) {
        this.viewedNotifications = this.notifications;
      } else {
        this.viewedNotifications = this.notifications.filter((item) => {
          return item.category.id === value.id;
        })
      }
    },
    navbarHideHandler() {
      window.addEventListener("scroll", () => {
        if(window.innerWidth > 991){
          this.showInfo = false;
        }
      })
    },
    getNotification() {
      return this.axios
          .get(`/api/get/notifications`)
          .then(response => {
            this.notifications = response.data.records;
            if (response.data.records.length) {
              this.isActive = true;
            }
          })
          .catch(error => {
            this.showError(error, 'Не удалось загрузить уведомления')
          });
    },
    getCategories() {
      return this.axios
          .get(`/api/get/notifications/category`)
          .then(response => {
            response.data.records.unshift({
              id: -1,
              name: 'Все'
            })
            this.categories = response.data.records;
          })
          .catch(error => {
            this.showError(error, 'Не удалось загрузить уведомления')
          });
    },
    readNotice(notice) {
      if (!notice.read) {
        let data = new FormData();
        data.append('id', notice.id)
        return this.axios
            .post(`/api/post/notification/read`, data)
            .then(response => {
              this.showSuccess('Уведомление отмечено как "прочитанное"');
              notice.read = true;
            })
            .catch(error => {
              this.showError()
            });
      }
    },
    readAll() {
      this.axios
          .post(`/api/post/notification/read`)
          .then(response => {
            this.showSuccess('Все уведомления отмечены как "прочитанные"');
            this.notifications.forEach((item) => {
              item.read = true;
              this.isActive = false;
            })
          })
          .catch(error => {
            this.showError()
          });
    }
  },
  watch: {
    showInfo() {
      if(window.innerWidth <= 991) {
        if (this.showInfo) {
          document.getElementsByClassName('site_body')[0].classList.add('overflow-hidden');
        } else {
          document.getElementsByClassName('site_body')[0].classList.remove('overflow-hidden');
        }
      }
    }
  },
  mounted() {
    this.navbarHideHandler();
    this.getNotification();
    this.getCategories();
  }
}
</script>


<style scoped lang="scss">
.header_notification {
  margin-right: 32px;
  width: 15px;
  height: 18px;
  position: relative;
  color: #000000;

  @media (max-width: 991px) {
    .shadow_over {
      display: block;
    }
  }

  .div_ico {
    width: 15px;
    height: 18px;
    //background: url(../assets/img/notification.svg) center / contain no-repeat;
    position: relative;
    cursor: pointer;

    &.active:after {
      content: "";
      position: absolute;
      top: 4px;
      right: 0;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background: #DF4A37;
    }
  }

  .div_info {
    width: 641px;
    border-radius: 7px;
    background: #ffffff;
    position: absolute;
    top: 25px;
    right: -92px;
    z-index: 1;

    .div_close {
      display: none;
    }
    &:before {
      content: "";
      border: 5px solid transparent;
      border-bottom: 5px solid #ffffff;
      position: absolute;
      right: 94px;
      top: -10px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      .close_ico:before,
      .close_ico:after {
        background-color: #ffffff;
      }
    }
    @media (max-width: 991px) {
      border-radius: 0;
      z-index: 9999;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      top: 20px;
      width: 648px;
      &:before {
        display: none;
      }
      .div_list {
        max-height: calc(100vh - 195px);
      }
      .div_close {
        display: block;
        position: absolute;
        top: 0px;
        right: -30px;
      }
    }
    @media (max-width: 767px) {
      left: 0;
      transform: none;
      top: 0;
      right: 0;
      bottom: 0;
      width: auto;
      border-radius: 0;
      .div_top {
        padding: 40px 20px 0 20px;
        .div_tit {
          flex-direction: column;
          > span {
            margin: 0 0 4px 0;
          }
        }
        .fs4 {
          font-size: 32px;
        }
      }
      .div_noitems {
        padding: 0 20px 20px 20px;
      }
      .div_close {
        top: 20px;
        right: 20px;
      }
    }
  }

  .div_top {
    padding: 40px 40px 0 40px;

    .div_tit {
      margin-bottom: 20px;
      display: flex;

      > span:first-child {
        margin-right: 10px;
      }
    }

    .div_btn {
      //border-bottom: 2px solid rgba(0, 0, 0, 0.08);
      margin-bottom: 20px;
      color: #E74362;
    }
    @media (max-width: 767px) {
      .div_tit {
        margin-bottom: 10px;
      }
      .div_btn {
        margin-bottom: 40px;
      }
    }
  }

  .div_list {
    overflow-y: auto;
    max-height: 55vh;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, .2) transparent;

    &::-webkit-scrollbar {
      width: 24px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .2);
      background-clip: padding-box;
      border-radius: 20px;
      border: 10px solid transparent;
    }
  }

  .div_item {
    position: relative;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    &.active {
      cursor: pointer;
    }

    &.active:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 17px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #DF4A37;
    }

    &:before {
      content: "";
      left: 40px;
      right: 40px;
      height: 1px;
      background: rgba(0, 0, 0, 0.08);
      bottom: 0px;
      position: absolute;
    }
    &:first-child {
      &:before {
        height: auto;
        background: none;
        border-bottom: 1px solid  rgba(0, 0, 0, 0.08);
        border-top: 1px solid  rgba(0, 0, 0, 0.08);
        top: 0;
      }
      > div {
        position: relative;
      }
    }

    &:last-child {
      &:before {
        display: none;
      }
    }

    &.active:hover {
      background: rgba(231, 67, 98, 0.08);

      .dt4 {
        opacity: 1;
      }
    }

    .dt0 {
      margin-right: 20px;
      flex: 1;
    }

    .dt1 {
      //background: url(../assets/img/document.svg) no-repeat 0 8px;
      padding-left: 50px;
      margin-right: 20px;
      flex: none;
      width: 46%;
    }

    .div_tit {
    }

    .div_text {
      color: rgba(0, 0, 0, 0.4);
    }

    .dt2 {
      flex: none;
      margin-right: 15px;
      width: 38px;

      img {
        width: 100%;
        filter: invert(1);
        display: block;
      }
    }

    .dt3 {
    }

    .dt4 {
      flex: none;
      width: 12px;
      height: 12px;
      border-top: 1px solid #E74362;
      border-right: 1px solid #E74362;
      transform: rotate(40deg);
      margin-left: auto;
      opacity: 0;
    }
    @media (max-width: 767px) {
      padding: 20px;
      flex-wrap: wrap;
      &.active:after {
        top: 30px;
        transform: none;
        left: 20px;
      }
      .dt0 {
        padding-left: 16px;
        font-size: 15px;
        line-height: 22px;
        .div_tit {
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
      }
      .dt1 {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        min-height: 42px;
        padding-left: 56px;
        background-position: 16px center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .dt2 {
        width: 19px;
        margin-right: 11px;
        margin-left: 16px;
      }
      .tag {
        min-height: 26px;
        padding: 4px 10px;
      }
    }
  }

  .div_noitems {
    padding: 0 40px 40px 40px;
  }

}
</style>