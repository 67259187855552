<template>
  <div class="block_top_list block_top_b"
       :style="[ getBkgrImage(dataInfo.image, 'fullHd')]">
    <container>
      <h1 class="hasSubtit" v-if="dataInfo.title">{{ dataInfo.title.value }}</h1>
      <div class="filter_block">
        <div class="dt w-30"  v-if="filter.selectOptionsCategory.length > 0">
          <multiselect
              v-model="filter.selectSelectedCategory"
              :options="filter.selectOptionsCategory"
              placeholder="Категория"
              track-by="id"
              label="name"
              @select="selectChangeCategory"
              :searchable="false"
              :allow-empty="false"
          ></multiselect>
        </div>
        <div class="dt w-100" v-if="filter.selectOptionsCategory.length > 0">
        <base-input v-model="filter.nameSearch"
                    :placeholder="'Поиск по наименованию'"
                    @inputSearch="inputSearch"
                    class="input_filter"
        />
        </div>
        <div class="dt">
          <cl-button @click="getListItem()">Найти</cl-button>
        </div>
      </div>
      <div class="block_top_list_total fs4" v-if="dataList?.length > 0">Всего оборудования: <span class="color-or" v-if="data.total">{{data.total}}</span><span class="icon-info-cl"><img src="/img/ico/info.svg" alt=""><message-left class="message-cl">На текущий момент работы<br> над сервисом продолжаются</message-left></span></div>
        <loader
            :animation-duration="3000"
            :size="60"
            v-if="isLoading"
        ></loader>
      <not-info
          v-if="dataList?.length == 0"
          :title="!Object.keys(getParams).length ? `Здесь пока ничего нет` : `Ничего не нашлось`"
          :text="!Object.keys(getParams).length ? `Нет результатов` : `Нет результатов, удовлетворяющих критериям поиска. Попробуйте сбросить фильтры`"
      >
        <template v-slot:btn v-if="Object.keys(getParams).length">
          <div class="div_btn">
            <cl-button @click="deleteParams();">Сбросить фильтры</cl-button>
          </div>
        </template>
      </not-info>
      <row v-if="dataList.length > 0">
        <column :key='item.id' v-for='item in dataList' md="3">
          <card card-type="rent" class="card-equipment2">
            <div class="div_img img_proportions bg_wh" :class="{'not_img': !item.gallery}">
              <img v-if="item.gallery?.galleryItems" :src="item.gallery?.galleryItems[0].url" alt="">
            </div>
            <div class="div_tag fs14" v-if="item.types?.length > 0">
              <template
                  v-for="(cat, index) in item.types"
                  :id="cat.id"
              >{{cat.name}}<template v-if="index + 1 < item.types.length">, </template></template>
            </div>
            <div class="div_tit fs21" v-if="item.name"> {{ item.name }}</div>
<!--            <cl-button class="btn-middle w-100" @click="$router.push({path: `${$route.fullPath.replace(/\list.*/, 'item')}/${item.id}`})">-->
            <cl-button class="btn-middle w-100" :router-link="`${$route.fullPath.replace(/\list.*/, 'item')}/${item.id}`">
              Подробнее
            </cl-button>
          </card>
        </column>
      </row>
      <div v-if="data?.page < data?.pages || isLoadingMore" class="show_more">
        <loader
            v-if="isLoadingMore"
            :animation-duration="1500"
            :size="55"
            :color="'#ED850A'"
        ></loader>
        <cl-button v-else @click="loadMore" type="light sm-w-100">Показать еще</cl-button>
      </div>


    </container>
  </div>
</template>

<script>
import BaseInput from "@/components/library/Input.vue";
import MessageLeft from "@/components/library/message-left.vue";

export default {
  name: "equipmentList",
  components: {MessageLeft, BaseInput},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingMore: false,
      data: false,
      dataList: false,
      getParams: {
        pageSize: this.dataInfo?.listCard?.pageSize ? this.dataInfo?.listCard?.pageSize : 8
      },
      controller: null,

      filter: {
        selectSelectedCategory: [],
        selectOptionsCategory: [],
        nameSearch: ""
      }
    }
  },
  methods: {
    getListItem(isLoadMore) {
      if (!isLoadMore) {
        // меняем ссылку
        history.replaceState(null, null, this.$route.fullPath.replace(/\list.*/, 'list/') + this.filter.selectSelectedCategory.id);
        delete this.getParams.page;
        this.isLoading = true;
      }
      return this.axios
          .get(`${this.data.path}`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            if (isLoadMore) {
              this.dataList = [...this.dataList, ...response.data.items];
            } else {
              this.dataList = response.data.items;
            }
            this.data = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    loadMore() {
      this.isLoadingMore = true;
      this.getParams.page = ++this.data.page;
      this.$nextTick(() => {
        this.getListItem(true).then(() => {
          this.isLoadingMore = false
        })
      });
    },
    //категории
    selectChangeCategory(val) {
      if (this.filter.selectSelectedCategory.length < 1) {
        delete this.getParams.type
      } else {
        this.getParams.type = this.filter.selectSelectedCategory.id;
      }
    },
    //поле поиск
    inputSearch(){
      if (this.filter.nameSearch == "") {
        delete this.getParams.search
      } else {
        this.getParams.search = this.filter.nameSearch;
      }
    },
    deleteParams(){
      //this.getParams = {};
      this.getParams.pageSize = this.dataInfo?.listCard?.pageSize ? this.dataInfo?.listCard?.pageSize : 8;
      delete this.getParams.search
      this.filter.nameSearch = "";

      this.getListItem();
    },
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    this.data = this.dataInfo.listCard;
    // this.dataList = this.dataInfo.listCard?.items;
    this.filter.selectOptionsCategory = this.dataInfo.categories;
    if(this.$route.fullPath.split('/').at(-1) != 'list'){
      this.filter.selectSelectedCategory = [...this.filter.selectOptionsCategory].filter(item => item.id == this.$route.fullPath.split('/').pop())[0];
      this.getParams.type = this.filter.selectSelectedCategory.id;
    }
    this.getListItem();
  },
  beforeUnmount() {
    this.controller.abort();
    this.controller = null;
  },
}
</script>

<style lang="scss" scoped>

</style>