<template>
  <div class="block_wrap gdaib" v-if="dataInfo">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
      <div class="gdaib_text fs4n" v-if="dataInfo.text" v-html="dataInfo.text.value"></div>
      <div class="div_slide" v-if="dataInfo.gallery1 || dataInfo.gallery2 && dataInfo.gallery1.length > 0">

        <!--      @mouseenter="autoplayStart"-->
        <!--      @mouseleave="autoplayStop"-->
        <!--      :freeMode="true"-->
        <!--      pauseOnMouseEnter: true-->
        <swiper
            v-if="dataInfo.gallery1 && dataInfo.gallery1.length > 0"
            :modules="modules"
            :slides-per-view="'auto'"
            class="slide100h sample-slider arrow_center arrow_center2"
            :loop="true"
            :spaceBetween="24"
            :speed="4000"
            :autoplay="{
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: true,
          }"
            :lazy="true"
        >
          <swiper-slide
              v-for="(galleryItem1, index) in dataInfo.gallery1"
              :key="galleryItem1.src"
          >
            <div class="gdaib_img" @click="showModalGalFun(dataInfo.gallery1, index)">
              <img :src="getImage(galleryItem1, 'hd')" loading="lazy" alt="">
            </div>
          </swiper-slide>
        </swiper>


        <swiper
            v-if="dataInfo.gallery2 && dataInfo.gallery2.length > 0"
            :modules="modules"
            :slides-per-view="'auto'"
            class="slide100h sample-slider arrow_center arrow_center2"
            :loop="true"
            :spaceBetween="24"
            :speed="4000"
            :autoplay="{
            delay: 0,
            disableOnInteraction: false,
          }"
            :lazy="true"
        >
          <swiper-slide
              v-for="(galleryItem2, index) in dataInfo.gallery2"
              :key="galleryItem2.src"
          >
            <div class="gdaib_img" @click="showModalGalFun(dataInfo.gallery2, index)">
              <img :src="getImage(galleryItem2, 'big')" loading="lazy" alt="">
            </div>
          </swiper-slide>
        </swiper>

        <modal-gallery :dataGallery="dataGallery" :initialSlide="initialSlide" v-modal="showModalGal" :modelValue="showModalGal"/>

      </div>
      <div class="div_info" v-if="dataInfo">
        <h3 class="div_tit" v-if="dataInfo.titleBot">{{ dataInfo.titleBot.value }}</h3>
        <div class="div_text fs4n" v-if="dataInfo.textBot" v-html="dataInfo.textBot.value"></div>
        <div class="div_btn" v-if="dataInfo.btnTextBot && dataInfo.btnUrlBot">
          <cl-button :link="true" target="_blank" type="default" :href="dataInfo.btnUrlBot.value" class="btn-width-mob-full">{{ dataInfo.btnTextBot.value }}</cl-button>
        </div>
      </div>
    </container>

  </div>

</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay, Mousewheel, Navigation} from "swiper";
import ModalGallery from "@/components/modalGallery.vue";

export default {
  name: "gallerieDescriptionAndInformationBlock",
  components: {
    ModalGallery,
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      dataGallery: false,
      initialSlide: 0,
      showModalGal: false
    }
  },
  methods: {
    showModalGalFun(dataGallery, initialSlide){
      this.dataGallery = dataGallery;
      this.initialSlide = initialSlide;
      this.showModalGal = true;
    }
  },
  setup() {
    /*const autoplayStop = (swiper) => {
      swiper.target.swiper.autoplay.stop();
    };
    const autoplayStart = (swiper) => {
      swiper.target.swiper.params.autoplay.delay = 0;
      swiper.target.swiper.params.autoplay.disableOnInteraction = false;
      swiper.target.swiper.autoplay.start();

    };*/
    return {
     /* autoplayStop,
      autoplayStart,*/
      modules: [Autoplay, Mousewheel, Navigation],
    }
  },
}
</script>

<style lang="scss" scoped>
.gdaib {
  padding: 64px 0;
  &_text {
    margin-bottom: 48px;
  }

  .div_slide {
    margin-bottom: 48px;
    .swiper {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .div_info {
    .div_tit {
      margin-bottom: 16px;
    }

    .div_text {
      margin-bottom: 16px;
    }
  }
  :deep(.swiper-slide){
    width: 386px;
  }
  .gdaib_img {
    height: 248px;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 767px) {
    .div_slide {
      margin-bottom: 24px;
    }
    .div_tit {
      font-size: 18px;
      line-height: 133.33%;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

</style>