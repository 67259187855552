<template>
  <div class="block_wrap list_movie block_wrap_eng">
    <container>
      <template v-if="dataInfo.list.length > 0">
        <h2 class="fs4 dt_tit" v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
        <div class="gal_item">
          <swiper
              :spaceBetween="24"
              :navigation="true"
              :threshold='10'
              :modules="modules"
              :loop="widthSite > 992 ? dataInfo.list.length > 4 : dataInfo.list.length > 2"
              :slidesPerView="'auto'"
              class="slide100h arrow_center arrow_center2 arrow_center_op arrowForeignLanguage"
              :lazy="true"
              :breakpoints="{
                  992: {
                    slidesPerView: 4,
                  },
                  768: {
                    slidesPerView: 2.5,
                  },
                  300: {
                    slidesPerView: 1.4,
                  }
                }"
          >
            <swiper-slide
                :key='itemFilm'
                v-for='(itemFilm, index) in dataInfo.list'
            >
              <card card-type="film">
                <div class="div_img div_img_prop">
                  <div class="div_img_wrapp">
                    <img v-if="itemFilm.img" :src="getImage(itemFilm.img, 'middle')" alt="" loading="lazy">
                  </div>
                  <div class="div_platform_wrap" v-if="itemFilm.link?.value">
                    <a :href="itemFilm.link.value" class="btnShowModal">
                      <span class="div_textButton" v-if="dataInfo.textButtonSlider">{{dataInfo.textButtonSlider.value}}</span>
                      <img class="btnImg" v-if="itemFilm.imgButton" :src="getImage(itemFilm.imgButton, 'small')" alt="">
                    </a>
                  </div>
                </div>
                <div class="div_title fs4" v-if="itemFilm.title">{{ itemFilm.title.value }}</div>
              </card>
            </swiper-slide>
          </swiper>
        </div>
      </template>
    </container>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";

export default {
  name: "listMovieGalleriesEngland",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  },
  computed:{
    ...mapGetters([
      'widthSite'
    ])
  },
}
</script>

<style lang="scss" scoped>
.btnShowModal{
  cursor: pointer;
  z-index: 1;
  width: 4.8rem;
  height: 4.8rem;
  padding: 1.2rem;
  border-radius: .9rem;
  background: black;
  border: 1px solid #464A53;
  display: flex;
  transition: .2s ease;
  justify-content: space-between;
  .dt_tit{
    text-transform: uppercase;
    padding: 1.2rem;
  }
  &:active {
    background-color: #ED850A;
  }
  .div_textButton{
    overflow: hidden;
    transition: .1s ease;
    font-size: 1.8rem;
    align-self: center;
    opacity: 0;
    width: 0;
    white-space: nowrap;
  }
}
.list_movie {
  padding: 64px 0;
  h3 {
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  .gal_item {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :deep(.card-film) {
    padding: 0;
    border-radius: 0;
    background: none;
    &:focus{
      .btnShowModal {
        background: #ED850A;
      }
    }
    &:hover {
      .div_platform_wrap {
        color: rgba(255, 255, 255, 1);
        &:before {
          opacity: 1;
        }
      }
      .btnShowModal{
        padding: 1.2rem 1.2rem 1.2rem 2.4rem;
        width: 14rem;
        transition: .2s ease;
        .div_textButton{
          margin-right: .8rem;
          opacity: 1;
          width: fit-content;
          transition: .1s ease;
        }
      }
    }
    .div_img {
      border-radius: 8px;
      padding-top: 143%;
      margin-bottom: 8px;
      overflow: hidden;
      background-color: #000106;
      border: 1px solid #6B7280;
    }
    .div_platform_wrap {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 8px;
      transition: all .3s ease;
      color: rgba(255, 255, 255, 0);
      > a{
        position: relative;
        .btnImg{
          position: absolute;
          top: 0;
          right: 0;
          width: 4.8rem;
          height: 4.8rem;
        }
      }
      &:before {
        content: "";
        display: block;
        background: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: all .3s ease;
      }
      .dt {
        text-align: right;
        width: 100%;
        position: relative;
      }
    }
    .div_platform {
      width: 48px;
      height: 48px;
      cursor: pointer;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    .div_new {
      position: absolute;
      right: 14px;
      bottom: 12px;
      padding: 0 0 7px 0;
      width: 172px;
      height: 54px;
      font-weight: 300;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url(@/assets/img/now_cinema.svg) no-repeat center top;
      background-size: contain;
    }

  }
}
@media(max-width: 991px) {
  .dt_tit {
    font-size: 2.4rem;
    letter-spacing: 0.48px;
  }
  .gal_item {
    .arrow_center2 {
      padding-top: 1.2rem;
    }
    .card-film{
      &:hover .btnShowModal {
        width: 13rem;
      }
      .card-holder{
        .div_title{
          font-size: 2.4rem;
        }
      }
    }
  }
}
@media (max-width: 767px) {

  .list_movie{
    padding-bottom: 4.8rem;
  }
  .gal_item {
    .arrow_center2 {
      padding-bottom: 0;
    }
    .card-film{
      margin-bottom: 1.6rem;
      &:hover .btnShowModal {
        width: 13rem;
      }
      .card-holder{
        .div_title{
          font-size: 2.4rem;
        }
      }
    }
  }
}
</style>