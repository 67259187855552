<template>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  mounted() {
    if (this.dataInfo.class?.value) {
      document.body.classList.add(this.dataInfo.class?.value);
    }
  },
  beforeUnmount() {
    if (this.dataInfo.class?.value) {
      document.body.classList.remove(this.dataInfo.class?.value);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>