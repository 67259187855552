<template>
  <container v-if="getIsAuthorization">
    <h1 class="hasSubtit hasSubtitProf">{{ getCompany ? 'Кабинет организации' : 'Личный кабинет' }}</h1>
    <div v-if="!getIsCompany" class="cabinet_tit">Личные данные</div>
    <div class="prof_info" :class="{'is-editing': editProfile, 'is-sending': sendingUserData, 'getFiz': !getCompany}">
      <div class="dt1">
        <div
            class="img"
            :class="{'not_img': (userImage === '/img/default_avatar.svg' && !userImageToAddPreview) || (!userImageToAddPreview && !userImage)}"
            :style="{backgroundImage: `url(${userImageToAddPreview ? userImageToAddPreview : userImage})`, }"
        >
          <div class="edit_avatar" v-if="editProfile && !getIsCompany">
            <file-picker :type="'edit'" :accept="'.png, .jpg, .jpeg'" v-model="userImageToAdd"/>
            <div class="text" v-if="editProfile">
              Размер изображения не должен первышать 100Кб
            </div>
          </div>
        </div>
        <div class="dt3">
          <template v-if="getUser.additionalRoles?.length">
            <div class="div_stat fs5">Вы являетесь</div>
            <div
                class="div_stat_el"
                v-for="role in getUser.additionalRoles"
            >
              <span class="dse_text">{{ role.name }}</span>
              <Popper
                  v-if="role.description"
                  :content="role.description"
                  class="light2 popper_info"
                  hover="true"
                  placement="top"
                  arrow
              >
                <img src="/build/img/alert-circle.svg">
              </Popper>
            </div>
          </template>
        </div>
        <div class="dt4" v-if="!getIsCompany">
          <template v-if="!editProfile">
            <a class="btn_change" @click="editProfile = true">Изменить</a>
          </template>
          <template v-else>
            <a class="btn_change" @click="sendData">Сохранить</a>
            <a class="btn_change" type="light" @click="discardUnsaved">Отменить</a>
          </template>
        </div>
      </div>
      <div class="dt2">
        <h4 class="div_tit fw-400">
          <template v-if="getIsCompany">{{ getCompany.name }}</template>
          <template v-else>
            <div v-if="getUser.lastName" class="div_tit_name">
              <div class="div_tit_tit">Фамилия</div>
              <div class="div_tit_val">{{ getUser.lastName }}</div>
            </div>
            <div v-if="getUser.firstName" class="div_tit_name">
              <div class="div_tit_tit">Имя</div>
              <div class="div_tit_val">{{ getUser.firstName }}</div>
            </div>
            <div v-if="getUser.middleName" class="div_tit_name">
              <div class="div_tit_tit">Отчество</div>
              <div class="div_tit_val">{{ getUser.middleName }}</div>
            </div>
          </template>
        </h4>
      </div>
    </div>

    <div class="contacts">
      <div v-if="!getIsCompany" class="contacts_tit">Контактная информация</div>
      <template v-if="getIsCompany">
        <row>
          <column md="6"
                  v-if="getCompany.actualAddress || (getCompany.phones && getCompany.phones.length > 0) || (getCompany.emails && getCompany.emails.length > 0)">
            <h4>Контактная информация</h4>
            <div v-if="getCompany.actualAddress" class="contacts_el">
              Адрес: <span class="contacts_text">{{ getCompany.actualAddress }}</span>
            </div>
            <div v-if="getCompany.phones && getCompany.phones.length > 0" class="contacts_el">
              Телефон: <span class="contacts_text">
                  <template v-for="(tel, index) in getCompany.phones"><a :href="'tel:' + tel">{{ tel }}</a><template
                      v-if="getCompany.phones.length > index + 1">, </template></template></span>
            </div>
            <div v-if="getCompany.emails && getCompany.emails.length > 0" class="contacts_el">
              Эл. почта: <span class="contacts_text">
                  <template v-for="(email, index) in getCompany.emails"><a :href="'mailto:' + email">{{ email }}</a><template
                      v-if="getCompany.emails.length > index + 1">, </template></template></span>
            </div>
          </column>
          <column md="6" v-if="getCompany.inn || getCompany.legalAddress || getCompany.mailIndex || getCompany.kpp">
            <h4>Реквизиты</h4>
            <div v-if="getCompany.inn" class="contacts_el">
              Инн: <span class="contacts_text">{{ getCompany.inn }}</span>
            </div>
            <div v-if="getCompany.legalAddress" class="contacts_el">
              Юр. адрес: <span class="contacts_text">{{ getCompany.legalAddress }}</span>
            </div>
            <div v-if="getCompany.mailIndex" class="contacts_el">
              Почта: <span class="contacts_text">{{ getCompany.mailIndex }}</span>
            </div>
            <div v-if="getCompany.kpp" class="contacts_el">
              КПП: <span class="contacts_text">{{ getCompany.kpp }}</span>
            </div>
            <!--                <div v-if="getCompany.inn" class="contacts_el">-->
            <!--                  Генеральный директор: <span class="contacts_text">{{ getCompany.inn }}</span>-->
            <!--                </div>-->

          </column>
        </row>
      </template>
      <template v-else>
        <div class="contacts_wrap">
          <div v-if="getUser.phone" class="contacts_el">
            <div class="contacts_left">
              <div class="contacts_title">Телефон:</div>
              <a :href="'tel:+' + getUser.phone" class="contacts_a">+{{ getUser.phone }}</a>
            </div>
            <img src="/img/ico/phone.svg">
          </div>
          <div v-if="getUser.email" class="contacts_el">
            <div class="contacts_left">
              <div class="contacts_title">Эл. почта:</div>
              <a :href="'mailto:' + getUser.email" class="contacts_a">{{ getUser.email }}</a>
            </div>
            <img src="/img/ico/mail.svg">
          </div>
        </div>
      </template>
    </div>


    <template v-if="!getIsCompany">
      <h3 class="company_title">Мои организации</h3>
      <my-company-selected :userAvatar="getPersonData?.logo?.imageUrl ?? false" :type="'profileCompany'"/>
    </template>


  </container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MyCompanySelected from "@/components/myCompanySelected.vue";
import Container from "@/components/library/Container.vue";

export default {
  name: "profile",
  components: {Container, MyCompanySelected},
  data() {
    return {
      editProfile: false,
      isShowModal: false,
      userImage: null,
      userImageToAddPreview: null,
      userImageToAdd: null,
      sendingUserData: false,
      controller: null
    }
  },

  computed: {
    ...mapGetters([
      'getIsAuthorization',
      'getIsCompany',
      'getCompany',
      'getUser',
      'getIndustry',
      'getIndustryColorByName',
      'getPersonData',
      'widthSite',
    ]),
  },
  watch: {
    getPersonData(val) {
      if (val.logo && !this.getIsCompany) {
        this.userImage = val.logo.imageUrl;
      }
    },
    getUser() {
      this.userImage = this.getIsCompany ? this.getCompany?.logo?.imageUrl ?? null : this.getPersonData?.logo?.imageUrl ?? null;
    },
    userImageToAdd(val) {
      if (val) {
        if (val.size > 100000) {
          this.showError(null, 'Размер изображения не должен превышать 100Кб');
          this.userImageToAdd = null;
        } else {
          let reader = new FileReader
          reader.onload = e => {
            this.userImageToAddPreview = e.target.result
          };
          reader.readAsDataURL(val);
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchPersonData'
    ]),
    sendUserAvatar() {
      let data = new FormData();
      data.append('logo', this.userImageToAddPreview);
      return this.axios
          .post(`/api/post/physical/persons/logo/change`, data)
          .then(response => {
            this.userImage = response.data.imageUrl;
            this.userImageToAdd = null;
            this.userImageToAddPreview = null;
            this.showSuccess('Ваше фото обновлено');
            this.editProfile = false;
          })
          .catch(error => {
            this.userImageToAdd = null;
            this.userImageToAddPreview = null;
            this.showError(error, `При загрузке нового фото что-то пошло не так`);
          })
    },
    sendData() {
      if (this.userImageToAdd) {
        this.sendUserAvatar()
            .then(() => {
              this.fetchPersonData();
            })
      } else {
        this.discardUnsaved();
      }
    },
    discardUnsaved() {
      this.editProfile = false;
      this.userImageToAdd = null;
      this.userImageToAddPreview = null;
    },
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    if (this.getIsCompany) {
      this.userImage = this.getCompany?.logo?.imageUrl ?? '/img/default_avatar.svg';
    } else {
      this.userImage = this.getPersonData?.logo?.imageUrl ?? '/img/default_avatar.svg';
    }
  },
  beforeUnmount() {
    // отмена запросов
    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style scoped lang="scss">

.cabinet_tit {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.prof_info {
  padding-bottom: 48px;
  display: flex;
  transition: .2s linear;
  margin-right: 0px;
  border-bottom: 1px solid #2E3138 !important;
  margin-bottom: 32px;

  &.getFiz {
    border: none;

    .dt2 {
      margin-right: 0px;

      .div_tit {
        margin-bottom: 8px;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        .div_tit_name {
          width: calc(50% - 12px);
          height: 56px;
          padding: 8px 17px 8px 16px;
          border-radius: 6px;
          background: #191A1F;

          .div_tit_tit {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.015em;
            text-align: left;
            color: #5D636F;
          }

          .div_tit_val {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.015em;
            text-align: left;
            color: #5D636F;
          }
        }
      }
    }
  }

  &.is-editing {
    background: rgba(255, 255, 255, 0.05);
  }

  &.is-sending {
    pointer-events: none;
    background: rgba(87, 136, 200, 0.15);
  }

  .dt1 {
    flex: none;
    margin-right: 32px;
    width: 136px;
    height: 136px;

    .img {
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      margin-bottom: 8px;

      .edit_avatar {
        position: relative;

        .text {
          position: absolute;
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          background: rgba(27, 43, 64, 0.95);
          line-height: 18px;
          width: 180px;
          padding: 5px;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 10px;
          border-radius: 7px;
          opacity: 0;
          transition: .2s linear;
        }
      }

      &:hover {
        .edit_avatar .text {
          opacity: 1;
        }
      }

      &.not_img {
        background-size: 20px;
        border: 1px solid rgba(255, 255, 255, .15);
        @media (max-width: 767px) {
          background-size: 40px;
        }
      }
    }
  }

  .dt2 {
    width: 100%;
    margin-right: 30px;

    .div_tit {
      margin-bottom: 20px;
    }
  }

  //.dt3 {
  //  margin-right: 30px;
  //  width: calc(50% - 30px - 90px);
  //}

  .dt4 {
    margin-left: auto;
    display: flex;
    flex-direction: column;

    .btn {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn_change {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #ED850A;
      display: flex;
      justify-content: center;
      margin-bottom: 6px;
    }
  }


  //.div_stat {
  //  margin-bottom: 8px;
  //}
  //
  //.div_stat_el {
  //  margin-bottom: 8px;
  //
  //  .dse_text {
  //    margin-right: 5px;
  //  }
  //
  //  > * {
  //    vertical-align: middle;
  //  }
  //
  //  &:last-child {
  //    margin-bottom: 0;
  //  }
  //}


  @media (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;

    .dt1 {
      width: 100px;
      height: 100px;
      margin: 0 20px 60px 0;
      order: 1;

      .img {
        width: 136px;
        height: 136px;
      }
    }
    .dt2 {
      order: 3;
      width: calc(86% - 30px);

      .div_tit {
        margin-bottom: 47px;
        padding-left: 10px;
      }
    }
    /*.dt3 {
      order: 4;
      width: 55%;
      margin-right: 0;
    }*/
    .dt4 {
      order: 2;
      text-align: right;
      width: calc(100% - 150px);
      justify-content: flex-end;
      margin-left: 70px;

      .btn {
        height: fit-content;
        margin: 0 0 0 20px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0px;

    .dt1 {
      width: 136px;
      height: 136px;

      .img {
        height: 136px;
        width: 136px;
      }
    }
    .dt2 {
      width: 100%;
      margin-bottom: 24px;
      flex-direction: column;

      .div_tit {
        margin-bottom: 10px;
        margin-right: 0;
        flex-direction: column;
        padding-left: 0px;

        .div_tit_name {
          width: 100% !important;
        }
      }
    }
    /*.dt3 {
      width: 100%;
      margin-right: 0;

    }*/
    .dt4 {
      flex-wrap: wrap;
      margin-left: 30px;

      .btn {
        margin: 0 0 0 20px;
        //width: 40px;
        height: 40px;
        min-height: 40px;
        flex: none;

        img {
          max-width: 13px;
        }
      }
    }
  }
}

.contacts {
  padding-bottom: 48px;
  border-bottom: 1px solid #2E3138 !important;
  margin-bottom: 32px;

  .contacts_tit {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 24px;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .contacts_wrap {
    display: flex;
    gap: 24px;

    .contacts_el {
      width: calc(50% - 12px);
      height: 56px;
      padding: 8px 17px 8px 16px;
      border-radius: 6px;
      background: #191A1F;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .contacts_left {

        .contacts_title {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.015em;
          text-align: left;
          color: #5D636F;
        }

        .contacts_a {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.015em;
          text-align: left;
          color: #5D636F;
        }
      }

      img {
        width: 24px;
        height: 24px;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;

      .contacts_el {
        width: 100%;
      }
    }
  }
}

.company_title {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-align: left;

  @media (max-width: 767px) {
    font-size: 32px;
  }
}

</style>

