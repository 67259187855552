<template>
<!--  v-model="selectSelected"-->
  <multiselect
      class="multiselect_multiple"

      :modelValue="modelValue"
      @update:modelValue="modelValue = $event"

      :track-by="trackby"
      :label="label"
      :placeholder="placeholder"
      :options="options"
      :searchable="false"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      @select="selectChange"
      @remove="selectChange"
      ref="multiselect"

  >
    <template v-slot:selection>
      <div v-if="modelValue.length > 0" class="selected_wrap">
        <div class="multiselect__placeholder">{{ placeholder }}</div>
        <div class="multiselect__length">{{ modelValue.length }}</div>
      </div>
    </template>
    <template v-slot:afterList>
      <div class="multiselect__bottom">
        <cl-button type="light2" class="" @click="modelValue = []; selectChange(); $refs.multiselect.deactivate()">Сбросить</cl-button>
        <cl-button @click="$refs.multiselect.deactivate()">Применить</cl-button>
      </div>
    </template>
  </multiselect>
</template>

<script>
export default {
  name: "selectAndBtn",
  props: {
    modelValue: [Array, String, Number],
    trackby: [String, Number],
    label: [String, Number],
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String
    }
  },
  methods: {
    selectChange(){
      //console.log(this.modelValue)
      this.$emit('update:modelValue', this.modelValue);
      this.$emit('selectChange', this.modelValue);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>