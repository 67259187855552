<template>
  <container>
      <div class="voting_film_school">
          <row>
              <column lg="6">
                  <div class="voting_dt1">
                    <div class="voting_tit" v-if="dataInfo.question?.voteQuestion.value">
                        {{ this.isSur ? "Спасибо за ваш голос, нам очень важно ваше мнение!" : dataInfo.question.voteQuestion.value }}
                    </div>
                    <div class="div_voting">
                        <row>
                            <column class="flex-1">
                              <template v-for="(item, key) in dataInfo.question.data">
                                  <div class="fake_radio fake_radio2" :class="activeRadio == item.id ? 'checked' : ''" v-if="key % 2 == 1" @click="activeRadio = isSur ? activeRadio : item.id">{{ item.description }}</div>
                              </template>
                            </column>
                            <column class="flex-1">
                                <template v-for="(item, key) in dataInfo.question.data">
                                  <div class="fake_radio fake_radio2" v-if="key % 2 == 0" :class="activeRadio == item.id ? 'checked' : ''" @click="activeRadio = isSur ? activeRadio : item.id">{{ item.description }}</div>
                                </template>
                            </column>
                        </row>
                    </div>
                    <div class="voting_btns" v-if="!this.isSur">
                        <cl-button class="btn-middle btn-width-mob-full" :class="!activeRadio ? 'btn-disable-background' : ''" @click="changeResult()">Проголосовать</cl-button>
                    </div>
                  </div>
              </column>
              <column lg="6">
                  <div class="voting_dt2">
                      <div v-for="item in answerVotesCircle" class="col-circl">
                          <div v-for="i in item" class="circle-item" :class="getPercentClass(i.percent)">
                            {{ i.description }}
                            <div class="percent">
                              {{ Math.round(i.percent) }}%
                            </div>
                          </div>
                      </div>
<!--                      <div class="info_voting">-->
<!--                          <div class="item_info">-->
<!--                              <div class="dot"></div>-->
<!--                              10%-->
<!--                          </div>-->
<!--                          <div class="item_info item_info20">-->
<!--                              <div class="dot"></div>-->
<!--                              20%-->
<!--                          </div>-->
<!--                          <div class="item_info item_info30">-->
<!--                              <div class="dot"></div>-->
<!--                              30%-->
<!--                          </div>-->
<!--                          <div class="item_info item_info40">-->
<!--                              <div class="dot"></div>-->
<!--                              40%-->
<!--                          </div>-->
<!--                          <div class="item_info item_info50">-->
<!--                              <div class="dot"></div>-->
<!--                              50%-->
<!--                          </div>-->
<!--                      </div>-->
                  </div>
              </column>
          </row>
      </div>
  </container>
</template>

<script>
import Container from "@/components/library/Container.vue";
import Column from "@/components/library/Column.vue";
import ClButton from "@/components/library/ClButton.vue";
import splitArray from "@/mixin/splitArray";
import Header from "@/App.vue";

export default {
  name: "votingFilmSchool",
  components: {Header, ClButton, Column, Container},
  props:{
    dataInfo: {
      type: Object,
      required: true,
    }
  },
  data() {
    return{
      activeRadio: null,
			answerVotesCircle: null,
			isSur: false,
			questionParams: {
				id: null,
				answer: null,
				session: null,
      }
    }
  },
  methods: {
		getPercentClass(percent){
      if(percent < 10 || !percent){
        return "circle-item10";
      } else if (percent < 21){
        return "circle-item20";
      } else if (percent < 31){
				return "circle-item30";
			} else if (percent < 41){
				return "circle-item40";
			} else if (percent < 51 || percent > 50){
				return "circle-item50";
			}
    },
		changeResult() {
			let randomNum;
			if(document.cookie.indexOf('schoolSurvey') != -1) {
				randomNum = this.getCookie().schoolSurvey;
			} else {
				randomNum = Math.random() * 1000000000000000000;
				var expires = (new Date(Date.now() + 24 * 60 * 60 * 1000)).toUTCString();
				document.cookie = `schoolSurvey=${randomNum}; expires=${expires}; path=/;`;
				document.cookie = `activeSchool=${this.activeRadio}; expires=${expires}; path=/;`;
        this.questionParams.id = this.dataInfo.question.voteQuestion.id;
        this.questionParams.answer = this.activeRadio;
        this.questionParams.session = randomNum;
        this.axios
          .post(`/api/data/vote`,null,{params: this.questionParams})
          .then(response => {
            this.dataInfo.question.data = response.data.data;
						this.answerVotesCircle = this.splitArray(response.data.data, 3);
            this.theme = 'result';
						this.isSur = true;
          })
          .catch(error => {

          })
			}
			//document.cookie = "mainSurvey=true; max-age=0"
		},
		getCookie() {
			return document.cookie.split('; ').reduce((acc, item) => {
				const [name, value] = item.split('=')
				acc[name] = value
				return acc
			}, {})
		},
  },
  mounted() {
      console.log(this);
		if(document.cookie.indexOf('schoolSurvey') != -1){
			this.changeResult();
			this.activeRadio = this.getCookie().activeSchool;
			this.isSur = true;
		}
		this.answerVotesCircle = this.splitArray(this.dataInfo.question.data, 3);
	}
};
</script>

<style lang="scss" scoped>
.voting_tit{
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0.064rem;
  text-transform: uppercase;
}
.voting_film_school{
  padding: 4.8rem 0;
  .voting_dt1{
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
  .div_voting{
    padding: 2.4rem;
    border: 1px solid #17191C;
    border-radius: 1.2rem;
    .fake_radio{
      padding: 0.8rem 1.2rem;
      margin-bottom: 0.4rem;
    }
  }
  .voting_btns{
    display: flex;
    gap: 1.6rem;
  }
}
.voting_dt2{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  height: 100%;
  //padding-bottom: 4rem;
  .col-circl{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: center;
  }
  .circle-item{
    display: flex;
    width: 5.6rem;
    height: 5.6rem;
    padding: 1.5rem 0.3rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    background: #FDE8CE;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 112.5%;
    letter-spacing: 0.012rem;
    text-align: center;
    word-break: break-all;
    color: #000106;
  }
  .circle-item20{
    background: #FBD19D;
    width: 8rem;
    height: 8rem;
    padding: 2.3rem 0.4rem;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 111.111%;
    letter-spacing: 0.0135rem;
  }
  .circle-item30{
    background: #F9BA6C;
    width: 10rem;
    height: 10rem;
    padding: 3rem 0.4rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.015rem;
  }
  .circle-item40{
    background: #F7A23B;
    width: 12rem;
    height: 12rem;
    padding: 3rem 1.4rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.015rem;
    color: #FFF;
  }
  .circle-item50{
    background: #ED850A;
    width: 15rem;
    height: 15rem;
    padding: 4.1rem 0.8rem;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 116.667%;
    letter-spacing: 0.018rem;
    color: #FFF;
  }
}
.info_voting{
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 7rem;
  .item_info{
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 114.286%;
    letter-spacing: 0.021rem;
    .dot{
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background: #FDE8CE;
      margin-bottom: 4px;
    }
  }
  .item_info20{
    .dot{
      background: #FBD19D;
    }
  }
  .item_info30{
    .dot{
      background: #F9BA6C;
    }
  }
  .item_info40{
    .dot{
      background: #F7A23B;
    }
  }
  .item_info50{
    .dot{
      background: #ED870A;
    }
  }
}

@media (max-width: 991px){
  .voting_film_school{
    .voting_tit{
      font-size: 2.4rem;
      line-height: 133.333%;
      letter-spacing: 0.048rem;
    }
    .div_voting{
      padding: 1.2rem;
    }
    .row{
      gap: 4.8rem 0;
    }
  }
  .voting_dt2{
    .circle-item{
      width: 5.2rem;
      height: 5.2rem;
      font-size: 0.8rem;
      letter-spacing: 0.0114rem;
    }
    .circle-item20{
      width: 7.5rem;
      height: 7.5rem;
      padding: 2rem 0.4rem;
    }
    .circle-item30{
      width: 10rem;
      height: 10rem;
      padding: 2rem 0.4rem;
    }
    .circle-item40{
      width: 11.4rem;
      height: 11.4rem;
      padding: 3rem 1rem;
    }
    .circle-item50{
      background: #ED850A;
      width: 14.2rem;
      height: 14.2rem;
      padding: 3rem 0.8rem;
      font-size: 1.1rem;
    }
  }
}
@media (max-width: 767px){
  .voting_film_school{
    .div_voting{
      .row{
        flex-direction: column;
        gap: 0;
      }
      .fake_radio{
        margin: 0;
      }
    }
  }
  .voting_dt2{
    .circle-item{
      width: 3.2rem;
      height: 3.2rem;
      font-size: 0.5rem;
      letter-spacing: 0.0068rem;
    }
    .circle-item20{
      width: 4.5rem;
      height: 4.5rem;
      font-size: 0.5rem;
      padding: 0.7rem 0.2rem;
      letter-spacing: 0.0077rem;
    }
    .circle-item30{
      width: 5.7rem;
      height: 5.7rem;
      padding: 0.7em 0.2rem;
      font-size: 0.6rem;
      letter-spacing: 0.0086rem;
    }
    .circle-item40{
      width: 6.8rem;
      height: 6.8rem;
      padding: 1.5rem 0.5rem;
      font-size: 0.6rem;
      letter-spacing: 0.0086rem;
    }
    .circle-item50{
      background: #ED850A;
      width: 8.5rem;
      height: 8.5rem;
      padding: 1.5rem 0.5rem;
      font-size: 0.7rem;
      letter-spacing: 0.0103rem;
    }
  }
}
</style>