<template>
  <div class="block_wrap list_collaps" :class="this.dataInfo.blockClass?.value">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title?.value }}</h2>
      <div class="block_min" v-if="dataInfo.list && dataInfo.list.length > 0">
        <cl-collapse
            v-for="item in dataInfo.list"
            :key="item.title.value">
          <template v-slot:name>
            <div v-if="item.title" v-html="item.title?.value" class="fs3"></div>
          </template>
          <template v-slot:card>
            <div v-if="item.text" v-html="item.text?.value" class="fs4n"></div>
          </template>
        </cl-collapse>
      </div>
    </container>
  </div>
</template>

<script>

export default {
  name: "collapseList",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.list_collaps {
  padding: 80px 0 80px 0;
  @media (max-width: 1400px) {
    padding: 64px 0;
  }
  @media (max-width: 991px) {
    padding: 32px 0;
    h2{
      margin-bottom: 24px;
    }
  }
  &.container_qa{
    margin: 4rem 0;
    padding: 60px 0;
    .container {
      max-width: 1440px;
      margin-right: auto;
      margin-left: auto;
      padding: 0;
      padding-left: 32px;
      padding-right: 32px;
    }
    h2{
      text-align: center;
    }
    @media(max-width: 1024px){
      padding-left: 40px;
      padding-right: 40px;
      .container{
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    @media(max-width: 767px){
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>