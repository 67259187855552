<template>
    <mos-school-top-filter @searchFun="searchFun" :deleteParamsStatus="deleteParamsStatus"/>
    <container class="list_programs">
        <h2 v-if="dataInfo.title?.value">{{ dataInfo.title.value }}</h2>
        <div class="programs_count">Все программы: <span class="value" v-if="data?.total || data?.total == 0">{{ data.total }}</span></div>
        <div class="programs_list">
            <loader
                v-if="isLoading"
                :animation-duration="3000"
                :size="60"
            ></loader>
            <not-info
                v-if="!isLoading && dataList?.length == 0"
                :title="!Object.keys(getParams).length ? `Здесь пока ничего нет` : `Ничего не нашлось`"
                :text="!Object.keys(getParams).length ? `Нет результатов` : `Нет результатов, удовлетворяющих критериям поиска. Попробуйте сбросить фильтры`"
            >
                <template v-slot:btn v-if="Object.keys(getParams).length">
                    <div class="div_btn">
                        <cl-button @click="deleteParamsStatus = true;">Сбросить фильтры</cl-button>
                    </div>
                </template>
            </not-info>
            <row>
                <column lg="3" md="6" v-for="item in dataList">
                    <card card-type="program" :route-link="`/kinoschools/programs/item/${item.guid}`">
                        <div class="card_info">
                            <div class="category-profession">
                                <span class="name" v-if="item.direction?.name">{{ item.direction.name }}</span>
                                <span class="dot" v-if="item.durationValue">·</span>
                                <span class="period" v-if="item.durationValue">{{ item.durationValue }}</span>
                            </div>
                            <div class="name-profession">
                                <div class="name" v-if="item.name">{{ item.name }}</div>
                                <div class="university-name" v-if="item.school?.fullName">«{{ item.school.fullName }}»</div>
                            </div>
                        </div>
                        <div class="card_img">
                            <img :src="item.school.logo.imageUrl" alt="" v-if="item.school?.logo">
                        </div>
                    </card>
                </column>
            </row>
            <div v-if="(data?.page < data?.pages || isLoadingMore) && !isLoading" class="show_more">
                <loader
                    v-if="isLoadingMore"
                    :animation-duration="1500"
                    :size="55"
                    :color="'#ED850A'"
                ></loader>
                <cl-button v-else @click="loadMore" type="light sm-w-100">Показать еще</cl-button>
            </div>
        </div>
    </container>
</template>

<script>
import MosSchoolTopFilter from "@/components/mosSchoolTopFilter.vue";
import Container from "@/components/library/Container.vue";
import Column from "@/components/library/Column.vue";
import Card from "@/components/library/Card.vue";
import ArendaKinopomecheniyTopFilter from "@/components/arendaKinopomecheniyTopFilter.vue";

export default {
    name: "listTrainingPrograms",
    components: {ArendaKinopomecheniyTopFilter, Card, Column, Container, MosSchoolTopFilter},
    data(){
        return{
            isLoading: true,
            isLoadingMore: false,

            controller: null,
            deleteParamsStatus: false,
            getParams: {},
            data: null,
            dataList: null,
        }
    },
    props: {
        dataInfo: {
            type: Object,
            require: true,
        }
    },
    methods: {
        searchFun(params){
            this.getParams = params;
            this.getPrograms();
            this.deleteParamsStatus = false;
            if (Object.keys(params).length > 0) {
                history.pushState({}, "", "?" + new URLSearchParams(params));
            }
        },
        getPrograms(isLoadMore){
            if (!isLoadMore) {
                delete this.getParams.page;
                this.isLoading = true;
            }
            return this.axios
                .get(`/api/data/educational-program`, {params: this.getParams, signal: this.controller.signal})
                .then(response => {
                    if (isLoadMore) {
                        this.dataList = [...this.dataList, ...response.data.records];
                    } else {
                        this.dataList = response.data.records;
                    }
                    this.data = response.data;
                })
                .catch(error => {
                    this.showError(error);
                })
                .finally(() => {
                    this.isLoading = false;
                })
        },
        loadMore() {
            this.isLoadingMore = true;
            this.getParams.page = ++this.data.page;
            this.$nextTick(() => {
                this.getPrograms(true).then(() => {
                    this.isLoadingMore = false
                })
            });
        },
    },
    created() {
        this.controller = new AbortController();
    },
    mounted() {
        this.getPrograms();
    },
    beforeUnmount() {
        this.controller.abort();
        this.controller = null;
    },
};
</script>

<style lang="scss" scoped>
.list_programs{
    padding-top: 230px;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    h2{
        margin: 0;
    }
    .programs_count{
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 133.333%;
        letter-spacing: 0.048rem;
        .value{
            color: #ED850A;
        }
    }
}
</style>