<template>
  <container>
    <div class="div_wrap">
      <div class="div_inner">
        <div class="div_cont">
          <h2 class="d-lg-block d-none">
            <template v-if="dataInfo.dataHeader">
              {{ dataInfo.dataHeader.dataTitle.value }}
            </template>
            <template v-else>
              {{ dataTextHead }}
            </template>
          </h2>
          <div class="div_cards">
            <div class="div_card">
              <div class="div_top">
                <img src="/img/ico/adress.svg" alt="">
                <div class="div_tit">
                  <template v-if="dataInfo.dataAdress">
                    {{ dataInfo.dataAdress.dataTitle.value }}
                  </template>
                  <template v-else>
                    {{ dataTextTit }}
                  </template>
                </div>
              </div>
              <div class="div_txt">
                <template v-if="dataInfo.dataAdress">
                  {{ dataInfo.dataAdress.dataText.value }}
                </template>
                <template v-else>
                  {{ dataTextAdr }}
                </template>
              </div>
              <a class="btn btn-light"
                 target="_blank"
                 href="https://yandex.ru/maps/org/moskinopark/111450418589/?ll=37.257231%2C55.422172&z=15.69"
              >
                Перейти на карту
              </a>
            </div>
            <div class="div_card grey_border">
              <div class="div_top">
                <img src="/img/ico/car.svg" alt="">
                <div class="div_tit">
                  <template v-if="dataInfo.dataCar">
                    {{ dataInfo.dataCar.dataTitle.value }}
                  </template>
                  <template v-else>
                    {{ dataTextCar }}
                  </template>
                </div>
              </div>
              <div class="div_txt mb-0">
                <template v-if="dataInfo.dataCar">
                  {{ dataInfo.dataCar.dataText.value }}
                </template>
                <template v-else>
                  {{ dataTextCartxt }}
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="ya-map-container">
          <h2 class="d-lg-none d-block">
            <template v-if="dataInfo.dataHeader">
              {{ dataInfo.dataHeader.dataTitle.value }}
            </template>
            <template v-else>
              {{ dataTextHead }}
            </template>
          </h2>
          <yandex-map
              :coords="[55.417070, 37.260839]"
              :controls="[]"
              :zoom=12
              class="ya-map-howtoget"
          >
            <ymap-marker
                :coords="[55.417070, 37.260839]"
                :icon="markerIcon"
                :options="markerOptions"
            >
            </ymap-marker>
          </yandex-map>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import {yandexMap, ymapMarker} from 'vue-yandex-maps'

export default {
  components: {yandexMap, ymapMarker},
  data() {
    return {
      dataTextHead: "Как добраться",
      dataTextTit: "Адрес:",
      dataTextAdr: "Москва, поселение Краснопахорское, квартал № 107",
      dataTextCar: "Самостоятельно на машине",
      dataTextCartxt: "Рядом с кинопарком организована парковка для частного транспорта"
    }
  },
  name: "howToGet",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  mounted() {
    if (this.dataInfo.address) {
      this.dataTextAdr = this.dataInfo.address;
    }
  }
}
</script>

<style lang="scss" scoped>

.div_wrap {
  padding: 12rem 0;
  min-height: 39.9rem;

  .div_inner {
    display: flex;
    justify-content: space-between;
    gap: 2.4rem;

    .div_cont {

      h2 {
        margin-bottom: 2.4rem;
      }

      .div_cards {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        .div_card {
          border: 2px solid #ED850A;
          min-width: 52.8rem;
          min-height: auto;
          padding: 2.4rem;
          border-radius: 1.2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .div_top {
            display: flex;
            gap: .8rem;
            margin-bottom: .8rem;
            align-items: center;

            .div_img {
              width: 2.6rem;
              height: 2.6rem;

              img {
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: 100%;
              }
            }

            .div_tit {
              font-size: 2.4rem;
              font-weight: 500;
              line-height: 3.2rem;
              letter-spacing: 0.02em;
              text-align: left;
              text-transform: uppercase;
            }
          }

          .div_txt {
            font-size: 1.6rem;
            line-height: 2.1rem;
            letter-spacing: 0.015em;
            text-align: left;
            margin-bottom: 1.6rem;
          }

          .btn {
            white-space: nowrap;
            max-width: 19rem;
            max-height: 4rem;
          }
        }

        .grey_border {
          border: 1px solid #6B7280;
        }
      }
    }

    .ya-map-container {
      min-width: 50rem;
      width: 100%;
      height: auto;

      .ya-map-howtoget {
        width: 100%;
        height: 100%;
        border-radius: 1.2rem;
      }
    }
  }

  @media (max-width: 1440px) {

    .ya-map-container {
      min-width: 100%;
    }
  }

  @media (max-width: 1024px) {

    .div_cont {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 6.4rem 0;

    .div_inner {
      min-height: auto;
      flex-direction: column-reverse;
      justify-content: center;

      .div_cont {

        h2 {
          font-size: 3.2rem;
          line-height: 4.8rem;
          letter-spacing: 0.02em;
          margin-bottom: 2.4rem;
        }

        .div_cards {

          .div_card {
            min-width: 100%;

            .div_top {

              .div_tit {
                font-size: 2.4rem;
                line-height: 3.2rem;
                letter-spacing: 0.02em;
              }
            }

            .div_txt {
              font-size: 1.6rem;
              line-height: 2.1rem;
              letter-spacing: 0.015em;
            }
          }
        }
      }

      .ya-map-container {
        min-width: 100%;

        h2 {
          font-size: 3.2rem;
          line-height: 4.8rem;
          letter-spacing: 0.02em;
          margin-bottom: 2.4rem;
        }

        .ya-map-howtoget {
          width: 100%;
          height: 29rem;
          border-radius: 1.2rem;
        }
      }
    }
  }
}

</style>