<template>
  <div class="block_wrap accordion_vertical_block">

    <template v-if="dataInfo.listCard && dataInfo.listCard.length > 0">
      <div class="accordion_vertical_block_fon"
           :style="[ getBkgrImage(lastEl.image, 'fullHd')]"
           v-if="lastEl.image"></div>
      <transition name="fade-base2">
        <div class="accordion_vertical_block_fon"
             :style="[ getBkgrImage(activeEl.image, 'fullHd')]"
             v-if="showFon"></div>
      </transition>
    </template>

    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title?.value }}</h2>
      <div class="accordion_vertical block_min" ref="accordionVertical" :class="{'event-none': !activeStatus}">
        <div v-for="(item, index) in dataInfo.listCard"
             :key="item.image"
             class="div_el"
             :class="{'active': item == activeEl}"
        >
          <div class="overflow-hidden">
            <div class="div_width" :style="{width: widthContent}" :id="index == 0 ? 'accordionVerticalEL' : ''"
                 :ref="index == 0 ? 'accordionVerticalEL' : ''">
              <div class="div_img"><img :src="getImage(item.image, 'middle')"></div>
              <h3 class="div_tit">{{ item.title.value }}</h3>
              <div class="div_text fs4n" v-html="item.text.value"></div>
            </div>
          </div>
          <div v-if="isMobile" class="div_tit_pos fs3" @mouseenter="showEl(item)">
            <div class="div_tit">{{ item.title.value }}</div>
          </div>
          <div v-else class="div_tit_pos fs3" @click="showEl(item)">
            <div class="div_tit">{{ item.title.value }}</div>
          </div>
        </div>
      </div>

    </container>
  </div>
</template>

<script>

import Header from "@/App.vue";

export default {
  name: "accordionVertical",
  components: {Header},
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      activeEl: false,
      lastEl: false,
      showFon: false,
      widthContent: '100%',
      activeStatus: true,
      isMobile: false,
    }
  },
  methods: {
    widthShow() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.widthContent = this.$refs.accordionVerticalEL[0].offsetWidth + 'px'
        }, 500);
      });
    },
    showEl(item) {
      //this.$refs.accordionVertical.classList.add('event-none');
      if (this.activeStatus && this.activeEl != item) {
        this.showFon = false;
        this.activeEl = item;
        this.activeStatus = false;
        this.$nextTick(() => {
          this.showFon = true;
        });
        setTimeout(() => {
          //this.lastEl = item;
          this.activeStatus = true;
          //this.$refs.accordionVertical.classList.remove('event-none');
        }, 400);
      }

    },
    resizeAc(){
      var heightAc = 0;
      const elementAc = document.querySelector(".accordion_vertical");
      if(window.innerWidth < 992 && elementAc){
        // elementAc.style.height = "";
        setTimeout(() => {
          heightAc = elementAc.offsetHeight;
          elementAc.style.height = heightAc + "px"
        }, 500)
        // const interval2 = setInterval(() => {
        //   if(heightAc < elementAc.offsetHeight){
        //   } else{
        //     elementAc.style.height = heightAc + "px"
        //     clearInterval(interval2)
        //   }
        // }, 500)
      } else {
        if(elementAc)
          elementAc.style.height = "";
      }
    },
  },
  mounted() {
    this.activeEl = this.dataInfo.listCard[0];
    this.lastEl = this.dataInfo.listCard[0];
    this.widthShow();

    const isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };

    if(isMobile.any()){
        this.isMobile = true;
    }

    this.resizeAc();
    window.addEventListener("resize", this.resizeAc);
  }
}
</script>

<style lang="scss" scoped>
.accordion_vertical_block {
  padding: 64px 0;
  position: relative;
  overflow: hidden;

  .accordion_vertical_block_fon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(22px);
  }

  > .container {
    position: relative;
  }
}

.accordion_vertical {
  display: flex;
  gap: 12px;

  .div_el {
    background: #0C0D0E;
    position: relative;
    flex: none;
    width: 80px;
    @media (max-width: 1401px) {
      width: 64px;
    }
    height: 640px;
    padding: 32px;
    overflow: hidden;
    transition: all .5s ease;

    &.active {
      flex: auto;
      width: 100%;

      .div_tit_pos {
        display: none;
        cursor: default;

        .div_tit {
          margin-left: -16px;
          padding-right: 32px;
        }
      }
    }
  }

  .div_width {
    height: calc(640px - 64px);
    min-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .div_img {
    margin-bottom: 8px;
    overflow: hidden;
    flex: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3{
    margin-bottom: 8px;
  }

  .div_text {
    flex: none;
  }

  .div_tit_pos {
    cursor: pointer;
    background: #0C0D0E;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 80px;
    @media (max-width: 1401px) {
      width: 64px;
      line-height: 133.33%;
    }


    .div_tit {
      padding: 16px 32px;
      transform: rotate(-90deg) translateX(-100%);
      transform-origin: left top;
      width: 640px;
      transition: padding 0.4s linear, margin 0.4s linear;
    }
  }
}

@media (max-width: 991px) {
  .accordion_vertical{
    flex-direction: column;
    .div_el {
      width: 100%;
      height: 56px;
      padding: 16px;
      transition: all 0.5s ease;
      &.active {
        height: 100%;
        .div_tit_pos {
          .div_tit {
          }
        }
      }
    }

    .overflow-hidden{
      height: 440px;
    }
    .div_width {
      width: 100% !important;
      height: 100%;
      .div_tit{
        margin: 24px 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 133.333%;
        letter-spacing: 0.36px;
      }
      .div_text{
        font-size: 16px;
        font-weight: 400;
        line-height: 131.25%;
        letter-spacing: 0.24px;
      }
      .div_tit, .div_text {
        text-align: center;
      }
    }

    .div_img {

      img {

      }
    }

    h3{
    }

    .div_text {
    }

    .div_tit_pos {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 57px;
      .div_tit {
        padding: 16px 16px;
        transform: rotate(0) translateX(0);
        transform-origin: left top;
        width: 100%;
        font-weight: 500;
        line-height: 133.333%;
        letter-spacing: 0.36px;
        font-size: 1.8rem;
        text-align: center;
      }
    }
  }
}
</style>