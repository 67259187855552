<template>
  <div class="cart-page">
    <div class="container">
      <h2 class="cart-title" v-if="dataInfo.title">{{ dataInfo.title.value }} <sup
          v-if="shoppingCart.length > 0">{{ shoppingCart.length }}</sup></h2>
      <div class="top-text fs4n" v-if="dataInfo.text" v-html="dataInfo.text.value"></div>
      <div class="row">
        <div class="col-md-8">
          <template v-if="shoppingCart.length > 0">
            <template v-for="(list, key) of cartList"
                      :key="key">
              <template v-if="list.listChild.length > 0">
                <h3 v-if="list.title">{{ list.title }}</h3>
                <div class="cart-list">
                  <div class="cart-item"
                       v-for="item of list.listChild"
                       :key="item.guid">
                    <div class="cart-item-info">
                      <div class="cart-item-type fs16" v-if="item.categories && item.categories.length > 0">
                        <template v-for="(cat, index) in item.categories">
                          {{ cat.name }}<span v-if="index >= item.categories.length">, </span>
                        </template>
                      </div>
                      <div class="cart-item-title fs3">{{ item.name }}</div>
<!--                      <router-link class="cart-item-title fs3" :to="`/arenda_rekvizita/mebel?guid=${item.guid}`">{{ item.name }}</router-link>-->
                      <div class="cart-item-id fs16" v-if="item.code">ID: {{ item.code }}</div>
                    </div>
                    <div class="cart-item-num fs21 fw600" v-if="item.price"><span class="sp fs16">от</span> {{ item.price }}₽/шт</div>
                    <div v-if="btnAddShow(item)" class="block_add_count block_add_count_min">
                      <div class="bac_btn" @click.prevent="removeProductCart(item, false)"><img src="/img/ico/minus_st.svg" alt=""></div>
                      <div class="bac_num fs4n">{{ btnAddShow(item) }}</div>
                      <div class="bac_btn img_wh" @click.prevent="btnAddShow(item) >= item.count ? '' : addProductCart(item)" :class="{'disabled': btnAddShow(item) >= item.count }"><img src="/img/ico/plus_st.svg" alt=""></div>
                    </div>
                    <div class="cart-item-remove" @click.stop="removeProductCart(item);">
                      <img src="../assets/img/ico/trash.svg" alt="">
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
          <template v-else>
            <div class="not-found-text">Ваша корзина пуста, можете посмотреть и&nbsp;добавить в&nbsp;список что&nbsp;вам&nbsp;нужно</div>
            <cl-button @click="$router.push({path: '/arenda_rekvizita'})">Перейти в категории</cl-button>
          </template>
        </div>
        <div class="col-md-4" v-if="showFormFirst">
          <div class="card cart-form" :id="'formContainer' + dataInfo.form.value">
            <div class="cart-form-description fs4n">Введите вашу электронную почту и мы отправим вам сформированный список кинореквизита, чтобы вы смогли успешно оформить заказ на складе
            </div>
            <measure-form-component
                v-if="dataInfo.form && showForm"
                :measureGuid="dataInfo.form.value"
                :showMode="showMode"
                :formParams="formParams"
                class="loader_ab"
                formType="1"
            />
          </div>
          <div class="card cart-form is-success d-none" :id="'formResult' + dataInfo.form.value">
            <div class="cart-form-success-icon">
              <img src="../assets/img/ico/check-circle.svg" alt="">
            </div>
            <div class="cart-form-success-text">Список товара успешно отправлен на&nbsp;указанный email</div>
            <div class="cart-form-success-btn">
              <cl-button @click="$router.push({path: '/arenda_rekvizita'})">Вернуться в категории</cl-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toogleShoppingCart from "@/mixin/toogleShoppingCart";
import MeasureFormComponent from "@/components/measureForm.vue";

export default {
  name: "cartPage",
  components: {MeasureFormComponent},
  mixins: [toogleShoppingCart],
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data: () => ({
    cartList: {},
    cartListId: [],
    //cartListForm: [],

    formParams: {

      preSetParams: {},
    },
    showMode: 'inline',
    showForm: false,

    showFormFirst: false,
  }),

  methods: {
    // передаем данные в форму и открываем
    cartListIdFun() {
      this.showForm = false;
      this.cartListId = [];
      this.cartListId = [...this.shoppingCart].map(item => item.guid).join();
      this.formParams.preSetParams.film_basket = this.cartListId;

      //this.formParams.id = "multiBasket";
      //this.formParams.items = this.cartListForm;


      this.$nextTick(() => {
        if (this.cartListId.length) {
          this.showForm = true;
          this.showFormFirst = true
        } else {
          this.showFormFirst = false
        }
      });
    },
    // данные хранятся в памяти браузера, раньше был другой массив, теперь надо удалить старые данные
    deliteOldCard(){
      [...this.shoppingCart].map((item, index) => {
        if(!item.categories) {
          this.removeProductCart(item);
        }
      });
    },
    // создаем список покупок
    cartListAdd() {
      this.cartList = {};
      //this.cartListForm = [];
      [...this.shoppingCart].map((item, index) => {
        if (!this.cartList[item.categories[0].id]) {
          // добавляем ключ в обьект
          this.cartList[item.categories[0].id] = {title: item.categories[0].name ?? 'Категрии нет', listChild: []};
        }
        // добавляем обект в список, если его нет в списке
        const remove = [...this.cartList[item.categories[0].id].listChild].filter(el => el.guid == item.guid);
        if (remove.length <= 0) {
          this.cartList[item.categories[0].id].listChild.push(item);
        }


        /*
        // для новых параметров, старые удалить #444722
        let indexEl;
        const removeNew = [...this.cartListForm].filter((el, index) => {
          indexEl = index;
          return el[0].value == item.guid;
        });
        if(removeNew.length <= 0){
          let itemBasket = [
            {
              "name": "film_basket_name",
            },
            {
              "name": "film_basket_count",
            }
          ];
          itemBasket[0].value = item.guid;
          itemBasket[1].value = 1;
          this.cartListForm.push(itemBasket);
        } else {
          this.cartListForm[indexEl][1].value = +this.cartListForm[indexEl][1].value + 1;
        }*/

      })
    },
    //отслеживаем изменение результатов формы
    formResult(){
      // выбираем элемент
      const target = document.querySelector('#formResult' + this.dataInfo.form.value);
      if(target){
        // создаем экземпляр наблюдателя
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            this.$store.commit('updateShoppingCart', []);
            // останавливаем наблюдение
            observer.disconnect();
          });
        });
        // настраиваем наблюдатель
        const config = { attributes: true, childList: true, characterData: true }
        // передаем элемент и настройки в наблюдатель
        observer.observe(target, config);
      }
    }
  },
  watch: {
    shoppingCart() {
      this.cartListAdd();
      this.cartListIdFun();
    }
  },
  mounted() {
    document.querySelector('body').classList.add('fon1');

    this.deliteOldCard();

    this.cartListAdd();
    this.cartListIdFun();

    if(this.shoppingCart.length > 0){
      this.showFormFirst = true;
      this.$nextTick(() => {
        this.formResult();
      });
    }
  },
  beforeUnmount() {
    document.querySelector('body').classList.remove('fon1');
  },
}
</script>

<style lang="scss" scoped>
.cart-page {
  padding-top: 150px;
}
.cart-title {
  margin-bottom: 0;
  letter-spacing: 0.96px;

  sup {
    color: #ED850A;
  }
}

.top-text {
  margin-bottom: 24px;
}

h3 {
  margin-bottom: 24px;
}
.cart-list {
  border-radius: 12px;
  border: 1px solid #FFF;
  padding: 0 24px;
  margin-bottom: 24px;
}
.cart-item {
  position: relative;
  padding: 32px 0;
  border-bottom: 1px solid #FFF;
  display: flex;
  align-items: center;
  gap: 32px;
  @media(max-width: 767px){
    flex-wrap: wrap;
  }

  &:last-child {
    border: none;
  }

  &-info {
    margin-right: auto;
    @media(max-width: 767px){
      width: calc(50% - 16px);
    }
  }

  &-type {
    margin-bottom: 4px;
  }

  &-title {
    margin-bottom: 4px;
  }

  &-id {
    color: rgba(255, 255, 255, 0.80);
  }

  &-num {
    display: flex;
    align-items: baseline;
    .sp {
      color: rgba(255, 255, 255, 0.80);
      margin-right: 8px;
    }
  }

  &-remove {
    cursor: pointer;
  }
}

.cart-form {
  margin-left: 21px;
  margin-top: 72px;
  height: auto;

  @media (max-width: 767px) {
    margin-left: 0;
  }
  
  &-description {
    margin-bottom: 24px;
  }

  &-input {
    margin-bottom: 24px;
  }

  &-btn {
    .btn {
      width: 100%;
    }
  }

  &.is-success {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000106;
  }

  &-success {
    &-icon {
      margin-bottom: 16px;

      img {
        width: 64px;
      }
    }

    &-text {
      font-size: 24px;
      line-height: 1.33;
      margin-bottom: 16px;
      text-align: center;
      letter-spacing: 0.48px;
    }

    &-btn {
      .btn:active {
        color: #000106;
      }
    }
  }
}

.not-found-text {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 0.64px;
  margin-bottom: 24px;
}
</style>