<template>
  <template v-if="canRemove">
    <div class="docs">
      <transition-group name="fade-base2" v-if="docs.length">
        <div
            class="document_remove"
            v-for="item in docs"
        >
          <div class="dt1">
            <a
                class="docs_item"
                is-external-resource
                :href="dataUrl(item)"
                :title="dataName(item)"
            >
              {{
                dataName(item).length < 32 ? dataName(item) : dataName(item).slice(0, 30).trim() + '...'
              }}
            </a>
          </div>
          <div class="dt2">
            <cl-button type="light" @click="$emit('remove', item)" :class="{'btn-circle': widthSite < 768}">
              <template v-if="widthSite > 767">удалить</template>
              <template v-else>
                <img src="/build/img/close.svg" alt="">
              </template>
            </cl-button>
          </div>
        </div>
      </transition-group>
    </div>
  </template>
  <template v-else>
    <div class="docs">
      <transition-group name="fade-base2">
        <a
            class="docs_item"
            is-external-resource
            v-for="item in docs"
            :href="dataUrl(item)"
        >
          {{ dataName(item) }}
        </a>
      </transition-group>
    </div>
  </template>

</template>

<script>
/*
* canRemove - вид с кнопкой удаления
* docs - масив документов
* @remove - событие удаления
* */
import {mapGetters} from "vuex";

export default {
  name: "Documents",
  props: {
    docs: Array,
    docsName: {
      type: String,
      default: 'документ'
    },
    canRemove: Boolean,
    docAdmin: {
      type: Boolean
    }
  },
  methods: {
    dataName(item){
      if(this.canRemove){
        return item.name ?? item.title;
      } else if (this.docAdmin){
        return item.media?.name;
      } else {
        return item.name ?? item.title ?? this.docsName;
      }
    },
    dataUrl(item){
      if(this.canRemove){
        return item.href ?? item.link;
      } else if (this.docAdmin){
        return item.media?.url;
      } else {
        return item.href ?? item.link ?? item.url;
      }
    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  },
}
</script>

<style scoped lang="scss">
.docs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &_item {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    transition: .2s linear;
    //word-break: break-all;
    &::before {
      content: "";
      width: 32px;
      height: 42px;
      background: url("../../assets/img/st/document.svg") no-repeat center center;
      background-size: contain;
      display: inline-block;
      margin-right: 20px;
      filter: invert(1);
      flex: none;
    }

    @media (max-width: 991px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 18px;
      &::before {
        width: 22px;
        height: 30px;
      }
    }

    &:hover {
      color: #E74362;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.document_remove {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 40px;
  background: rgba(87, 136, 200, 0.15);
  border-radius: 7px;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .dt1 {
    margin-right: 20px;

    a {
      vertical-align: middle;
    }
  }

  @media (max-width: 767px) {
    padding: 20px;
    //display: block;
    .dt1 {
      margin-right: 0;
    }
    .dt2 {
      margin-left: 5px;
    }
    .btn.btn-circle {
      width: 38px;
      height: 38px;
      min-height: 10px;

      img {
        width: 12px;
      }
    }

  }
}

</style>