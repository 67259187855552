<template>
  <li class="my-select--item" :class="{
    'my-select--group-label': item.type === groupType,
    'selected': item.isSelected,
    'disabled': disabled && !item.isSelected
    }"
  >
    <span @click.stop="selectItem(item)">{{ item[trackBy] }}</span>
    <slot name="group" />
  </li>
</template>

<script>
/**
 * Компонент элемента селекта
 */

export default {
  name: "ListItem",
  props: {
    item: {
      type: Object
    },
    multiple: {
      type: Boolean
    },
    groupType: {
      type: String
    },
    trackBy: {
      type: String,
      required: true,
      default: "label"
    },
    disabled: Boolean
  },
  methods: {
    /**
     * Функция для создания события выбора элемента
     * @param $item - элемент списка
     */
    selectItem($item) {
      this.$emit('select-item', $item)
    },
  }
}
</script>

<style scoped lang="scss">
  .my-select {
    &--group-list {
      list-style: none;
      padding: 0;
      > li {
        padding-left: 10px;
      }
    }
    &--item {
      > span {
        padding: 5px 15px;
        min-height: 40px;
        display: flex;
        width: 100%;
        align-items: center;
        cursor: pointer;
      }
      &:not(.disabled) > span:hover {
        background-color: #35495e;
        color: #fff;
      }
      &.selected {
        > span {
          background-color: #35495e;
          color: #fff;
        }
      }
      &.disabled {
        opacity: 0.6;
        > span {
          cursor: default;
        }
      }
    }
    &--group-label {
      position: relative;
      &:before {
        position: absolute;
        right: 15px;
        top: 18px;
        color: #999;
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: #999 transparent transparent;
        content: "";
        transition: transform .2s ease;
      }
      &.my-select--group-label--open:before {
        transform: rotate(180deg);
      }
    }
  }
</style>