<template>
  <div class="techWork container" style='background-image: url("/img/bkgr_fon_tech.png")'>
    <div class="dt1">
      <div class="img">
        <img src="/img/ico/camera.svg" alt="">
      </div>
    </div>
    <div class="dt2">
      <div class="code" v-if="dataInfo.code?.value">
        {{ dataInfo.code.value }}
      </div>
      <div class="title fs2" v-if="dataInfo.title?.value">{{ dataInfo.title.value }}</div>
      <div class="description fs4" v-if="dataInfo.desc?.value" v-html="dataInfo.desc.value"></div>
      <cl-button :link="true" type="light" :href="dataInfo.btnLink?.value" v-if="dataInfo.btnText?.value && dataInfo.btnLink.value">{{ dataInfo.btnText.value }}</cl-button>
    </div>
  </div>
</template>

<script>
import ClButton from "@/components/library/ClButton.vue";
import Container from "@/components/library/Container.vue";

export default {
  name: "techWork",
  components: {Container, ClButton},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.techWork{
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
  .dt1{
    height: 100vh;
    display: flex;
    align-items: end;
    @media (max-width: 991px){
      align-items: center;
    }
    @media (max-width: 767px){
      display: none;
    }
    .img{
      height: calc(100vh - 16rem);
      img{
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .dt2{
    display: flex;
    flex-direction: column;
    gap: 2.9rem;
    width: 100%;
    max-width: 80rem;
    align-items: start;
    @media(max-width: 767px){
      align-items: center;
      .description{
        text-align: center;
      }
    }
    .code{
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: 0.28rem;
      text-transform: uppercase;
    }
  }
}
</style>