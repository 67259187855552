<template>
  <card card-type="rent2" :routeLink="routeLink || '?guid=' + item.guid">
    <div class="div_img div_img_prop" :class="{'not_img': !item.logo?.imageUrl}">
      <div class="div_img_wrapp">
        <img v-if="item.logo?.imageUrl" :src="item.logo?.imageUrl" alt="">
      </div>
    </div>
    <div class="div_tag fs14" v-if="item.category?.name">{{ item.category.name }}</div>
    <div class="div_tit fs4n div-line1"> {{ item.name }}</div>
    <div class="div_info" v-if="item.area">
      <div class="sp1">Общая площадь:</div>
      <div class="sp2 div-line1">{{ item.area }} м<sup>2</sup></div>
    </div>
    <div class="div_adress" v-if="item.address && item.address != ' '"><img src="/img/ico/maping.svg" alt="">
      <div class="sp_text">{{ item.address }}</div>
    </div>
  </card>
</template>

<script>

/*
* routeLink - ссылка на карточку
* item - нформация по карточке
* */
export default {
  name: "cardRent2Pavilony",
  props: {
    routeLink: {
      type: String
    },
    item: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>

</style>