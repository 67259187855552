<template>
  <div class="editor">
    <input type="hidden"
      :id="id"
      :name="name"
      :data-name="dataName"
      :data-srcmap="dataSrcmap"
      :data-outername="dataOutername"
      :data-qa-column="dataQaColumn"
      v-model="value"
    >
    <div ref="editor"></div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import Quill from "quill";
import {clearAttr} from "@/main";
export default {
  name: "wysiwyg",
  props: {
    initialValue: {
      type: String,
      required: true
    },
    placeholder: String,
    readonly: Boolean,
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    dataName: {
      type: String,
      required: true
    },
    dataSrcmap: String,
    dataOutername: String,
    dataQaColumn: String
  },
  data: () => ({
    value: ""
  }),
  emits: [
    "is-input", "is-focus", "is-blur"
  ],
  mounted() {
    this.value = this.initialValue
    this.$refs.editor.innerHTML = this.value
    const quill = new Quill(this.$refs.editor, {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }]
        ]
      },
      placeholder: this.placeholder,
      readOnly: this.readonly,
      theme: "snow"
    })
    quill.on("text-change", () => {
      this.value = [...this.$refs.editor.querySelector(".ql-editor").childNodes].filter(item => {
        let tags = ["b", "strong", "i", "em", "u", "ul", "ol", "br", "p", "#text"]
        return tags.includes(item.nodeName.toLowerCase())
      }).reduce((oldVal, newVal) => {
        if (newVal.nodeName === "#text") {
          return oldVal + newVal.data
        } else {
          clearAttr(newVal)
          return oldVal + newVal.outerHTML
        }
      }, "").replace("&lt;script&gt;", "").replace("&lt;/script&gt;", "")
      this.$emit("is-input", this.value)
    })
    this.$refs.editor.querySelector(".ql-editor").addEventListener("focus", event => this.$emit("is-focus", event))
    this.$refs.editor.querySelector(".ql-editor").addEventListener("blur", event => this.$emit("is-blur", event))
  }
}
</script>

<style scoped>

</style>