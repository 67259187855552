<template>
  <container>
    <loader
        v-if="showLoaderGrant"
        :animation-duration="3000"
        :size="60"
    ></loader>
    <template v-else>
      <h2>Киногранты</h2>
      <row v-if="dataInfo.listCards?.length > 0" class="grant_list">
        <column :key='item'
                v-for='item in dataInfo.listCards'
                lg="4"
                md="6">
          <card card-type="grant" :route-link="`/filmgrants/grant/${item.guid}`" :targetBlank="true">
            <div class="div_company">
              <div class="dt1" :class="{'not_img': !item.company.logo.previewUrl}">
                <img v-if="item.company.logo.previewUrl" :src="item.company.logo.previewUrl" alt="">
              </div>
              <div class="rightInfoCard">
                <div class="div_top">
                  <div class="date_period" :class="getStatusTime(item.supportBeginReceiveDate, item.supportEndReceiveDate)" v-if="item.supportBeginReceiveDate && item.supportEndReceiveDate">{{ getTextDate(item.supportBeginReceiveDate, item.supportEndReceiveDate) }}</div>
                  <div class="div_text" v-if="item.amountSupportTo">до {{ item.amountSupportTo }} млн ₽<template v-if="item.briefExplanation"> · {{ item.briefExplanation }}</template></div>
                  <div class="div_text" v-else-if="item.amountSupportFrom">от {{ item.amountSupportTo }} млн ₽</div>
                </div>
                <div class="dt2 div_text0" v-if="item.company.name">{{ item.company.name }}</div>
              </div>
            </div>
            <div class="div_tit fs21" v-if="item.name">{{ item.name }}</div>
          </card>
        </column>
      </row>
    </template>
  </container>
</template>

<script>
import ClModal from "@/components/library/ClModal.vue";

export default {
  name: "filmGrantsList",
  components: {ClModal},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return{
      showLoaderGrant: true,
    }
  },
  methods: {
    setData(){
      const promises = [];
      for(let item in this.dataInfo.listCards){
        if(this.dataInfo.listCards[item].guid?.value){
          const promise = this.axios.get(`/api/get/grant/${this.dataInfo.listCards[item].guid.value}`)
              .then(response => {
                this.dataInfo.listCards[item] = response.data;
              })
              .catch(error => {
                this.showError(error);
              });
          promises.push(promise);
        }
      }
      Promise.allSettled(promises)
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {
            this.showLoaderGrant = false;
          });
    },
    getStatusTime(start, end){
      const date = new Date();
      const dateStart = new Date(start);
      const dateEnd = new Date(end);

      if(date > dateEnd){
        return "close";
      } else if(date < dateStart){
        return "wait";
      } else {
        return "active";
      }
    },
    getDate(date){
      return new Date(date).toLocaleString('ru-RU', {year: 'numeric', month: 'numeric', day: 'numeric',});
    },
    getTextDate(start, end){
      let text = "";
      switch (this.getStatusTime(start, end)){
        case "close":
          text = "Прием заявок закрыт";
          break;
        case "wait":
          text = `Прием заявок c ${this.getDate(start)} - ${this.getDate(end)}`;
          break;
        case "active":
          text = `Прием заявок до ${this.getDate(end)}`;
          break;
      }
      return text;
    }
  },
  mounted() {
    this.setData();
    this.getStatusTime("2023-08-10T00:00:00", "2023-11-14T23:59:00");
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 32px;
  margin-bottom: 32px;
}

.btn-default {
  transition: .2s ease;
  background: rgba(0, 0, 0, 0);
  color: #6B7280;
  border: 1px solid #6B7280;

  &:hover {
    transition: .2s ease;
    background: rgba(0, 0, 0, 0);
    color: #ED850A;
    border: 1px solid #ED850A;
  }

  &:focus {
    background: #ED850A;
    color: #FFFFFF;
    border: unset;
  }

}

.card {
  border: 1px solid #2E3138;
  background: unset;

  .div_company {
    margin-bottom: 1.6rem;

    .dt1 {
      width: 64px;
      height: 64px;
      border-radius: .5565rem;
      margin-right: 1.85rem;
      margin-bottom: auto;
    }

    .rightInfoCard {
      display: flex;
      flex-direction: column;
      width: 100%;

      .div_top {
        display: flex;
        flex-direction: column;

        .date_period {
          font-size: 1.4rem;
          padding-left: 2rem;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: .5rem;
            width: 1.2rem;
            height: 1.2rem;
            background: #ED850A;
            border-radius: 100%;
          }

          &.close {
            &:after {
              background: #6B7280;
            }
          }
          &.wait {
            &:after {
              background: transparent;
              border: 1px solid #ED850A;
            }
          }
          &.active {
            &:after {
              background: #ED850A;
            }
          }
        }

        .div_text {
          font-size: 1.4rem;
          color: #ED850A;
        }

      }
    }
  }
}
</style>

<style lang="scss">
/*.renting_oborud {
  padding-bottom: 0 !important;
}*/
.grant_list {
  margin-bottom: 100px;
}
.card-grant {
  .card-holder {
    display: flex;
    flex-direction: column;
  }

  .div_company {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .dt1 {
      flex: none;
      margin-right: 8px;
      width: 48px;
      height: 48px;
      overflow: hidden;
      border-radius: 100%;
      background-color: #ffffff;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .div_tit {
    min-height: 56px;
  }

  .div_text0 {
    margin-top: auto;
    color: #ACB0B9;
  }

  .div_text {
    margin-bottom: 4px;
  }

  @media (max-width: 1440px) {
    .card-holder {

      .div_company {

        .rightInfoCard {

        .div_top {

          .date_period {
            font-size: 14px;

          }

          .div_text {
            font-size: 14px;
          }
        }
        .div_text0 {
          font-size: 18px;

        }
      }
      }
      .div_tit {
        font-size: 21px;
      }

  }
  }

  @media (max-width: 1024px) {
    padding: 16px;
    .card-holder {

      .div_company {

        .rightInfoCard {
          .div_top {

            .date_period {
            }

            .div_text {
            }
          }
          .div_text0 {
            font-size: 16px;
          }
        }
      }
      .div_tit {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 767px) {
    .card-holder {

      .div_company {

        .rightInfoCard {
          .div_top {

            .date_period {
              font-size: 12px;
            }

            .div_text {
              font-size: 12px;
            }
          }
          .div_text0 {
            font-size: 12px;
          }
        }
      }
      .div_tit {
      }
    }
  }
}
</style>