<template>
  <span class="a_arrow" :class="[buttonType, buttonDisabled]" :disabled="disabled">
    <span><slot /></span>
  </span>
</template>

<script>

/**
 * Компонент кнопки
 * В пропсах принимает следующие значения
 * type: light - тип кнопки, по умолчанию default
 * disabled - для отключения
 */
export default {
  name: "ArrowButton",
  props: {
    type: String,
    disabled: Boolean
  },
  data() {
    return {
      buttonType: this.type ? `a_arrow_${this.type}` : "",
      buttonDisabled: this.disabled ? "btn-disabled" : null
    }
  }
}
</script>

<style lang="scss">
.a_arrow {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  > span {
    display: inline-block;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../../assets/img/st/arrow-right.svg") no-repeat center center;
    background-size: contain;
    margin-left: 10px;
    transition: all 0.2s ease;
  }
  &:hover {
    color: #ffffff;
    &:after {
      filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
    }
  }
  &:active,
  &:focus {
    color: #ffffff;
    &:after {
      filter: none;
    }
  }
  &:disabled,
  &.disabled {
    opacity: 0.5;
    &:after {
      //filter: grayscale(100%) invert(1) contrast(100);
    }
  }
  &.a_arrow_bottom {
    &:after {
      transform: rotate(90deg);
    }
  }
}
</style>