<template>
  <div class="placement-issue">
    <div class="rec rec-1"></div>
    <div class="rec rec-2"></div>
    <container class="indexUp block_min">
      <div class="personInfo">
        <div class="person_leftInfo">
        <div class="personInfo__title" v-if="dataInfo.title">
          {{ dataInfo.title.value }}
        </div>
        <div class="personInfo__subTitle" v-if="dataInfo.subTitle">
          {{ dataInfo.subTitle.value }}
        </div>
        </div>
        <div class="personInfo__contact">
          <cl-button link type="light" v-if="dataInfo.phone" img="/img/ico/phone.svg"
                     :href="'tel:' + dataInfo.phone.value">{{ dataInfo.phone.value }}
          </cl-button>
          <cl-button link type="light" v-if="dataInfo.mail" img="/img/ico/mail.svg"
                     :href="'mailto:' + dataInfo.mail.value">{{ dataInfo.mail.value }}
          </cl-button>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";
import ClButton from "@/components/library/ClButton.vue";

export default {
  name: "placement-issue",
  components: {ClButton, Container},
  props: {
    dataInfo: {
      type: Object,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
.placement-issue {
  position: relative;
  padding: 8.1rem 0 15.8rem;
  min-height: 229px;
  background-color: white;

  .rec {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    clip-path: polygon(0 0, 0 100%, 100% 0, 100% 0);

    &.rec-1 {
      height: 47rem;
      background-color: #ED850A;
    }

    &.rec-2 {
      height: 38.54rem;
      background-color: #000106;
    }
  }

  .indexUp {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 64px;

    padding: 0;
  }

  .personInfo {
    display: flex;
    width: 100%;
    gap: 2.4rem;
    padding: 6.4rem;
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(20.25px);
    -webkit-backdrop-filter: blur(20.25px);

    .person_leftInfo{
      max-width: 87.8rem;
      width: 100%;
    }

    &__title {
      color: #000106;
      font-size: 4.8rem;
      font-weight: 500;
      line-height: 133.333%;
      letter-spacing: 0.096rem;
      text-transform: uppercase;
    }

    &__subTitle {
      color: #6B7280;
      font-size: 2.1rem;
      font-weight: 400;
      line-height: 133.333%;
      letter-spacing: 0.0315rem;
    }

    &__contact {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      max-width: 60.2rem;
      width: 100%;

    }

  }

  @media (max-width: 991px) {
    padding: 7.1rem 0 7.1rem;
    .indexUp {
      gap: 24px;
      padding: 0 2.4rem;
    }
    .personInfo {
      padding: 3.2rem;
      flex-direction: column;

      .person_leftInfo{
        max-width: 65.6rem;
        width: 100%;
      }

      &__title {
        font-size: 3.2rem;
        line-height: 150%;
        letter-spacing: 0.064rem;
        text-align: center;
      }

      &__subTitle {
        font-size: 1.8rem;
        letter-spacing: 0.027rem;
        text-align: center;
      }

      &__contact {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        max-width: 65rem;
        width: 100%;

      }

    }
    .rec {

      &.rec-1 {
        height: 24rem;
      }

      &.rec-2 {
        height: 21.54rem;
      }
    }
  }

  @media (max-width: 767px) {
    .indexUp {
      gap: 24px;
      flex-direction: column;
      padding-top: 50px;
    }

    .personInfo {
      padding: 1.2rem;
      gap: 1.2rem;

      .person_leftInfo{
        max-width: unset;
        gap: 1.2rem;
        display: flex;
        flex-direction: column;
      }

      &__title {
        font-size: 2.4rem;
        line-height: 133.33%;
        letter-spacing: 0.048rem;
      }

      &__subTitle {
        font-size: 1.4rem;
        letter-spacing: 0.021rem;
        text-align: left;
      }

      &__contact {
        gap: 1.6rem;
        max-width: unset;
        width: 100%;
      }

    }
    .rec {

      &.rec-1 {
        height: 27rem;
      }

      &.rec-2 {
        height: 23.54rem;
      }
    }

  }

}

@media (min-width: 1400px) {
  .block_min{
    max-width: 87%;
  }
}

</style>