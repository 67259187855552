<template>
  <teleport to="#site_header_container" v-if="!isLoadingFilter">
    <div class="pos_rel">
      <div class="filter_header">
        <container>
          <loader
              v-if="isLoadingFilter"
              :animation-duration="3000"
              :size="60"
          ></loader>
          <div
              v-else
              class="filter_header_flex">

            <div class="td1"
                 :class="{'td_lite': showInput && widthSite > 991, 'td_lite2': (Object.keys(getParams).length - (filter.selectSelectedFilmType != '' ? 1 : 0) - (filter.nameSearch != '' ? 1 : 0) - (getParams.page ? 1 : 0)) > 0 }"
                 v-if="filter.selectOptionsFilmType.length > 0">
              <div ref="prev" class="swiper-button-prev"></div>
              <div ref="next" class="swiper-button-next"></div>
              <swiper
                  :modules="modules"
                  :slides-per-view="'auto'"
                  :spaceBetween="12"
                  :navigation="{
                    prevEl: prev,
                    nextEl: next,
                  }"
                  class="filter_header_slide slide_auto fs16 fw500"
              >
                <!--                :loop="filter.selectOptionsFilmType.length > 4 ? true : false"-->
                <swiper-slide>
                  <div class="filter_header_slide_item"
                       :class="{'active': filter.selectSelectedFilmType == 'undefined' || (this.$route.params.post_id == 'pavilony' && this.$route.query == '')}"
                       @click="filmTypeFun('undefined')">
                    <div class="div_img">
                      <img :src="'/img/ico/ico48_FilmType_allLocations.svg'" alt="">
                    </div>
                    <div class="div_tit">Все локации</div>
                  </div>
                </swiper-slide>
                <swiper-slide
                    v-for="filmTypeItem in filter.selectOptionsFilmType"
                    :key="filmTypeItem.id">
                  <div class="filter_header_slide_item"
                       @click="filmTypeFun(filmTypeItem)"
                       :class="{'active': filter.selectSelectedFilmType == filmTypeItem}">
                    <div class="div_img">
                      <img :src="filmTypeItem.image ? filmTypeItem.image : '/img/ico/ico24_filmTypeItem.svg'" alt="">
                    </div>
                    <div class="div_tit" v-if="filmTypeItem.name">{{ filmTypeItem.name }}</div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="td2">
              <input-search
                  v-model="filter.nameSearch"
                  @search="findByText"
                  @showInputFun="showInputFun"
                  placeholder="Название"
                  view="top"
                  id="topInputFilter"
              />
            </div>
            <div class="td3">
              <cl-button type="light3" class="btn-middle btn-ico-right"
                         :class="{'btn-text-none': widthSite <= 991}"
                         img="/img/ico/sliders-horizontal.svg"
                         @click="btnShowFilter">Фильтры
                <template v-slot:after>
                  <div class="circle_or"
                       v-if="(Object.keys(getParams).length - (filter.selectSelectedFilmType != '' ? 1 : 0) - (filter.nameSearch != '' ? 1 : 0) - (getParams.page ? 1 : 0) - (getParams.all ? 1 : 0)) > 0">
                    {{
                      Object.keys(getParams).length - (filter.selectSelectedFilmType != '' ? 1 : 0) - (filter.nameSearch != '' ? 1 : 0) - (getParams.page ? 1 : 0) - (getParams.all ? 1 : 0)
                    }}
                  </div>
                </template>
              </cl-button>
            </div>
          </div>
        </container>
      </div>
    </div>
  </teleport>

  <cl-modal v-model="modalFilterShow" modal-type="center" class="filter_modal modal-scroll">
    <template v-slot:header>
      <div class="text-center">Фильтры</div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar id="perfectUpdate">
        <div class="filter_cont" v-if="filter.filterAll">
          <row class="filter_cont_row1">
            <column md="12" class="filter_cont_col1 order-1">
              <div class="filter_tit fs4">Категория</div>
              <filter-tabs
                  :tabActive="filter.selectSelectedCategory"
                  :tabList="filter.selectOptionsCategory"
                  @changeTab="selectChangeCategory"
                  :tabSample="1"
                  label="description"
                  tabKey="value"
                  class="filter_tabs_wh filter_tabs_48 filter_tabs_48p"
              >
                <template v-slot:before>
                  <div class="filter_tabs_el">
                    <button class="btn btn-light btn-tag" :class="{'active': filter.selectSelectedCategory.length == 0}"
                            @click="filter.selectSelectedCategory = []; selectChangeCategory()">Не выбрано
                    </button>
                  </div>
                </template>
              </filter-tabs>
            </column>
            <column md="12" class="filter_cont_col1 order-3">
              <div class="filter_tit fs4">Район</div>
              <row class="filter_cont_row2">
                <column md="4" class="align-self-center fw500"
                        v-for="itemCheckbox in filter.selectOptionsDistrict"
                >
                  <div class='fake_checkbox cursor-pointer'
                       @click="selectChangePavilions(itemCheckbox.value)"
                       :class="{'checked': filter.selectSelectedDistrict.indexOf(itemCheckbox.value) != -1}"
                  >{{ itemCheckbox.description }}
                  </div>
                </column>
              </row>
            </column>
<!--            если выбраны павильоны-->
            <template  v-if="filter.selectSelectedCategory[0] == '1315701'">
              <template v-for="(itemFilter, key) in filter.filterAll">
                <template
                    v-if="key == 'ceilingHeight' || key == 'ceilingHeightTrusses' || key == 'length' || key == 'width' || key == 'powerElectricity' || key == 'areaFilter'">
                  <column md="4" class="align-self-center fw500  order-3" v-html="translateWord(key)"></column>
                  <column md="8" class="order-3">
                    <filter-tabs
                        :tabActive="filter[key]"
                        :tabList="itemFilter"
                        @changeTab="changeTabEvent(key)"
                        :label="key == 'areaFilter' ? 'altDescription' : 'description'"
                        tabKey="value"
                        class="filter_tabs_wh filter_tabs_48 filter_tabs4"
                    >
                      <template v-slot:before>
                        <div class="filter_tabs_el">
                          <button class="btn btn-light btn-tag" :class="{'active': filter[key].length == 0}"
                                  @click="clearTab(key)">Не выбрано
                          </button>
                        </div>
                      </template>
                    </filter-tabs>
                  </column>
                </template>
                <template v-else-if="key != 'categoryFilter'">
                  <column md="12" :class="{'filter_cont_col0': key == 'technical', 'order-2': key == 'companyIds', 'order-3': key != 'companyIds'}" class="filter_cont_col1">
                    <div class="filter_tit fs4" v-if="key != 'technical'">{{ translateWord(key) }}</div>
                    <row class="filter_cont_row2">
                      <column md="4" class="align-self-center fw500"
                              v-for="itemCheckbox in itemFilter"
                      >
                        <div class='fake_checkbox cursor-pointer'
                             @click="changeCheckbox(+itemCheckbox.value, key)"
                             :class="{'checked': filter[typeCheckbox(key).filterName].indexOf(+itemCheckbox.value) != -1}"
                        >{{ nameCheckbox(itemCheckbox, key) }}
                        </div>
                      </column>
                    </row>
                    <cl-button class="btn-lite"
                               @click="chooseAll(itemFilter, key)"
                               v-if="btnChooseAll(itemFilter, key)"
                    >Выбрать все
                    </cl-button>
                    <cl-button type="lite" class="btn-light2"
                               @click="cancelAll(itemFilter, key)"
                               v-else
                    >Отменить все
                    </cl-button>
                  </column>
                </template>
              </template>
            </template>

          </row>
        </div>
      </perfect-scrollbar>
    </template>
    <template v-slot:footer>
      <cl-button type="light" class="btn-middle color_or" @click="deleteParams">Очистить <span
          class="d-none d-md-inline">все</span></cl-button>
      <cl-button class="btn-middle" @click="searchRent">Показать <span class="d-none d-md-inline">площадки</span>
      </cl-button>
    </template>
  </cl-modal>


</template>

<script>
/*
* deleteParamsStatus - сбрасывает фильтры, нужно для активации сброса вне компонента
* */
import translates from '@/assets/js/resources/translate/rent';
import {ref} from 'vue';
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import {mapGetters} from "vuex";
import PerfectScrollbar from "perfect-scrollbar";
import {all} from "core-js/internals/document-all";

export default {
  name: "arendaKinopomecheniyTopFilter",
  components: {Swiper, SwiperSlide},
  props: {
    deleteParamsStatus: {
      type: Boolean
    },
  },
  data() {
    return {
      showInput: false,
      modalFilterShow: false,

      getParams: {},
      getParamsFilter: {
        type: 'pavilionsAll'
      },
      controller: null,

      ps: null,
      filterSelectedAll: {
        id: "all",
        name: "Все локации"
      },
      isLoadingFilter: true,
      filter: {
        filterAll: false,

        areaFilter: [],
        selectSelectedCategory: [],
        selectOptionsCategory: [],
        selectSelectedDistrict: [],
        selectOptionsDistrict: [],

        nameSearch: "",
        selectSelectedFilmType: "",
        selectOptionsFilmType: [],

        ceilingHeight: [],
        ceilingHeightTrusses: [],
        length: [],
        width: [],
        powerElectricity: [],

        technical: [],
        service: [],
        comfort: [],
        checkboxAll: [],

        companyIds: [],
      },
    }
  },
  methods: {
    //передаем параметры на страницу со списком
    emitParamsList() {
      this.$emit("searchFun", this.getParams);
    },
    getFilter() {
      this.isLoadingFilter = true;
      return this.axios
          .get(`/api/data/filters`, {params: this.getParamsFilter, signal: this.controller.signal})
          .then(response => {

            this.filter.filterAll = {...response.data};
            //delete this.filter.filterAll['areaFilter'];
            delete this.filter.filterAll['districtFilter'];

            this.filter.selectOptionsDistrict = response.data.districtFilter;
            this.filter.selectOptionsCategory = response.data.categoryFilter;
            //companyIds
            // console.log(response.data)


            //делаем поиск если в адресе query
            if (Object.keys(this.$route.query).length > 0) {
              for (const [key, value] of Object.entries(this.$route.query)) {
                if (key == 'ceilingHeight' || key == 'ceilingHeightTrusses' || key == 'length' || key == 'width' || key == 'powerElectricity' || key == 'areaFilter' || key == 'newArea') {
                  if (key == 'newArea' || key == 'areaFilter') {
                    this.filter.areaFilter = value.split(',');
                    this.changeTabEvent('areaFilter');
                  } else {
                    this.filter[key] = value.split(',');
                    this.changeTabEvent(key);
                  }
                } else if (key == 'types') {
                  const typesItem = [...this.filter.selectOptionsFilmType].filter(item => item.id == value)[0];
                  if (typesItem) {
                    this.filmTypeFun(typesItem, false);
                  }
                } else if (key == 'name') {
                  this.filter.nameSearch = value;
                  this.findByText(value, false);
                } else if (key == 'district') {
                  this.filter.selectSelectedDistrict = value.split(',');
                  this.selectChangePavilions(false, 'result');
                } else if (key == 'categories') {
                  this.filter.selectSelectedCategory = [value];
                  this.selectChangeCategory(value);
                } else if (key != 'categoryFilter') {
                  let filterName = this.typeCheckbox(key).filterName,
                      getParamsName = this.typeCheckbox(key).getParamsName;
                  this.filter[filterName] = value.split(',').map(function (num) {
                    return +num;
                  });
                  this.getParams[getParamsName] = value;
                }
              }
              //this.filter
              this.$nextTick(() => {
                setTimeout(() => {
                  this.emitParamsList();
                }, 100);
              });
            }

            this.isLoadingFilter = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    getFilterType() {
      return this.axios
          .get(`/api/data/film-type`, {signal: this.controller.signal})
          .then(response => {
            this.filter.selectOptionsFilmType = response.data;
            this.getFilter();
          })
          .catch(error => {
            this.showError(error);
          });
    },
    translateWord(word) {
      return translates[word] ? translates[word] : 'Значение не найдено';
    },
    //поиск
    searchRent() {
      this.modalFilterShow = false;
      this.emitParamsList();
    },
    // очистка фильтров в модалке
    deleteParams(category = false, startSearch = true) {
      /*if (Object.keys(this.$route.query).length > 0) {
        history.pushState({}, '', this.$route.path);
      }*/

      this.getParams = {};
      if((category && category != '1315701') || !category){
        this.filter.selectSelectedCategory = [];
        this.filter.selectSelectedDistrict = [];
      } else {
        this.selectChangeCategory(false, 'result');
        this.selectChangePavilions(false, 'result');
      }

      this.filter.areaFilter = [];
      this.filter.ceilingHeight = [];
      this.filter.ceilingHeightTrusses = [];
      this.filter.length = [];
      this.filter.width = [];
      this.filter.powerElectricity = [];
      this.filter.checkboxAll = [];
      this.filter.companyIds = []

      this.filter.nameSearch = "";
      this.filter.selectSelectedFilmType = "";

      if(startSearch) {
        this.modalFilterShow = false;
        this.emitParamsList();
      }
    },
    // поле типов
    filmTypeFun(item, startSearch = true) {
      if (this.filter.selectSelectedFilmType != item) {
        this.filter.selectSelectedFilmType = item;
        this.getParams.types = item.id;
      } else {
        this.filter.selectSelectedFilmType = "";
        delete this.getParams.types;
      }
      if (startSearch) {
        this.emitParamsList();
      }
    },
    // поле поиска
    findByText(text, startSearch = true) {
      if (text != "") {
        this.getParams.name = text;
      } else {
        delete this.getParams.name;
      }
      if (startSearch) {
        this.emitParamsList();
      }
    },
    // разкрытие/закрытие поиска
    showInputFun(status) {
      this.showInput = status;
    },
    //категории
    selectChangeCategory(val, eventType = 'click') {
      if (this.filter.selectSelectedCategory.length < 1) {
        delete this.getParams.categories
      } else {
        this.getParams.categories = this.filter.selectSelectedCategory[0];
      }
      if(eventType == 'click' && this.filter.selectSelectedCategory[0] != '1315701'){
        this.deleteParams('1315701', false);
      }
    },
    //районы
    selectChangePavilions(val, eventType = 'click') {
      if (eventType == 'click') {
        if (this.filter.selectSelectedDistrict.indexOf(val) != -1) {
          this.filter.selectSelectedDistrict = [...this.filter.selectSelectedDistrict].filter(item => item != val);
        } else {
          this.filter.selectSelectedDistrict = [...this.filter.selectSelectedDistrict, val];
        }
      }
      if (this.filter.selectSelectedDistrict.length < 1) {
        delete this.getParams.district
      } else {
        this.getParams.district = [...this.filter.selectSelectedDistrict].join();
      }
    },
    // модалка табы
    changeTabEvent(key) {
      if (this.filter[key].length > 0) {
        if (key == "areaFilter") {
          this.getParams.newArea = this.filter[key].join();
        } else {
          this.getParams[key] = this.filter[key].join();
        }
      } else {
        if (key == "areaFilter") {
          delete this.getParams.newArea;
        } else {
          delete this.getParams[key];
        }
      }
    },
    // модалка табы кнопка не выбрано
    clearTab(key) {
      this.filter[key] = [];
      delete this.getParams[key];
      if (key == "areaFilter") {
        delete this.getParams.newArea;
      }
    },
    // проверка типа чекбокса, запрос на this.getParams.checkbox или он уникальный
    typeCheckbox(key){
      if(key == 'companyIds'){
        return {isCheckbox: false, filterName: key, getParamsName: key};
      } else {
        return {isCheckbox: true, filterName: 'checkboxAll', getParamsName: 'checkbox'};
      }
    },
    // вывод заголовка у чекбокса
    nameCheckbox(item, key){
      return this.typeCheckbox(key).isCheckbox ? Object.values(item)[0] : item.description;
    },
    // модалка чекбоксы
    changeCheckbox(value, key = 'checkbox') {
      let filterName = this.typeCheckbox(key).filterName,
          getParamsName = this.typeCheckbox(key).getParamsName;
      if (this.filter[filterName].indexOf(value) != -1) {
        this.filter[filterName] = [...this.filter[filterName]].filter(item => item != value);
      } else {
        this.filter[filterName] = [...this.filter[filterName], value];
      }
      if (this.filter[filterName].length < 1) {
        delete this.getParams[getParamsName]
      } else {
        this.getParams[getParamsName] = [...this.filter[filterName]].join();
      }
    },
    // модалка выбрать все
    chooseAll(itemFilter, key) {
      let filterName = this.typeCheckbox(key).filterName,
          getParamsName = this.typeCheckbox(key).getParamsName;
      const addEl = [...itemFilter].map(item => +item.value),
          addElFilter = addEl.filter(item => ![...this.filter[filterName]].some(record => record == item));
      this.filter[filterName] = [...this.filter[filterName], ...addElFilter];
      this.getParams[getParamsName] = [...this.filter[filterName]].join();
    },
    // отменить все
    cancelAll(itemFilter, key) {
      let filterName = this.typeCheckbox(key).filterName,
          getParamsName = this.typeCheckbox(key).getParamsName;
      const removeEl = [...itemFilter].map(item => item.value);
      this.filter[filterName] = [...this.filter[filterName]].filter(item => ![...removeEl].some(record => record == item));
      this.getParams[getParamsName] = [...this.filter[filterName]].join();
      if (this.getParams[getParamsName] == "") {
        delete this.getParams[getParamsName]
      }
    },
    // показывать кнопку выбрать все
    btnChooseAll(itemFilter, key) {
      const inStockEl = [...itemFilter].map(item => item.value);
      let filterName = this.typeCheckbox(key).filterName;
      if (inStockEl.length != [...this.filter[filterName]].filter(item => [...inStockEl].some(record => record == item)).length) {
        return true;
      } else {
        return false;
      }
    },
    btnShowFilter(){
      this.modalFilterShow = true;
      setTimeout(() => {
        this.ps = new PerfectScrollbar(document.querySelector('#perfectUpdate'));
        const resizeObserver = new ResizeObserver(() => {
          this.ps.update();
        });
        resizeObserver.observe(document.querySelector('#perfectUpdate').querySelector(".filter_cont"))
      }, 200);
    },
    // Выбор всех локаций, если нет фильтра на странице павильона
    getSelectAll(){
      if (Object.keys(this.$route.query).length == 0 && this.$route.params.post_id == "pavilony"){
        this.filmTypeFun('undefined');
      }
    }
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    document.querySelector('#site_header').setAttribute('fon', '1');
    this.getFilterType();
    this.getSelectAll();
    //this.getFilter();
    //this.emitParamsList();
  },
  beforeUnmount() {
    document.querySelector('#site_header').setAttribute('fon', '');
    this.controller.abort();
    this.controller = null;
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  },
  watch: {
    deleteParamsStatus() {
      this.deleteParamsStatus ? this.deleteParams() : '';
    }
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
}
</script>

<style lang="scss">

</style>