<template>
  <div class="input">
    <label v-if="label" :for="id" class="">
      {{ label }}
    </label>
    <input
        :id="id"
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        @input="updateInput"
        class="form-control"
    />
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    }
  },
  methods: {
    updateInput(event) {
      this.$emit("update:modelValue", event.target.value);
      this.$emit("inputSearch", event.target.value);
    }
  }
}
</script>

<style scoped lang="scss">
.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-control {
  padding: 15px 24px;
  height: 64px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.10);
  border: 1px solid rgba(255, 255, 255, 0.10);
  outline: none;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 32px;
  width: 100%;
  transition: .2s linear;
  font-variant-numeric: lining-nums proportional-nums;


  &:focus,
  &:not(:placeholder-shown),
  &:not(:empty),
  &[value]  {
    border-color: #FFF;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill {
    background-color: transparent;
    color: #FFFFFF;
    -webkit-text-fill-color: #FFFFFF;
    background-color: transparent;
    font-size: 24px;
    line-height: 32px;
  }
}
.input_line {
  .form-control {
    background: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ED850A;
    &:focus,
    &:not(:placeholder-shown),
    &:not(:empty),
    &[value] {
      border-color: #ED850A;
    }
  }
}
.input_filter {
  .form-control {
    background: #ffffff;
    border-radius: 6px;
    height: 56px;
    font-size: 21px;
    color: #000106;
    &:focus,
    &:not(:placeholder-shown),
    &:not(:empty),
    &[value] {
      border: none;
      background: #ffffff;
    }
    &::placeholder {
      color: #6B7280;
    }
  }
}
.input_filter2 {
  .form-control {
    background: #191A1F;
    border-color: #191A1F;
    &:focus,
    &:not(:placeholder-shown) {
      background: #191A1F;
      border: 1px solid #ED850A;
      color: #ffffff;
    }
    &::placeholder {
      color: #6B7280;
    }
  }
}
</style>