<template>
  <container :class="{'container2': dataInfo.bannerWidthChecked}">
    <div class="banner-form" v-if="dataInfo.bannerBtnGuid?.value"
         :style="[ getBkgrImage(dataInfo.bannerImg, 'fullHd')]"
    >
      <h4 class="div_tit" v-if="dataInfo.bannerTitle">{{ dataInfo.bannerTitle.value }}</h4>
      <div class="div_text" v-if="dataInfo.bannerText">{{ dataInfo.bannerText.value }}</div>
      <template v-if="dataInfo.bannerBtnGuid">
        <div class="div_btn">
          <cl-button @click="formShow = !formShow">{{ dataInfo.bannerBtnText.value }}</cl-button>
        </div>
        <cl-modal v-model="formShow" class="modal_form" :closeFonActive="false">
          <h4 class="modal-header" v-if="formTitle">
            {{ formTitle }}
          </h4>
          <measure-form-component
              :measureGuid="dataInfo.bannerBtnGuid.value"
              :showMode="showMode"
              :formParams="measureFormParams"
              :form-type="1"
              @formTitle="formTitleFun"
          />
        </cl-modal>
      </template>

    </div>
  </container>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm";
export default {
  name: "bannerForm",
  components: {MeasureFormComponent},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return {
      formTitle: null,
      formShow: false,
      showMode: 'inline',
      measureFormParams: {
        //'appendCss': '/override/modal_wh.css',
      },
      getParams: {}
    }
  },
  methods: {
    //возврашаем заголовок формы
    formTitleFun(name) {
      this.formTitle = name;
    },
  }
}
</script>

<style lang="scss" scoped>
.banner-form {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #ED850A;
  padding: 40px 40px 40px 334px;
  background-image: url(@/assets/img/banner-form.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  margin-bottom: 64px;
  .div_tit {
    margin-bottom: 8px;
  }
  .div_text {
    margin-bottom: 12px;
  }
  @media (max-width: 991px) {
    padding: 16px 16px 16px 309px;
    .div_tit {
      font-size: 2.1rem;
    }
    .div_text{
      font-size: 1.6rem;
    }
  }
  @media (max-width: 767px) {
    padding: 258px 16px 16px 16px;
    background-size: 340px;
    background-position: center 16px;
    text-align: center;
    .div_tit {
      font-size: 1.8rem;
    }
    .div_text {
      font-size: 1.4rem;
    }
  }
}
</style>