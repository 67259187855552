<template>
  <teleport to="#site_header_td3" >
    <div>
      <router-link to="/arenda_rekvizita/korzina" class="top_card">
        <img src="/img/ico/shopping-cart.svg" alt="">
        <div class="top_card_num" v-if="shoppingCart.length > 0">{{shoppingCart.length}}</div>
      </router-link>
    </div>

  </teleport>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "cartTop",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  computed: {
    ...mapGetters([
      'shoppingCart'
    ])
  }
}
</script>

<style lang="scss" scoped>
.top_card {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  .top_card_num {
    position: absolute;
    left: 6px;
    top: 0;
    min-width: 18px;
    height: 18px;
    display: inline-flex;
    padding: 2px 2.5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: #ED850A;

    font-size: 12px;
    font-weight: 500;
    line-height: 14px; /* 116.667% */
    letter-spacing: 0.12px;
    text-transform: uppercase;

  }
}
</style>