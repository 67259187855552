<template>
  <container class="container2 block_top_b">
    <h1 class="fs2 document_border" v-if="dataInfo.title.value">{{ dataInfo.title.value }}</h1>

    <div class="filter_block filter_block2" :class="{'flex-direction-row': widthSite >= 768}">
      <div class="dt w-100">
        <input-search
            v-model="filter.nameSearch"
            @search="filter.nameSearchAdd = filter.nameSearch"
            :placeholder="'что вы ищете?'"
            class="search_pole_h"
            id="search_doc"
            :close="true"
        />
      </div>
      <div class="dt" v-if="widthSite >= 768">
        <cl-button @click="searchBtn">Найти</cl-button>
      </div>
    </div>

    <div class="document_h2">
      <filter-tabs
          class="filter_tabs2"
          :tabActive="filter.typeDoc"
          :tabList="filter.typeDocOptions"
          @changeTab="changeTabEvent"
          :firstActive="true"
          :tabSample="1"
          label="name"
          tabKey="guid"
          v-if="widthSite > 991"
      ></filter-tabs>
      <multiselect
          v-else
          v-model="filter.typeDocSelect"
          :options="filter.typeDocOptions"
          placeholder="Категория"
          track-by="guid"
          label="name"
          @select="changeTabEvent2"
          :searchable="false"
          :allow-empty="false"
          class="multiselect_gray"
          :class="{'multiselect_active': filter.typeDocSelect}"
      ></multiselect>
    </div>
    <div class="document_border" v-if="docListActive">
      <h2 class="document_h2" v-if="docListActive.groupName">{{ docListActive.groupName }}</h2>
      <template v-if="docListActiveFilter.length > 0">
        <div v-for="docGroup in docListActiveFilter">
          <h3 class="document_h3" v-if="docGroup.subGroupName">{{ docGroup.subGroupName }}</h3>
          <row class="document_row">
            <column lg="6"
                    :key="file"
                    v-for="(file, index) in docGroup.file">
              <card-document :dataDoc="file" />
            </column>
          </row>
        </div>
      </template>
      <div class="document_not_info"
           v-else
      >
        <h4>{{ dataInfo.textDoc.value }}</h4>
        <div class="fs5n">{{ dataInfo.textDocBefore.value }}</div>
      </div>


    </div>

  </container>

</template>

<script>
import CardDocument from "@/components/cardDocument.vue";
import {mapGetters} from "vuex";

export default {
  name: "documentsAndAgreements",
  components: {CardDocument},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      controller: null,
      isLoading: true,
      docListActive: false,
      filter: {
        nameSearch: "",
        nameSearchAdd: "",
        typeDoc: [],
        typeDocSelect: false,
        typeDocOptions: [],
      },
    }
  },
  methods: {
    filterArr(arr, str) {
      return (arr || [])
          .map(n => ({ ...n, file: this.filterArr(n.file, str) }))
          .filter(n => {
                return n.name?.toLowerCase().includes(str.toLowerCase()) || n.file.length
              }
          );
    },
    // группы табы
    changeTabEvent(){
      this.docListActive = {...this.dataInfo.listDoc[this.filter.typeDoc[0]].list};
    },
    // группы селекты
    changeTabEvent2(){
      this.docListActive = {...this.dataInfo.listDoc[this.filter.typeDocSelect.guid].list}
    },
    searchBtn(){
      document.getElementById('search_doc').parentNode.parentNode.querySelector('.search_pole_btn').click()
    }
  },
  mounted() {
    console.log(this.dataInfo.listDoc)
    this.docListActive = {...this.dataInfo.listDoc[0].list};
    // добавляем данные для табов
    [...this.dataInfo.listDoc].map((item, index) => {
      this.filter.typeDocOptions.push({name: item.list.groupName, guid: index})
    });
    this.filter.typeDoc = [this.filter.typeDocOptions[0].guid];
    this.filter.typeDocSelect = this.filter.typeDocOptions[0];
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
    docListActiveFilter(){
      return this.filterArr([...this.docListActive.subGroup], this.filter.nameSearchAdd)
    }
  },
  created() {
    this.controller = new AbortController();
  },
  beforeUnmount() {
    this.controller.abort();
    this.controller = null;
  },
}
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .block_top_b {
    padding-top: 80px;
  }
}
.filter_block {
  margin-bottom: 20px;
  @media (min-width: 768px) {
    .search_pole {
      width: 100%;
    }
    .dt:last-child {
      width: auto;
    }
  }
}
.document_h2,
.document_h3 {
  margin-bottom: 16px;
}
.document_row {
  margin: 0 -48px 16px -48px;
  gap: 48px 0;
  > div {
    padding: 0 48px;
  }
  @media (max-width: 991px) {
    margin: 0 -24px 16px -24px;
    > div {
      padding: 0 24px;
    }
  }
  @media (max-width: 767px) {
    margin: 0 -20px 16px -20px;
    > div {
      padding: 0 20px;
    }
  }
}
.document_border {
  padding-bottom: 3.2rem;
  margin-bottom: 3.2rem;
  border-bottom: 1px solid #9096A2;
}

</style>