<template>
  <div class="not_info">
    <slot>
      <h4 class="div_tit" v-html="title"></h4>
      <div class="div_text" v-if="text" v-html="text"></div>
      <slot name="btn">
        <div class="div_btn" v-if="btn"><cl-button :href="url" link>{{ btn }}</cl-button></div>
      </slot>
    </slot>
  </div>
</template>

<script>
/*
* title заголовок
* text  текст
* btn текст кнопки
* url ссылка кнопки
* */
export default {
  name: "notInfo",
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    btn: {
      type: String,
    },
    url: {
      type: String
    }
  },
}
</script>

<style scoped lang="scss">

.not_info {
  background: #000106;
  padding: 50px;
  text-align: center;
  margin: 50px auto;
  max-width: 600px;
  color: #FFFFFF;
  .div_tit {
    margin-bottom: 20px;
  }
  .div_text {
    margin-bottom: 40px;
  }
  &.not_info_big {
    border-radius: 7px;
    background: rgba(255, 255, 255, .15);
    max-width: 100%;
    margin: 0;
    padding: 120px 20px;
  }
  &.not_info_backg {
    padding: 0;
    background: none;
  }
}
</style>