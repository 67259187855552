<template>
  <div class="block-stars">
    <input type="hidden"
           :id="item['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
           :name="item['full_name']"
           :data-name="item['full_name']"
           v-model="value"
           :readonly="readonly"
           :data-qa-column="dataQaColumn"
    >
    <div class="stars-item" :class="{'active': star.isActive}" v-for="star of starsArray" :key="star.value" @click="chooseStarHandler(star)">
      <div class="img"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stars",
  props: {
    item: Object,
    readonly: Boolean,
    dataQaColumn: String
  },
  data() {
    return {
      value: null,
      starsArray: []
    }
  },
  mounted() {
    this.initStarsArray(this.item.options['max_star_count'])
  },
  methods: {
    initStarsArray(n) {
      for (let i = 1;i <= n;i++) {
        this.starsArray.push({
          value: i,
          isActive: this.item.value >= i
        })
      }
    },
    chooseStarHandler(star) {
      if (!this.readonly) {
        this.starsArray.forEach(item => item.isActive = false)
        for (let i = 0;i < star.value;i++) {
          this.starsArray[i].isActive = true
        }
        this.value = star.value
        this.$emit("choose-star", this.value)
      }
    }
  }
}
</script>

<style scoped>

</style>