<template>
  <div class="block_wrap block_top rent_top">

    <arenda-kinopomecheniy-top-filter v-if="dataInfo.visibleMenu" @searchFun="searchFunPath"/>

<!--    <transition name="fade-base2">-->
<!--      <div class="block_top_img" v-if="dataItemActive?.image"-->
<!--           :style="[ getBkgrImage(dataItemActive?.image, 'fullHd') ]"-->
<!--      >-->
<!--      </div>-->
<!--    </transition>-->
    <container class="pos_rel">
      <h1 v-if="dataInfo.title" class="hasSubtit0">{{ dataInfo.title?.value }}</h1>
      <transition name="fade-base2">
        <div v-if="dataItemActive">
          <h2 v-if="dataItemActive.subTitle?.value" v-html="dataItemActive.subTitle?.value"></h2>
          <div class="div_text fs3n" v-if="dataItemActive.text" v-html="dataItemActive.text?.value"></div>
        </div>
      </transition>

      <row class="filter_top" v-if="dataItemActive && dataItemActive.listCardsChild?.length > 0">
        <column md="4"
                v-for="(item, index) in dataItemActive.listCardsChild"
                :key="item.text?.value"
        >
          <div class="block_number_text">
            <div class="dt dt1 fs2">{{ index + 1 }}.</div>
            <div class="dt dt2 fs4n" v-if="item.text" v-html="item.text?.value"></div>
          </div>
        </column>
      </row>
    </container>

  </div>

</template>

<script>

import ClButton from "@/components/library/ClButton.vue";
import ArendaKinopomecheniyTopFilter from "@/components/arendaKinopomecheniyTopFilter.vue";

export default {
  name: "FilmTheaterRental",
  components: {ArendaKinopomecheniyTopFilter, ClButton},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      dataItemActive: false,
      dataItemActiveTimeout: false,
      visibleMenu: true,
      controller: null,
    }
  },
  methods: {
    // смена инфы по времени
    intervalInfo(){
      if(this.dataInfo.listCards?.length > 0){
        this.dataItemActive = this.dataInfo.listCards[0];
        setTimeout(()=>{
          this.dataItemActiveTimeout = this.dataInfo.listCards[0];
        }, 1000);
        const maxEL = this.dataInfo.listCards.length - 1;
        let indexEl = 1;
        setInterval(() => {
          this.dataItemActive = false;
          this.$nextTick(() => {
            this.dataItemActive = this.dataInfo.listCards[indexEl];
            setTimeout(()=>{
              this.dataItemActiveTimeout = this.dataInfo.listCards[indexEl];
            }, 1000);
          });

          maxEL == indexEl ? indexEl = 0 : indexEl = indexEl + 1;
        }, 8000);
      }
    },
    //переход на страницу списка
    searchFunPath(params){
      this.$nextTick(() => {
        if (Object.keys(params).length > 0) {
          this.$router.push({path: `/arenda_kinopomecheniy/pavilony`, query: JSON.parse(JSON.stringify(params))})
        } else {
          this.$router.push({path: `/arenda_kinopomecheniy/pavilony`})
        }
      });
    }
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    this.intervalInfo();
  },
  beforeUnmount() {
    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style lang="scss" scoped>
.rent_top {
  padding: 304px 0 76px 0;
  background-position: center bottom;


  h2 {
    margin-bottom: 24px;

    span {
      color: #ED850A;
    }
  }

  .div_text {
    min-height: 17rem;
    margin-bottom: 0;
  }

  /*.filter_block + .filter_top {
    margin-top: 140px;
  }*/

}
@media (max-width: 991px) {
  .rent_top{
    padding: 309px 0 50px;
    .div_text {
      min-height: 10rem;
    }
  }
}
/*@media (max-width: 767px) {
  .rent_top{
    padding: 120px 0 40px;
    .filter_block{
      flex-direction: column;
      .dt{
        width: 100%;
        margin-right: 0;
        .btn{
          width: 100%;
        }
      }
    }
  }
}*/
</style>