<template>
  <loader
      v-if="isLoadingRemove"
      :animation-duration="3000"
      :size="60"
  ></loader>
  <div class="form-file" v-else>
    <div class="file_el_list" v-if="(modelValue && modelValue.length > 0) || (formItemAttr['data-url'] && formItemShow)">

      <div class="file_el file_el_new1" v-for="file in (modelValue || [formItemAttr['data-url']])">
        <div class="file_td1"><div class="file_ico"></div></div>
        <div class="file_td2 file_td2dop file_td2_text">
          <div class="file_tit plUpFilename">
            <div class="file_tit_flex" v-if="file.name">
              <div class="file_tit_flex_dt1 plUploadedFilename">{{ [...file.name.split('.')][0] }}</div>
              <div class="file_tit_flex_dt2 plUpExt2">.{{ [...file.name.split('.')].at(-1) }}</div>
            </div>
            <a v-else
               class="file_tit_flex"
               target="_blank"
               :download="formItemAttr['data-name']"
               :href="formItemAttr['data-url']">
              <div class="file_tit_flex_dt1 plUploadedFilename">{{ formItemAttr['data-name'] }}</div>
              <div class="file_tit_flex_dt2 plUpExt2">.{{ [...formItemAttr['data-url'].split('.')].at(-1) }}</div>
            </a>
          </div>
          <div class="file_text_st"><span class="file_st_green status_hold"><span>Документ загружен</span></span></div>
        </div>
        <div class="file_td2 file_td2_del"><span class="krug_del krug_del_img" @click="clearInput(formItemAttr['data-fileid'])"></span></div>
      </div>

    </div>
    <span
        v-if="(!modelValue && !formItemAttr['data-url']) || (!formItemShow && !modelValue) || multiple"
        class="btn btn-default btn-middle" @click="open"><span class="sp_img"><img class="img_wh" src="/img/ico/upload.svg" alt=""></span>добавить файл</span>
    <input
        hidden
        ref="input"
        type="file"
        @change="updateInput"
        :accept="accept"
        :name="name"
        :data-name="name"
        :id="id"
        :readonly="readonly"
        :size="size"
        class=""
    />
  </div>
</template>

<script>
/*
* id - id input
* name - name input
* readonly - readonly
* accept - допустимые расширения image/jpeg,image/png,image/gif
* size - допустимый размер
* multiple - множественный выбор
* modelValue
* */
export default {
  name: 'FilePickerForms',
  props: {
    id: {
      type: String
    },
    name: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    modelValue: {
      type: [String, Number, Object]
    },
    accept: {
      type: String,
    },
    size: {
      type: String,
    },
    multiple: {
      type: Boolean
    },
    formItemAttr: {
      type: Object,
    },
  },
  data() {
    return {
      formItemShow: true,
      isLoadingRemove: false
    }
  },
  watch:{
    modelValue(){
      this.$emit("changeInput", this.modelValue);
    }
  },
  methods: {
    open() {
      this.$refs.input.click();
    },
    updateInput(event) {
      this.$emit("update:modelValue", event.target.files);
    },
    clearInput(id){
      if(this.modelValue){
        this.$refs.input.value = null;
        this.$emit("update:modelValue", '');
      } else {
        this.isLoadingRemove = true;
        return this.axios
            .post(`/api/post/delete/file/${id}`)
            .then(response => {
              this.showInfo(false, 'Спасибо за внимание', 'Файл удален');
              this.formItemShow = false;
            })
            .catch(error => {
              this.showError(error);
            })
            .finally(() => {
              this.isLoadingRemove = false;
            });
      }

    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

</style>