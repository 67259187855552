<template>
    <div class="block_top rent_requisites_top rent_list"
         :style="[ dataInfo.image?.value?.src?.fullHd?.url ? { backgroundImage: `url(${dataInfo.image?.value?.src?.fullHd?.url})` } : '' ]">
        <container>
            <h1 class="hasSubtit" v-if="dataInfo.title">{{ dataInfo.title.value }}</h1>
        </container>
    </div>
</template>

<script>
export default {
    name: 'bannerCards',
    props: {
        dataInfo: {
            type: Object,
            require: true
        },
    },
};
</script>

<style lang="scss" scoped>
.row > div {
    position: relative;
}

.card-rent {
    border: 2px solid transparent;
    overflow: visible;
    &:hover {
        z-index: 1;
        border: 2px solid #ED850A;
    }
}

.rent_requisites_top {
    padding: 135px 0 32px 0;

    h1 {
        margin-bottom: 49px;
    }

    .filter_block {
        margin: 0 auto 56px auto;
        position: relative;
        max-width: 1358px;
        z-index: 1;
    }

    .block_number_text_m {
        margin-bottom: 72px;
    }
}
.div_spec {
    margin-bottom: 12px;
    .div_label {
        font-size: 14px;
        line-height: 1.14;
        letter-spacing: 0.21px;
        color: #ACB0B9;
    }
}
.card {
    .btn {
        width: 100%;
    }
    .div_btn {
        position: absolute;
        right: -2px;
        background-color: rgb(38, 38, 42);
        left: -2px;
        top: calc(100% - 32px);
        padding: 12px 24px 24px 24px;
        border: 2px solid #ed850a;
        border-top: transparent;
        border-radius: 12px;
        &:before, &:after {
            content: "";
            position: absolute;
            top: 0;
            width: 9px;
            height: 24px;
            background: #26262a;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }
    &:not(:hover) {
        .div_btn {
            display: none;
        }
    }
}
</style>