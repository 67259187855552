<template>
    <container>
        <row class="trusted_advantages">
            <column md="4" v-for="item in dataInfo.list">
                <img :src="getImage(item.icon)" alt="">
                <h4 class="div_tit">
                    {{ item.title.value }}
                </h4>
                <div class="div_desc" v-html="item.description.value"></div>
            </column>
        </row>
        <row v-if="getUser?.roles?.length > 0 && getUser?.roles?.indexOf('ROLE_AUTH_X509') != -1 && getUser?.ukepAuthorization" class="trusted_persone">
            <div id="loader-measure-ms-form" class="loader-trusted" v-if="loaderTrusted">
                <loader
                    :animation-duration="3000"
                    :size="60"
                    :color="'#ED850A'"
                ></loader>
            </div>
            <column lg="3" md="6">
                <card card-type="trusted" class="trusted_add" @click="modalTrustedShow = !modalTrustedShow">
                    <div class="div_icon">
                        <img src="/img/ico/user-plus.svg" alt="">
                    </div>
                    <div class="div_tit">
                        Добавить сотрудника
                    </div>
                </card>
            </column>

            <column lg="3" md="6" v-for="item in trusted">
                <card card-type="trusted">
                    <div class="trusted_row1">
                        <div class="div_tag">Доверенное лицо</div>
                        <div class="div_buttons">
                            <div class="btn_edit btn_img" @click="modalEditShow = !modalEditShow; activePersone = item.trusteeUserSSO">
                                <img src="/img/ico/pencil.svg" alt="">
                            </div>
                            <div class="btn_clear btn_img" @click="modalDeleteShow = !modalDeleteShow; activePersone = item.trusteeUserSSO">
                                <img src="/img/ico/trashOrg.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="trusted_row2">
                        {{ item.surname }} {{ item.name }} {{ item.patronymic }}
                    </div>
                </card>
            </column>
        </row>
        <row v-else class="trusted_persone">
            <column lg="3" md="6">
                <card card-type="trusted imitation" class="trusted_add">
                    <div class="div_icon">
                        <img src="/img/ico/user-plus.svg" alt="">
                    </div>
                    <div class="div_tit">
                        Добавить сотрудника
                    </div>
                </card>
            </column>

            <column lg="3" md="6" v-for="item in 7">
                <card card-type="trusted imitation">
                    <div class="trusted_row1">
                        <div class="div_tag"></div>
                        <div class="div_buttons">
                            <div class="btn_edit btn_img">
                                <img src="/img/ico/pencil.svg" alt="">
                            </div>
                            <div class="btn_clear btn_img">
                                <img src="/img/ico/trashOrg.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="trusted_row2">
                    </div>
                </card>
            </column>
            <div class="modal_bkcg">
                <div class="modal_info">
                    <div class="mInfo_row1">
                        <h4 class="mInfo_tit">Понадобится авторизация с УКЭП</h4>
                    </div>
                    <div class="mInfo_row2">
                        <div class="mInfo_info">
                            <img src="/img/ico/ukep.svg" alt="">
                            <span>Вставьте УКЭП в USB-порт и авторизуйтесь как юридическое лицо на mos.ru</span>
                        </div>
                        <a href="/auth/sudir/trustee/persons" class="btn btn-light btn-lite">Перейти</a>
                    </div>
                </div>
            </div>
        </row>
        <cl-modal v-model="modalTrustedShow" modal-type="center" class="modal-trusted" :closeFonActive="false"   :closeNone="true">
            <ms-form :urlData="'/api/add/trustee/person'" :submitName="'Добавить'" :btnCloseModal="true" @closeModal="modalTrustedShow = false" @ok="getTrusted" :title="'Добавление нового доверенного лица'"/>
        </cl-modal>
        <cl-modal v-model="modalEditShow" modal-type="center" :closeFonActive="false" class="modal-trusted" :closeNone="true">
            <ms-form :urlData="'/api/update/trustee/person'" :paramsGetForm="{id: activePersone}" :submitName="'Добавить'" :btnCloseModal="true" @closeModal="modalEditShow = false" @ok="getTrusted" :title="'Редактирование пользователя'"/>
        </cl-modal>
        <cl-modal v-model="modalDeleteShow" modal-type="center" :closeFonActive="false" class="modal-trusted" :closeNone="true">
            <div class="delete_person">
                <div class="delete_person_img">
                    <img src="/img/ico/trashOrg.svg" alt="">
                </div>
                <div class="delete_person_tit">Вы точно хотите удалить пользователя?</div>
                <div class="delete_person_buttons">
                    <cl-button class="btn-light btn-middle sp_img btn-ico" img="/img/ico/cancel.svg" @click="modalDeleteShow = flase">Отменить</cl-button>
                    <cl-button class="btn-middle  sp_img btn-ico" img="/img/ico/checkWhite.svg" @click="removeTrusted">Подтвердить</cl-button>
                </div>
            </div>
        </cl-modal>
    </container>
</template>

<script>

import Card from "@/components/library/Card.vue";
import MsForm from "@/components/forms/MsForm.vue";
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";

export default {
    name: "trustedPersons",
    components: {ClButton, ClModal, MsForm, Card},
    props: {
        dataInfo: {
            type: Object,
            require: true
        },
    },
    data(){
        return{
            trusted: null,
            modalTrustedShow: false,
            modalEditShow: false,
            modalDeleteShow: false,
            activePersone: null,
            loaderTrusted: true,
        }
    },
    computed: {
        ...mapGetters([
            'getUser'
        ]),
    },
    methods: {
        getTrusted(){
            this.loaderTrusted = true;
            this.axios
                .get("/api/get/list/trustee/persons", '')
                .then(response => {
                    this.trusted = response.data;
                })
                .catch(error => {
                    this.showError(error);
                })
                .finally(() => {
                    this.loaderTrusted = false;
                })
        },
        removeTrusted(){
            this.modalDeleteShow = false;
			this.loaderTrusted = true;
            this.axios
                .post("/api/remove/trustee/person/" , null, {
                    params: {
                        id: this.activePersone,
                    }
                })
                .then(response => {
                    this.getTrusted();
                })
				.finally(() => {
					this.loaderTrusted = false;
				});
        },
    },
    mounted(){
        if(
            this.getUser?.roles?.length > 0 &&
            this.getUser?.roles?.indexOf('ROLE_AUTH_X509') != -1 &&
            this.getUser?.ukepAuthorization
        ){
            this.getTrusted();
        }
    }
};
</script>

<style lang="scss" scoped>
.trusted_advantages{
    margin-top: 2.4rem;
    img{
        width: 64px;
        height: 64px;
        object-fit: contain;
    }
}
.trusted_persone{
    margin-top: 3.6rem;
    position: relative;
    .modal_bkcg{
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 1, 6, 0.80);
    }
    .modal_info{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 32px 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-radius: 6px;
        background: #17191C;
        max-width: 800px;
    }
    .mInfo_row2{
        display: flex;
        gap: 12px;
        height: 64px;
        .mInfo_info{
            display: flex;
            gap: 12px;
            align-items: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 131.25%;
            letter-spacing: 0.24px;
        }
        img{
            height: 100%;
            width: auto;
            object-fit: contain;
        }
    }
}
.delete_person{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;

    .delete_person_tit{
        text-align: center;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 133.333%;
        letter-spacing: 0.048rem;
        color: #fff;
    }

    .delete_person_img{
        width: 64px;
        height: 64px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.delete_person_buttons{
    display: flex;
    gap: 1.6rem;
    align-items: center;
}
.loader-trusted{
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
}
</style>