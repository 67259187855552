<template>
  <div class="block_wrap reviews_wrap">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
    </container>
      <div class="reviews_list"
           v-if="dataInfo.listCard && dataInfo.listCard.length > 0"
      >
        <!--      :slides-per-view="'auto'"   :slides-per-view="2"
                    :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }"

                        :free-mode="true"
            :mousewheel="{
              releaseOnEdges: true,
            }"
        -->
        <!--        @mouseenter="() => this.swiper.autoplay.stop()"-->
<!--        @mouseleave="() => this.swiper.autoplay.start()"-->
        <swiper
            :modules="modules"
            :slides-per-view="'auto'"
            class="reviews_slide slide100h sample-slider"
            :loop="true"
            :speed="4000"
            :freeMode="true"
            @mouseenter="autoplayStart"
            @mouseleave="autoplayStop"
        >
          <swiper-slide
              v-for="reviewsItem in dataInfo.listCard"
              :key="reviewsItem.title"
          >
            <div class="reviews_el">
              <div class="div_top div_txtline">
                  <div class="dt1 dt_text">
                      <span class="dt">Film 200-6</span>
                      <span class="dt"> 00</span>
                      <span class="dt">200-6 Frame</span>
                  </div>
                  <div class="dt2 dt_text">
                      <span class="dt">Film 200-6</span>
                      <span class="dt"> 00</span>
                      <span class="dt">200-6 Frame</span></div>
              </div>
              <div class="div_line">
                <div class="dt"></div>
                <div class="dt"></div>
                <div class="dt"></div>
                <div class="dt"></div>
                <div class="dt"></div>
                <div class="dt"></div>
                <div class="dt"></div>
                <div class="dt"></div>
                <div class="dt"></div>
                <div class="dt"></div>
                <div class="dt d-600"></div>
                <div class="dt d-600"></div>
                <div class="dt d-600"></div>
                <div class="dt d-600"></div>
                <div class="dt d-600"></div>
              </div>
              <div class="div_center">
                <div class="child_div_center">
                  <div class="div_text" v-if="reviewsItem.text" v-html="reviewsItem.text.value"></div>
                  <div class="div_tit fs3n"  v-if="reviewsItem.name">{{ reviewsItem.name.value }}</div>
                  <div class="div_post fs4n" v-if="reviewsItem.post">{{ reviewsItem.post.value }}</div>
                </div>
              </div>
              <div class="div_line">
                  <div class="dt"></div>
                  <div class="dt"></div>
                  <div class="dt"></div>
                  <div class="dt"></div>
                  <div class="dt"></div>
                  <div class="dt"></div>
                  <div class="dt"></div>
                  <div class="dt"></div>
                  <div class="dt"></div>
                  <div class="dt"></div>
                  <div class="dt d-600"></div>
                  <div class="dt d-600"></div>
                  <div class="dt d-600"></div>
                  <div class="dt d-600"></div>
                  <div class="dt d-600"></div>
              </div>
              <div class="div_bottom div_txtline">
                  <div class="dt1 dt_text">
                      <span class="dt">Film 200-6</span>
                      <span class="dt"> 00</span>
                      <span class="dt">200-6 Frame</span>
                  </div>
                  <div class="dt2 dt_text">
                      <span class="dt">Film 200-6</span>
                      <span class="dt"> 00</span>
                      <span class="dt">200-6 Frame</span></div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Autoplay, Mousewheel, FreeMode} from "swiper";

export default {
  name: "reviews",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  setup() {
    const autoplayStop = (swiper) => {
      //swiper.target.swiper.params.speed = 0;
      swiper.target.swiper.autoplay.stop()
      //console.log('stop');
      //console.log(swiper.target.swiper.autoplay);
    };
    const autoplayStart = (swiper) => {
      //swiper.target.swiper.params.speed = 4000;
      swiper.target.swiper.params.autoplay.delay = 0;
      swiper.target.swiper.params.autoplay.disableOnInteraction = false;
      swiper.target.swiper.autoplay.start();
      //console.log(swiper.target.swiper.params);
      //console.log('start');

    };
    return {
      autoplayStop,
      autoplayStart,
      modules: [Autoplay, Mousewheel, Navigation, FreeMode],
    }
  },
};
</script>

  <style lang="scss">
.reviews_wrap {
  padding: 6.4rem 0;

  h2 {
    margin-bottom: 24px;
  }
}

.reviews_slide {
  overflow: hidden;
  .swiper-slide {
    width: 80.4rem;
  }
}

.reviews_el {
  height: 100%;
  padding: 1.6rem 0;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 -1px;
  background-color: #0C0C0E;
  border-top: 1px solid rgba(255, 255, 255, 0.40);
  border-bottom: 1px solid rgba(255, 255, 255, 0.40);

  .div_line {
    display: flex;
    justify-content: space-around;
    flex: none;
    position: relative;

    .dt {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 0.4rem;
      background: #17191C;
      position: relative;
    }
  }

  .div_txtline{
    display: flex;
    justify-content: space-around;
    &.div_top{
      margin-bottom: 0.4rem;
    }
    &.div_bottom{
      margin-top: 0.4rem;
      transform: rotate(180deg);
    }
    .dt_text{
      display: flex;
      gap: 1.6rem;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.032rem;
      text-transform: uppercase;
      color: #ED850A;
    }
  }

  .div_center {
    flex: 1;
    position: relative;
    background: url(@/assets/img/font_rev.jpg) no-repeat center center;
    padding: 6.4rem 3.2rem;
    margin: 3.2rem 0;
    background-size: cover;
    display: flex;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.40);
  }

  .div_tit {
  }

  .div_text {
    margin-bottom: 36px;
  }
}

.child_div_center{
  display: flex;
  flex-direction: column;
  height: 100%;
  .div_text{
    flex-grow: 1;
    display: flex;
  }
  .div_tit{
    font-weight: 600;
  }
}

@media (max-width: 991px) {
  .reviews_wrap{
    padding: 4.8rem 0;
  }
  .reviews_slide{
    .swiper-slide{
      width: calc(100vh - 3.2rem);
    }
  }
  .reviews_el{
    .div_center{
      padding: 4.8rem 2.4rem;
      margin: 2.4rem 0;
    }
  }
}
@media (max-width: 767px) {
  .reviews_wrap{
    padding: 3.2rem 0;
  }
  .reviews_slide{
    .swiper-slide{
      width: calc(100vw - 2.4rem);
    }
  }
  .reviews_el{
    .div_center{
      padding: 3.2rem 1.6rem;
    }
  }
}
@media (max-width: 600px) {
  .reviews_el{
    .d-600{
      display: none;
    }
    .div_txtline{
      .dt2{
        display: none;
      }
    }
    .div_line{
      .dt {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }
}
</style>