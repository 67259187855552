<template>
    <div class="progress" :style="{fontSize: strokeWidth + 'px' }">
        <template v-if="type === 'circle'">
            <svg class="circle" viewBox="0 0 100 100">
                <circle class="track" :stroke="backColor" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0"></circle>
                <circle class="indication" :stroke="mainColor" fill="transparent" :stroke-dasharray="track" :stroke-dashoffset="indication"></circle>
            </svg>
        </template>
        <template v-else>

        </template>
    </div>
</template>

<script>
export default {
    name: 'progressBar',
    props: {
        type: {
            type: String,
            default: 'circle'
        },
        progress: Number,
        strokeWidth: {
          type: Number,
          default: 10
        },
        size: Number,
        mainColor: {
            type: String,
            default: '#ED850A'
        },
        backColor:  {
            type: String,
            default: '#fff'
        },
    },
    computed: {
        track: function () {
            return Math.PI*(this.radius*2);
        },
        indication: function () {
            return ((100-this.value)/100)*this.track
        },
    },
    data() {
        return {
            value: 0,
            radius: (100 / 2) - (this.strokeWidth/2)
        }
    },
    watch: {
        progress: function (val) {
            this.value = val;
        }
    },
    mounted() {
        setTimeout( () => {
            this.value = this.progress
        }, 100)
    }
}
</script>

<style lang="scss" scoped>
.progress {
    .circle {
        stroke-dashoffset: 0;
        stroke: #666;
        stroke-width: 1em;
        width: 100%;
        .indication, .track {
            cx: 50%;
            cy: 50%;
            r: calc(50% - .5em);
            fill: transparent;
            transition: stroke-dashoffset 1s ease-in-out;
        }
        .indication {

        }
        .track {

        }
    }
}
</style>