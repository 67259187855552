<template>
    <div class="message-left">
      <slot></slot>
    </div>
</template>

<script>
export default {
    name: "message-left",
};
</script>

<style lang="scss" scoped>
  .message-left{
    position: relative;

    width: max-content;

    padding: 1.7rem;
    background-color: white;
    border: 1px solid #F1F2F3;
    border-radius: 12px;

    font-size: 1.4rem;
    font-weight: 400;
    line-height: 114.286%;
    letter-spacing: 0.21px;
    color: #000106;

    &:after{
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      top: 50%;
      left: -7px;
      border-left: 1px solid #F1F2F3;
      border-top: 1px solid #F1F2F3;
      border-radius: 2px 0 0 0;
      transform: translateY(-50%) rotate(-45deg);
      background-color: white;
    }
    @media (max-width: 767px) {
      &:after{
        top: 0;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-315deg);
      }
    }
  }
</style>