<template>
  <Header :dataLogo="dataHeaderLogo"/>
  <router-view @updateHeaderLogo="updateHeaderLogo"/>

  <cl-modal :modelValue="true" class="d-none center" :closeStandart="false" :buttonTextClose="true" id="modal-form-success">
    <template v-slot:body> </template>
    <template v-slot:header> </template>
    <template v-slot:footer> </template>
  </cl-modal>

  <!--  компонент модалка при переходе к авторизации старый  -->
<!--  <cl-modal v-model="modalAuthShow" class="d-none" modal-type="center text-center modalInfo" id="modalAuthShow" :closeStandart="'false'" v-if="!getIsAuthorization">-->
<!--    <template v-slot:header>Для подачи заявки на проект вы будете перенаправлены на портал mos.ru для прохождения авторизации.</template>-->
<!--    <template v-slot:footer>-->
<!--      <cl-button id="modalAuthHref">перейти</cl-button>-->
<!--      <cl-button @click="modalAuthClose" type="light2">отмена</cl-button>-->
<!--    </template>-->
<!--  </cl-modal>-->

  <!--  компонент модалка при переходе к авторизации  -->
  <cl-modal v-model="modalAuthShow" class="d-none" modal-type="center modal-ico" id="modalAuthShow" :closeStandart="'false'" v-if="!getIsAuthorization">

    <template v-slot:body>
      <div class="div_img"><img src="/img/ico/user-round-check.svg" alt=""></div>
      <h4 class="div_tit">Для продолжения, пожалуйста авторизуйтесь!</h4>
    </template>
    <template v-slot:footer>
      <cl-button link href="/connect/mosru">Авторизоваться</cl-button>
    </template>
  </cl-modal>

  <!--  компонент уведомлений  -->
  <notifications position="bottom right" width="400">
    <template #body="props">
      <div class="notification" :class="props.item.type">
        <div class="notification-title">
          {{ props.item.title }}
        </div>
        <div class="notification-content" v-html="props.item.text"/>
        <div class="notification-close" @click="props.close">
          <div class="close_el"></div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<style lang="scss">

</style>

<script>
import Header from "@/components/header.vue";
import {mapActions, mapGetters} from "vuex";
export default {
  components: {Header},
  data(){
    return {
      dataHeaderLogo: false,
      modalAuthShow: true,
    }
  },
  methods: {
    ...mapActions([
      'widthSite',
      'fetchPersonData'
    ]),
    updateHeaderLogo(data){
      this.dataHeaderLogo = data;
    },
    modalAuthClose() {
      document.getElementById('modalAuthShow').classList.add('d-none');
    },
  },
  mounted() {
    window.addEventListener('resize', this.widthSite);
  },
  computed: {
    ...mapGetters([
      'getIsAuthorization',
    ])
  },
  created() {
    if (this.getIsAuthorization) {
      this.fetchPersonData();
    }
  }
}
</script>