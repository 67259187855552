<template>
  <div class="block_wrap block_wh update_shema">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
      <div class="metmashUpdateSheme fs21" v-if="dataInfo.listCard && dataInfo.listCard.length > 0">
        <div class="div_bkgFon">
          <div class="div_krugAnimate">
            <img :src="getImage(dataInfo.imageBkg, 'fullHd')" alt="">
          </div>
          <div class="div_krugAnimate">
            <img :src="getImage(dataInfo.imageBkg, 'fullHd')" alt="">
          </div>
        </div>
        <row>
          <column  :lg="dataInfo.listCard.length % 3 == 0 ? 4 : index >= (dataInfo.listCard.length - dataInfo.listCard.length % 3) ? 12 / (dataInfo.listCard.length % 3) : 4" md="12"  v-for="(item, index) in dataInfo.listCard"
                  :key="key">
            <a class="div_el"
                 :class="'div_el' + index"
                 :href="item.cardLink?.value"
            >
              <div class="div_leftInfo">
               <h4 class="div_tit" v-if="item.cardTitle" v-html="item.cardTitle.value"></h4>
               <div class="div_description" v-if="item.cardDescription" v-html="item.cardDescription.value"></div>
              </div>
              <div class="div_img" v-if="item.imageCard">
                <img  :src="getImage(item.imageCard, 'middle')" alt="">
              </div>
              <div class="div_imgHoverCard" v-if="item.cardBkg">
                <img :src="getImage(item.cardBkg, 'big')" alt="">
              </div>
            </a>
          </column>
        </row>
      </div>
    </container>
  </div>
</template>

<script>
import Row from "@/components/library/Row";
import Column from "@/components/library/Column";
export default {
  name: "fullNewShemaServices",
  components: {Column, Row},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.update_shema {
  position: relative;
  color: #FFFFFF;
  background-color: black;
  padding-top: 6.4rem;
  padding-bottom: 13.8rem;
  overflow: hidden;

  h2 {
    margin-bottom: 2.6rem;
  }

  .metmashUpdateSheme {
    border-radius: 2.4rem;
    border: 1px solid #17191C;
    overflow: hidden;

    .div_bkgFon{
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .div_krugAnimate{
        animation: animBAba 7s linear infinite;
        width: 50%;
        > img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        &:last-child{
          animation: animBAba2 7s linear infinite;
          transform: rotate(180deg);
        }
      }
    }

    .row {
      gap: 0;
      padding: 0 1.2rem;

      > div {
        padding: 0;
      }
    }

    .div_el {
      position: relative;
      display: flex;
      overflow: hidden;
      padding: 2.4rem;
      height: 29rem;
      width: 100%;
      border: 1px solid #17191C;
      background: rgba(0, 1, 6, 0.20);
      backdrop-filter: blur(1.2rem);
      -webkit-backdrop-filter: blur(1.2rem);
      z-index: 1;

      .div_leftInfo {
        z-index: 1;
        display: flex;
        flex-direction: column;

        .div_tit {
          transition: .2s ease-in;
          font-size: 2.4rem;
          font-weight: 500;
          line-height: 133.333%;
          letter-spacing: 0.048rem;
          margin-top: 9.4rem;
          margin-bottom: auto;
        }

        .div_description {
          transition: .2s ease-in;
          font-size: 1.6rem;
          line-height: 131.25%;
          letter-spacing: 0.024rem;
          color: #ACB0B9;
          opacity: 0;
          flex-direction: column;

          & > :deep(p) {
            margin-bottom: 0;
          }
        }
      }

      .div_img {
        z-index: 1;
        height: 26.8rem;
        max-width: 23rem;
        width: 100%;
        margin: 0 -2.4rem -2.4rem auto;

        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .div_imgHoverCard {
        transition: .2s ease-in;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 0;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &:hover {
        .div_leftInfo {
          .div_tit {
            transition: .2s ease-in;
            margin-top: 0;
            //margin-bottom: auto;
          }

          .div_description {
            transition: .2s ease-in;
            opacity: 1;
            height: fit-content;
          }
        }

        .div_imgHoverCard {
          transition: .2s ease-in;
          opacity: 1;
        }
      }
    }
  }

  @media(max-width: 991px) {
    padding-bottom: 6.4rem;
    overflow: hidden;

    .metmashUpdateSheme {
      border: .5px solid #17191C;

      .div_bkgFon{
        flex-direction: column;
        top: calc(0% + 20rem);
        .div_krugAnimate{
          width: 100%;
          > img{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          &:last-child{
            width: 100%;
          }
        }
      }

      .div_el4 {

        .div_img {
          height: 29.8rem;
        }
      }
    }
  }

  @media(max-width: 767px) {
    padding-top: 4.8rem;
    padding-bottom: 3.9rem;

    .metmashUpdateSheme {
      .div_bkgFon{
        display: none;
      }

      .div_el {
        height: 17rem;

        .div_leftInfo {

          .div_tit {
            font-size: 1.5rem;
            letter-spacing: 0.0301rem;
            margin-top: 5.4rem;
          }

          .div_description {
            font-size: 1.1279rem;
            line-height: 133.33%;
            letter-spacing: 0.024rem;
            height: 0;
          }
        }

        .div_img {
          height: 14.391rem;
          max-width: 14.4rem;
        }

        .div_imgHoverCard {
          transition: .2s ease-in;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 0;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .div_el {

        .div_img {
          min-width: 12.4rem;
          width: auto;
        }
      }
    }
  }
}
@keyframes animBAba {
  0% {
    transform: rotate(0)
  }

  100%{
    transform: rotate(360deg)
  }
}
@keyframes animBAba2 {
  0% {
    transform: rotate(180deg)
  }
  //50% {
  //  transform: rotate(360deg)
  //}
  100%{
    transform: rotate(-180deg)
  }
}
</style>