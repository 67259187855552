<template>
    <slot/>
</template>

<script>
export default {
  name: "FonGrey",
  data() {
    return {

    }
  },
  mounted() {
    document.querySelector('body').classList.add('fon-grey');
  },
  beforeUnmount() {
    document.querySelector('body').classList.remove('fon-grey');
  },
}
</script>

<style scoped>

</style>