<template>
    <film-grants-card v-if='blockComponent == "grantCard"' :dataInfo="dataInfo" :id="id"/>
    <rent-item v-if='blockComponent == "rentItem"' :dataInfo="dataInfo" :id="id"/>
    <rent-requisites-item v-if='blockComponent == "rentRequisitesItem"' :dataInfo="dataInfo" :id="id"/>
    <modal-banner-competition v-if='blockComponent == "modalCompetition"' :dataInfo="dataInfo" :id="id"/>
    <competition-banner v-if='blockComponent == "competitionBanner"' :dataInfo="dataInfo" :id="id"/>
    <steps-competition v-if='blockComponent == "stepsCompetitions"' :dataInfo="dataInfo" :id="id"/>
    <component-fon v-if='blockComponent == "componentFon"' :dataInfo="dataInfo" :id="id"/>
    <jury-competition v-if='blockComponent == "juryCompetitions"' :dataInfo="dataInfo" :id="id"/>
    <movie-themes v-if='blockComponent == "movieThemes"' :dataInfo="dataInfo" :id="id"/>
    <afisha-card v-if='blockComponent == "afishaCard"' :dataInfo="dataInfo" :id="id"/>
  <!--  <test-component-dynamic v-else-if='blockComponent == "testComponent"' :dataInfo="dataInfo" :id="id"/>-->
</template>

<script>/**
 * dataInfo - данные приходящие в блок
 * id - якорь блока
 * blockComponent - название компонента (которое прописывается в админке)
 */

import FilmGrantsCard from "@/components/filmGrantsCard.vue";
import RentItem from "@/components/rentItem.vue";
import RentRequisitesItem from "@/components/rentRequisitesItem.vue";
import ModalBannerCompetition from "@/components/modalBannerCompetition.vue";
import CompetitionBanner from "@/components/competitionBanner.vue";
import StepsCompetition from "@/components/stepsCompetition.vue";
import ComponentFon from "@/components/componentFon.vue";
import JuryCompetition from "@/components/juryCompetition.vue";
import MovieThemes from "@/components/movieThemes.vue";
import AfishaCard from "@/components/afishaCard.vue";

export default {
  name: "templateDynamic1",
  components: {
    MovieThemes,
    JuryCompetition,
    ComponentFon,
    StepsCompetition,
    CompetitionBanner,
    RentRequisitesItem,
    RentItem,
    FilmGrantsCard,
    ModalBannerCompetition,
    AfishaCard
  },
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
    id: {
      type: String,
    },
    blockComponent: {
      type: String,
    }
  }
}
</script>