<template>
    <cl-button v-if="type === 'add'" @click="open">добавить</cl-button>
    <cl-button v-if="type === 'edit'" type="light" @click="open" class="btn-circle">
        <img src="/img/ico/pencil.svg" alt="">
    </cl-button>
    <input
        hidden
        ref="input"
        type="file"
        @change="updateInput"
        :accept="accept"
        class=""
    />
</template>

<script>
export default {
    name: 'filePicker',
    props: {
        type: {
            type: String,
            default: 'add'
        },
        modelValue: {
            type: [String, Number],
            default: {},
        },
        accept: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            fileData: null
        }
    },
    methods: {
        open() {
            this.$refs.input.click();
        },
        updateInput(event) {
            this.fileData = event.target.files[0]
            this.$emit("update:modelValue", event.target.files[0]);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>