<template>
  <div class="block_scenario_table">
    <container class="container3">
      <div class="block_scheme">
        <h3 v-if="dataInfo.blockData.title">
          {{ dataInfo.blockData.title.value }}
        </h3>
        <div class="scheme animation-start" v-if="dataInfo.concursList">
          <template v-for="(item, index) of dataInfo?.concursList">
            <div class="el" :class="'el'+(index+1)">
              <div class="img">
                <img :src="getImage(item.imageConcurs, 'reference')" alt="">
                <div class="desc">
                  <span v-html="item.textConcurs.value"/>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
export default {
  name: "joinConcursScenarists",
  data(){
    return {}
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  mounted() {
    function startAnimation(){
      document.querySelector('.animation-start').classList.add('animate');
      setTimeout( function () {
        document.querySelector('.animation-start').classList.add('animate_end');
      }, 2950)
    }
    if (document.querySelector('.scroll')) {
      document.querySelector('.scroll').addEventListener('click', e => (e.preventDefault(), document.getElementById('themes_film').scrollIntoView({behavior: 'smooth'})));
    }
    window.addEventListener("scroll", function() {
      let blockAnim = document.querySelector('.block_scenario_table .block_scheme').getBoundingClientRect();
      let windowHeight = window.innerHeight;

      if ((blockAnim.top + windowHeight / 2) <= windowHeight && blockAnim.bottom >= 0) {
        startAnimation()
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.block_scenario_table{
  margin: 4rem 0;
  .container{
    max-width: 1440px;
  }
  @media(max-width: 1440px){
    .container{
      padding: 0;
    }
  }
  @media(max-width: 991px){
    .container{
      padding: 0 4rem;
    }
    .block_scheme{
      border-radius: 2.4rem;
    }
  }
  @media(max-width: 767px){
    .container{
      padding: 0;
    }
  }
}
.block_scheme{
  background: #17191C;
  border-radius: 4rem;
  padding: 12rem;
  h3{
    font-size: 32px;
    text-align: center;
    margin-bottom: 3.2rem;
  }
  .scheme{
    display: flex;
    flex-wrap: wrap;
    gap: 8rem 6rem;
    justify-content: space-between;
    overflow: hidden;
    .el{
      width: calc(33% - 4rem);
      padding: 3.2rem;
      background: black;
      border: 1px solid white;
      border-radius: 2.4rem;
      position: relative;
      z-index: 10;
      .img{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        img{
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      }
      .desc{
        font-size: 1.8rem;
        line-height: 140%;
        text-align: center;
        font-weight: 300;
        text-transform: uppercase;
      }
      &:before{
        content: "";
        position: absolute;
        right: -7rem;
        top: calc(50% - .6rem);
        width: 7.1rem;
        height: 1.6rem;
        background: url(../assets/img/courses/left_line.svg) no-repeat center center;
        background-size: contain;
      }
      &.el3{
        &:before{
          background: url(../assets/img/courses/under_line.svg) no-repeat center center;
          background-size: contain;
          top: unset;
          bottom: -8.1rem;
          right: 50%;
          width: 63.9rem;
          height: 8rem;
        }
      }
      &.el4{
        margin-left: auto;
        @media (max-width: 991px) {
         margin-left: unset;
        }
      }
      &.el5{
        margin-right: auto;
        margin-left: .6rem;
        &:before{
          content: unset;
        }
        @media (max-width: 991px) {
          margin-left: unset;
        }
      }
    }
  }
  @media(max-width: 991px){
    padding: 6rem 1.6rem;
    .scheme{
      flex-direction: column;
      gap: 3.2rem;
      .el{
        width: 100%;
        &:before, &.el3:before{
          position: absolute;
          background: url(../assets/img/courses/left_line.svg) no-repeat center center;
          background-size: contain;
          bottom: -6.7rem;
          right: calc(50% - 5rem);
          width: 10rem;
          height: 3rem;
          transform: rotate(90deg);
          top: unset;
        }
        .desc{
          text-transform: initial;
        }
      }
    }
  }
  @keyframes slideUp2 {
    0% {
      transform: translateY(500%);
      opacity: 0;
    }
    20% {
      transform: translateY(500%);
      opacity: 0;
    }
    40% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slideUp3 {
    0% {
      transform: translateY(500%);
      opacity: 0;
    }
    40% {
      transform: translateY(500%);
      opacity: 0;
    }
    60% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slideUp4 {
    0% {
      transform: translateY(500%);
      opacity: 0;
    }
    60% {
      transform: translateY(500%);
      opacity: 0;
    }
    80% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slideUp5 {
    0% {
      transform: translateY(500%);
      opacity: 0;
    }
    80% {
      transform: translateY(500%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes arrowVisible1 {
    0% {
      opacity: 0;
    }
    18% {
      opacity: 0;
    }
    22% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }@keyframes arrowVisible2 {
    0% {
      opacity: 0;
    }
    38% {
      opacity: 0;
    }
    42% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes arrowVisible3 {
    0% {
      opacity: 0;
    }
    58% {
      opacity: 0;
    }
    62% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes arrowVisible4 {
    0% {
      opacity: 0;
    }
    78% {
      opacity: 0;
    }
    82% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  .scheme.animation-start{
    .el{
      &:not(&.el1){
        opacity: 0;
      }
      &:before{
        opacity: 0;
      }
    }
    &.animate{
      .el{
        &.el1{
          &:before{
            animation: arrowVisible1 3s;
          }
        }
        &.el2{
          opacity: 0;
          animation: slideUp2 3s;
          &:before{
            animation: arrowVisible2 3s;
          }
        }
        &.el3{
          opacity: 0;
          animation: slideUp3 3s;
          &:before{
            animation: arrowVisible3 3s;
          }
        }
        &.el4{
          opacity: 0;
          animation: slideUp4 3s;
          &:before{
            animation: arrowVisible4 3s;
          }
        }
        &.el5{
          opacity: 0;
          animation: slideUp5 3s;
        }
      }
    }
    &.animate.animate_end{
      .el{
        &:not(&.el1){
          opacity: 1;
        }
        &:before{
          opacity: 1;
        }
      }
    }
  }
}
</style>