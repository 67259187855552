<template>
  <div class="block_wrap pavilony_list">
    <container>
      <div class="tit_div">
        <h2 v-if="dataInfo.title?.value">{{ dataInfo.title.value }}</h2>
        <template v-if="dataInfo.types?.value">
          <a class="btn btn-light d-none d-lg-flex" target="_blank" :href="`/arenda_kinopomecheniy/pavilony?types=${dataInfo.types.value}`">Посмотреть все</a>
        </template>
      </div>

      <loader
          v-if="isLoading"
          :animation-duration="3000"
          :size="60"
      ></loader>
      <not-info
          v-if="!isLoading && dataList?.length == 0"
          :title="`Здесь пока ничего нет`"
          :text="`Нет результатов`"
      >
      </not-info>
      <template v-if="!isLoading && dataList?.length > 0">
        <swiper
            :spaceBetween="24"
            :navigation="true"
            :threshold='10'
            :modules="modules"
            :loop="widthSite > 992 ? dataList.length > 4 : dataList.length > 2"
            :slidesPerView="'auto'"
            class="slide100h arrow_center arrow_center_op"
            :breakpoints="{
                  '992': {
                    slidesPerView: 4,
                  },
                  '768': {
                    slidesPerView: 2,
                    loop: dataList.length > 2
                  },
                  '300': {
                    slidesPerView: 1,
                    loop: dataList.length > 1
                  }
                }"
        >
          <swiper-slide
              :key='item.guid'
              v-for='item of dataList'
          >
            <card-rent2-pavilony :item="item" :routeLink="dataInfo.link?.value + item.guid" class="border_img"/>
          </swiper-slide>
        </swiper>
        <template v-if="dataInfo.types?.value">
          <div class="sub_slider d-block d-lg-none">
            <a class="btn btn-light" target="_blank" :href="`/arenda_kinopomecheniy/pavilony?types=${dataInfo.types.value}`">Посмотреть все</a>
          </div>
        </template>
      </template>
    </container>
  </div>
</template>

<script>
/*
* types - тип для фильтра (обязательное поле)
* */
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";
import CardRent2Pavilony from "@/components/cardRent2Pavilony.vue";

export default {
  name: "pavilonyListTypes",
  components: {
    CardRent2Pavilony,
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      isLoading: true,
      getParams: {
        all: 1
      },
      dataList: false,
      controller: null,
      loopStatus: false,
    }
  },
  methods: {
    getRentItem(isLoadMore) {
      return this.axios
          .get(`/api/data/all-platforms`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            this.dataList = response.data.records;
            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    this.getParams.types = this.dataInfo.types.value;
    this.getRentItem();
  },
  beforeUnmount() {
    this.controller.abort();
    this.controller = null;
  },
}
</script>

<style lang="scss" scoped>
.pavilony_list {
  padding: 64px 0;

  .tit_div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;
    h2{
      margin-bottom: 0;
    }
    .btn{
      height: fit-content;
    }
  }

  .sub_slider{
    margin-top: 2.4rem;
    .btn{
      width: 100%;
    }
  }
}
</style>