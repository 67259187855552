<template>
  <div class="block_wrap block_wh metmash_services">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
      <div class="metmash_services_shem fs21" v-if="dataInfo.listCards && dataInfo.listCards.length > 0">
        <div class="div_el"
             :class="'div_el' + index"
             v-for="(item, index) in dataInfo.listCards"
             :key="item.text?.value">
          <h4 class="div_tit" v-if="item.title" v-html="item.title.value"></h4>
          <div class="div_text ul_dot" v-if="item.text" v-html="item.text.value"></div>
        </div>
        <div class="div_center">
          <h3 class="div_center_tit" v-if="dataInfo.subTitle">{{ dataInfo.subTitle.value }}</h3>
          <div class="div_center_text" v-if="dataInfo.text">{{ dataInfo.text.value }}</div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
export default {
  name: "fullCycleServices",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.metmash_services {
  padding: 68px 0 150px 0;
  h2 {
    margin-bottom: 56px;
  }
  @media (max-width: 1024px) {
    padding: 68px 0 95px 0;
  }
  @media (max-width: 991px) {
    padding: 68px 0 150px 0;
  }
  @media (max-width: 767px) {
    padding: 32px 0;
    h2 {
      margin-bottom: 32px;
    }
  }
}
.metmash_services_shem {
  height: 924px;
  background:  url(@/assets/img/metmash_services_shem2.svg) no-repeat center 243px;
  position: relative;
  .div_center {
    position: absolute;
    top: 506px;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFFFFF;
    text-align: center;
    .div_center_tit {
      margin-bottom: 0;
    }
  }
  .div_el {
    width: 362px;
    position: absolute;
    .div_tit {
      margin-bottom: 0;
      text-align: center;
    }
    .div_text{
      text-align: center;
    }
  }
  .div_el0 {
    top: 54px;
    left: 50%;
    transform: translateX(-51%);
    width: 479px;
  }
  .div_el1 {
    top: 232px;
    left: 50%;
    transform: translateX(229px);
  }
  .div_el2 {
    top: 498px;
    left: 50%;
    transform: translateX(328px);
  }
  .div_el3 {
    top: 713px;
    left: 50%;
    transform: translateX(219px);
  }
  .div_el4 {
    top: 721px;
    right: 50%;
    transform: translateX(-251px);
  }
  .div_el5 {
    top: 510px;
    transform: translateX(-349px);
    right: 50%;
  }
  .div_el6 {
    top: 283px;
    right: 50%;
    transform: translateX(-256px);
  }
  .div_el7 {
    top: 850px;
    right: 50%;
    transform: translateX(200px);
  }
  .div_el8 {
    top: 138px;
    right: 50%;
    transform: translateX(-220px);
  }
  @media (max-width: 1024px) and (min-width: 992px) {
    background:  url(@/assets/img/metmash_services_shem2.svg) no-repeat center 124px;
    height: 466px;
    width: 295.936px;
    background-size: contain;
    margin: 0 auto;

    .div_center{
      top: 252px;
      width: 108px;
      .div_center_tit{
        font-size: 2.4rem;
      }
      .div_center_text{
        font-size: 1.8rem;
      }
    }
    .div_el{
      width: 255px;
      .div_text{
        text-align: center;
      }
    }
    .div_el0 {
      top: 12px;
      width: 476px;
      transform: translateX(-49%);
    }
    .div_el1 {
      top: 121px;
      transform: translateX(100px);
    }
    .div_el2 {
      top: 243px;
      transform: translateX(124px);
    }
    .div_el3 {
      top: 329px;
      transform: translateX(97px);
    }
    .div_el4 {
      top: 335px;
      transform: translateX(-102px);
    }
    .div_el5 {
      top: 244px;
      transform: translateX(-125px);
    }
    .div_el6 {
      top: 126px;
      transform: translateX(-97px);
    }
    .div_el7 {
      width: 288px;
      top: 425px;
      transform: translateX(148px);
    }
  }
  @media (max-width: 991px) {
    background:  none;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    height: auto;
    gap: 20px;
    .div_center {
      display: none;
    }
    .div_el {
      width: calc(50% - 10px);
      transform: none;
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      .div_tit {
        text-align: left;
        font-size: 18px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 7px;
        font-weight: 500;
        &:before {
          content: "";
          width: 24px;
          height: 24px;
          border: 2px solid #000000;
          transform: rotate(314deg);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        }
      }
      .div_text {
        text-align: left;
      }
    }
  }
  @media (max-width: 767px) {
    .div_el {
      width: 100%;
    }
  }
}
</style>