<template>
  <div class="filter_btn"
       :class="{'show': isShowMobFilter, 'filter_btn_right': positionRight}"
       v-click-away="hideFilter"
       ref="filterBtn"
  >
    <cl-button type="filter btn-ico btn-light" @click="isShowMobFilter = !isShowMobFilter"><div class="dot_red"></div></cl-button>
    <div class="filter_btn_area">
      <div class="filter_btn_tit">
        <div class="fbt_dt1">Фильтры</div>
        <div class="fbt_dt2 close_ico" @click="hideFilter"></div>
      </div>
      <div class="filter_btn_scroll" id="teleport_filter">
        <slot/>
      </div>
    </div>
    <div class="shadow_over" v-if="isShowMobFilter" @click="hideFilter"></div>
  </div>
</template>

<script>
/*
* filter_yes класс добавляем на контейнер, когда выбраны фильтры
*
*/

export default {
  name: "BtnFilter",
  props: {
  },
  methods: {
    hideFilter(){
      this.isShowMobFilter = false
    },
  },
  data() {
    return {
      isShowMobFilter: false,
      positionRight: false
    }
  },
  watch: {
    isShowMobFilter() {
      if(window.innerWidth <= 500) {
        if (this.isShowMobFilter) {
          document.getElementsByClassName('site_body')[0].classList.add('overflow-hidden');
        } else {
          document.getElementsByClassName('site_body')[0].classList.remove('overflow-hidden');
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if((window.innerWidth - this.$refs.filterBtn.getBoundingClientRect().left) < 370){
        this.positionRight = true;
      }
    });

  }
}
</script>

<style lang="scss">
.filter_btn {
  .btn-filter,
  .filter_btn_tit {
    display: none;
  }
}
@media (max-width: 991px) {
  .filter_btn {
    position: relative;
    .btn-filter {
      display: inline-flex;
    }
    &.show {
      .filter_btn_area {
        display: block;
      }
    }

    &.filter_btn_right {
      .filter_btn_area {
        right: -10px;
        left: auto;

        &:before {
          right: 33px;
          left: auto;
        }
      }
    }

    .filter_btn_area {
      min-width: 370px;
      display: none;
      z-index: 25;
      position: absolute;
      top: 70px;
      left: -10px;
      background: #ffffff;
      color: #000000;
      border-radius: 7px;
      padding: 30px;
      @media (max-width: 500px) {
        min-width: 200px;
      }

      &:before {
        content: "";
        border: 5px solid transparent;
        border-bottom: 5px solid #ffffff;
        position: absolute;
        left: 33px;
        top: -10px;
      }

      .btn {
        min-height: 39px;
        padding: 0 15px;
      }

      .btn-light:not(.active) {
        color: #000000;
        border: 1px solid rgba(0, 0, 0, .2);

        &:hover {
          color: #ffffff;
        }
      }

      .multiselect__tags {
        color: #000000;
        border: 1px solid rgba(0, 0, 0, .2);
        min-height: 39px;
        padding: 5px 15px 5px 15px;
      }

      .multiselect__select {
        width: 30px;

        &:before {
          width: 14px;
          height: 14px;
          margin-bottom: 1px;
          filter: invert(1);
          opacity: .8;
        }
      }

      .multiselect__input, .multiselect__single {
        color: #000000;
      }

      .filter_right {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .filter_btn_tit {
        display: none;
      }
      @media (max-width: 500px) {
        padding: 15px 20px 20px 20px;
        z-index: 9999;
        position: fixed;
        top: auto !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        border-radius: 0;
        &:before {
          display: none;
        }
        .filter_right_tit {
          font-size: 15px;
        }
        .filter_btn_tit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 30px;
        }
        .filter_btn_scroll {
          //overflow-y: auto;
          max-height: calc(100vh - 100px);
          padding-bottom: 1px;
        }
      }
    }
    @media (max-width: 500px) {
      .filter_btn_area {
        display: block;
        transform: translateY(100%);
        transition: transform .3s ease, z-index .3s ease;
        z-index: -1;
      }
      &.show {
        .filter_btn_area {
          transform: translateY(0);
          z-index: 9999;
        }
      }
      .shadow_over {
        display: block;
        z-index: 999;
      }
    }
  }
}
</style>