export default {
    mounted(el, binding) {
        const options = {
            rootMargin: '0px 0px -20% 0px',
            threshold: 0.2
        }
        const callback = (entries, observer) => {
          if(entries[0].isIntersecting ){
              binding.value(el);
          }
        };
        const observer = new IntersectionObserver(callback, options);
        observer.observe(el)
    },
    name: 'intersection-callback'
}