<template>

  <arenda-kinopomecheniy-top-filter v-if="this.topFilter" @searchFun="searchFun" :deleteParamsStatus="deleteParamsStatus"/>

  <div class="block_top rent_list_top">
    <container>
<!--      :style="[ getBkgrImage(dataInfo.image, 'fullHd') ]"-->
<!--      <h1 class="hasSubtit" v-if="dataInfo.title">{{ dataInfo.title.value }}</h1>-->
<!--      </div>-->
      <div class="fs3">Все объекты: <span class="color-or">{{ data?.total }}</span></div>
    </container>
  </div>


  <container>
    <div class="rent_list">
      <loader
          v-if="isLoading"
          :animation-duration="3000"
          :size="60"
      ></loader>
      <not-info
          v-if="!isLoading && dataList?.length == 0"
          :title="!Object.keys(getParams).length ? `Здесь пока ничего нет` : `Ничего не нашлось`"
          :text="!Object.keys(getParams).length ? `Нет результатов` : `Нет результатов, удовлетворяющих критериям поиска. Попробуйте сбросить фильтры`"
      >
        <template v-slot:btn v-if="Object.keys(getParams).length">
          <div class="div_btn">
            <cl-button @click="deleteParamsStatus = true;">Сбросить фильтры</cl-button>
          </div>
        </template>
      </not-info>
      <row v-if="!isLoading && dataList?.length > 0">
        <template :key='item.guid' v-for='(item, index) in dataList'>
          <column md="6" lg="4" xl="3">
            <card-rent2-pavilony :item="item" :routeLink="'?guid=' + item.guid"/>
          </column>
          <column md="12" v-if="index == 7 || (dataList.length < 7 && index + 1 == dataList.length)">
            <div class="not_location" v-if="measureGuid">
              <h4 class="div_tit">Не нашли требуемую локацию для съемок?</h4>
              <div class="div_text">Подайте заявку в Кинокомиссию Москвы и получите доступ к новым объектам</div>
              <div class="div_btn">
                <cl-button v-if="getIsAuthorization && (!(measureGuid == 'ea91d047b20f4dcf9655aede4e1e5808' || measureGuid == '6f0b7e20239342a28def73088d508dfd' || measureGuid == 'a3a7da300a1e4de19909cd30cdb04cdb') || this.getCompany)" @click="formShow = !formShow">Подать заявку</cl-button>
                <Popper
                    v-else-if="getIsAuthorization"
                    class="light"
                    arrow
                    hover
                >
                  <cl-button class="btn-disabled">Подать заявку</cl-button>
                  <template #content>
                    Заявка доступна только юридическим лицам
                  </template>
                </Popper>
                <cl-button v-else :link="true" href="/connect/mosru">Подать заявку</cl-button>
              </div>
              <cl-modal v-model="formShow" class="modal_form" :closeFonActive="false">
                <h4 class="modal-header" v-if="formTitle">
                  {{ formTitle }}
                </h4>
                <measure-form-component
                    v-if="formShowParams"
                    :measureGuid="measureGuid"
                    :showMode="showMode"
                    :formParams="measureFormParams"
                    :form-type="1"
                    @formTitle="formTitleFun"
                />
              </cl-modal>
            </div>
          </column>
        </template>
      </row>
      <div v-if="(data?.page < data?.pages || isLoadingMore) && !isLoading" class="show_more">
        <loader
            v-if="isLoadingMore"
            :animation-duration="1500"
            :size="55"
            :color="'#ED850A'"
        ></loader>
        <cl-button v-else @click="loadMore" type="light sm-w-100">Показать еще</cl-button>
      </div>
    </div>


  </container>

</template>

<script>

import ArendaKinopomecheniyTopFilter from "@/components/arendaKinopomecheniyTopFilter.vue";
import MeasureFormComponent from "@/components/measureForm.vue";
import CardRent2Pavilony from "@/components/cardRent2Pavilony.vue";
import {mapGetters} from "vuex";

export default {
  name: "rentList",
  components: {CardRent2Pavilony, MeasureFormComponent, ArendaKinopomecheniyTopFilter},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingMore: false,
      topFilter: true,
      data: false,
      dataList: false,

      getParams: {},

      controller: null,

      deleteParamsStatus: false,

      formTitle: null,
      formShow: false,
      formShowParams: true,
      showMode: 'inline',
      measureFormParams: {
        preSetParams: {},
        //'appendCss': '/override/modal_wh.css',
      },
      measureGuid: this.dataInfo.formNew?.value,

    }
  },
  methods: {
    searchFun(params){
      this.getParams = params;
      this.getRentItem();
      this.deleteParamsStatus = false;
      if (Object.keys(params).length > 0) {
        history.pushState({}, "", "?" + new URLSearchParams(params));
      }
    },
    getRentItem(isLoadMore) {
      if (!isLoadMore) {
        delete this.getParams.page;
        this.isLoading = true;
      }
      return this.axios
          .get(`/api/data/all-platforms`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            if (isLoadMore) {
              this.dataList = [...this.dataList, ...response.data.records];
            } else {
              this.dataList = response.data.records;
            }
            this.data = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    loadMore() {
      this.isLoadingMore = true;
      this.getParams.page = ++this.data.page;
      this.$nextTick(() => {
        this.getRentItem(true).then(() => {
          this.isLoadingMore = false
        })
      });
    },
    //возврашаем заголовок формы
    formTitleFun(name) {
      this.formTitle = name;
    },
    // получение должности и передача в форму
    getUserPosition(){
      if(this.getUser){
        return this.axios
            .get(`/api/get/trustee/persons/${this.getUser.iasGuid}`, {signal: this.controller.signal})
            .then(response => {
              if(response.data.position){
                this.formShowParams = false;
                this.measureFormParams.preSetParams.position = response.data.position;
                this.$nextTick(() => {
                  this.formShowParams = true;
                });
              }

            })
            .catch(error => {
              this.showError(error);
            })
            .finally(() => {
            });
      }
    }
  },
  watch: {
    getUser(){
      this.getUserPosition();
    }
  },
  created() {
    this.controller = new AbortController();
  },
  computed: {
    ...mapGetters([
      'getIsAuthorization',
      'getCompany',
      'widthSite',
      'getUser',
    ])
  },
  mounted() {
    document.querySelector('body').classList.add('fon1');

    if (Object.keys(this.$route.query).length == 0) {
      this.getRentItem();
    }
    this.getUserPosition();
  },
  beforeUnmount() {
    document.querySelector('body').classList.remove('fon1');
    this.controller.abort();
    this.controller = null;
  },
}
</script>

<style lang="scss" scoped>

.rent_list_top {
  padding: 240px 0 24px 0;

  @media (max-width: 767px) {
    padding-top: 240px;
  }
  //h1 {
  //  margin-bottom: 43px;
  //}
}
.rent_list {

}

.div-line1{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.not_location {
  border-radius: 12px;
  border: 1px solid #ED850A;
  padding: 40px 40px 40px calc(25% + 20px);
  background: url(@/assets/img/not_location_img_left.png) no-repeat left top, url(@/assets/img/not_location_img_right.png) no-repeat right top;
  background-size: auto 100%;
  .div_tit {
    margin-bottom: 8px;
  }
  .div_text {
    margin-bottom: 12px;
  }
  @media (max-width: 1024px) {
    .div_tit {
      font-size: 24px;
      font-weight: 500;
    }
    padding-left: 333px;
    background: url(@/assets/img/not_location_img_left.png) no-repeat left top;
    background-size: auto 100%;
  }
  @media (max-width: 991px) {
    padding: 13px 13px 13px 337px;
    background-position: -60px top;
  }
  @media (max-width: 767px) {
    padding: 24px 24px 130px 24px;
    text-align: center;
    background: url(@/assets/img/not_location_img_mob.png) no-repeat center bottom;
    background-size: 100% auto;
    .div_tit {
      font-size: 18px;
    }
    .div_text {
      font-size: 14px;
    }
  }
}
</style>