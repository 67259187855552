<template>
  <teleport to="#site_header_td2">
    <nav v-if="dataInfo && dataInfo.listLink && dataInfo.listLink.length > 0" class="menu_top fs21">
      <a
          v-for="item in dataInfo.listLink"
          @click="item.anchor ? $router.push({hash: item.anchor}) : $router.push({path: item.link?.value})"
          :class="{'router-link-exact-active': $route.path == item.link?.value || $route.path == item.anchor}">
        {{ item.name?.value }}
      </a>
    </nav>
  </teleport>
</template>

<script>
export default {
  name: "headerMenu",
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
}
</script>

<style lang="scss" scoped>
.menu_top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 48px;
}
</style>