<template>
  <template v-if="!view">
    <div class="search_pole">
      <!-- ошибка v-model : v-model="modelValue" :value="modelValue" -->
      <base-input
          :id="id"
          :placeholder="placeholder"

          :modelValue="modelValue"
          @update:modelValue="modelValue = $event"

          @keyup.enter="updateInput"
      />
      <span @click="updateInput" :class="{'d-none': !(!close || close && modelValue == '')}" class="search_pole_btn"><img src="@/assets/img/st/search_ico.svg" alt=""></span>
      <span v-if="close && modelValue != ''" @click="modelValue = ''; updateInput()" class="search_pole_btn img_wh"><img src="/img/ico/close.svg" alt=""></span>
    </div>
  </template>
  <template v-else-if="view == 'top'">
    <div class="search_pole_top" :class="{'active': showInput}" v-click-away="showInputAway">
      <base-input
          :id="id"
          :placeholder="placeholder"
          :modelValue="modelValue"
          @update:modelValue="modelValue = $event"
          @keyup.enter="updateInput"
      />
      <cl-button type="middle" :class="{'btn-default': showInput, 'btn-light3': !showInput}" class="btn-ico-right" img="/img/ico/search.svg"
                 @click="showInput || widthSite <= 991 ? updateInput() : showInputFun()">Поиск</cl-button>
    </div>
  </template>
</template>

<script>
/*
* view - какой вид показывать
* id - id поля
* modelValue - значение поля поиска
* placeholder - подсказка поля поиска
* */
import ClButton from "@/components/library/ClButton.vue";
import {mapGetters} from "vuex";

export default {
  name: 'InputSearch',
  components: {ClButton},
  props: {
    id: {
      type: String,
      default: "",
    },
    view: {
      type: String,
    },
    modelValue: [String, Number],
    placeholder: [String, Number],
    close: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showInput: this.modelValue == "" ? false : true,
    }
  },
  methods: {
    updateInput(){
      this.$emit('update:modelValue', this.modelValue);
      this.$emit('search', this.modelValue)
    },
    showInputFun(){
      this.showInput = !this.showInput;
      document.getElementById('topInputFilter').focus();
      this.$emit('showInputFun', this.showInput)
    },
    showInputAway(){
      if(this.modelValue == ""){
        this.showInput = false;
        this.$emit('showInputFun', this.showInput)
      }
    }
  },
  watch:{
    modelValue(){
      if(this.modelValue == "" && document.getElementById('topInputFilter') != document.activeElement){
        this.showInput = false;
        this.$emit('showInputFun', this.showInput)
      }
    }
  },
  mounted() {
    if(this.modelValue != "" && this.view == 'top'){
      this.$emit('showInputFun', this.showInput)
    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  },
}
</script>

<style lang="scss">
  .search_pole {
    position: relative;
  }
  .search_pole_h {
    .form-control {
      height: 56px;
    }
  }
  .search_pole_btn {
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    padding-right: 17px;
    display: inline-flex;
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
  }

  .search_pole_top {
    position: relative;
    width: 135px;
    &:not(.active) .form-control::placeholder {
      opacity: 0;
    }
    .form-control {
      width: 100%;
      border-radius: 6px;
      border: 1px solid #FFF;
      background: none;
      height: 48px;
      font-size: 18px;
      padding-left: 16px;
    }
    .btn-light3 {
      border: 1px solid #FFF;
    }
    .btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 135px;
    }
    &.active {
      width: 600px;
      .btn {
        right: 0;
        top: 0;
        border-radius: 0 6px 6px 0;
      }
    }
    @media (max-width: 1200px) {
      &.active {
        width: 400px;
      }
    }
    @media (max-width: 991px) {
      width: 100%;
      &.active {
        width: 100%;
      }
      &:not(.active) .form-control::placeholder {
        opacity: 1;
      }
      .btn {
        width: 48px;
        border-color: rgba(0, 0, 0, 0) !important;
        padding: 2px;
        background: #000106 !important;
        box-shadow: none !important;
        background-clip: padding-box !important;
        .text {
          display: none;
        }
        .sp_img {
          margin: 0;
        }
      }
    }
  }
</style>