<template>
  <div class="col" :class="[adaptiveClassNames.classSm, adaptiveClassNames.classMd, adaptiveClassNames.classLg, adaptiveClassNames.classXl]">
    <slot />
  </div>
</template>

<script>
/**
 * Компонент колонок для флекс-гридов
 * Пропсами принимают количество блоков в строке на определенном разрешении
 * xl: десктоп и планшеты от 1280px, значения от 1 до 12
 * lg: десктоп и планшеты от 992px, значения от 1 до 12
 * md: планшеты от 768 до 990, значения от 1 до 12
 * sm: телефоны от 320, значения от 1 до 12
 */
export default {
  name: "Column",
  props: ["xl", "lg", "md", "sm"],
  data() {
    return {
      adaptiveClassNames: {
        classXl: this.xl ? `col-xl-${this.xl}` : null,
        classLg: this.lg ? `col-lg-${this.lg}` : null,
        classMd: this.md ? `col-md-${this.md}` : null,
        classSm: this.sm ? `col-sm-${this.sm}` : null,
      }
    }
  }
}
</script>

<style lang="scss">
.col {
  max-width: 100%;
}
@media (min-width: 320px) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      $value: 100% / 12 * $i;
      flex: 0 0 $value;
      max-width: $value;
    }
  }
  .col-sm-auto {
    flex: auto;
    width: 100% !important;
  }
  .col-sm-fl {
    flex: 1;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    flex: auto;
    width: 100% !important;
  }
  .col-md-fl {
    flex: 1;
  }
  .col-md {
    max-width: 100%;
    flex: auto;
  }
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      $value: 100% / 12 * $i;
      flex: 0 0 $value;
      max-width: $value;
    }
  }
}
@media (min-width: 992px) {
  .col-lg-auto {
    flex: auto;
    width: 100% !important;
  }
  .col-lg-fl {
    flex: 1;
  }
  .col-lg {
    max-width: 100%;
    flex: auto;
  }
  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      $value: 100% / 12 * $i;
      flex: 0 0 $value;
      max-width: $value;
    }
  }
}
@media (min-width: 1200px) {
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-xl-fl {
      flex: 1;
    }
    @for $i from 1 through 12 {
        .col-xl-#{$i} {
            $value: 100% / 12 * $i;
            flex: 0 0 $value;
            max-width: $value;
        }
    }
}
</style>