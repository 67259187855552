<template>
  <div class="block_wrap block_top renting_costumes">
    <container>
      <h1 v-if="dataInfo.title.value">{{ dataInfo.title.value }}</h1>
      <div class="div_text fs2" v-if="dataInfo.subTitle" v-html="dataInfo.subTitle.value"></div>

      <row v-if="dataInfo.listCards && dataInfo.listCards.length > 0">
        <column md="4"
                v-for="(item, index) in dataInfo.listCards"
                :key="item.text?.value"
        >
          <div class="block_number_text">
            <div class="dt dt1 fs2">{{ index + 1 }}</div>
            <div class="dt dt2 fs4n" v-if="item.text" v-html="item.text.value"></div>
          </div>
        </column>
      </row>
    </container>
  </div>
</template>

<script>
export default {
  name: "bannerRecv",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.renting_costumes {
  padding: 24.6rem 0 7.6rem 0;
  h1 {
    margin-bottom: 0.8rem;
  }
  .div_text {
    margin-bottom: 3.2rem;
    text-transform: uppercase;
  }
  @media (max-width: 1440px) {
    padding: 223px 0 50px;
  }
  @media (max-width: 991px) {
    padding: 180px 0 50px;
  }
  @media (max-width: 767px) {
    padding: 18rem 0 4rem;
    h1{
      margin-bottom: 8px;
    }
    .div_text{
      margin-bottom: 3.2rem;
    }
  }
  .block_number_text{
    background: transparent;
    border: 1px solid #ED850A;
    border-radius: 1.2rem;
    flex-direction: column;
    align-items: start;
    gap: 2.4rem;
    .dt1{
      width: 6.4rem;
      height: 6.4rem;
      border-radius: 50%;
      border: 1px solid #17191C;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media (max-width: 991px) {
        width: 4.8rem;
        height: 4.8rem;
      }
    }
  }
}
</style>