<template>
    <container>
        <div class="film_school_adventages">
            <h2 v-if="dataInfo.title?.value">{{dataInfo.title.value}}</h2>
            <div class="film_schoolAdv_desc" v-if="dataInfo.desc?.value" v-html="dataInfo.desc?.value"></div>
            <cl-button type="light" v-if="dataInfo.nameBtn?.value" class="btn-middle btn-width-mob-full" @click="showForm = !showForm">{{ dataInfo.nameBtn?.value }}</cl-button>
            <div class="filmschool_advantages_list">
                <row v-if="widthSite > 991">
                    <column md="3" v-if="dataInfo.imageMain" class="card_main">
                        <img :src="getImage(dataInfo.imageMain)" alt="">
                    </column>
                    <column v-for="item in dataInfo.list" class="flex-1">
                        <div class="card_filmAdv">
                            <div class="card_ico" v-if="item.icon">
                                <img :src="getImage(item.icon)" alt="">
                            </div>
                            <div class="card_tit" v-if="item.title?.value" v-html="item.title.value"></div>
                            <div class="card_desc" v-if="item.desc?.value" v-html="item.desc.value"></div>
                        </div>
                    </column>
                </row>
                <swiper
                    v-else
                    :spaceBetween="24"
                    :breakpoints="{
                      '992': {
                        slidesPerView: 4
                      },
                      '768': {
                        slidesPerView: 2.7
                      },
                      '320': {
                        spaceBetween: 16,
                        slidesPerView: 1.5
                      }
                    }"
                >
                  <swiper-slide v-for="item in dataInfo.list" :key="item" class="swiper-slide-adv">
                    <div class="card_filmAdv">
                      <div class="card_ico" v-if="item.icon">
                        <img :src="getImage(item.icon)" alt="">
                      </div>
                      <div class="card_tit" v-if="item.title?.value" v-html="item.title.value"></div>
                      <div class="card_desc" v-if="item.desc?.value" v-html="item.desc.value"></div>
                    </div>
                  </swiper-slide>
                </swiper>
            </div>
        </div>
    </container>
    <cl-modal v-model="showForm" :closeFonActive="false" class="modal_form">
        <h4 class="modal-header" v-if="formTitle">
            {{ formTitle }}
        </h4>
        <measure-form-component
            :form-type="1"
            @formTitle="formTitleFun"
            :measureGuid="dataInfo.guid?.value"
            :showMode="showMode"
            :formParams="formParams"
        />
    </cl-modal>
</template>

<script>
import ClButton from "@/components/library/ClButton.vue";
import Column from "@/components/library/Column.vue";
import Container from "@/components/library/Container.vue";
import ClModal from "@/components/library/ClModal.vue";
import MeasureFormComponent from "@/components/measureForm.vue";
import {mapGetters} from "vuex";
import {Swiper, SwiperSlide} from "swiper/vue";

export default {
    name: "filmSchoolAdvantages",
    components: {MeasureFormComponent, ClModal, Container, Column, ClButton, SwiperSlide, Swiper},
    props: {
        dataInfo: {
            type: Object,
            required: true,
        }
    },
    data(){
        return{
            showForm: false,
            formTitle: null,
            showMode: 'inline',
            formParams: {
                'appendCss': '/override/measure.css',
            },
        }
    },
    methods: {
        formTitleFun(name) {
            this.formTitle = name;
        },
    },
    computed: {
      ...mapGetters([
        'widthSite'
      ])
    },
};
</script>

<style lang="scss" scoped>
.film_school_adventages{
    padding: 5.8rem 0;
    h2{
        margin-bottom: 1.2rem;
    }
    .film_schoolAdv_desc{
        margin-bottom: 2.4rem;
        @media (max-width: 767px) {
          font-size: 16px;
        }
    }
    .filmschool_advantages_list{
        margin-top: 6.4rem;
    }
    .card_main{
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 1.9rem;
        }
    }
    .card_filmAdv{
        height: 100%;
        padding: 3.2rem 1.6rem;
        border-radius: 1.2rem;
        border: 1px solid #ED850A;
        background-color: #000106;
        display: flex;
        flex-direction: column;
        .card_ico{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 50%;
            border: 1px solid #17191C;
            margin-bottom: 0.8rem;
            img{
                width: 3.2rem;
                height: 3.2rem;
                object-fit: contain;
            }
        }
        .card_tit{
            font-size: 2.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: 133.333%;
            letter-spacing: 0.048rem;
            margin-bottom: 2.4rem;
        }
        .card_desc{
            flex-grow: 1;
            display: flex;
            align-items: end;
        }
        @media (max-width: 767px) {
          .card_tit {
            font-size: 21px;
          }
          .card_desc{
            font-size: 16px;
          }
        }
    }
}
.swiper-slide-adv{
  height: unset;
}
</style>