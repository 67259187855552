<template>
  <div class="block_re_and_rul">
    <container class="container3">
      <div class="re_and_rul">
        <h3 class="h3-title">Правила и&nbspограничения</h3>
        <div class="table_elem" v-if="dataInfo.rulesList">
          <template v-for="(item, index) in dataInfo.rulesList">
            <div class="rar_elem">
              <div class="title" v-if="item.ruleName" v-html="item.ruleName.value">
              </div>
              <div class="text" v-if="item.ruleDescription">
                {{ item.ruleDescription.value }}
              </div>
            </div>
            <template v-if="(index + 1 == 6) || ((index < 6) && (dataInfo.rulesList.length == (index + 1))) ">
              <div class="rar_elem rar_elem_regdoc">
                <a :href="dataInfo?.reglamentDoc.regDoc.value.src" download>
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.6" y="0.6" width="46.8" height="46.8" rx="23.4" stroke="white" stroke-width="1.2"/>
                    <path class="arrow" d="M25.1996 15.6H32.3996M32.3996 15.6V22.8M32.3996 15.6L15.5996 32.4" stroke="#ED850A" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <div v-if="dataInfo.reglamentDoc.regText">
                    {{ dataInfo.reglamentDoc.regText.value }}
                  </div>
                </a>
              </div>
            </template>
          </template>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
export default {
  name: "rulesAndRestr",
  data(){
    return {}
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.h3-title{
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
}
.block_re_and_rul{
  margin: 4rem 0;
  .container{
    max-width: 1440px;
  }
  @media(max-width: 1440px){
    .container{
      padding: 0;
    }
  }
  @media(max-width: 991px){
    .container{
      padding: 0 4rem;
    }
  }
  @media(max-width: 767px){
    .container{
      padding: 0;
    }
  }
}
.re_and_rul{
  background: #0C0C0E;
  border-radius: 4rem;
  padding: 12rem;
  .table_elem{
    border-radius: 3.2em;
    display: flex;
    flex-wrap: wrap;
  }
  .rar_elem{
    width: 33.333%;
    padding:  5.2rem 2.4rem;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      top: -1px;
      left: -1px;
      border: 2px solid white;
    }
    a, .title, .text{
      z-index: 10;
      position: relative;
    }
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 12px;
      font-size: 24px;
    }
    .title{
      font-size: 24px;
      line-height: 150%;
      margin-bottom: 8px;
    }
    .text{
      font-size: 18px;
      color: #6B7280;
    }
    &:nth-child(1){
      border-radius: 3.2rem 0 0 0;
      &:before{
         border-radius: 3.2rem 0 0 0;
      }
    }
    &:nth-child(3) {
      border-radius: 0 3.2rem 0 0;
      &:before {
        border-radius: 0 3.2rem 0 0;
      }
    }
    &:nth-last-child(1){
      border-radius: 0 0 3.2rem 0;
      &:before{
        border-radius: 0 0 3.2rem 0;
      }
    }
    &:nth-last-child(3){
      border-radius: 0 0 0  3.2rem;
      &:before{
        border-radius: 0 0 0  3.2rem;
      }
    }
    &.rar_elem_regdoc{
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .4s ease;
      .arrow{
        transition: all .4s ease;
      }
      &:before{
        border-color: #ED850A;
        z-index: 5;
      }
      &:hover{
        background: #ED850A;
        .arrow{
          stroke: black;
        }
      }
    }
  }
  @media(max-width: 1240px){
    padding: 12rem 4rem;
    .rar_elem{
      min-height: 215px;
    }
  }
  @media (max-width: 991px) {
    padding: 60px 12px;
    .rar_elem{
      min-height: 180px;
      width: 100%;
      background: #17191c;
      border-radius: 16px !important;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:before{
        content: unset !important;
      }
      &.rar_elem_regdoc{
        border: 1px solid #ED850A;
        background: unset;
        order: 1000;
      }
    }
    .table_elem{
      flex-direction: column;
      gap: 16px;
    }
  }
  @media(max-width: 767px){
    .rar_elem{
      &:nth-child(1){
        border-radius: 1.6rem;
        &:before{
          border-radius: 1.6rem;
        }
      }
      &:nth-child(3) {
        border-radius: 1.6rem;
        &:before {
          border-radius: 1.6rem;
        }
      }
      &:nth-last-child(1){
        border-radius: 1.6rem;
        &:before{
          border-radius: 1.6rem;
        }
      }
      &:nth-last-child(3){
        border-radius: 1.6rem;
        &:before{
          border-radius: 1.6rem;
        }
      }
    }
  }
}
</style>