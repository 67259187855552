import Container from "@/components/library/Container";
import Row from "@/components/library/Row";
import Column from "@/components/library/Column";
import ClButton from "@/components/library/ClButton";
import Card from "@/components/library/Card";
import ArrowButton from "@/components/library/ArrowButton";
import Hselect from "@/components/library/hselect";
import InputSearch from "@/components/library/InputSearch";
import filterTabs from "@/components/library/FilterTabs";
import ClCollapse from "@/components/library/ClCollapse";
import Loader from "@/components/library/Loader";
import Documents from "@/components/library/Documents";
import BaseInput from "@/components/library/Input";
import ClStatus from "@/components/library/ClStatus";
import ClModal from "@/components/library/ClModal";
import ClCalendar from "@/components/library/Calendar";
import ClCalendarTwo from "@/components/library/CalendarTwo";
import notInfo from "@/components/library/notInfo";
import modalSelect from "@/components/library/modalSelect";
import animatedNumber from "@/components/library/animated-number";
import messageLeft from "@/components/library/message-left";
//import CardMap from "@/components/library/CardMap";
import progressBar from "@/components/library/progressBar";
import filePicker from "@/components/library/filePicker";
import sliderMob from "@/components/library/SliderMob";
import btnFilter from "@/components/library/BtnFilter";
import breadcrumbs from "@/components/library/Breadcrumbs.vue";
import selectAndBtn from "@/components/library/selectAndBtn.vue";
export default [
  Container, Row, Column, ClButton, Card, ArrowButton, Hselect, InputSearch, ClCollapse, Loader, Documents,
  filterTabs, BaseInput, ClStatus, ClModal, ClCalendar, ClCalendarTwo, notInfo, modalSelect, animatedNumber, progressBar, filePicker,
  sliderMob, btnFilter, breadcrumbs, selectAndBtn, messageLeft
]