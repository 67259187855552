<template>
  <div class="rutube-video">
    <container>
      <h2 v-if="dataInfo.title?.value">{{ dataInfo.title.value }}</h2>
      <div class="rutube-content">
        <div class="rutube-loader" v-if="isLoadingVideo">
          <loader
              v-if="isLoadingVideo"
              :animation-duration="3000"
              :size="60"
          ></loader>
        </div>
        <swiper
            v-else
            :spaceBetween="24"
            :navigation="true"
            :threshold='10'
            :modules="modules"
            :loop="widthSite > 992 ? dataInfo.list.length > 4 : dataInfo.list.length > 2"
            :slidesPerView="'auto'"
            class="slide100h arrow_center arrow_center_op"
            :lazy="true"
            :breakpoints="{
                  992: {
                    slidesPerView: 4,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  300: {
                    slidesPerView: 1,
                  }
                }"
        >
          <swiper-slide v-for="item in dataInfo.list"
                        class="cursor-pointer"
                        @click="infoVideo.name = item.name.value; infoVideo.iframe = item.iframe; showModal = !showModal">
            <div class="card-video">
              <div class="card-preview" v-if="item.preview">
                <img :src="item.preview">
              </div>
              <div class="card-content">
                <div class="div_name" v-if="item.name?.value">{{ item.name.value }}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </container>
    <cl-modal modal-type="center" class="modal-rutube" v-model="showModal">
      <!--            <template v-slot:header>-->
      <!--                <div class="div_name" v-if="infoVideo.name">{{ infoVideo.name }}</div>-->
      <!--            </template>-->
      <template v-slot:body>
        <div v-if="infoVideo.iframe" v-html="infoVideo.iframe"></div>
      </template>
    </cl-modal>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";
import Column from "@/components/library/Column.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";

export default {
  name: "video-rutube2",
  components: {
    ClModal,
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true,
    }
  },
  data() {
    return {
      infoVideo: {
        name: null,
        iframe: null,
      },
      showModal: false,
      isLoadingVideo: true,
      langViews: {
        ru: ' тыс. просмотров',
        en: 'K Views'
      }
    }
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      videos: null,
    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  },
  methods: {
    getVideos() {
      this.isLoadingVideo = true;
      if (this.dataInfo.list?.length > 0) {
        let promises = [];
        this.dataInfo.list.forEach(item => {
          if (item.guid?.value) {
            const promis = this.axios.get("/api/data/rutube", {
              params: {
                id: item.guid.value,
              }
            })
                .then(response => {
                  item.preview = response.data.thumbnailUrl;
                  item.iframe = response.data.html;
                  if (item.showViews) {
                    if (response.data.hits > 1000) {
                      item.hits = Math.trunc(response.data.hits / 1000) + this.langViewsFun()
                    } else {
                      item.hits = "";
                    }
                  }
                  promises.push(promis);
                })
          }
        });
        Promise.all(promises)
            .then(() => {
              this.isLoadingVideo = false;
            });
      }
    },
    langViewsFun() {
      let text = this.langViews[this.$route.params.id];
      if (!text) {
        text = this.langViews['ru'];
      }
      return text;
    }
  },
  mounted() {
    this.getVideos();
  }
};
</script>

<style lang="scss" scoped>
.rutube-loader {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rutube-video {
  padding: 4.8rem 0;

  h2 {
    text-transform: uppercase;
    margin-bottom: 1.2rem;
  }

  .rutube-content {
    position: relative;
    min-height: 10rem;
  }

  :deep(.arrow_center) {
    .swiper-button-prev,
    .swiper-button-next {
      top: 39%;
      @media(max-width: 991px) {
        top: 40.5%;
      }
      @media(max-width: 767px) {
        top: 37.5%;
      }
    }
  }

  .card-preview {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    border-radius: 1.6rem;
    overflow: hidden;
    margin-bottom: 1.2rem;
    
    &:after {
      content: "";
      width: 5.6rem;
      height: 5.6rem;
      background: url(../assets/img/ico/play-circle.svg) center center;
      filter: invert(1);
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      opacity: .8;
    }

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .div_name {
    font-size: 2.1rem;
    letter-spacing: 0.01em;
    line-height: 133.333%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 5.6rem;
  }
}

@media(max-width: 991px) {
  .rutube-video {
    .card-video {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .card-preview {
      margin-bottom: .8rem;
      border-radius: .8rem;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .div_name {
        font-size: 1.8rem;
        letter-spacing: 0.036rem;
      }
    }
  }
}

@media (max-width: 767px) {
  .rutube-video {
    .card-video {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 1.6rem;
    }

    .card-preview {
      margin-bottom: .626rem;
    }
  }
}
</style>