<template>
  <div class="full-page-carousel" v-if="dataInfo">
    <div class="car-main">
      <swiper
        @swiper="setSwiper($event, 'mainSwiper')"
        :modules="modulesMain"
        loop
        :thumbs="{ swiper: thumbsSwiper }"
      >
        <swiper-slide v-for="slide of slides" :key="slide">
          <div class="slide-top-content">
            <h2>{{slide.title?.toUpperCase()}}</h2>
            <div v-if="slide.subtitle" class="subtitle">{{slide.subtitle}}</div>
            <div v-if="slide.text" class="text">{{slide.text}}</div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="car-navs">
        <button @click.prevent="mainSwiper.slidePrev()">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M16.114 18.7279L9.75 12.364L16.114 6" stroke="white" stroke-width="2"/>
          </svg>
        </button>
        <div class="car-number" v-if="numeric && mainSwiper?.activeIndex">
          {{ mainSwiper.realIndex + 1 }} / {{ slides.length }}
        </div>
        <div class="car-dots" v-else>
          <span v-for="slide in slides" :key="slide.id" :class="{ 'active': mainSwiper.realIndex === slides.indexOf(slide) }"></span>
        </div>
        <button @click.prevent="mainSwiper.slideNext()">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M9.75 18.7279L16.114 12.364L9.75 6" stroke="white" stroke-width="2"/>
          </svg>
        </button>
      </div>
    </div>
    <div class="car-thumbs-wrap">
      <swiper
          @swiper="setSwiper($event, 'thumbsSwiper')"
          :modules="modulesSecond"
          :effect="'fade'"
          class="car-thumbs"
          :allow-touch-move="false"
          loop
      >
        <swiper-slide v-for="slide of slides" :key="slide">
          <div class="car-thumbs-img">
            <img :src="slide.img?.src?.reference?.url" alt="">
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {EffectFade, Thumbs} from "swiper";
import 'swiper/css/effect-fade';

export default {
  components: {SwiperSlide, Swiper},
  props: {
    dataInfo: {
      type: Object,
      required: true
    },
    numeric:{
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    slides: [],
    thumbsSwiper: null,
    mainSwiper: null
  }),
  mounted() {
    this.slides = this.dataInfo.carouselContent
    this.slides.forEach(item => {
      for (let key in item) {
        item[key] = item[key].value
      }
    })
  },
  methods: {
    setSwiper(swiper, targetVar) {
      this[targetVar] = swiper
      this[targetVar].activeIndex = 1
    },
  },
  setup() {
    return {
      modulesMain: [Thumbs],
      modulesSecond: [EffectFade]
    }
  },
}
</script>

<style lang="scss" scoped>
.full-page-carousel {
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-height: 100dvh;
    height: 100dvh;
    .car-thumbs-wrap {
      order: 1;
      height: 100%;
    }
  }
  .car-main {
    position: absolute;
    top: 0;
    right: 0;
    padding: 24px 48px;
    background-color: #000106;
    width: 714px;
    z-index: 1;
    height: 280px;
    &:before {
      content: "";
      position: absolute;
      border-right: 500px solid #000106;
      border-bottom: 280px solid transparent;
      border-left: 500px solid transparent;
      right: 100%;
      top: 0;
    }
    @media (max-width: 991px) {
      width: 434px;
      padding: 24px;
      &:before {
        border-right-width: 335px;
      }
    }
    @media (max-width: 767px) {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      width: 100vw;
      order: 2;
      padding: 32px 16px 16px 16px;
      height: auto;
    }
  }
  .car-thumbs {
    position: relative;
    z-index: 0;
    pointer-events: none;
    height: 100%;
    * {
      pointer-events: none;
    }
    &-img {
      display: flex;
      align-items: flex-end;
      overflow: hidden;
      max-height: calc(100vh + 100px);
    }
    @media (max-width: 991px) {
      &-img {
        max-height: 300vh;
      }
    }
    @media (max-width: 767px) {
      &-img {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
.slide-top-content {
  h2 {
    margin-bottom: 0;
  }
  .subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    margin-bottom: 24px;
    color: #ACB0B9;
    letter-spacing: 0.36px;
  }
  .text {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: 0.36px;
  }
}
.car-navs {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.car-dots {
  display: flex;
  align-items: center;
  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #ED850A;
    margin: 0 4px;
    &.active {
      background-color: #ED850A;
    }
  }
}

.bottonLeft{
  .car-main{
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 990px;
    height: 296px;
    &:before{
      content: none;
    }
    &:after{
      content: "";
      position: absolute;
      border-left: 276px solid #000106;
      border-top: 296px solid transparent;
      border-right: 500px solid transparent;
      left: 100%;
      bottom: 0;
    }
    .slide-top-content h2{
      font-size: 32px;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.64px;
    }
    .car-number{
      font-size: 40px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: 8px;
    }
    button{
      svg{
        width: 40px;
      }
      svg path{
        stroke: #ED850A;
      }
    }
  }
}
</style>