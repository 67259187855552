const mustache = require("mustache")
const Blitzsc = require("./blitzsc")


/**
 * Класс для создания объекта подписания файлов
 * @param container - элемент, в котором будет выводиться информация в процессе подписания
 * @param file - объект файл или blob, который необходимо подписать
 * @param callbackSuccess - функция, которая вызовется в случае успешного выполнения. Первым параметром получит объект с результатом выполнения
 * @param callbackFail - функция, которая вызовется в случае неудачи
 * @constructor
 */
export const SignPluginBlitz = class {
  processingTemplate =
    '<div id="processing" class="media sign_loader {{#warn}}sign_loader_error{{/warn}}">' +
    '<div class="media-body"><div class="loader_all"><svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#c8c8c8"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18" stroke="#16FF00"><animateTransform attributeName="transform"  type="rotate"  from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg><div class="loader-progress"></div></div>' +
    '<div class="media-text"><h4 class="media-heading {{#warn}}text-danger{{/warn}}">{{{ title }}}</h4>' +
    '<p>{{{ msg }}}</p></div>' +
    '</div>' +
    '</div>'
  ;

  errorTemplate =
    '<p class="text-danger">{{{ error }}}</p> {{#msg}}<p>{{{ msg }}}</p>{{/msg}}'
  ;

  certsTemplate =
    '<div class="sign_tit2">Нажмите на сертификат ключа проверки электронной подписи</div>' +
    '<div class="list-group sign_list_group">' +
    '{{#certs}}' +
    '<a id="{{ id }}" href="#" class="sign_company list-group-item {{#expired}}list-group-item-danger disabled{{/expired}} {{^expired}}list-group-item-success{{/expired}}">' +
    '<h4 class="list-group-item-heading">{{ subject.CN }}</h4>' +
    '{{#subject.emailAddress}}<div class="sign_company_text1">{{ subject.emailAddress }}</div>{{/subject.emailAddress}}' +
    '<div class="sign_company_text2"><span class="sct_sp1">Кем выдан:</span><span class="sct_sp2">{{ issuer.CN }}</span></div>' +
    '{{#expired}}<div class="text-warning">Просрочен</div>{{/expired}}' +
    '</a>' +
    '{{/certs}}' +
    '</div>' +
    '<div class="sign_refresh_div">' +
    '<div id="refresh" class="btn_icons btn_icons3">' +
    '<span>Обновить</span><i class="obj_icon"></i>' +
    '</div>' +
    '</div>'

  ;

  blitzscMsgs = ((u) => {
    let ms = {
      "blitzsc.err.signFailed": "Произошла ошибка при попытке подписать данные на выбранном сертификате.",
      "blitzsc.err.initPKCS11": "Ошибка инициализации модуля работы с токенами. Обратитесь в техническую поддержку. Ошибка: {0}.",
      "blitzsc.err.chrome_extension_not_available": "blitzsc.err.chrome_extension_not_available",
      "blitzsc.err.canceledByUser": "Операция отменена.",
      "blitzsc.status.waitingActivationPlugin.msg": "Ожидание активации плагина.",
      "blitzsc.status.sending.title": "Обработка электронной подписи.",
      "blitzsc.err.plugin_not_available": "blitzsc.err.plugin_not_available",
      "blitzsc.status.waitingExtension.title": "Для работы со средством электронной подписи активируйте или установите <a href=\"{0}\" target=\"_blank\" style=\"color:#337ab7\">расширение для Chrome<\/a>. Примечание: если демо-страница открыта с файловой системы, то убедитесь, что у расширения 'Blitz plugin adapter' стоит галочка 'Разрешить открывать файлы по ссылкам' ('Allow access to file URLs').",
      "blitzsc.status.waitingFirefoxExtension.title": "Для работы со средством электронной подписи активируйте или установите <a href=\"{0}\" onclick=\"return installFirefoxExtention(event);\" style=\"color:#337ab7\">расширение для Firefox<\/a>.",
      "blitzsc.status.interaction.title": "Пожалуйста, подождите",
      "blitzsc.err.getCertsForSign": "Ошибка получения списка сертификатов. Обратитесь в техническую поддержку. Ошибка: {0}.",
      "blitzsc.msg.again": "Повторить",
      "blitzsc.err.plugin_not_allowed": "blitzsc.err.plugin_not_allowed",
      "blitzsc.status.waitingPlugin.title": "Плагин не установлен или используется старая версия плагина. Для работы со средством электронной подписи установите актуальную версию плагина: <a href=\"https://login.mos.ru/public/plugin.html\" style=\"color:#337ab7\" target='_blank'>Скачать<\/a>.",
      "blitzsc.err.common": "Произошла ошибка при обращении к плагину.",
      "blitzsc.err.plugin_old_version": "У Вас установлена старая версия плагина. Пожалуйста, установите новую версию плагина: <a href=\"https://login.mos.ru/public/plugin.html\" style=\"color:#337ab7\">Скачать</a>.",
      "blitzsc.status.waitingExtension.msg": "Ожидание установки расширения.",
      "blitzsc.err.noModules": "В системе не установлено ни одного модуля для работы со средством электронной подписи или они работают некорректно.",
      "blitzsc.status.waitingCerts.msg": "Поиск сертификатов на подключенных средствах электронной подписи.",
      "blitzsc.status.waitingActivationPlugin.title": "Плагин заблокирован, пожалуйста, активируйте плагин.",
      "blitzsc.status.waitingCerts.title": "Подсоедините средство электронной подписи к компьютеру",
      "blitzsc.err.plugin_not_valid": "Плагин работает некорректно. Переустановите плагин или обратитесь в техническую поддержку.",
      "blitzsc.err.noCertsForSign": "Подсоедините средство электронной подписи к комьютеру.",
      "blitzsc.status.interaction.msg": "Идет обращение к средству электронной подписи...",
      "blitzsc.status.waitingPlugin.msg": "Ожидание установки плагина.",
      "blitzsc.err.attemptsIsExceeded": "Убедитесь, что вы выбрали корректный сертификат. Будьте внимательны, большое количество неуспешных попыток ввода пин-кода может привести к блокировке средства электронной подписи.",
      "blitzsc.err.cryptokiUsageConflict": "Невозможно получить доступ к средству электронной подписи. Вероятно, оно используется другим приложением. Проверьте настройки криптопровайдера (например, ViPNet CSP) или обратитесь в службу технической поддержки.",
    };
    return function(k) {
      let m;
      if (typeof k == "object") {
        for (let i = 0; i < k.length && ms[k[i]] !== u; i++) {
          m = ms[k[i]] || k[0]
        }
      } else {
        m = ((ms[k] !== u) ? ms[k] : k)
      }
      for (let i = 1; i < arguments.length; i++) {
        m = m.replace('{' + (i - 1) + '}', arguments[i])
      }
      return m;
    }
  })();


  constructor(container, file, callbackSuccess, callbackFail) {
    this.container = container;
    this.file = file;
    this.callbackSuccess = callbackSuccess;
    this.callbackFail = callbackFail;
  }


  init() {
    if (typeof mustache !== 'object') {
      console.error('Необходимо инициализировать mustache');
      if (typeof this.callbackFail === 'function') {
        this.callbackFail();
      }
      return;
    }

    if (typeof Blitzsc !== 'object') {
      console.error('Необходимо инициализировать Blitzsc');
      if (typeof this.callbackFail === 'function') {
        this.callbackFail();
      }
      return;
    }

    mustache.parse(this.processingTemplate);
    mustache.parse(this.errorTemplate);
    mustache.parse(this.certsTemplate);

    this.pFactory = Blitzsc.createFactory(
      "Blitz Smart Card Plugin",
      "application/x-blitz-sc-plugin",
      "ru.reaxoft.firewyrmhost",
      "1.6.0.0"
    );
    this.showProcessing(
      this.blitzscMsgs("blitzsc.status.interaction.title"),
      this.blitzscMsgs("blitzsc.status.interaction.msg"),
      false
    );
    this.pFactory(
      this.successFactory.bind(this),
      this.failFactory.bind(this)
    );
  }

  showProcessing (title, msg, warn) {
    let m = {
      'title': title,
      'msg': msg
    };
    if (warn) {
      m.warn = warn;
    }
    this.container.innerHTML = mustache.render(this.processingTemplate, m);
  }

  retain (obj) {
    if (typeof (obj.retain) == "function") {
      obj.retain();
    }
  }

  extractErrorMessage (err) {
    let res;
    switch (typeof err) {
      case "string":
        res = err;
        break;
      case "object":
        if (err && err.message) {
          res = err.message;
        } else {
          res = "unknown";
        }
        break;
      default :
        res = "unknown";
    }
    return res;
  }

  showError (err, msg) {
    let errObj = {
      'error': err,
      'msg': msg
    };

    this.container.innerHTML = mustache.render(this.errorTemplate, errObj);

    if (typeof this.callbackFail === 'function') {
      this.callbackFail(errObj);
    }
  }

  showCommonError (err) {
    this.showError(this.blitzscMsgs("blitzsc.err.common"));
    console.error(err);
  }

  sign (p11, certsInfo, certNum) {
    const errorHandler = (err) => {
      /*function bindAgain() {
          var $btn = _self.container.find("#again");
          $btn.off('click');
          $btn.on('click', function(event){
              event.preventDefault();
              _self._successFactory().bind(this);
          });
      }*/
      //console.log(err);
      let msg = this.extractErrorMessage(err);
      let code = msg.split(':')[0];
      // var againHtml = "<button id='again' type='button' class='btn btn-primary'><span class='glyphicon glyphicon-repeat' aria-hidden='true'></span> " + _self.blitzscMsgs("blitzsc.msg.again") + "</button>";
      let againHtml = "";
      let msgKey = null;
      switch(code) {
        case "1":
          msgKey = "blitzsc.err.canceledByUser";
          break;
        case "290":
          msgKey = "blitzsc.err.cryptokiUsageConflict";
          break;
        default:
          msgKey = "blitzsc.err.signFailed";
      }
      this.showError(this.blitzscMsgs(msgKey), againHtml);
      // bindAgain();
      console.error(msg);
    };
    this.showProcessing(this.blitzscMsgs("blitzsc.status.interaction.title"), this.blitzscMsgs("blitzsc.status.interaction.msg"));
    signFile();

    let successCmsMass = (signes) => {
      this.container.innerHTML = "";
      if (typeof this.callbackSuccess === 'function') {
        this.callbackSuccess({
          signatures: signes,
          sertData: certsInfo[certNum],
          files: this.file
        });
      }
      window.signer.free();
    }

    let successCms = (signature) => {
      //console.log(signature);
      this.container.innerHTML = "";
      if (typeof this.callbackSuccess === 'function') {
        this.callbackSuccess({
          signature: signature,
          sertData: certsInfo[certNum],
          file: this.file
        });
      }
    };

    let readFileByChunk = (file, cbToRead, cbToFinish) => {
      let fileSize   = file.size;
      let chunkSize  = 1024*1024; // bytes
      let offset     = 0;
      var chunkReaderBlock = null;
      let readEventHandler = (evt) => {
        if (evt.target.error == null) {
          let next = () => {
            offset += evt.target.result.byteLength;
            if (offset >= fileSize) {
              cbToFinish();
              return;
            }
            chunkReaderBlock(offset, chunkSize, file);
          };
          cbToRead(evt.target.result, next, offset, fileSize);
        } else {
          console.error("Read error: " + evt.target.error);
          this.showError("Ошибка чтения файла: " + evt.target.error);
        }
      };

      chunkReaderBlock = (_offset, _chunkSize, _file) => {
        let r = new FileReader();
        let blob;

        if (_file.slice) {
          blob = _file.slice(_offset, _chunkSize + _offset);
        } else if (_file.webkitSlice) {
          blob = _file.webkitSlice(_offset, _chunkSize + _offset);
        } else if (_file.mozSlice) {
          blob = _file.mozSlice(_offset, _chunkSize + _offset);
        }
        r.onload = readEventHandler;
        r.readAsArrayBuffer(blob);
      };

      chunkReaderBlock(offset, chunkSize, file);
    };

    /**
     * @return {string}
     */
    let Uint8ToString = (u8a) => {
      let CHUNK_SZ = 0x8000;
      let c = [];
      for (let i=0; i < u8a.length; i+=CHUNK_SZ) {
        c.push(String.fromCharCode.apply(null, u8a.subarray(i, i+CHUNK_SZ)));
      }
      return c.join("");
    };

    let _self = this;

    function createSigner() {
      if (!window.hasOwnProperty('lastUsedCertNum') || window.lastUsedCertNum === false || window.lastUsedCertNum === null || !window.hasOwnProperty('signer') || !window.signer)
      {
        certsInfo[certNum].cert.start_signing(false, 3)
          .then((signer) => {
            window.lastUsedCertNum = certNum;
            _self.retain(signer);
            window.signer = signer;
          });
      }
    }
    function downloadFileByLink (url, successCallback, failCallback) {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = "blob";
      xhr.onreadystatechange = function () {
        if (xhr.readyState < 4){
          return;
        }
        if(xhr.status !== 200){
          if (typeof failCallback === 'function') {
            failCallback(xhr.response);
          } else {
            alert('Ошибка получения файла')
          }
        }
        if (xhr.readyState === 4) {
          if (typeof successCallback === 'function') {
            successCallback(xhr.response);
            xhr = null;
          }
        }
      };
      xhr.send(null);
    }

    function signFile(){
      certsInfo[certNum].cert.start_signing(false, 3)
        .then((signer) => {
          _self.retain(signer);
          window.signer = signer;
          let parser = new DOMParser()
          let progressHtml = [...parser.parseFromString('<div id="fileSigningProgress" class="sign_line_proc">' +
            '<div class="sign_tit2">Подписание файл'+(Object.prototype.toString.call( _self.file ) === '[object Array]' ? 'ов' : 'а')+':</div>' +
            '<div class="progress">' +
            '<div style="width:0%" class="progress-bar progress-bar-success progress-bar-striped" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>' +
            '</div>' +
            '</div>', "text/html").body.childNodes];
          _self.container.innerHTML = ""
          _self.container.append(progressHtml[0]);

          let $progress = _self.container.querySelector("#fileSigningProgress");
          let $progressBar = $progress.querySelector(".progress-bar");
          let timeInMs = Date.now();

          if (Object.prototype.toString.call( _self.file ) === '[object Object]')
          {
            //console.log(_self.file);
            let guids = [];
            let signes = [];
            for (let guid in _self.file) {
              guids.push(guid);
            }

            (function loop(i) {
              if (!(i in guids)) {
                setTimeout(function () {
                  successCmsMass(signes); // all done
                }, 1000);
                return;
              }

              let currentGuid = guids[i];
              let currentFile = null;
              if (Object.prototype.toString.call(_self.file[currentGuid]) ==='[object String]')
              {
                downloadFileByLink(_self.file[currentGuid], doit)
              } else {
                currentFile = _self.file[currentGuid];
                doit(currentFile);
              }

              function doit(currentFile) {
                readFileByChunk(currentFile, (arrBuf, next, offset, fileSize) => {
                  let intArray = new Uint8Array(arrBuf);
                  let base64 = btoa(Uint8ToString(intArray));
                  window.signer.add_data_in_base64(base64)
                    .then((res) => {
                      let perc = Math.round((offset/fileSize)*100);
                      $progressBar.style.width = perc + "%";
                      next();
                    });
                }, () => {
                  $progressBar.style.width = "100%";
                  let data = window.signer.finish();
                  //let free = window.signer.free();
                  data.then(function (signature){
                    signes[currentGuid] = signature;
                    loop(i+1);
                  }, errorHandler);
                });
              }
            })(0);
          } else {
            readFileByChunk(_self.file, (arrBuf, next, offset, fileSize) => {
              // console.log("add data: [offset=" + offset + ", fileSize=" + fileSize + "]");
              let intArray = new Uint8Array(arrBuf);
              let base64 = btoa(Uint8ToString(intArray));
              signer.add_data_in_base64(base64)
                .then((res) => {
                  let perc = Math.round((offset/fileSize)*100);
                  $progressBar.style.width = perc + "%";
                  next();
                });
            }, () => {
              // console.log("reading file complete: " + (Date.now() - timeInMs) + "ms");
              $progressBar.style.width = "100%";
              // console.log("finishing signature");
              let data = signer.finish();
              let free = signer.free();

              data.then(successCms, errorHandler)
            });
          }


        }, errorHandler);
    }
  }

  renderCerts (p11, certsInfo) {
    let _self = this;
    let m = {'certs': []};
    let lastCertSn = document.getElementById("last-cert-sn") !== null ? document.getElementById("last-cert-sn").value : null;
    let infoAcc = '';
    for (let i = 0; i < certsInfo.length; ++i) {
      this.retain(certsInfo[i].cert);
      let info = certsInfo[i].info;
      infoAcc += JSON.stringify(info) + ',';
      info.expired = new Date(info.not_after) < new Date();
      info.id = i;
      if (info.sn === lastCertSn) {
        m.certs.unshift(info);
      } else {
        m.certs.push(info);
      }
    }
    let parser = new DOMParser()
    let $html = [...parser.parseFromString(mustache.render(this.certsTemplate, m), "text/html").body.childNodes];
    this.container.innerHTML = ""
    $html.forEach(item => {
        this.container.append(item)
    })
    this.container.querySelectorAll("a").forEach(item => {
      item.addEventListener("click", event => {
        event.preventDefault();
        _self.sign(p11, certsInfo, item.id);
      })
    });
    this.container.querySelector("#refresh").addEventListener("click", event => {
      event.preventDefault();
      this.showProcessing(this.blitzscMsgs("blitzsc.status.interaction.title"), this.blitzscMsgs("blitzsc.status.interaction.msg"));
      this.showCerts(p11);
    });
  }

  showCerts (p11) {
    this.showProcessing(this.blitzscMsgs("blitzsc.status.interaction.title"), this.blitzscMsgs("blitzsc.status.interaction.msg"));
    p11.getCertsForSign(true)
      .then((certs) => {
        if (typeof certs === 'undefined' || certs.length === 0) {
          this.showProcessing(this.blitzscMsgs("blitzsc.status.waitingCerts.title"), this.blitzscMsgs("blitzsc.status.waitingCerts.msg"), true);
          setTimeout(() => this.showCerts(p11), 2000);
          return;
        }

        let certsInfo = [];
        let latch = certs.length;
        let complete = false;

        let checkLatch = () => {
          --latch;
          if (latch === 0) {
            complete = true;
            this.renderCerts(p11, certsInfo);
          }
        };

        let successInfo = (cert) => {
          this.retain(cert);
          return (info) => {
            certsInfo.push({'cert': cert, 'info': info});
            checkLatch();
          };
        };

        let failInfo = (err) => {
          checkLatch();
          console.error(err);
        };

        let checkComplete = () => {
          if (!complete) {
            this.showCommonError("waiting certificate full info timeout");
          }
        };

        for (let i = 0; i < certs.length; ++i) {
          certs[i].full_info.then(successInfo(certs[i]), failInfo);
        }
        setTimeout(checkComplete, 10000);

      }, (err) => {
        this.showError(this.blitzscMsgs("blitzsc.err.getCertsForSign", this.extractErrorMessage(err)));
        console.error(err);
      });

  }

  _successFactory (plugin) {
    this.showProcessing(this.blitzscMsgs("blitzsc.status.interaction.title"), this.blitzscMsgs("blitzsc.status.interaction.msg"));

    plugin.initPKCS11(["capi:Crypto-Pro GOST R 34.10-2001 Cryptographic Service Provider,1:Crypto-Pro GOST R 34.10-2012 Strong Cryptographic Service Provider,1:Infotecs Cryptographic Service Provider,1:Signal-COM GOST R 34.10-2012 (512) Cryptographic Provider,1:Signal-COM CPGOST Cryptographic Provider,1:Signal-COM GOST R 34.10-2012 (256) Cryptographic Provider,1","Aladdin R.D. Unified JaCarta","ISBC ESMART","SafeNet"])
      .then((p11) => {
        this.retain(p11);
        p11.modules().then((modules) => {
          let enableExists = false;
          for (let i = 0; i < modules.length; ++i) {
            enableExists = enableExists || modules[i].enable;
          }
          if (enableExists) {
            this.showCerts(p11);
          } else {
            this.showError(this.blitzscMsgs("blitzsc.err.noModules"));
          }
        }, this.showCommonError);
      }, (err) => {
        this.showError(this.blitzscMsgs("blitzsc.err.initPKCS11", this.extractErrorMessage(err)));
        console.error(err);
      });
  }

  successFactory (plugin) {
    window.signMode = 'file';
    this.container.innerHTML = "";
    this._successFactory(plugin);
  }

  failFactory (error) {
    let errPref = "blitzsc.err.";
    let errAcc;
    let waitExtensionCb;
    switch (error) {
      case "plugin_not_available":
        errAcc = this.blitzscMsgs("blitzsc.status.waitingPlugin.msg");
        this.showProcessing(this.blitzscMsgs("blitzsc.status.waitingPlugin.title"),
          errAcc, true);
        let waitingPluginCb = arguments[2];
        waitingPluginCb(this.successFactory.bind(this), this.failFactory.bind(this));
        break;
      case "plugin_not_allowed":
        errAcc = this.blitzscMsgs("blitzsc.status.waitingActivationPlugin.msg");
        this.showProcessing(this.blitzscMsgs("blitzsc.status.waitingActivationPlugin.title"),
          errAcc, true);
        let waitingAllowCb = arguments[2];
        waitingAllowCb(this.successFactory.bind(this), this.failFactory.bind(this));
        break;
      case "plugin_not_valid":
        errAcc = this.blitzscMsgs(errPref + error, this.extractErrorMessage(arguments[1]));
        this.showError(errAcc);
        break;
      case "chrome_extension_not_available":
        errAcc = this.blitzscMsgs("blitzsc.status.waitingExtension.msg");
        this.showProcessing(this.blitzscMsgs("blitzsc.status.waitingExtension.title", this.extractErrorMessage(arguments[1])), errAcc, true);
        waitExtensionCb = arguments[2];
        waitExtensionCb(this.successFactory.bind(this), this.failFactory.bind(this));
        break;
      case "firefox_extension_not_available":
        errAcc = this.blitzscMsgs("blitzsc.status.waitingExtension.msg");
        window.installFirefoxExtention = (aEvent) => {
          for (let a = aEvent.target; a.href === undefined;) a = a.parentNode;
          arguments[4]();
          return false;
        };
        this.showProcessing(this.blitzscMsgs("blitzsc.status.waitingFirefoxExtension.title", arguments[3]), errAcc, true);
        waitExtensionCb = arguments[2];
        waitExtensionCb(this.successFactory.bind(this), this.failFactory.bind(this));
        break;
      case "plugin_old_version":
        /*function bindAgain() {
            this.container.find("#again")
                .click(function (event) {
                    event.preventDefault();
                    errAcc = _self.blitzscMsgs("blitzsc.status.interaction.msg");
                    _self.showProcessing(_self.blitzscMsgs("blitzsc.status.interaction.title"), errAcc);
                    setTimeout(function () {
                        _self.pFactory(_self.successFactory.bind(_self), _self.failFactory.bind(_self));
                    }, 1000);
                });
        }*/

        let againHtml = "<button id='again' type='button' class='btn btn-primary'><span class='glyphicon glyphicon-repeat' aria-hidden='true'></span> " + _self.blitzscMsgs("blitzsc.msg.again") + "</button>";
        let errBuf = this.blitzscMsgs(errPref + error);
        errAcc = errBuf.split(':')[0];
        this.showError(errBuf, againHtml);
        // bindAgain();
        break;
      default:
        console.error(error);
        errAcc = this.blitzscMsgs(errPref + "plugin_not_valid");
        this.showError(errAcc);
    }
  }
}


