<template>
  <div class="rent_req_item">
    <loader
          v-if="isLoading"
          :animation-duration="3000"
          :size="60"
    ></loader>
    <container v-else-if='data'>
      <div class="rent_recv_title d-none-lg">
          {{ data.name }}
      </div>
      <div class="rent_recv_code d-none-lg">
          <div class="title-info" v-if="data.code"><span class="id">ID:</span> {{data.code}}</div>
      </div>
      <div class='row row_gap'>
        {{data.categories.id}}
        <div class='col-lg-7' v-if='data.photos && data.photos.length > 0'>
          <div class='sliderThumbs'>
            <swiper
                :spaceBetween="10"
                :navigation="true"
                :thumbs="{ swiper: thumbsSwiper }"
                :threshold='10'
                :modules="modules"
                :loop="data.photos.length > 1 ? true : false"
                class="sliderThumbsMain"
            >
              <swiper-slide v-for="photo of data.photos" :key="photo.guid">
                <img :src="photo.imageUrl" alt=""/>
              </swiper-slide>
            </swiper>
            <swiper
                @swiper="setThumbsSwiper"
                :spaceBetween="24"
                :slidesPerView="4"
                :freeMode="true"
                :watchSlidesProgress="true"
                :threshold='10'
                :modules="modules"
                class="sliderThumbsThumbs"
            >
              <swiper-slide v-for="photo of data.photos" :key="photo.guid">
                <img :src="photo.imageUrl" alt=""/>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class='col-lg-5'>
          <div class="rent_recv_title d-lg">
              {{ data.name }}
          </div>
          <div class="rent_recv_code d-lg">
              <div class="title-info" v-if="data.code"><span class="id">ID:</span> {{data.code}}</div>
          </div>
          <div class="content_block">
            <div class="subtitle">Описание</div>
            <div class="text" v-if="data.description">{{data.description}}</div>
            <div class="company_info">
              <div class="text_spec" v-if="data.timePeriod">
                <div class="item_spec">Эпоха</div>
                <div class="item_spec2">{{ data.timePeriod }}</div>
              </div>
              <div class="text_spec" v-if="data.width && data.height && data.depth">
                <div class="item_spec">Размер (ШхВхГ):</div>
                <div class="item_spec2">{{ data.width }}x{{ data.height }}x{{ data.depth }}см.</div>
              </div>
              <div class="text_spec" v-if="data.materials?.length > 0">
                <div class="item_spec">Материал:</div>
                <div class="item_spec2"><span v-for="(item, index) in data.materials">{{ item.name + (index == data.materials?.length - 1 ? "" : ", ") }}</span></div>
              </div>
              <div class="text_spec" v-if="data.count">
                <div class="item_spec">Количество: </div>
                <div class="item_spec2">{{ data.count }}</div>
              </div>
              <div class="text_spec text_spec2 align-items-center" v-if="data.price">
                <div class="item_spec">Стоимость от: </div>
                <div class="item_spec2 price">от {{ data.price }} ₽/шт</div>
              </div>
            </div>
<!--            <div class="text row">-->
<!--              <div class="col-md-12">Стоимость зависит от объема и продолжительности аренды. Для уточнения свяжитесь по телефону или электронной почте указанным ниже.</div>-->
<!--            </div>-->
            <div class="btns_one">
              <div v-if="btnAddShow(data)" class="block_add_count cart">
                <div class="bac_btn" @click.prevent="removeProductCart(data, false)"><img src="/img/ico/minus_st.svg" alt=""></div>
                <div class="bac_num">
                  <div class="bac_top fs16">{{ btnAddShow(data) }}</div>
                  <div class="bac_bot">В наличие {{ data.count }} шт.</div>
                </div>
                <div class="bac_btn img_wh" @click.prevent="btnAddShow(data) >= data.count ? '' : addProductCart(data)" :class="{'disabled': btnAddShow(data) >= data.count }"><img src="/img/ico/plus_st.svg" alt=""></div>
              </div>
              <cl-button  v-else @click.stop="addProductCart(data)"
                          class="btn-middle w-100">В корзину</cl-button>
            </div>
            <div class="subtitle">Контакты</div>
            <div class="company_info">
              <div class="text_spec" v-if="data.stock.name">
                <div class="item_spec">Название: </div>
                <div class="item_spec2">{{ data.stock.name }}</div>
              </div>
              <div class="text_spec" v-if="data.stock.address">
                <div class="item_spec">Адрес: </div>
                <div class="item_spec2">{{ data.stock.address }}</div>
              </div>
              <div class="text_spec" v-if="data.stock.workTime">
                <div class="item_spec">Часы работы: </div>
                <div class="item_spec2">{{ data.stock.workTime }}</div>
              </div>
            </div>
            <div class="btns">
              <cl-button v-if="data.stock.phone" type="light" class="btn-middle btn-ico phone" img="/img/ico/phone.svg" @click="v_ym(metrikCategory.get(data.categories[0].id))" @click.prevent.once="showNumber = !showNumber" >
                <template v-if="!showNumber">Показать телефон</template>
                <template v-else><a :href="'tel:' + data.stock.phone">{{ data.stock.phone }}</a></template>
              </cl-button>
              <cl-button v-if="data.stock.email" type="light" class="btn-middle btn-ico mail" img="/img/ico/mail.svg" @click="v_ym(metrikCategoryMail.get(data.categories[0].id))" @click.prevent="getMail">
                <template v-if="!showMail">Написать на почту</template>
                <template v-else>{{ data.stock.email }}</template>
              </cl-button>
            </div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import toogleShoppingCart from "@/mixin/toogleShoppingCart";
import translates from '@/assets/js/resources/translate/rent'
import {Swiper, SwiperSlide} from 'swiper/vue';
import {FreeMode, Navigation, Thumbs} from 'swiper';
import 'swiper/css';

import 'swiper/css/free-mode'
import 'swiper/css/thumbs'
import card from "@/components/library/Card.vue";
import BannerCards from "@/components/bannerCards.vue";

export default {
  name: 'rentRequisitesItem',
  mixins: [toogleShoppingCart],
  components: {
    BannerCards,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      thumbsSwiper: null,
      data: null,
      isLoading: false,
      showNumber: false,
      showMail: false,
      metrikCategory:null,
      metrikCategoryMail:null
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
    dataFilters:{
      type: Object,
      require: true,
    },
  },
  methods: {
    removePhone(phone){
      let updatePhone =  phone.replace(/[^\d]/g,'').substr(0,11);
      const numberPhone = Array.from(updatePhone);
      numberPhone[0] = "+7(";
      numberPhone.splice(4, 0, ')-');
      numberPhone.splice(8, 0, '-');
      let resultPhone = numberPhone.join('');
      return resultPhone;
    },
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
    translateWord(word) {
      return translates[word] ? translates[word] : 'Значение не найдено';
    },
    getData(id) {
      this.isLoading = true;
      return this.axios
        .get(`/api/data/one-requisite?id=${id}`)
        .then(response => {
          this.data = response.data;

          this.$nextTick(() => {
            helperApp.addBreadcrumbsLink(this.data.name);
          });
        })
        .finally(() => {
          this.isLoading = false;
        })
        .catch(error => {
          this.showError(error);
        });
    },
    getMail(){
        if(!this.showMail){
            this.showMail = !this.showMail;
        } else {
            window.location.href = 'mailto:' + this.data.stock.email;
        }
    }
  },
  watch:{
    dataFilters: {
      handler(){
      },
      deep: true,
    }
  },
  setup() {
    return {
      modules: [FreeMode, Navigation, Thumbs]
    }
  },
  mounted() {
    const pathArray = this.$route.path.split("/");
    this.getData(pathArray[pathArray.length - 1]);
    this.metrikCategory = new Map();
    this.metrikCategory.set("1315201","mebelРhone")
    .set("1315202","fdPhone")
    .set("1315203", "kostumPhone")
    .set("1315204", "rekPhone")
    .set("1315206", "bidPhone")
    .set("1315205","vkPhone");

    this.metrikCategoryMail = new Map();
    this.metrikCategoryMail.set("1315201","mebelEmail")
        .set("1315202","fdEmail")
        .set("1315203", "kostumEmail")
        .set("1315204", "rekEmail")
        .set("1315206", "bidEmail")
        .set("1315205","vkEmail");
  }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.rent_req_item {
  padding-top: 239px;
  @media (max-width: 767px) {
    padding-top: 200px;
  }
}

.rent_recv_title{
  margin-bottom: 12px;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.96px;
  text-transform: none;
  @media (max-width: 991px) {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.64px;
    text-transform: none;
  }
}

.rent_recv_code{
  margin-bottom: 20px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.48px;
  .id{
    color: #ACB0B9;
  }
  @media (max-width: 991px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.36px;
  }
}

.slider {
    margin-bottom: 70px;
}

.sliderThumbsMain{
    border-radius: 14px;
    border: 1px solid #17191C;
}

.titleBlock {
    margin-bottom: 42px;
}

.titRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
    h3 {
        margin-right: 20px;
        margin-bottom: 0;
    }
}

.characters {
    margin-bottom: 20px;
}

.checkboxes {
    margin-bottom: -20px;
    .character {
        margin-bottom: 20px;
    }
}

.character {
    display: flex;
    margin-bottom: 16px;
    padding-right: 24px;
    span:first-child {
        margin-right: auto;
        padding-right: 24px;
    }
    span:last-child {
        min-width: 25%;
        text-align: left;
    }
}

.company_block {
    border: 2px solid $color-grey;
    padding: 32px;
    border-radius: 12px;
    display: flex;
    .icon {
        max-width: 182px;
        margin-right: 24px;
    }
}

.d-none-lg{
  @media (max-width: 991px) {
    display: none;
  }
}

.d-lg{
  @media (min-width: 992px) {
    display: none;
  }
}

.content_block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0;
    background-color: transparent;
    @media (min-width: 992px){
      margin-left: 40px;
    }
    h3 {
        margin-bottom: 4px;
    }
    .title-info {
        color: rgba(255, 255, 255, 0.8);
    }
    .subtitle {
        //margin-bottom: 24px;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.64px;
      @media (max-width: 767px){
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 133.333%;
        letter-spacing: 0.48px;
      }
    }
    .text {
        //margin-bottom: 24px;
      .pt2 {
        padding-top: 2px;
      }
    }
    .company_info{
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .text_spec2{
      margin-top: 8px;
    }
    .text_spec{
      display: flex;
      gap: 24px;
      @media (max-width: 767px){
        font-size: 16px;
        font-weight: 400;
        line-height: 131.25%;
        letter-spacing: 0.24px;
      }
      .item_spec{
        min-width: 150px;
        white-space: nowrap;
        color: #ACB0B9;
      }
      .item_spec2{
        &.price{
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 133.333%;
          letter-spacing: 0.48px;
        }
      }
    }
    .btns_one {
      padding-bottom: 24px;
      border-bottom: 1px solid #6B7280;
      &:last-child {
        border: none;
        padding: 0;
      }
    }
    .btns {
        display: grid;
        grid-template-areas: "phone mail" "cart cart";
        grid-gap: 16px;
        grid-template-columns: 1fr 1fr;
        .phone {
            grid-area: phone;
        }
        .mail {
            grid-area: mail;
        }
        .cart {
            grid-area: cart;
            width: 100%;
        }
    }
  @media (max-width: 767px) {
    .btns{
      grid-template-areas: "phone" "mail" "cart";
      grid-template-columns: 1fr;
    }
  }
}
</style>