<template>
<!--  {{ dataInfo.dataBlock }}-->
  <div style="visibility: collapse;" class="_hid_block">
    hidden element fix
  </div>

  <template v-if="dataInfo?.dataBlock?.jakorSrc">
    <teleport :to="dataInfo.dataBlock.jakorSrc">
      <div class="card_list_block">
<!--      <div class="card_list_block">-->
        <div class="">
          <h2 v-if="dataInfo?.dataBlock?.titleBlock">
            {{ dataInfo.dataBlock.titleBlock.value }}
          </h2>
          <div class="sub_h2" v-if="dataInfo?.dataBlock?.descBlock">
            {{ dataInfo.dataBlock.descBlock.value }}
          </div>
          <template v-if="dataList">
            <column class="col_subs_card card_backgr_gray" v-for="item in dataList">
              <div class="title_desc">
                <template v-if="item.Name">
                  <div class="name_event">
                    {{ item.Name }}
                  </div>
                </template>
                <template v-if="item.Description">
                  <div class="desc_event">
                    {{ item.Description }}
                  </div>
                </template>
              </div>
              <card card-type="event" :route-link="'events/item/' + item.guid">
                <div class="div_img" :class="{'not_img': !item.logo?.imageUrl}">
                  <div class="div_tag">
                    <div class="div_tag_el div_tag_el_min" v-if="item.ageLimit || item.ageLimit == 0">{{ item.ageLimit }}+</div>
                    <div class="div_tag_el" v-if="item.isRegistrationInRpp">Требуется регистрация</div>
                  </div>
                  <img v-if="item.logo?.imageUrl" :src="item.logo.imageUrl" alt="">
                </div>
                <div class="div_info">
                  <div class="div_tag2" v-if="item.types && item.types.length > 0"><span v-for="tag in item.types">{{ tag.name }}</span></div>
                  <div class="div_tit fs5" v-if="item.title">{{ item.title }}</div>
                  <div class="div_dat">
                    <div class="dt1">{{$filters.ruDate2(new Date(item.eventStartDate))}} <template v-if="item.startTime">{{ item.startTime.split(':')[0] + ':' + item.startTime.split(':')[1] }}</template></div>
                    <div class="dt2">
                      <template v-if="item.regEventVenues !== undefined && item.regEventVenues.length == 1">
                        {{ item.regEventVenues[0].name }}
                      </template>
                      <template v-else>
                        <template v-if="item.platform?.name">
                          {{ item.platform.name }}
                        </template>
                      </template>
                    </div>
                  </div>
                  <div class="div_proj" v-if="item.regEventVenues && item.regEventVenues !== null">
                    <div class="dt op0"> a</div>
                    <div class="dt">
                      <template v-if="item.regEventVenues.length == 1">
                        {{ item.regEventVenues[0].address }}
                      </template>
                      <template v-else>
                        {{ item.regEventVenues.length }} {{ getPlural(item.regEventVenues.length, 'площадка', 'площадки', 'площадок') }}
                      </template>
                    </div>
                  </div>
                </div>
              </card>
            </column>
          </template>
        </div>
      </div>
    </teleport>
  </template>
  <template v-else>
    <div class="card_list_block" :id="idBlockAnchor" :class="{ 'gray_fon_card': GrayFonCard, 'width50cards': Width50Pers }">
      <container :class="{ 'gray_fon_block': GrayFonBlock }">
        <div>
          <h2 v-if="dataInfo?.dataBlock?.titleBlock">
            {{ dataInfo.dataBlock.titleBlock.value }}
          </h2>
          <div class="sub_h2" v-if="dataInfo?.dataBlock?.descBlock">
            {{ dataInfo.dataBlock.descBlock.value }}
          </div>
          <row>
            <template v-if="dataList">
              <column class="card_col card_backgr_gray" lg="4" md="12" sm="12" v-for="item in dataList">
                <div class="title_desc">
                  <template v-if="item.Name">
                    <div class="name_event">
                      {{ item.Name }}
                    </div>
                  </template>
                  <template v-if="item.Description">
                    <div class="desc_event">
                      {{ item.Description }}
                    </div>
                  </template>
                </div>
                <card card-type="event" :route-link="'events/item/' + item.guid">
                  <div class="div_img" :class="{'not_img': !item.logo?.imageUrl}">
                    <div class="div_tag">
                      <div class="div_tag_el div_tag_el_min" v-if="item.ageLimit || item.ageLimit == 0">{{ item.ageLimit }}+</div>
                      <div class="div_tag_el" v-if="item.isRegistrationInRpp">Требуется регистрация</div>
                    </div>
                    <img v-if="item.logo?.imageUrl" :src="item.logo.imageUrl" alt="">
                  </div>
                  <div class="div_info">
                    <div class="div_tag2" v-if="item.types && item.types.length > 0"><span v-for="tag in item.types">{{ tag.name }}</span></div>
                    <div class="div_tit fs5" v-if="item.title">{{ item.title }}</div>
                    <div class="div_dat">
                      <div class="dt1">{{$filters.ruDate2(new Date(item.eventStartDate))}} <template v-if="item.startTime">{{ item.startTime.split(':')[0] + ':' + item.startTime.split(':')[1] }}</template></div>
                      <div class="dt2">
                        <template v-if="item.regEventVenues !== undefined && item.regEventVenues.length == 1">
                          {{ item.regEventVenues[0].name }}
                        </template>
                        <template v-else>
                          <template v-if="item.platform?.name">
                            {{ item.platform.name }}
                          </template>
                        </template>
                      </div>
                    </div>
                    <div class="div_proj" v-if="item.regEventVenues">
                      <div class="dt"></div>
                      <div class="dt">
                        <template v-if="item.regEventVenues.length == 1">
                          {{ item.regEventVenues[0].address }}
                        </template>
                        <template v-else>
                          {{ item.regEventVenues.length }} {{ getPlural(item.regEventVenues.length, 'площадка', 'площадки', 'площадок') }}
                        </template>
                      </div>
                    </div>
                  </div>
                </card>
              </column>
            </template>
            <column ref="cardhold" class="cardholder_telep card_col card_col_sub" :class="{'blockElem1': dataInfo.dataBlock?.numberElemCard.value == 1, 'blockElem2': dataInfo.dataBlock?.numberElemCard.value == 2}" v-if="dataInfo.dataBlock.numberElemCard && dataInfo.settings" :id="dataInfo.settings.anchor"></column>
          </row>
        </div>
      </container>
    </div>
  </template>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm";

export default {
  name: "cardsListCinema",
  data(){
    return{
      getParams: {
        ids: "",
      },
      dataList: false,
      teleportDisabled: false,
      GrayFonCard: false,
      GrayFonBlock: false,
      Width50Pers: false,
      idBlockAnchor: "",
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  methods:{
    getPlural(number, one, two, many) {
      if (number % 10 === 1 && number % 100 !== 11) {
        return one;
      } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
        return two;
      } else {
        return many;
      }
    },
    getEvents(isLoadMore) {
      return this.axios
          .get(`/api/data/cinema-events`, {params: this.getParams})
          .then(response => {
            // dateFrom
            if (isLoadMore) {
              this.dataList = [...this.dataList, ...response.data.records];
            } else {
              this.dataList = response.data.records;
            }
            this.data = response.data;

            this.dataList.forEach((item, index) => {
              if (this.dataInfo.dataCard[index].titleEvent !== undefined && this.dataInfo.dataCard[index].titleEvent){
                this.dataList[index].Name = this.dataInfo.dataCard[index].titleEvent.value;
              }
              if (this.dataInfo.dataCard[index].descEvent !== undefined && this.dataInfo.dataCard[index].descEvent){
                this.dataList[index].Description = this.dataInfo.dataCard[index].descEvent.value;
              }
            })

            if (this.dataInfo.dataBlock.GrayFonCard && this.dataInfo.dataBlock.GrayFonCard.value !== undefined){
              this.GrayFonCard = this.dataInfo.dataBlock.GrayFonCard.value;
            }
            if (this.dataInfo.dataBlock.GrayFonBlock && this.dataInfo.dataBlock.GrayFonBlock.value !== undefined){
              this.GrayFonBlock = this.dataInfo.dataBlock.GrayFonBlock.value;
            }
            if (this.dataInfo.dataBlock.Width50Pers && this.dataInfo.dataBlock.Width50Pers.value !== undefined){
              this.Width50Pers = this.dataInfo.dataBlock.Width50Pers.value;
            }
            if (this.dataInfo.dataBlock.idAnchor && this.dataInfo.dataBlock.idAnchor.value !== undefined){
              this.idBlockAnchor = this.dataInfo.dataBlock.idAnchor.value;
            }


            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    collectedGuids(elem){
      // this.getEvents();
      let k = []
      elem.forEach( el => {
        k.push(el.guidEvent.value);
      })
      // console.log(k);
      this.getParams.ids = k.join(',');
      // console.log(this.getParams.ids)
      this.getEvents();
    }
  },
  mounted() {
    this.collectedGuids(this.dataInfo.dataCard);
    // console.log(this.dataInfo.dataCard);
  },
}
</script>

<style lang="scss" scoped>
  .row{
    justify-content: center;
    > div{
      height: 100%;
    }
  }
  .card_list_block{
    margin: 3rem 0;
    border-radius: 3.2rem;
    .container{
      max-width: 152rem;
      &.gray_fon_block{
        padding: 12rem;
        background: #0C0C0E;
        border-radius: 2.4rem;
        max-width: 144.4rem;
      }
    }
    h2, .sub_h2{
      text-align: center;
    }
    h2{
      font-size: 3.2rem;
      margin-bottom: 1.6rem;
    }
    .sub_h2{
      font-size: 1.8rem;
      line-height: 150%;
      color: #6B7280;
      margin-bottom: 3.2rem;
    }
    .card{
      background: #17191C;
      height: 100%;
    }
    .title_desc{
      text-align: center;
      .name_event{
        font-size: 2.4rem;
        line-height: 150%;
        margin-bottom: 1.6rem;
      }
      .desc_event{
        color: #6B7280;
        font-size: 1.6rem;
        margin-bottom: 3.2rem;
      }
    }
  }
  .div_proj {
    display: flex;
    justify-content: space-between;
    .dt{
      margin-left: auto;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .cardholder_telep{
    display: flex;
    gap: 12px;
    &.blockElem1{
      width: 33%;
    }
    &.blockElem2{
      width: 66%;
    }
    .card_list_block{
      margin: 0;
      width: calc(100% - 12px);
    }
    .block_fon{
      padding: 0;
    }
    .card{
      width: 100%;
    }
  }

  .gray_fon_card{
    &.card_list_block{
      .card_backgr_gray{
        padding: 2.4rem;
        background: #0C0C0E;
        border-radius: 1.2rem;
        justify-content: center;
      }
    }
    .card_list_block{
      .card_backgr_gray{
        padding: 2.4rem;
        background: #0C0C0E;
        border-radius: 1.2rem;
        justify-content: center;
      }
    }
  }

  .width50cards{
    &.card_list_block{
      .card_col{
        max-width: calc(50% - 12px);
        width: 100%;
        flex: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        .card-event{
          max-width: 38.4rem;
        }
        .col_subs_card{
          display: flex;
          align-items: center;
          flex-direction: column;
          .card-event{
            max-width: 38.4rem;
          }
        }
      }
    }
  }

  @media(max-width: 991px){
    .card_list_block {
      margin: 3rem 4rem;
      .container.gray_fon_block {
        padding: 6rem 1.6rem;
      }
      .row{
        gap: 16px 0;
        flex-direction: column;
      }
    }
    .card-event .div_img {
      height: 334px;
    }
    .cardholder_telep {
      display: flex;
      padding: 0;
      &.blockElem1 {
        width: 100%;
        padding: 0 1.2rem;
      }
      &.blockElem2 {
        width: 100%;
        flex-direction: column;
      }
    }
    .width50cards.card_list_block{
      .card_col{
        max-width: 100%;
        &.cardholder_telep{
          padding: 0;
        }
        .card-event, .col_subs_card .card-event{
          max-width: 100%;
        }
      }
    }
    .cardholder_telep{
      .card_list_block{
        width: 100%;
      }
    }
  }
  @media(max-width: 767px){
    .card_list_block{
      margin: 3rem 0;
      .div_img{
        height: 26rem;
      }
    }
    .gray_fon_card.card_list_block .card_backgr_gray{
      padding: 6rem 1.6rem;
    }
  }

</style>