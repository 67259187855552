<!--suppress ALL -->
<template>
  <div class="block_wrap moskino_park moskino_park_2" v-if="dataInfo">
    <container class="pos_rel mobile_hidden">
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
      <div class="moskino_park_menu fs4" v-if="dataInfo.slideList && dataInfo.slideList.length > 0 && widthSite > 769">
        <template
            v-for="(item, index) in dataInfo.slideList"
            :key="item.title?.value"
        >
          <div
              class="div_el"
              @click="changeMap(index); this.activeItem = item;"
              :class="{'active': activeItem == item}"
          >
            {{ item.title?.value }}
          </div>
          <span class="sp_line" v-if="index + 1 < dataInfo.slideList.length">/</span>
        </template>
      </div>
      <multiselect
              class="light"
              v-model="activeItem"
              :options="dataInfo.slideList"
              label="title"
              @select="changeMap(activeItem.indexImage.value - 1);"
              :searchable="false"
              :allow-empty="false"
              v-if="listChildrenShow && dataInfo.slideList && dataInfo.slideList.length > 0 && widthSite < 769"
      >
          <template v-slot:singleLabel="{ option }">{{ option.title.value }}</template>
          <template v-slot:option="{ option }">{{ option.title.value }}</template>
      </multiselect>
      <div class="moskino-content" v-if="dataInfo.slideList && dataInfo.slideList.length > 0">
        <div class="moskino_park_list fs3n selector_hover">
          <transition-group name="fade">
            <div class="div_el selector_elem"
                 v-for="(itemChild, index) of slideList"
                 :key="itemChild.title?.value"
                 :elemhover="'elem_id'+(index + 1)"
                 :class="'elem_id'+(index + 1)"
            >
              <a target="_blank" :href="itemChild.source?.value">
                {{index + 1}}. {{ itemChild.title?.value }}
              </a>
            </div>
          </transition-group>
        </div>
        <div class="moskino_img">
          <div class="moskino_park_img moskino_height_card" style="visibility: hidden; !important; position: relative !important">
            <img class="mainImage" :src="getImage(activeItem.image, 'banner')" alt="">
          </div>
          <div class="moskino_park_img mos_park_selector d-block">
              <img class="mainImage" :src="getImage(activeItem.image, 'banner')" alt="">
              <div class="elements_hover selector_hover elements_hover1">
                <div class="elem-hover-img elem1 selector_elem elem_id1" elemhover="elem_id1">
                  <img src="/img/statica_moskino/part1/img_stat_1_2.png" alt="">
                </div>
                <div class="elem-hover-img elem1_1 selector_elem elem_id1" elemhover="elem_id1">
                  <img src="/img/statica_moskino/part1/img_stat_1_1.png" alt="">
                </div>
                <div class="elem-hover-img elem2 selector_elem elem_id2" elemhover="elem_id2">
                  <img src="/img/statica_moskino/part1/img_stat_2_1.png" alt="">
                </div>
                <div class="elem-hover-img elem2_1 selector_elem elem_id2" elemhover="elem_id2">
                  <img src="/img/statica_moskino/part1/img_stat_2_2.png" alt="">
                </div>
                <div class="elem-hover-img elem3 selector_elem elem_id3" elemhover="elem_id3">
                  <img src="/img/statica_moskino/part1/img_stat_3_1.png" alt="">
                </div>
                <div class="elem-hover-img elem3_1 selector_elem elem_id3" elemhover="elem_id3">
                  <img src="/img/statica_moskino/part1/img_stat_3_2.png" alt="">
                </div>
                <div class="elem-hover-img elem4 selector_elem elem_id4" elemhover="elem_id4">
                  <img src="/img/statica_moskino/part1/img_stat_4.png" alt="">
                </div>
  <!--              <div class="elem-hover-img elem5 selector_elem elem_id5" elemhover="elem_id5">-->
  <!--                <img src="/img/statica_moskino/part1/img_stat_5.png" alt="">-->
  <!--              </div>-->
                <div class="elem-hover-img elem6 selector_elem elem_id5" elemhover="elem_id5">
                  <img src="/img/statica_moskino/part1/img_stat_6_2.png" alt="">
                </div>
                <div class="elem-hover-img elem6_1 selector_elem elem_id5" elemhover="elem_id5">
                  <img src="/img/statica_moskino/part1/img_stat_6_1.png" alt="">
                </div>
              </div>
              <div class="elements_lines">
                <div class="elem-line line1_1 elem_id1">
                  <svg width="364" height="160" stroke="white" viewBox="0 0 364 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 157.5L363 2.00001" stroke-width="4"/>
                  </svg>
                </div>
                <div class="elem-line line1_2 elem_id1">
                  <svg width="362" height="31" stroke="white"  viewBox="0 0 362 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 28.5L361.5 1.99999" stroke-width="4"/>
                  </svg>
                </div>
                <div class="elem-line line2_1 elem_id2">
                  <svg width="158" height="163" stroke="white"  viewBox="0 0 158 163" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M155.5 2.50006L2.5 160.5" stroke-width="4" stroke-linecap="round"/>
                  </svg>
                </div>
                <div class="elem-line line2_2 elem_id2">
                  <svg width="257" height="182" stroke="white"  viewBox="0 0 257 182" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M254.5 2.50012L2.00024 179.5" stroke-width="4" stroke-linecap="round"/>
                  </svg>
                </div>
                <div class="elem-line line3_1 elem_id3">
                  <svg width="198" height="81" stroke="white"  viewBox="0 0 198 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 2.5L195.5 78.4997" stroke-width="4" stroke-linecap="round"/>
                  </svg>
                </div>
                <div class="elem-line line3_2 elem_id3">
                  <svg width="199" height="65" stroke="white"  viewBox="0 0 199 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 63L196.5 2.49969" stroke-width="4" stroke-linecap="round"/>
                  </svg>
                </div>
                <div class="elem-line line4 elem_id4">
                  <svg width="284" height="219" stroke="white"  viewBox="0 0 284 219" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.50029 216.5L281.5 1.99978" stroke-width="4" stroke-linecap="round"/>
                  </svg>
                </div>
  <!--              <div class="elem-line line5 elem_id5">-->
  <!--                <svg width="196" height="196" stroke="white"  viewBox="0 0 196 196" fill="none" xmlns="http://www.w3.org/2000/svg">-->
  <!--                  <path d="M1.5 2L194 194.5" stroke-width="4"/>-->
  <!--                </svg>-->
  <!--              </div>-->
                <div class="elem-line line6_1 elem_id5">
                  <svg xmlns="http://www.w3.org/2000/svg"  stroke="white" width="313" height="237" viewBox="0 0 313 237" fill="none">
                    <path d="M2.5 2L311 235" stroke-width="4" stroke-linecap="round"/>
                  </svg>
                </div>
                <div class="elem-line line6_2 elem_id5">
                  <svg width="313" height="104" viewBox="0 0 313 104" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 2.50024L311 102" stroke-width="4" stroke-linecap="round"/>
                  </svg>
                </div>
              </div>
              <div class="elements-pin selector_hover">
              <div class="pin pin1 selector_elem elem_id1" elemhover="elem_id1">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">1</div>
              </div>
              <div class="pin pin2 selector_elem elem_id2" elemhover="elem_id2">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">2</div>
              </div>
              <div class="pin pin3 selector_elem elem_id3" elemhover="elem_id3">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">3</div>
              </div>
              <div class="pin pin4 selector_elem elem_id4" elemhover="elem_id4">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">4</div>
              </div>
<!--              <div class="pin pin5 selector_elem elem_id5" elemhover="elem_id5">-->
<!--                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>-->
<!--                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>-->
<!--                </svg>-->
<!--                <div class="num">5</div>-->
<!--              </div>-->
              <div class="pin pin6 selector_elem elem_id5" elemhover="elem_id5">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">5</div>
              </div>
            </div>
          </div>
          <div class="moskino_park_img mos_park_selector park_img2 moskino_park_img2">
          <img class="mainImage" :src="getImage(activeItem.image, 'banner')" alt="">
          <div class="elements_hover elements_hover2 selector_hover ">
            <div class="elem-hover-img elem1 selector_elem elem_id1" elemhover="elem_id1">
              <img src="/img/statica_moskino/part2/img_stat_1.png" alt="">
            </div>
            <div class="elem-hover-img elem2_1 selector_elem elem_id2" elemhover="elem_id2">
              <img src="/img/statica_moskino/part2/img_stat_2_1.png" alt="">
            </div>
            <div class="elem-hover-img elem2_2 selector_elem elem_id2" elemhover="elem_id2">
              <img src="/img/statica_moskino/part2/img_stat_2_2.png" alt="">
            </div>
            <div class="elem-hover-img elem3 selector_elem elem_id3" elemhover="elem_id3">
              <img src="/img/statica_moskino/part2/img_stat_3.png" alt="">
            </div>
            <div class="elem-hover-img elem4_1 selector_elem elem_id4" elemhover="elem_id4">
              <img src="/img/statica_moskino/part2/img_stat_4_1.png" alt="">
            </div>
            <div class="elem-hover-img elem4_2 selector_elem elem_id4" elemhover="elem_id4">
              <img src="/img/statica_moskino/part2/img_stat_4_2.png" alt="">
            </div>
<!--            <div class="elem-hover-img elem5_1 selector_elem elem_id5" elemhover="elem_id5">-->
<!--              <img src="/img/statica_moskino/part2/img_stat_5_1.png" alt="">-->
<!--            </div>-->
<!--            <div class="elem-hover-img elem5_2 selector_elem elem_id5" elemhover="elem_id5">-->
<!--              <img src="/img/statica_moskino/part2/img_stat_5_2.png" alt="">-->
<!--            </div>-->
          </div>
          <div class="elements_lines elements-lines2">
            <div class="elem-line line1_1 elem_id1">
              <svg width="344" height="129" viewBox="0 0 344 129" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.00069 2.50022L341.592 126.194" stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line2_1 elem_id2">
              <svg width="261" height="37" viewBox="0 0 261 37" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.49945 2.00001L258.5 35" stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line3 elem_id2">
              <svg xmlns="http://www.w3.org/2000/svg" stroke="white" width="262" height="109" viewBox="0 0 262 109" fill="none">
                <path d="M1.99944 107L259.999 2.50012"  stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line2_2 elem_id3">
              <svg width="237" height="138" viewBox="0 0 237 138" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M234.5 135.5L2.5003 2.00036" stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line4_1 elem_id4">
              <svg width="300" height="118" viewBox="0 0 300 118" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M298 2.49995L1.99989 115.5" stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line4_2 elem_id4">
              <svg xmlns="http://www.w3.org/2000/svg" width="301" stroke="white" height="34" viewBox="0 0 301 34" fill="none">
                <path d="M298.5 32L2.00062 2.50013"  stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
<!--            <div class="elem-line line5_1 elem_id5">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="240" stroke="white" height="55" viewBox="0 0 240 55" fill="none">-->
<!--                <path d="M237.501 52.5001L2.50069 2.00004" stroke-width="4" stroke-linecap="round"/>-->
<!--              </svg>-->
<!--            </div>-->
<!--            <div class="elem-line line5_2 elem_id5">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="236" stroke="white" height="87" viewBox="0 0 236 87" fill="none">-->
<!--                <path d="M233.999 2.00006L2.49935 85" stroke-width="4" stroke-linecap="round"/>-->
<!--              </svg>-->
<!--            </div>-->
          </div>
          <div class="elements-pin elements-pin2 selector_hover">
            <div class="pin pin1 selector_elem elem_id1" elemhover="elem_id1">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">1</div>
            </div>
            <div class="pin pin2 selector_elem elem_id2" elemhover="elem_id2">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">2</div>
            </div>
            <div class="pin pin3 selector_elem elem_id3" elemhover="elem_id3">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">3</div>
            </div>
            <div class="pin pin4 selector_elem elem_id4" elemhover="elem_id4">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">4</div>
            </div>
<!--            <div class="pin pin5 selector_elem elem_id5" elemhover="elem_id5">-->
<!--              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>-->
<!--                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>-->
<!--              </svg>-->
<!--              <div class="num">5</div>-->
<!--            </div>-->
          </div>
        </div>
          <div class="moskino_park_img mos_park_selector park_img3 moskino_park_img2">
          <img class="mainImage" :src="getImage(activeItem.image, 'banner')" alt="">
          <div class="elements_hover elements_hover3 selector_hover ">
            <div class="elem-hover-img elem1 selector_elem elem_id1" elemhover="elem_id1">
              <img src="/img/statica_moskino/part3/img_stat_1.png" alt="">
            </div>
<!--            <div class="elem-hover-img elem2 selector_elem elem_id2" elemhover="elem_id2">-->
<!--              <img src="/img/statica_moskino/part3/img_stat_2.png" alt="">-->
<!--            </div>-->
            <div class="elem-hover-img elem3 selector_elem elem_id2" elemhover="elem_id2">
              <img src="/img/statica_moskino/part3/img_stat_3.png" alt="">
            </div>
            <div class="elem-hover-img elem4 selector_elem elem_id3" elemhover="elem_id3">
              <img src="/img/statica_moskino/part3/img_stat_4.png" alt="">
            </div>
            <div class="elem-hover-img elem5_1 selector_elem elem_id4" elemhover="elem_id4">
              <img src="/img/statica_moskino/part3/img_stat_5_1.png" alt="">
            </div>
            <div class="elem-hover-img elem5_2 selector_elem elem_id4" elemhover="elem_id4">
              <img src="/img/statica_moskino/part3/img_stat_5_2.png" alt="">
            </div>
            <div class="elem-hover-img elem5_3 selector_elem elem_id4" elemhover="elem_id4">
              <img src="/img/statica_moskino/part3/img_stat_5_3.png" alt="">
            </div>
            <div class="elem-hover-img elem6 selector_elem elem_id5" elemhover="elem_id5">
              <img src="/img/statica_moskino/part3/img_stat_6.png" alt="">
            </div>
            <div class="elem-hover-img elem7 selector_elem elem_id6" elemhover="elem_id6">
              <img src="/img/statica_moskino/part3/img_stat_7.png" alt="">
            </div>
          </div>
          <div class="elements_lines elements-lines3">
            <div class="elem-line line1 elem_id1">
              <svg width="137" height="111" viewBox="0 0 137 111" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M134.999 2.50013L89.4994 100.5L1.99945 109"  stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
<!--            <div class="elem-line line2 elem_id2">-->
<!--              <svg width="105" height="85" viewBox="0 0 105 85" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M2.00028 82.5L103 1.99988"  stroke-width="4" stroke-linecap="round"/>-->
<!--              </svg>-->
<!--            </div>-->
            <div class="elem-line line3 elem_id2">
              <svg xmlns="http://www.w3.org/2000/svg" width="93" height="140" stroke="white" viewBox="0 0 93 140" fill="none">
                <path d="M2.25 2L2.25006 100L91 138"  stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line4 elem_id3">
              <svg width="112" height="133" viewBox="0 0 112 133" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M109.5 130.5L2.5 2"  stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line5_1 elem_id4">
              <svg width="84" height="65" viewBox="0 0 84 65" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.49982 63.0001L81.4996 2.49982"  stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line5_2 elem_id4">
              <svg width="65" height="70" viewBox="0 0 65 70" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M62.5005 68L2.5 2.49976"  stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line5_3 elem_id4">
              <svg width="108" height="71" viewBox="0 0 108 71" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.00016 68.5L106 2.50005"  stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line6 elem_id5">
              <svg width="49" height="205" viewBox="0 0 49 205" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 202.5L47 2.5"  stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="elem-line line7 elem_id6">
              <svg width="118" height="215" viewBox="0 0 118 215" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M115.5 2.5L115.5 174.5L1.99987 212.5" stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>


          </div>
          <div class="elements-pin elements-pin3 selector_hover">
            <div class="pin pin1 selector_elem elem_id1" elemhover="elem_id1">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">1</div>
            </div>
<!--            <div class="pin pin2 selector_elem elem_id2" elemhover="elem_id2">-->
<!--              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>-->
<!--                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>-->
<!--              </svg>-->
<!--              <div class="num">2</div>-->
<!--            </div>-->
            <div class="pin pin3 selector_elem elem_id2" elemhover="elem_id2">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">2</div>
            </div>
            <div class="pin pin4 selector_elem elem_id3" elemhover="elem_id3">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">3</div>
            </div>
            <div class="pin pin5 selector_elem elem_id4" elemhover="elem_id4">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">4</div>
            </div>
            <div class="pin pin5_1 selector_elem elem_id4" elemhover="elem_id4">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">4</div>
            </div>
            <div class="pin pin6 selector_elem elem_id5" elemhover="elem_id5">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">5</div>
            </div>
            <div class="pin pin7 selector_elem elem_id6" elemhover="elem_id6">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
              </svg>
              <div class="num">6</div>
            </div>
          </div>
        </div>
          <div class="moskino_park_img mos_park_selector park_img4 moskino_park_img2">
            <img class="mainImage" :src="getImage(activeItem.image, 'banner')" alt="">
            <div class="elements_hover elements_hover4 selector_hover ">
<!--              <div class="elem-hover-img elem1 selector_elem elem_id1" elemhover="elem_id1">-->
<!--                <img src="/img/statica_moskino/part4/img_stat_1.png" alt="">-->
<!--              </div>-->
              <div class="elem-hover-img elem2 selector_elem elem_id1" elemhover="elem_id1">
                <img src="/img/statica_moskino/part4/img_stat_2.png" alt="">
              </div>
              <div class="elem-hover-img elem3 selector_elem elem_id2" elemhover="elem_id2">
                <img src="/img/statica_moskino/part4/img_stat_3.png" alt="">
              </div>
              <div class="elem-hover-img elem4 selector_elem elem_id3" elemhover="elem_id3">
                <img src="/img/statica_moskino/part4/img_stat_4.png" alt="">
              </div>
<!--              <div class="elem-hover-img elem5 selector_elem elem_id4" elemhover="elem_id4">-->
<!--                <img src="/img/statica_moskino/part4/img_stat_5.png" alt="">-->
<!--              </div>-->
              <div class="elem-hover-img elem6 selector_elem elem_id4" elemhover="elem_id4">
                <img src="/img/statica_moskino/part4/img_stat_6.png" alt="">
              </div>
              <div class="elem-hover-img elem7 selector_elem elem_id5" elemhover="elem_id5">
                <img src="/img/statica_moskino/part4/img_stat_7.png" alt="">
              </div>
              <div class="elem-hover-img elem8 selector_elem elem_id6" elemhover="elem_id6">
                <img src="/img/statica_moskino/part4/img_stat_8.png" alt="">
              </div>
            </div>
            <div class="elements_lines elements-lines4">
<!--              <div class="elem-line line1 elem_id1">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="238" height="88" stroke="white" viewBox="0 0 238 88" fill="none">-->
<!--                  <path d="M2.00085 86.0001L236.001 2.50001"  stroke-width="4" stroke-linecap="round"/>-->
<!--                </svg>-->
<!--              </div>-->
              <div class="elem-line line2 elem_id1">
                <svg width="126" height="52" viewBox="0 0 126 52" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.50034 1.99982L124 49.9998"  stroke-width="4" stroke-linecap="round"/>
                </svg>
              </div>
              <div class="elem-line line3 elem_id2">
                <svg width="25" height="102" viewBox="0 0 25 102" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 99.5L23 2"  stroke-width="4" stroke-linecap="round"/>
                </svg>
              </div>
              <div class="elem-line line4 elem_id3">
                <svg width="253" height="191" viewBox="0 0 253 191" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.50107 189L250.501 2.00002"  stroke-width="4" stroke-linecap="round"/>
                </svg>
              </div>
<!--              <div class="elem-line line5 elem_id4">-->
<!--                <svg width="250" height="103" viewBox="0 0 250 103" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M247.5 2L2.49974 100.5"  stroke-width="4" stroke-linecap="round"/>-->
<!--                </svg>-->
<!--              </div>-->
              <div class="elem-line line6 elem_id4">
                <svg xmlns="http://www.w3.org/2000/svg" width="97" stroke="white" height="140" viewBox="0 0 82 123" fill="none">
                  <path d="M80 121L2 2.5"  stroke-width="4" stroke-linecap="round"/>
                </svg>
              </div>
              <div class="elem-line line7 elem_id5">
                <svg width="114" height="111" viewBox="0 0 114 111" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M111.999 2.00001L2.49926 109"  stroke-width="4" stroke-linecap="round"/>
                </svg>
              </div>
              <div class="elem-line line8 elem_id6">
                <svg width="4" height="191" viewBox="0 0 4 191" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 189L2 2.50024"  stroke-width="4" stroke-linecap="round"/>
                </svg>
              </div>
            </div>
            <div class="elements-pin elements-pin4 selector_hover">
<!--              <div class="pin pin1 selector_elem elem_id1" elemhover="elem_id1">-->
<!--                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>-->
<!--                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>-->
<!--                </svg>-->
<!--                <div class="num">1</div>-->
<!--              </div>-->
              <div class="pin pin2 selector_elem elem_id1" elemhover="elem_id1">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">1</div>
              </div>
              <div class="pin pin3 selector_elem elem_id2" elemhover="elem_id2">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">2</div>
              </div>
              <div class="pin pin4 selector_elem elem_id3" elemhover="elem_id3">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">3</div>
              </div>
<!--              <div class="pin pin5 selector_elem elem_id4" elemhover="elem_id4">-->
<!--                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>-->
<!--                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>-->
<!--                </svg>-->
<!--                <div class="num">4</div>-->
<!--              </div>-->
              <div class="pin pin6 selector_elem elem_id4" elemhover="elem_id4">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">4</div>
              </div>
              <div class="pin pin7 selector_elem elem_id5" elemhover="elem_id5">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">5</div>
              </div>
              <div class="pin pin8 selector_elem elem_id6" elemhover="elem_id6">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="top-pin" d="M61 27.2C61 43.1 39.5 59 39.5 59C39.5 59 18 43.1 18 27.2C18 21.5774 20.2652 16.1851 24.2972 12.2093C28.3292 8.23357 33.7978 6 39.5 6C45.2022 6 50.6708 8.23357 54.7028 12.2093C58.7348 16.1851 61 21.5774 61 27.2Z" stroke="#ED850A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  <path class="bot-pin" d="M49 69.5C49 70.104 48.4077 70.9839 46.6607 71.7701C45.0075 72.514 42.6535 73 40 73C37.3465 73 34.9925 72.514 33.3393 71.7701C31.5923 70.9839 31 70.104 31 69.5C31 68.896 31.5923 68.0161 33.3393 67.2299C34.9925 66.486 37.3465 66 40 66C42.6535 66 45.0075 66.486 46.6607 67.2299C48.4077 68.0161 49 68.896 49 69.5Z" fill="white" stroke-width="2" stroke="#ED850A"/>
                </svg>
                <div class="num">6</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </container>
    

  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "moskinoCinemaPark",
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      activeItem: false,
      listChildrenShow: false,
      imgIndex: 0,
    }
  },
  computed: {
    slideList() {
      if (this.activeItem) {
        return [...this.activeItem?.slideListChild]
      }
    },
    ...mapGetters([
      'widthSite'
    ])
  },
  watch: {
    activeItem() {
      this.listChildrenShow = false;
      setTimeout(() => {
        this.listChildrenShow = true;
        this.refresh_list();
      });
    },
  },
  methods: {
    mapLinks(){
      document.querySelectorAll('.moskino-content .selector_hover .selector_elem').forEach(element => {
        if (element.getAttribute('elemhover')){
          let attr = element.getAttribute('elemhover');
          element.addEventListener('mouseover', () => {
            document.querySelectorAll('.elements_hover .elem-hover-img').forEach( elem => {
              if (elem.classList.contains(attr)){
                elem.classList.add('elem-hover');
              }
            })
            document.querySelectorAll('.elements_lines .elem-line').forEach( elem => {
              if (elem.classList.contains(attr)){
                elem.classList.add('elem-hover');
              }
            })
            document.querySelectorAll('.elements-pin .pin').forEach( elem => {
              if (elem.classList.contains(attr)){
                elem.classList.add('elem-hover');
              }
            })
            document.querySelectorAll('.moskino-content .moskino_park_list .div_el').forEach( elem => {
              if (elem.classList.contains(attr)){
                elem.classList.add('elem-hover');
              }
            })
          })
          element.addEventListener('mouseout', () => {
            document.querySelectorAll('.elements_hover .elem-hover-img').forEach( elem => {
              if (elem.classList.contains(attr)){
                elem.classList.remove('elem-hover');
              }
            })
            document.querySelectorAll('.elements_lines .elem-line').forEach( elem => {
              if (elem.classList.contains(attr)){
                elem.classList.remove('elem-hover');
              }
            })
            document.querySelectorAll('.elements-pin .pin').forEach( elem => {
              if (elem.classList.contains(attr)){
                elem.classList.remove('elem-hover');
              }
            })
            document.querySelectorAll('.moskino-content .moskino_park_list .div_el').forEach( elem => {
              if (elem.classList.contains(attr)){
                elem.classList.remove('elem-hover');
              }
            })
          })
        }
      })
    },
    colorLeftMenu(){
      document.querySelectorAll('.elements_hover').forEach( element => {
        element.addEventListener('click', () => {
          let k = event.target.getAttribute('elemhover')
          document.querySelectorAll('.moskino-content .moskino_park_list .div_el').forEach(el => {
            if (el.classList.contains(k)){
              if (el.firstChild.getAttribute('href')){
                el.firstChild.click();
                this.$nextTick(() => {
                  this.containerHeight = this.$el.querySelector('.content').offsetHeight;
                });
              } else {
                console.log('Ошибка: не задана ссылка')
              }
            }
          })
        })
      })
    },
    refresh_list(){
      document.querySelectorAll('.moskino-content .moskino_park_list .div_el').forEach(element => {
        let attr = element.getAttribute('elemhover');
        element.addEventListener('mouseover', () => {
          document.querySelectorAll('.elements_hover .elem-hover-img').forEach( elem => {
            if (elem.classList.contains(attr)){
              elem.classList.add('elem-hover');
            }
          })
          document.querySelectorAll('.elements_lines .elem-line').forEach( elem => {
            if (elem.classList.contains(attr)){
              elem.classList.add('elem-hover');
            }
          })
          document.querySelectorAll('.elements-pin .pin').forEach( elem => {
            if (elem.classList.contains(attr)){
              elem.classList.add('elem-hover');
            }
          })
        })
        element.addEventListener('mouseout', () => {
          document.querySelectorAll('.elements_hover .elem-hover-img').forEach( elem => {
            if (elem.classList.contains(attr)){
              elem.classList.remove('elem-hover');
            }
          })
          document.querySelectorAll('.elements_lines .elem-line').forEach( elem => {
            if (elem.classList.contains(attr)){
              elem.classList.remove('elem-hover');
            }
          })
          document.querySelectorAll('.elements-pin .pin').forEach( elem => {
            if (elem.classList.contains(attr)){
              elem.classList.remove('elem-hover');
            }
          })
        })
      })
    },
    changeMap(id){
      let newEl, oldEl;
      newEl = id;
      oldEl = this.imgIndex;
      // console.log(oldEl + ' => ' + newEl)
      let listMap = document.querySelectorAll('.moskino_img .moskino_park_img.mos_park_selector');
      if (listMap[oldEl]) {
        listMap[oldEl].classList.add('animate');

        listMap[oldEl].addEventListener('transitionend', function() {
          this.classList.remove('animate');
        });
      }

      listMap.forEach(elem => {
        elem.classList.remove('d-block');
      });

      if (listMap[newEl]) {
        listMap[newEl].classList.add('d-block');
      }

      let k = document.querySelector('.moskino_park_list');


      this.imgIndex = newEl;
    }
  },
  mounted() {
    if (this.dataInfo.slideList) {
      this.activeItem = this.dataInfo.slideList[0];
    }
    const interval = setInterval(() => {
        if(document.querySelector(".block_wrap.moskino_park") && document.querySelector(".moskino_park_img img")){
          if(document.querySelector(".block_wrap.moskino_park").offsetHeight == document.querySelector(".moskino_park_img").offsetHeight){
              clearInterval(interval);
          } else {
            document.querySelector(".block_wrap.moskino_park").style.minHeight = document.querySelector(".moskino_park_img").offsetHeight + "px";
          }
        }
    }, 300)
    window.addEventListener("resize", () => {
      if(document.querySelector(".block_wrap.moskino_park") && document.querySelector(".moskino_park_img")){
        document.querySelector(".block_wrap.moskino_park").style.minHeight = document.querySelector(".moskino_park_img").offsetHeight + "px";
      }
    });
    if (this.dataInfo.slideList.length > 0){
      this.mapLinks();
      this.colorLeftMenu();
    }
  },
}
</script>

<style lang="scss" scoped>

.moskino_park {
  position: relative;
  padding: 50px 0 130px 0;
  min-height: 90vh;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    padding: 4.8rem 0 8rem;
  }
  .moskino_park_img {
    position: relative;
    min-height: 864px;
    left: 0;
    right: 0;
    top: 0;
    flex: none;
    img {
      max-width: 1728px;
      width: 100%;
    }
    @media (min-width: 1920px) {
      right: 0;
    }
    @media (max-width: 767px) {
      position: relative;
      inset: unset;
      order: 3;
      display: flex;
      justify-content: flex-end;
      img{
        width: 140%;
      }
    }
  }
  h2 {
    margin-bottom: 0;
    z-index: 4;
    position: relative;
  }
}

.moskino_park_menu {
  color: #C7CAD1;
  text-transform: uppercase;
  margin-bottom: 132px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  z-index: 4;
  @media (max-width: 991px) {
    margin-bottom: 4rem;
  }
  .div_el {
    cursor: pointer;
    &.active {
      color: #ED850A;
      cursor: default;
    }
  }
}

.moskino_park_list {
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  max-height: 700px;
  overflow: hidden;
  z-index: 10;
  .div_el{
    transition: all .4s ease;
  }
  @media(max-width: 1100px){
    max-height: 400px;
  }
}

$top-height: 10rem;

@for $i from 0 through 8 {
  .moskino_park_list .div_el.fade-leave-to:nth-child(#{$i+1}) {
    top: calc( ((3.2rem * 1.25) + 40px) * $i + $top-height);
    @media(max-width: 1025px){
      top: calc( ((3.2rem * 1.25) + 26px) * $i + $top-height);
    }
    @media(max-width: 991px){
      top: calc( ((1.6rem * 1.5) + 22px) * $i);
    }
    @media(max-width: 767px){
      top: calc( ((1.6rem * 1.5) + 12px) * $i);
    }
  }
}

/* Стили для анимации */

.fade-leave-to{
  opacity: 0;
  position: absolute;
  flex-grow: 0;
}
.fade-leave-active{
  opacity: 0;
}
.moskino_park_list .div_el{
  transition: opacity .6s ease;
}
.faded-enter-active,
.faded-leave-active {
  transition: opacity 0.5s;
}
.faded-enter,
.faded-leave-to {
  opacity: 0;
}
.fade-enter-active {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.slide-in-move,
.slide-in-enter-active{
  transition: all 0.3s ease;
}
.slide-in-enter-from{
  opacity: 0;
}
/* Стили карты */
.moskino_park{
  &.moskino_park_2{
    top: 0;
    @media(min-width: 1024px){
      .moskino_park_menu{
        margin-bottom: 0;
      }
    }
  }
}
.moskino_img{
  margin-left: auto;
  position: absolute;
  right: -5rem;
  top: 0;
  width: fit-content;
  margin-left: 10rem;
  @media(max-width: 1500px){
    margin-left: 5rem;
  }
  @media(max-width: 1360px){
    margin-left: 20rem;
  }
  @media(max-width: 1320px){
    margin-left: 25rem;
    right: -25rem;
  }
  @media(max-width: 1150px){
    right: 0;
  }
  @media(max-width: 991px){
    position: relative;
    margin-left: auto;
  }
}

.moskino-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 750px;
  .moskino_park_list{
    min-width: 420px;
    height: 100%;
    margin-top: 0;
    margin-bottom: auto;
    margin-top: 10rem;
    .div_el{
      transition: height .4s ease-in, opacity .4s ease-in;
      a:hover{
        color: #ED850A;
      }
      @media(max-width: 991px){
        a{
          color: #B2B5BB;
        }
      }
      &.elem-hover{
        a {
          color: #ED850A;
        }
      }
    }
  }
  @media(max-width: 1250px){
    min-height: 690px;
  }
  @media(max-width: 1025px){
    min-height: 505px;
  }
  .moskino_park_img{
    position: absolute;
  }
  .elements_hover{
    .elem-hover-img{
      position: absolute;
      top: 0;
      right: 0;
      height: 130px;
      width: 130px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 3;
      overflow: hidden;
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        border: 6px solid white;
        z-index: 4;
      }
      img{
        width: 100%;
        height: 100%;
      }
      &.elem-hover{
        &:after{
          border-color: orange;
        }
      }
    }
    &.elements_hover1{
      .elem-hover-img{
        cursor: pointer;
        &.elem1{
          top: 25%;
          right: 18%;
        }
        &.elem1_1{
          top: 41%;
          right: 18%;
        }
        &.elem2{
          top: 7%;
          right: 30%;
        }
        &.elem2_1{
          top: 7%;
          right: 18%;
        }
        &.elem3{
          right: unset;
          left: 26px;
          top: 41%;
        }
        &.elem3{
          right: unset;
          left: 26px;
          top: 41%;
        }
        &.elem3_1{
          right: unset;
          left: 26px;
          top: 57%;
        }
        &.elem4{
          right: unset;
          top: 82%;
          left: 26px;
        }
        &.elem5{
          top: 82%;
          right: 18%;
        }
        &.elem6{
          right: unset;
          left: 26px;
          top: 7%;
        }
        &.elem6_1{
          left: 26px;
          top: 23%;
          right: unset;
        }
      }
    }
  }
  .elements_lines{
    .elem-line{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      &.line1_1{
        top: 33%;
        right: 29%;
        left: unset;
      }
      &.line1_2{
        top: 48%;
        right: 29%;
        left: unset;
      }
      &.line2_1{
        top: 20%;
        right: 36%;
        left: unset;
      }
      &.line2_2{
        top: 18%;
        right: 27%;
        left: unset;
      }
      &.line3_1{
        top: 47%;
        left: 13%;
      }
      &.line3_2{
        top: 56%;
        left: 13%;
      }
      &.line4{
        top: 58%;
        left: 10%;
      }
      &.line5{
        top: 60%;
        left: unset;
        right: 24%;
      }
      &.line6_1{
        top: 14.6%;
        left: 12.9%;
      }
      &.line6_2{
        top: 29.6%;
        left: 12.9%;
      }
      &.elem-hover{
        svg{
          stroke: orange;
        }
      }
    }
  }
  .elements-pin{
    .pin{
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      cursor: pointer;
      .num{
       font-size: 24px;
       font-style: normal;
       font-weight: 600;
       line-height: 28px; /* 116.667% */
       letter-spacing: 0.48px;

       color: #ED850A;
       position: absolute;
       text-align: center;
       top: 14px;
       left: 0;
       right: 0;
      }
      &.pin1{
        top: 43%;
        right: 57.5%;
      }
      &.pin2{
        top: 31%;
        right: 46.5%;
      }
      &.pin3{
        top: 48.2%;
        left: 26.6%;
        right: unset;
      }
      &.pin4{
        top: 50%;
        left: 31.5%;
        right: unset;
      }
      &.pin5{
        top: 52%;
        right: 38%;
      }
      &.pin6{
        right: unset;
        left: 37%;
        top: 33.5%;
        z-index: 12;
      }
      &.elem-hover{
        .num{
          color: white;
        }
        svg{
          .top-pin{
            fill: #ED850A;
            stroke: white;
          }
          .bot-pin{
            fill: #ED850A;
            stroke: white;
          }
        }
      }
    }
  }
  .elements_hover.elements_hover2{
    .elem-hover-img{
      &.elem1{
        top: 24.5%;
        right: unset;
        left: 1%;
      }
      &.elem2_1{
        left: 1%;
        right: unset;
        top: 45%;
      }
      &.elem2_2{
        left: 1%;
        right: unset;
        top: 61%;
      }
      &.elem3{
        top: 86%;
        right: 12%;
      }
      &.elem4_1{
        top: 35.5%;
        right: 12%;
      }
      &.elem4_2{
        top: 51.5%;
        right: 12%;
      }
      &.elem5_1{
        right: 24.8%;
        top: 6%;
      }
      &.elem5_2{
        right: 24.8%;
        top: 22%;
      }
    }
  }
  .elements-pin.elements-pin2 .pin{
    &.pin1{
      top: 38.4%;
      right: 54.5%;
    }
    &.pin2{
      top: 47.2%;
      right: unset;
      left: 31.2%;
    }
    &.pin3{
      top: 64.2%;
      right: 37.4%;
      left: unset;
    }
    &.pin4{
      top: 47.5%;
      right: 46%;
      left: unset;
    }
    &.pin5{
      top: 13%;
      right: 53%;
    }
  }
  .elements_lines.elements-lines2 {
    .elem-line{
      &.line1_1{
        top: 32%;
        right: unset;
        left: 12%;
      }
      &.line2_1{
        top: 51%;
        right: unset;
        left: 12%;
      }
      &.line2_2{
        top: 72%;
        right: 20%;
        left: unset;
      }
      &.line3{
        top: 55%;
        right: unset;
        left: 12%;
      }
      &.line4_1{
        top: 42%;
        right: 23%;
        left: unset;
      }
      &.line4_2{
        top: 55%;
        right: 23%;
        left: unset;
      }
      &.line5_1{
        left: unset;
        right: 35%;
        top: 21%;
      }
      &.line5_2{
        left: unset;
        right: 35%;
        top: 11%;
      }
    }
  }
  .elements-pin.elements-pin3 .pin{
    &.pin1{
      top: 50%;
      right: 59%;
    }
    &.pin2{
      top: 72.5%;
      right: 22%;
    }
    &.pin3{
      top: 59.5%;
      left: unset;
      right: 16%;
    }
    &.pin4{
      top: 67.5%;
      right: 18.5%;
      left: unset;
    }
    &.pin5{
      top: 6.5%;
      left: 9.5%;
      right: unset;
    }
    &.pin5_1{
      top: 47%;
      right: unset;
      left: 16%;
    }
    &.pin6{
      top: 56.5%;
      left: 30%;
      right: unset;
    }
    &.pin7{
      top: 69.5%;
      left: unset;
      right: 15%;
    }
  }
  .elements_hover.elements_hover3{
    .elem-hover-img{
      &.elem1{
        top: 35.5%;
        right: unset;
        left: 49%;
      }
      &.elem2{
        top: 88%;
        right: 31%;
        left: unset;
      }
      &.elem3{
        top: 38%;
        right: 21.5%;
        left: unset;
      }
      &.elem4{
        top: 88%;
        right: 2.5%;
        left: unset;
      }
      &.elem5_1{
        right: unset;
        left: 0;
        top: 21%;
      }
      &.elem5_2{
        right: unset;
        left: 13%;
        top: 21%;
      }
      &.elem5_3{
        right: unset;
        left: 0%;
        top: 59%;
      }
      &.elem6{
        right: unset;
        left: 23%;
        top: 87.5%;
      }
      &.elem7{
        top: 39%;
        right: 2.5%;
        left: unset;
      }
    }
  }
  .elements_lines.elements-lines3 {
    .elem-line{
      &.line1{
        top: 45.5%;
        right: 50%;
        left: unset;
      }
      &.line2{
        top: 80.5%;
        right: 25%;
        left: unset;
      }
      &.line3{
        top: 52%;
        right: 19%;
        left: unset;
      }
      &.line4{
        top: 76%;
        right: 12%;
        left: unset;
      }
      &.line5_1{
        top: 14%;
        right: unset;
        left: 6%;
      }
      &.line5_2{
        top: 14%;
        right: unset;
        left: 13%;
      }
      &.line5_3{
        top: 55%;
        right: unset;
        left: 10%;
      }
      &.line6{
        top: 64%;
        right: unset;
        left: 29.5%;
      }
      &.line7{
        top: 53%;
        right: 8%;
        left: unset;
      }
    }
  }
  .elements_hover.elements_hover4{
    .elem-hover-img{
      &.elem1{
        right: unset;
        left: 8%;
        top: 65%;
      }
      &.elem2{
        right: unset;
        left: 8%;
        top: 41%;
      }
      &.elem3{
        right: unset;
        left: 35%;
        top: 88%;
      }
      &.elem4{
        right: unset;
        left: 8%;
        top: 88%;
      }
      &.elem5{
        right: 13%;
        left: unset;
        top: 41%;
      }
      &.elem6{
        right: 32%;
        left: unset;
        top: 88%;
      }
      &.elem7{
        right: 32%;
        left: unset;
        top: 41%;
      }
      &.elem8{
        right: 13%;
        left: unset;
        top: 88%;
      }
    }
  }
  .elements-pin.elements-pin4 .pin{
    &.pin1{
      top: 54%;
      left: 36%;
      right: unset;
    }
    &.pin2{
      top: 51.5%;
      left: 24.2%;
      right: unset;
    }
    &.pin3{
      top: 69.2%;
      left: 39.3%;
      right: unset;
    }
    &.pin4{
      top: 63.5%;
      left: 36.3%;
      right: unset;
    }
    &.pin5{
      top: 59%;
      right: 38.2%;
    }
    &.pin6{
      top: 67%;
      right: 46.2%;
      left: unset;
    }
    &.pin7{
      top: 57.5%;
      right: 48.5%;
      left: unset;
    }
    &.pin8{
      top: 59.5%;
      right: 15.2%;
    }
  }
  .elements_lines.elements-lines4 {
    .elem-line {
      &.line1 {
        top: 62%;
        left: 19%;
      }
      &.line2 {
        top: 54%;
        left: 17%;
      }
      &.line3 {
        top: 77%;
        left: 41%;
      }
      &.line4 {
        top: 71%;
        left: 18%;
      }
      &.line5 {
        top: 55%;
        right: 20%;
      }
      &.line6 {
        right: 41%;
        left: unset;
        top: 75%;
      }
      &.line7 {
        top: 53%;
        right: 42%;
        left: unset;
      }
      &.line8 {
        top: 68%;
        right: 18.5%;
        left: unset;
      }
    }
  }
}

@media (max-width: 767px){
  .moskino_park{
    .pos_rel{
      h2{
        margin-bottom: 0.8rem;
      }
      .multiselect{
        width: max-content;
        max-width: 100%;
        margin-bottom: 3.2rem;
        .multiselect__single{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .multiselect__content-wrapper{
          max-width: 100%;
          .multiselect__content{
            max-width: 100%;
            overflow: hidden;
          }
          .multiselect__option{
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@media(max-width: 1650px){
  .moskino_park.moskino_park_2{
    .moskino-content{
      .moskino_park_img{
        min-height: unset;
        width: 984px;
        right: 30px;
        left: unset;
        .mainImage{
          left: 0px;
          position: relative;
          top: -8px;
          user-select: none;
        }
      }
      .elements-pin{
        .pin{
          height: 55px;
          width: 55px;
          .num{
            font-size: 20px;
            top: 6px;
          }
        }
      }
      .elements_hover{
        .elem-hover-img {
          height: 112px;
          width: 112px;
          &:after{
            border: 3px solid white;
          }
          &.elem-hover{
            &:after{
              border-color: orange;
            }
          }
        }
        &.elements_hover1{
          .elem-hover-img {
            &.elem6_1{
              //top: 26%;
              //right: 18%;
            }
          }
        }
      }
      .elements_lines{
        .elem-line {
          &.line1_1{
            width: 307px;
            transform: rotate(4deg);
          }
          &.line1_2{
            width: 309px;
            transform: rotate(5deg);
          }
          &.line2_1{
            width: 132px;
            transform: rotate(2deg);
          }
          &.line2_2{
            width: 212px;
            transform: rotate(2deg);
          }
          &.line3_1{
            width: 162px;
            transform: rotate(-5deg);
          }
          &.line3_2{
            width: 173px;
            transform: rotate(-9deg);
          }
          &.line4{
            width: 248px;
            transform: rotate(-6deg);
          }
          &.line5_1{
            //заполнить при показе 5 пункта
          }
          &.line5_2{
            //заполнить при показе 5 пункта
          }
          &.line6_1 {
            width: 261px;
            transform: rotate(-3deg);
          }
          &.line6_2{
            width: 263px;
            transform: rotate(-3deg);
          }
        }
        &.elements-lines2{
          .elem-line {
            &.line1_1 {
              transform: rotate(-6deg);
              width: 304px;
            }
            &.line2_1{
              width: 221px;
              transform: rotate(-5deg);
            }
            &.line3{
              width: 235px;
              transform: rotate(-7deg);
              left: 11%;
            }
            &.line2_2{
              transform: rotate(8deg);
            }
            &.line4_1{
              width: 257px;
              transform: rotate(3deg);
            }
            &.line4_2{
              width: 263px;
              transform: rotate(4deg);
            }
            &.line5_1{
                width: 217px;
                transform: rotate(9deg);
            }
            &.line5_2{
              width: 210px;
              transform: rotate(7deg);
            }
          }
        }
        &.elements-lines3 {
          .elem-line {
            &.line1 {
              width: 116px;
              transform: rotate(10deg);
            }
            &.line2{
              top: 79%;
            }
            &.line3{
              width: 69px;
            }
            &.line4{
              width: 120px;
              transform: rotate(8deg);
              top: 75%;
              right: 10%;
            }
            &.line5_1{
              transform: rotate(-20deg);
              width: 73px;
            }
            &.line5_2{
              transform: rotate(2deg);
              top: 13%;
              left: 12%;
            }
            &.line5_3{
              transform: rotate(-9deg);
              left: 9%;
              top: 54%;
            }
            &.line6{
              top: 63%;
              transform: rotate(-8deg);
            }
            &.line7{
              width: 95px;
            }
          }
        }
        &.elements-lines4 {
          .elem-line {
            &.line1 {
              width: 99px;
              top: 53%;
            }
            &.line2{
              top: 51%;
              left: 14.5%;
            }
            &.line3{
              top: 76%;
              left: 39.5%;
            }
            &.line4{
              top: 69.5%;
              right: unset;
              width: 226px;
              transform: rotate(2deg);
              left: 16%;
            }
            &.line5{
              right: 21%;
              width: 200px;
              top: 54.5%;
              transform: rotate(0deg);
            }
            &.line6{
              width: 85px;
              top: 73%;
            }
            &.line7{
              top: 49%;
              right: 40%;
            }
            &.line8{
              top: 66%;
              right: 18%;
            }
          }
        }
      }
    }
  }
}

@media(max-width: 1025px) and (min-width: 992px){
  .moskino-content .moskino_park_list .div_el a{
    font-size: 2.4rem;
  }
}

@media(max-width: 1400px) and (min-width: 1024px){
  .moskino_park.moskino_park_2 .moskino-content{
    .elements_hover.elements_hover3{
      .elem-hover-img{
        &.elem3{
          right: 25.5%;
        }
        &.elem4{
          right: 11%;
        }
        &.elem5_3{
          left: 2%;
        }
        &.elem5_2{
          left: 15%;
        }
        &.elem5_1{
          left: 2%;
        }
        &.elem7{
          right: 12%;
          top: 38%;
        }
      }
    }
    .elements_lines.elements-lines3{
      .elem-line {
        &.line3{
          width: 77px;
          top: 50.5%;
          transform: rotate(-2deg);
        }
        &.line7{
          transform: rotate(-30deg);
          right: 13%;
          top: 51%;
        }
      }
    }
  }
}

@media(max-width: 1024px){
  .moskino_park.moskino_park_2{
    h2{
      margin-bottom: 8px;
    }
    .moskino_park_menu{
      margin-bottom: 40px;
      flex-wrap: nowrap;
    }
    .multiselect{
      margin-bottom: 32px;
    }
    .moskino-content{
      .moskino_park_img{
        width: 620px;
         .mainImage{
          left: -10px;
          position: relative;
          top: -8px;
        }
      }
      .elements-pin{
        .pin{
          height: 34px;
          width: 34px;
          .num{
            font-size: 13px;
            top: -1px;
          }
        }
        &.elements-pin4 .pin.pin8{
          right: 16%;
        }
      }
      .moskino_park_list{
        gap: 24px;
      }
      .elements_hover{
        .elem-hover-img {
          height: 80px;
          width: 80px;
          &:after{
            border: 3px solid white;
          }
          &.elem-hover{
            &:after{
              border-color: orange;
            }
          }
        }
        &.elements_hover1{
          .elem-hover-img {
            &.elem1{
              top: 26%;
              right: 18%;
            }
            &.elem1_1{
              top: 45%;
              right: 18%;
            }
            &.elem2{
              top: 7%;
              right: 18%;
            }
            &.elem2_1{
              top: 7%;
              right: 33%;
            }
            &.elem3{
              left: 4%;
              top: 44%;
            }
            &.elem3_1{
              left: 4%;
              top: 62%;
            }
            elem4 {
              top: 82%;
              left: 4%;
            }
            &.elem6{
              right: unset;
              left: 4%;
              top: 8%;
            }
            &.elem6_1{
              left: 26px;
              top: calc(26%);
              right: unset;
            }
          }
        }
        &.elements_hover2{
          .elem-hover-img {
            &.elem1 {
              top: 26%;
            }
            &.elem2_2{
              top: 64%;
            }
            &.elem4_2{
              top: 54.5%;
            }
            &.elem5_2{
              top: 25%;
            }
          }
        }
        &.elements_hover3{
          .elem-hover-img{
            &.elem5_2{
              left: 15%;
            }
          }
        }
      }
      .elements_lines{
        .elem-line{
          &.line1_1{
            width: 191px;
            transform: rotate(3deg);
          }
          &.line1_2{
            width: 193px;
            transform: rotate(6deg);
          }
          &.line2_1{
            width: 80px;
          }
          &.line2_2{
            width: 132px;
            transform: rotate(3deg);
          }
          &.line3_1{
            width: 100px;
            transform: rotate(-5deg);
          }
          &.line3_2{
            width: 104px;
            transform: rotate(-9deg);
          }
          &.line4{
            width: 161px;
            transform: rotate(-7deg);
          }
          &.line6_1{
            top: 13.6%;
            width: 170px;
            left: 12%;
          }
          &.line6_2{
            width: 164px;
            transform: rotate(-5deg);
          }
        }
        &.elements-lines2{
           .elem-line{
             &.line1_1{
               width: 191px;
               transform: rotate(-6deg);
             }
             &.line2_1{
               transform: rotate(-5deg);
                width: 135px;
             }
             &.line2_2{
               transform: rotate(8deg);
             }
             &.line3{
               width: 142px;
               transform: rotate(-8deg);
             }
             &.line4_1{
               width: 158px;
               transform: rotate(4deg);
             }
             &.line4_2{
               width: 163px;
               transform: rotate(5deg);
             }
             &.line5_1{
               width: 130px;
               transform: rotate(7deg);
             }
             &.line5_2{
               width: 130px;
               transform: rotate(7deg);
             }
           }
        }
        &.elements-lines3{
          .elem-line{
            &.line1{
              width: 72px;
              transform: rotate(10deg);
            }
            &.line2{
              top: 78.5%;
              width: 79px;
            }
            &.line3{
              width: 47px;
              right: 19%;
            }
            &.line4{
              width: 72px;
              transform: rotate(17deg);
              top: 75.5%;
              right: 12%;
            }
            &.line5_1{
              transform: rotate(-19deg);
              top: 14%;
              width: 50px;
            }
            &.line5_2{
              width: 40px;
              transform: rotate(2deg);
              top: 13%;
              left: 12%;
            }
            &.line5_3{
              width: 62px;
              transform: rotate(-19deg);
              left: 10%;
              top: 55%;
            }
            &.line6{
              width: 30px;
              transform: rotate(-8deg);
              top: 63%;
            }
            &.line7{
              width: 59px;
            }
          }
        }
        &.elements-lines4 {
          .elem-line {
            &.line1 {
              width: 132px;
              transform: rotate(-9deg);
            }
            &.line2{
              width: 60px;
              transform: rotate(-9deg);
              top: 54%;
              left: 17%;
            }
            &.line3{
              width: 14.5px;
              top: 76%;
              transform: rotate(-9.5deg);
              left: 40.8%;
            }
            &.line4{
              width: 138px;
              transform: rotate(-6deg);
              left: 18%;
              top: 71%;
            }
            &.line5{
              width: 129px;
              top: 54.3%;
            }
            &.line6{
              width: 52px;
              transform: rotate(-3deg);
              top: 73%;
              right: 40%;
            }
            &.line7{
              width: 58px;
              top: 52%;
              right: 42%;
            }
            &.line8{
              height: 114px;
              top: 66%;
              right: 18.4%;
              svg{
                height: 100%;
              }
            }
          }
        }
      }
      .elements-pin.elements-pin2 .pin.pin2{
        left: 30.2%;
      }
    }
  }
}

@media(max-width: 1023px) and (min-width: 768px){
  .moskino-content .moskino_park_list .div_el a{
    font-size: 2.1rem;
  }
}

@media(max-width: 991px){
  .moskino_park.moskino_park_2{
    padding-bottom: 80px;
    .moskino_park_img{
      margin-left: auto;
      right: -40px;
      left: unset;
    }
    .moskino-content{
      align-items: unset;
      flex-direction: column;
      .moskino_park_list{
        margin: 0;
      }
    }
  }
}

@media(max-width: 767px){
  .container.mobile_hidden{
    overflow: hidden;
    padding-bottom: 20px;
  }
  .moskino_park.moskino_park_2{
    .moskino-content{
      gap: 10px;
      .moskino_park_list {
        gap: 16px;
      }
      .moskino_park_img{
        left: 0;
        right: unset;
        top: 0;
        width: 420px;
        height: 320px;
        .mainImage{
          left: 0;
          top: 3px;
          width: 424px;
        }
        &.park_img3{
          left: -10px;
        }
        &.park_img4{
          left: -30px;
        }
      }
      .elements-pin{
        .pin{
          .num{
            font-size: 9px;
            top: -4px;
          }
        }
        &.elements-pin4 .pin.pin8{
          right: 16%;
        }
      }
      .elements_hover{
        .elem-hover-img {
          height: 56px;
          width: 56px;
        }
      }
      .elements_hover .elem-hover-img:after {
        border: 2px solid white;
      }
      .elements_hover .elem-hover-img.elem-hover:after {
        border-color: orange;
      }
      .elements_lines{
        .elem-line{
          &.line1_1{
            width: 135px;
            top: 35%;
          }
          &.line1_2{
            width: 136px;
            top: 49%;
          }
          &.line2_1{
            top: 21%;
            width: 61px;
          }
          &.line2_2{
            width: 99px;
            top: 20%;
          }
          &.line3_1{
            width: 85px;
            transform: rotate(-16deg);
            left: 11%;
            top: 51%;
          }
          &.line3_2{
            width: 92px;
            transform: rotate(-9deg);
            top: 59%;
            left: 10%;
          }
          &.line4{
            top: 61%;
            left: 12%;
            width: 109px;
          }
          &.line6_1{
            width: 121px;
            top: 14.5%;
            transform: rotate(-1deg);
          }
          &.line6_2{
            width: 116px;
            top: 32%;
          }
        }
        &.elements-lines2{
          .elem-line{
            &.line1_1 {
              width: 129px;
            }
            &.line2_1{
              top: 52%;
              width: 98px;
            }
            &.line2_2{
              top: 75%;
              right: 19.2%;
            }
            &.line3{
              top: 58%;
              width: 99px;
            }
            &.line4_1{
              top: 44%;
              width: 115px;
            }
            &.line4_2{
              top: 56%;
              width: 114px;
            }
            &.line5_1{
              top: 24%;
              width: 98px;
            }
            &.line5_2{
              width: 94px;
              top: 14%;
            }

          }
        }
        &.elements-lines4{
          .elem-line {
            &.line1 {
              width: 92px;
              transform: rotate(1deg);
            }
            &.line2{
              width: 50px;
              transform: rotate(6deg);
            }
            &.line3{
              width: 14.5px;
              top: 79%;
              transform: rotate(-6.5deg);
            }
            &.line4{
              top: 71%;
              left: 13%;
              width: 118px;
              transform: rotate(2deg);
            }
            &.line5{
              width: 96px;
              top: 56.3%;
              right: 20%;
            }
            &.line6{
              width: 43px;
              transform: rotate(0deg);
              top: 76%;
            }
            &.line7{
              width: 49px;
              top: 52%;
              right: 41%;
            }
            &.line8{
              height: 70px;
              top: 69%;
              right: 19.5%;
            }
          }
        }
        &.elements-lines3 {
          .elem-line {
            &.line1{
              width: 61px;
              transform: rotate(10deg);
            }
            &.line2{
              right: 26%;
              top: 81.5%;
              width: 39px;
            }
            &.line3{
              right: 20%;
              transform: rotate(-3deg);
              top: 48%;
            }
            &.line4{
              top: 78.5%;
              left: unset;
              right: 14.5%;
              width: 44px;
            }
            &.line7{
              transform: rotate(-45deg);
              top: 49%;
              right: 15%;
              width: 50px;
            }
            &.line5_2{
              top: 15%;
              left: 13%;
              width: 24px;
            }
            &.line5_3 {
              width: 39px;
              transform: rotate(8deg);
            }
            &.line6{
              width: 29px;
              transform: rotate(-8deg);
              top: 66%;
            }
          }
        }
      }
      .elements_hover.elements_hover1{
        .elem-hover-img{
          &.elem1 {
            top: 26%;
            right: 18%;
          }
          &.elem1_1 {
            top: 45%;
            right: 18%;
          }
          &.elem2 {
            top: 7%;
            right: 18%;
          }
          &.elem3{
            left: 0;
            top: 46%;
          }
          &.elem3_1{
            left: 0;
            top: 65%;
          }
          &.elem4{
            left: 0;
            top: 85%;
          }
          &.elem6 {
            right: unset;
            left: 0%;
          }
          &.elem6_1{
            left: 0;
            top: 27%;
          }
        }
      }
      .elements_hover.elements_hover2{
        .elem-hover-img {
          &.elem5_1 {
            right: 28%;
          }
          &.elem5_2{
            right: 28%;
          }
          &.elem4_1{
            right: 16%;
          }
          &.elem4_2{
            right: 16%;
          }
          &.elem3{
            right: 16%;
          }
        }
      }
      .elements_hover.elements_hover3 {
        .elem-hover-img {
          &.elem1 {
            left: 44%;
          }
          &.elem3 {
            right: 28.5%;
          }
          &.elem4{
            right: 13.5%;
          }
          &.elem7{
            right: 14.5%;
          }
        }
      }
    }
  }
  .multiselect{
    .multiselect__tags{
      height: 36px;
      min-height: 36px;
      padding-left: 10px;
    }
    .multiselect__select {
      height: 36px;
    }
  }
}

// Доработка анимации

.moskino_park_img {
  opacity: 0;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  visibility: hidden;
}

.moskino_park_img.d-block {
  opacity: 1;
  visibility: visible;
}

.moskino_park_img.animate {
  opacity: 0;
  visibility: hidden;
}

// Размеры карты для анимации

.moskino_height_card{
  visibility: hidden !important;
  position: relative !important;
}

.moskino_park.moskino_park_2 .moskino-content .moskino_height_card {
  flex: none;
  @media(min-width: 320px) {
    width: 420px;
    height: 320px;
  }
  @media(min-width: 767px){
    width: 620px;
    height: 469px;
  }
  @media(min-width: 1100px){
    width: 968px;
    height: 744px;
  }
  @media(min-width: 1650px){
    width: 1142px;
    height: 864px;
    img{
      width: 1142px;
      height: 864px;
    }
  }
}

</style>
