<template>
  <div class="row" :class="[alignmentClassNames.xAlign, alignmentClassNames.yAlign]">
    <slot></slot>
  </div>
</template>

<script>
/**
 * Компонент строки для системы сеток на флексах
 * В пропсах принимает значения для выравнивания по осям:
 * x: start, end, between, around, center
 * y: start, end, center
 */
export default {
  name: "row",
  props: {
    xAlign: {
      type: String
    },
    yAlign: {
      type: String
    }
  },
  data() {
    return {
      alignmentClassNames: {
        xAlign: this.xAlign ? `justify-content-${this.xAlign}` : null,
        yAlign: this.yAlign ? `align-items-${this.yAlign}` : null,
      }
    }
  }
}
</script>

<style lang="scss">
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    gap: 24px 0;
    &.justify-content- {
      &start {
        justify-content: flex-start;
      }
      &end {
        justify-content: flex-end;
      }
      &between {
        justify-content: space-between;
      }
      &around {
        justify-content: space-around;
      }
      &center {
        justify-content: center;
      }
    }
    &.align-items- {
      &start {
        align-items: flex-start;
      }
      &end {
        align-items: flex-end;
      }
      &center {
        align-items: center;
      }
    }
    > div {
      padding: 0 12px;
      width: 100%;
    }
  }
  .row_gap {
    gap: 24px 0;
  }
  .nowrap_col{
    flex-wrap: nowrap;
  }
  @media (min-width: 768px) {
    .nowrap_col_md{
      flex-wrap: nowrap;
    }
  }
  @media (min-width: 992px) {
    .nowrap_col_lg{
      flex-wrap: nowrap;
    }
  }
</style>