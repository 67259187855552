<template>
  <span
      class="status"
      :class="[statusType]"
  >
    <span v-if="$slots.ico" class="status_ico"><slot name="ico"></slot></span>
    <span><slot/></span>
  </span>
</template>

<script>

/**
 * В пропсах принимает следующие значения
 * type: light - тип кнопки, по умолчанию default
 * disabled - для отключения
 */
export default {
  name: "ClStatus",
  props: {
    type: String
  },
  data() {
    return {
      statusType: this.type ? `status-${this.type}` : "status-default",
    }
  }
}
</script>

<style lang="scss">
.status {
  border-radius: 29px;
  padding: 4px 16px;
  display: inline-flex;
  align-items: center;
  min-height: 36px;
  position: relative;
  .status_ico {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    flex: none;
  }
  &.status-type1 {
    padding: 0px 12px;
    font-weight: 500;
    min-height: auto;
    border-radius: 0;
  }
  &.status-default {
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, .7);
  }
  &.status-red {
    color: #F43F5E;
    background: rgba(244, 63, 94, 0.30);
  }
  &.status-wh {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, .6);
  }
  &.status-text {
    border-radius: 0;
    padding: 0;
    background: none !important;
    border: none;
    min-height: 1px;
  }
  &.status-ico-wrap {
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      margin-right: 12px;
      flex: none;
      border-radius: 100%;
      @media (max-width: 991px) {
        width: 17px;
        height: 17px;
        margin-right: 10px;
      }
      @media (max-width: 991px) {
        width: 15px;
        height: 15px;
      }
    }
  }
  &.status-ico-wrap2 {
    border-radius: 0;
    padding: 0;
    background: none !important;
    border: none;
    min-height: 1px;
    &:before {
      width: 40px;
      height: 40px;
      margin-right: 4px;
    }
    &.status-approved {
      &:before {
        background-size: 24px;
      }
    }
    &.status-new{
      &:before {
        background-size: 24px;
      }
    }
    &.status-consideration {
      &:before {
        background-size: 24px;
      }
    }
    &.status-rejected {
      &:before {
        background-size: 24px;
      }
    }
    &.status-draft {
      &:before {
        background-size: 24px;
      }
    }
    &.status-lock {
      &:before {
        background-size: 24px;
      }
    }
  }
  &.status-approved {
    background: rgba(16, 185, 129, 0.30);
    color: #10B981;
    &:before {
      background: url(@/assets/img/ico/check-white.svg) no-repeat center center #10B981;
      background-size: 12px;
    }
  }
  &.status-new {
    color: #FFF;
    background: rgba(121, 1, 215, 0.25);
    &:before {
      background: url(@/assets/img/ico/mail_wh14.svg) no-repeat center center #7901D7;
    }
  }
  &.status-consideration {
    color: #ED870A;
    background: rgba(237, 133, 10, 0.30);
    &:before {
      background: url(@/assets/img/ico/clock_outline_24.svg) no-repeat center center #ED850A;
    }
  }
  &.status-rejected {
    color: #F43F5E;
    background: rgba(244, 63, 94, 0.30);
    &:before {
      background: url(@/assets/img/ico/close-wh.svg) no-repeat center center #F43F5E;
      background-size: 14px;
    }
  }
  &.status-draft {
    color: #9096A2;
    background: rgba(144, 150, 162, 0.25);
    &:before {
      background: url(@/assets/img/ico/Edit-Fill.svg) no-repeat center center #9096A2;
    }
  }
  &.status-lock {
    color: #FFF;
    background: rgba(12, 21, 36, 0.45);
    &:before {
      background: url(@/assets/img/ico/bookmark-check.svg) no-repeat center center #0C1524;
    }
  }
  &.status-close {
    position: relative;
    .close_el {
      font-size: 12px;
    }
  }
  &.status-ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    padding: 0;
    background: rgba(255, 255, 255, 0.17);
    &:hover {
      .add_el {
          opacity: .5;
      }
    }
    .add_el {
      font-size: 14px;
    }
  }
  &.color_black {
    color: #000000;
    .close_el {
      &:before,
      &:after {
        background-color: #000000;
      }
    }
  }
  @media (max-width: 991px) {
    min-height: 30px;
  }
  @media (max-width: 767px) {
    min-height: 26px;
  }
}
</style>