<template>
  <div class="block_wrap our_artners" :class="{'block_wh': $attrs.class == ''}">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>

      <!--      Если у вас есть вопрос по сотрудничеству и вы хотите встать на нашу площадку,<br><b>пишите на почту инфоаки@мос.ру</b>-->
    </container>
    <div class="div_flex">
        <swiper
                :modules="modules"
                :slides-per-view="3"
                class=" slide100h sample-slider"
                :loop="true"
                :speed="4000"
                :freeMode="true"
                @mouseenter="autoplayStart"
                @mouseleave="autoplayStop"
                :breakpoints="{
                  '768': {
                    slidesPerView: 3
                  },
                  '200': {
                    slidesPerView: 2
                  }
                }"
        >
            <swiper-slide
                          v-for="item in dataInfo.listImage"
                          :key="item.image?.value"
            ><div class="div_img div_img_partner"><img :src="getImage(item.image, 'hd')" alt=""></div></swiper-slide>
        </swiper>
    </div>
    <container>
      <div class="div_text" v-if="dataInfo.text" v-html="dataInfo.text?.value"></div>
    </container>
  </div>
</template>

<script>

import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay, FreeMode, Mousewheel, Navigation} from "swiper";
import Container from "@/components/library/Container.vue";

export default {
  name: "ourPartners",
  components: {
      Container,
      Swiper,
      SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
    setup() {
        const autoplayStop = (swiper) => {
            //swiper.target.swiper.params.speed = 0;
            swiper.target.swiper.autoplay.stop()
            //console.log('stop');
            //console.log(swiper.target.swiper.autoplay);
        };
        const autoplayStart = (swiper) => {
            //swiper.target.swiper.params.speed = 4000;
            swiper.target.swiper.params.autoplay.delay = 0;
            swiper.target.swiper.params.autoplay.disableOnInteraction = false;
            swiper.target.swiper.autoplay.start();
            //console.log(swiper.target.swiper.params);
            //console.log('start');

        };
        return {
            autoplayStop,
            autoplayStart,
            modules: [Autoplay, Mousewheel, Navigation, FreeMode],
        }
    },
}
</script>

<style lang="scss" scoped>
.our_artners {
  padding: 47px 0 65px 0;

  h2 {
    margin-bottom: 32px;
  }

  .div_flex {
    margin-bottom: 63px;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #fff;
    }
  }

  .div_img {
    //width: 390px;
    height: 190px;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
      /*width: 100%;
      height: 100%;
      object-fit: contain;*/
    }

    border-right: 1px solid #000106;
    @media (max-width: 991px) {
      height: 100%;
    }
  }

  .div_text {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.64px;
  }
}
.slide100h .swiper-slide {
  height: auto;
}

.with_support {
  padding: 152px 0;
  @media (max-width: 1400px) {
    padding: 55px 0;
  }

  h2 {
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  .div_img {
    //width: 33.3%;
    padding: 32px 64px;
    border-right: 1px solid white;
    border-bottom: 0;
  }

  .div_flex {
    &:before {
      background: #000106;
    }
  }
  //
  //.div_img {
  //  border-color: #ffffff;
  //
  //  &:nth-child(odd) {
  //    border-color: #ffffff;
  //  }
  //}
}
@media (max-width: 767px) {
  .with_support .div_img {
    padding: 12px 24px;
  }
  .our_artners .div_flex{
    margin-bottom: 12px;
  }
}
</style>