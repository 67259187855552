export default {
    methods: {
        /**
         * Метод достижения цели (работает только в production режиме)
         * @param event - цель метрики
         * @param id - идентификатор метрики
         */
        v_ym(event, id = 94757334){
            if(process.env.NODE_ENV === "production"){
                ym(id,'reachGoal', event);
            }
        }
    }
}