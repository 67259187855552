<template>
  <v-date-picker
      class="datepicker-inline datepicker-two"
      :modelValue="modelValue"
      @update:modelValue="modelValue = $event"
      :attributes="attributes"
      :select-attribute='selectAttribute'
      :drag-attribute='dragAttribute'
      :min-date="minDate"
      :disabled-dates='disabledDates'
      is-range
      color="orange"
      is-dark
      is-expanded
      :columns="this.widthSite < 768 ? 1 : 2"
      @update:pages="fromPageCalendar"
  >
    <template v-slot:day-popover="{ format, day, dayTitle, attributes }" v-if="typeCalendar && typeCalendar == 'reservationPavilony'">
      <div class="popover_cal popover_cal2">
          <template
              v-for="{key, customData} in attributes"
              :key="key"
          >
              {{ customData.rentStatus.Name }}<br>
          </template>
      </div>
    </template>
  </v-date-picker>
</template>

<script>
import {mapGetters} from "vuex";

/**
 * typeCalendar тип календаря, подсказок ...
 *
*/
export default {
  name: "ClCalendarTwo",
  props: {
    attributes: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Array,
      default: ""
    },
    minDate: {
      type: String
    },
    disabledDates: {
      type: String
    },
    typeCalendar: {
      type: String
    }
  },
  data() {
    return {
      dragAttribute: {
        highlight: {
          color: 'orange',
          fillMode: 'outline',
          class: 'highlight_num',
        }
      },
      selectAttribute: {
        highlight: {
          color: 'orange',
          fillMode: 'outline',
          class: 'highlight_num',
        }
      },
      lastMonth: 0,
    }
  },
  watch: {
    modelValue() {
      if(!this.btnCalendar){
        this.$emit('update:modelValue', this.modelValue);
        this.$emit('change', this.modelValue);
      }
    },
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
  },
  methods: {
    // перелистывание календаря
    fromPageCalendar(item) {
      if (item[0].month !== this.lastMonth) {
        //console.log(`month: ${this.lastMonth} -> ${item[0].month}`);
        this.lastMonth = item[0].month;
        this.$emit('fromPageCalendar', item);
      }
    },
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>

</style>