<template>
  <slot/>
</template>

<script>
export default {
  name: "Default",
}
</script>

<style scoped>

</style>