<template>
  <div class="block_wrap questions_suggestions">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title?.value }}</h2>
    </container>
    <div class="div_flex">
      <div class="dt1"><img src="/img/logo.svg" alt=""></div>
      <div class="dt2"><h3 class="color-or">Форма обратной связи</h3></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "questionsSuggestions",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.questions_suggestions {
  h2 {
    margin-bottom: 32px;
  }
  .div_flex {
    display: flex;
  }
  .dt1 {
    width: 57%;
    max-height: 585px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .dt2 {
    width: 43%;
    padding: 64px;
  }
}
</style>