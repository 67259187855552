<template>
  <div
      v-if="routeLink"
      class="card"
      :class="[type]"
  >
    <slot name="inner">
      <a :href="routeLink" target="_blank" v-if="targetBlank" class="card-holder">
        <slot/>
      </a>
      <router-link
          v-else
          :to="routeLink" class="card-holder">
        <slot/>
      </router-link>
    </slot>
    <slot name="button"></slot>
  </div>
  <div v-else class="card" :class="[type]">
    <slot name="inner">
      <div class="card-holder">
        <slot/>
      </div>
    </slot>
    <slot name="button"></slot>
  </div>
</template>

<script>
/**
 * Компонент карточки
 */
export default {
  name: "Card",
  props: {
    cardType: {
      type: String,
      required: true
    },
    routeLink: String,
    targetBlank: Boolean
  },
  data() {
    return {
      type: `card-${this.cardType}`
    }
  }
}
</script>

<style lang="scss">
.card {
  display: block;
  border-radius: 12px;
  padding: 24px;
  background-color: rgba(48, 48, 52, 0.80);
  position: relative;
  overflow: hidden;
  height: 100%;
  backdrop-filter: blur(5px);

  &.card-wh {
    border: 1px solid #ECECEC;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 2px 4px 0px rgba(0, 0, 0, 0.05), 0px 7px 7px 0px rgba(0, 0, 0, 0.04), 0px 15px 9px 0px rgba(0, 0, 0, 0.03), 0px 27px 11px 0px rgba(0, 0, 0, 0.01), 0px 42px 12px 0px rgba(0, 0, 0, 0.00);
    color: #000106;
  }
}

.linkCard {
  padding: 0 0 24px;

  .card-holder {
    display: block;
    padding: 24px 24px 0 24px;
  }
}

.card-rent {
  cursor: pointer;

  .div_img {
    border-radius: 8px;
    height: 164px;
    margin-bottom: 12px;
    overflow: hidden;
    background-color: #000106;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .div_tag {
    color: #ED850A;
    margin-bottom: 4px;
  }

  .div_tit {
    margin-bottom: 4px;
  }

  .div_info {
    color: #B2B5BB;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.16px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;

    .sp1 {
      margin-right: 8px;
    }
  }

  .div_adress {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    letter-spacing: 0.14px;

    img {
      height: auto;
      margin-right: 8px;
    }

    .sp_text {
      width: calc(100% - 32px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.card-wh {
    .div_info {
      color: #000106;
    }

    .div_adress {
      .sp_text {
        white-space: normal;
      }
    }
  }
}

.card-rent2 {
  cursor: pointer;
  padding: 0;
  border-radius: 0;
  background: none;

  &.border_img {
    .div_img {
      border: 1px solid #6B7280;
    }
  }

  .div_img {
    border-radius: 8px;
    //height: 380px !important;
    padding-top: 62%;
    margin-bottom: 8px;
    overflow: hidden;
    background-color: #000106;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .div_tag {
    color: #ED850A;
    margin-bottom: 4px;
  }

  .div_tit {
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .div_info {
    color: #B2B5BB;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.16px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;

    .sp1 {
      margin-right: 8px;
    }
  }

  .div_adress {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    letter-spacing: 0.14px;

    img {
      height: auto;
      margin-right: 8px;
    }

    .sp_text {
      width: calc(100% - 32px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media (max-width: 1024px) {
    .div_img {
      height: 270px !important;
    }
  }

  &.card-wh {
    .div_info {
      color: #000106;
    }

    .div_adress {
      .sp_text {
        white-space: normal;
      }
    }
  }
}

.card-rent-requisites{
  padding: 0;
  border-radius: 8px;
  background: transparent;

  &:hover{
    outline: 2px solid #464A53;
  }

  .card-holder{
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .div_img{
    border-radius: 8px;
    overflow: hidden;
  }
  .div_content{
    position: relative;
    padding: 1.2rem  1.6rem 7.2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.card-equipment1 {
  .card-holder {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .div_img {
    height: calc((100vw - 216px) / 3 * 0.45833);
  }

  .div_tit_center {
    margin-bottom: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
  }

  .div_text {
    margin-bottom: 12px;
  }

  cursor: default;
  @media (max-width: 767px) {
    .div_img {
      height: calc((100vw - 216px) / 3 * 3.45833);
    }
  }
}

.card-equipment2 {
  .card-holder {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .div_tit {
    flex-grow: 1;
    margin-bottom: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  cursor: default;
}

.card-support-measure {
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.02);
  background: rgba(255, 255, 255, 0.06);
  font-size: 1.8rem;

  .card-holder {
    position: relative;
  }

  .div_status {
    margin-bottom: 2.4rem;
    display: flex;
    gap: .8rem;
    align-items: center;
    .light2 {
      height: 20px;
    }
  }
  .div_tit_h {
    margin-bottom: .8rem;
  }
  .div_tit_flex {
    display: flex;
    gap: .8rem;
    margin-bottom: 1.6rem;
    .dt1 {
      flex: none;
      width: 38px;
      height: 38px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .dt2 {
      flex: 1;
    }
  }
  .div_tit_flex2 {
    display: flex;
    gap: 2.4rem;
  }
  .div_tit_gray {
    font-size: 1.2rem;
    line-height: 1.4rem;
    letter-spacing: 0.015em;
    color: #9096A2;
  }
  .div_number {
    margin-bottom: 1.6rem;
    display: flex;
    gap: .8rem;
    .sp_num {
      margin-right: .8rem;
    }
  }
  .color_gray {
    color: #9096A2;
  }

  .div_btn {
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (max-width: 991px) {

  }
  @media (max-width: 767px) {
    padding: 16px;
    .div_tit_h {
      font-size: 2.4rem;
    }
    .div_tit_flex {
      flex-direction: column;
    }
    .div_tit_flex2 {
      display: none;
    }
    .div_number {
      flex-wrap: wrap;
      .sp_num {
        display: block;
        width: 100%;
        margin: 0;
      }
    }
    .div_btn {
      text-align: left;
      position: relative;
      top: auto;
      right: auto;
    }
  }
}

.swiper:not(.slide100window).swiper-slide-duplicate {
  .card-smi {
    box-shadow: none;
  }
}

.card-smi {
  border-radius: 12px;
  border: 1px solid #F1F2F3;
  background: linear-gradient(180deg, #FFF 0%, #FAFAFA 100%);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

  .card-holder {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
  }

  .div_comp {
    display: flex;
    align-items: center;
  }

  .div_logo {
    flex: none;
    border-radius: 8px;
    background: #FFF;
    margin-right: 24px;
    border: 1px solid #DFE1E6;
    width: 128px;
    height: 128px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }
  }

  .div_btn {
    margin-top: auto;
  }
}

.card-trusted{
  padding: 1.6rem 1.6rem 3.2rem;
  border-radius: 0.6rem;
  background: rgba(23, 25, 28, 0.60);

  &.imitation{
    opacity: 0.4;
    .card-holder{
      .trusted_row1{
        .div_tag{
          width: 17.4rem;
          height: 4rem;
        }
      }
      .trusted_row2{
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        &:after, &:before{
          content: '';
          height: 2rem;
          background-color: #fff;
          border-radius: 10rem;
          opacity: 0.45;
        }
        &:before{
          width: 100%;
        }
        &:after{
          width: 20.6rem;
        }
      }
    }
  }

  .card-holder{
    display: flex;
    flex-direction: column;
    //gap: 2.4rem;
    .trusted_row1{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .div_tag{
        padding: 0.9rem 1.6rem 1rem;
        border-radius: 3rem;
        background: rgba(237, 133, 10, 0.25);
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 131.25%;
        letter-spacing: 0.024rem;
        color: #ED870A;
      }
      .div_buttons{
        display: flex;
        align-items: center;
        .btn_img{
          width: 4.8rem;
          height: 4.8rem;
          padding: 1.2rem;
          cursor: pointer;
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .trusted_row2{
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 133.333%;
      letter-spacing: 0.036rem;
      min-height: 6.4rem;
      margin-top: 2.4rem;
    }
    .trusted_row3{
      margin-top: 0.8rem;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 131.25%;
      letter-spacing: 0.024rem;
      color: #ACB0B9;
    }
  }

  &.trusted_add{
    cursor: pointer;
    border: 1px solid #2E3138;
    display: flex;
    align-items: center;
    justify-content: center;
    .card-holder{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0;
      .div_icon{
        display: flex;
        width: 4.8rem;
        height: 4.8rem;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .div_tit{
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 133.333%;
        letter-spacing: 0.036rem;
        height: 6.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}
.card-program{
  border: 1px solid #ACB0B9;
  border-radius: 1.2rem;
  padding: 2.4rem;
  margin-bottom: 2.4rem;
  background-color: transparent;
  cursor: pointer;
  .card-holder{
    display: flex;
    justify-content: space-between;
    gap: 0.8rem;
    height: 100%;
  }
  .card_info{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .card_img{
    width: 6.4rem;
    height: 6.4rem;
    min-width: 6.4rem;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .category-profession{
    display: flex;
    gap: 0.8rem;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 114.286%;
    letter-spacing: 0.021rem;
    color: #ED850A;
  }
  .name-profession{
    display: flex;
    flex-direction: column;
    height: 100%;
    .name{
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 2.1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 133.333%;
      letter-spacing: 0.0315rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      flex-grow: 1;
    }
    .university-name{
      color: #B2B5BB;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 133.333%;
      letter-spacing: 0.027rem;
    }
  }
}
.card-helper{
  border-radius: 0.6rem;
  padding: 1.6rem 1.6rem 3.2rem;
  background: rgba(23, 25, 28, 0.60);
  .card-holder{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.036rem;
    text-transform: uppercase;
    .btn{
      width: 100%;
    }
    .ico{
      width: 4.8rem;
      height: 4.8rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}


.card-event {
  font-size: 14px;
  padding: 0;
  background: none;
  &:hover {
    outline: 2px solid #2E3138;
    .div_img {
      img {
        transform: scale(1.08);
      }
    }
  }
  &:active {
    outline: 2px solid #ED850A
  }
  .div_img {
    height: 260px;
    border-radius: 12px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    }
    &.not_img {
      background: url(../../assets/img/not_img.svg) no-repeat center center;
      border: 1px solid #6b7280;
    }
    .div_tag {
      position: absolute;
      left: 9px;
      top: 8px;
      right: 8px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      z-index: 1;
    }
    .div_tag_el {
      backdrop-filter: blur(12px);
      background: rgba(0, 0, 0, 0.3);
      border-radius: 30px;
      padding: 8px 12px;
      min-width: 36px;
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &.div_tag_el_min {
        padding: 8px 6px;
      }
    }

  }
  .div_info {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .div_proj {
      display: flex;
      justify-content: space-between;
      .dt{
        margin-left: auto;
      }
    }
  }
  .div_tag2 {
    color: #5d636f;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .div_tit {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 4.8rem;
    text-overflow: ellipsis;
    height: 4.8rem;
  }
  .div_dat {
    color: #9096a2;
    display: flex;
    justify-content: space-between;
  }
}
</style>