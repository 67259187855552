<template>
  <teleport to="#addListCard">
    <not-info
        v-if="dataList?.length == 0"
        :title="`Здесь пока ничего нет`"
    >
    </not-info>
    <row v-if="dataList.length > 0">
      <column :key='item.id' v-for='item in dataList' md="3">
        <card card-type="rent" class="card-equipment1">
          <div class="div_img" :class="{'not_img': !item.gallery}">
            <img v-if="item.gallery?.galleryItems" :src="item.gallery?.galleryItems[0].url" alt="">
          </div>
          <div class="div_tit_center fs21" v-if="item.name">{{ item.name }}</div>
<!--          <cl-button class="btn-middle w-100" :router-link="`${$route.fullPath}/item/${item.id}`" @click="$router.push({path: `${$route.fullPath}/item/${item.id}`})">-->
          <cl-button class="btn-middle w-100" :router-link="`${$route.fullPath}/item/${item.id}`">
            Перейти
          </cl-button>
        </card>
      </column>
    </row>
    <div v-if="data?.page < data?.pages || isLoadingMore" class="show_more">
      <loader
          v-if="isLoadingMore"
          :animation-duration="1500"
          :size="55"
          :color="'#ED850A'"
      ></loader>
      <cl-button v-else @click="loadMore" type="light sm-w-100">Показать еще</cl-button>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "filmSchoolsList",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      isLoadingMore: false,
      data: false,
      dataList: false,
      getParams: {
        pageSize: this.dataInfo?.listCard?.pageSize ? this.dataInfo?.listCard?.pageSize : 8
      },
      controller: null,
    }
  },
  methods: {
    getListItem(isLoadMore) {
      if (!isLoadMore) {
        delete this.getParams.page;
        this.isLoading = true;
      }
      return this.axios
          .get(`${this.data.path}`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            if (isLoadMore) {
              this.dataList = [...this.dataList, ...response.data.items];
            } else {
              this.dataList = response.data.items;
            }
            this.data = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    loadMore() {
      this.isLoadingMore = true;
      this.getParams.page = ++this.data.page;
      this.$nextTick(() => {
        this.getListItem(true).then(() => {
          this.isLoadingMore = false
        })
      });
    },
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    this.data = this.dataInfo.listCard;
    this.dataList = this.dataInfo.listCard?.items;
  },
  beforeUnmount() {
    this.controller.abort();
    this.controller = null;
  },
}
</script>

<style lang="scss" scoped>

</style>