<template>
  <container class="container3">
    <div class="juriCompetition">
      <h3 class="div_tit" v-if="dataInfo.title?.value">
        {{ dataInfo.title.value }}
      </h3>
      <swiper
          :modules="modules"
          :slidesPerView="'auto'"
          :spaceBetween="24"
          :navigation="{
                    prevEl: prev,
                    nextEl: next,
                  }"
      >
        <swiper-slide v-for="(item, key) of dataInfo.items" :key="key">
          <div class="img" v-if="item.image">
            <img :src="getImage(item.image)" alt="">
          </div>
          <h4 class="tit" v-if="item.name?.value">
            {{ item.name.value }}
          </h4>
          <div class="desc" v-if="item.description?.value" v-html="item.description.value"></div>
        </swiper-slide>
      </swiper>
      <div class="swiper-btns">
        <div ref="prev" class="swiper-btn swiper-button-prev">
          <img src="/img/ico/move-left.svg">
        </div>
        <div ref="next" class="swiper-btn swiper-button-next">
          <img src="/img/ico/move-right2.svg">
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import Container from "@/components/library/Container.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation} from "swiper";
import {ref} from "vue";

export default {
  components: {Container, Swiper, SwiperSlide},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
};
</script>

<style lang="scss" scoped>
  .juriCompetition {
    padding: 6.4rem 12rem 3.2rem;
    border-radius: 3.2rem;
    background-color: #17191C;
    overflow: hidden;
    margin-top: 40px;
    margin-bottom: 40px;
    .swiper {
      overflow: visible;
      margin-bottom: 3.2rem;
    }
    .div_tit {
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 3.2rem;
    }
    .swiper-slide {
      width: 38.4rem;
      max-width: 38.4rem;
    }
    .img {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 0;
      padding-top: 129.6%;
      border-radius: 3.2rem;
      margin-bottom: 1.6rem;
      background: url(../assets/img/jury_background.png) no-repeat;
      background-size: cover;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        object-fit: cover;
      }
    }
    .tit {
      margin-bottom: 4px;
      text-transform: uppercase;
    }
    .desc {
      font-size: 1.6rem;
      line-height: 150%;
      color: #53595E;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .swiper-btns {
      display: flex;
      gap: 6.4rem;
      .swiper-btn {
        position: static;
        &:before {
          content: unset;
        }
      }
      .swiper-button-disabled {
        display: flex;
      }
      img {
        width: 4.8rem;
        height: 4.8rem;
        object-fit: contain;
      }
    }
    @media (max-width: 1440px) {
      padding: 6rem 4.8rem 3.2rem;
    }
    @media (max-width: 991px) {
      padding: 6rem 2.4rem 3.2rem;
      .swiper-slide {
        width: 29.5rem;
        max-width: 29.5rem;
      }
      .div_tit {
        font-size: 3.2rem;
        font-weight: 500;
        line-height: 150%;
      }
    }
    @media (max-width: 767px) {
      padding: 6rem 1.6rem 3.2rem;
      .swiper-btns{
        display: none;
      }
    }
  }
</style>