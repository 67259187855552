<template>
  <div class="block_wrap big_tabs" :style="[ getBkgrImage(activeItem.image, 'fullHd') ]">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
      <div class="big_tabs_flex" v-if="dataInfo.slideList && dataInfo.slideList.length > 0">
        <div class="dt1 fs3">
          <div class="big_tabs_a"
               v-for="item in dataInfo.slideList"
               :key="item.title?.value"
               @click="activeItem = item;"
               :class="{'active': activeItem == item}"
          ><span>{{item.title?.value}}</span><img class="d-none" :src="getImage(item.image, 'fullHd')" alt=""></div>
        </div>
        <div class="dt2">
              <transition name="fade">
                <div>
                  <div class="div_info fs3n"
                       ref="blockInfo"
                       v-if="activeItemRight"
                       v-html="activeItem.text?.value"
                  ></div>
                  <cl-button v-if="activeItem.buttonName" :routerLink="activeItem.buttonLink?.value">{{ activeItem.buttonName?.value }}</cl-button>
                </div>
              </transition>
        </div>
      </div>
    </container>
  </div>
</template>

<script>

export default {
  name: "tabsBigFonImg",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return {
      activeItem: false,
      activeItemRight: true
    }
  },
  watch: {
    activeItem(){
      this.activeItemRight = false;
      setTimeout(()=>{
        this.activeItemRight = true;
      },100);
    }
  },
  methods: {
  },
  mounted() {
    if(this.dataInfo.slideList){
      this.activeItem = this.dataInfo.slideList[0];
    }
  }
}
</script>

<style lang="scss" scoped>
.big_tabs {
  padding: 89px 0 48px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 872px;
  display: flex;
  .container {
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
  h2 {
    margin-bottom: 147px;
  }
  .big_tabs_flex {
    flex: 1;
    display: flex;
    margin-bottom: 100px;
    .dt1 {
      width: 50%;
      padding-right: 60px;
      flex: none;
    }
    .dt2 {
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 56px; /* 140% */
      letter-spacing: 1.2px;
    }
    .div_info {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .big_tabs_a {
    transition: all .3s ease;
    position: relative;
    padding: 12px 0 12px 88px;
    min-height: 80px;
    color: #B2B5BB;
    display: flex;
    align-items: center;
    &:not(.active){
      cursor: pointer;
    }
    span {
      transition: transform .3s ease;
    }
    &:before {
      content: "";
      position: absolute;
      left: 34px;
      top: 0;
      height: 100%;
      background: #B2B5BB;
      width: 4px;
      transition: transform .3s ease;
    }
    &.active {
      //font-size: 48px;
      //line-height: 56px; /* 116.667% */
      //letter-spacing: 0.96px;
      color: #ffffff;
      span {
        transform: scale(1.34);
        transform-origin: left center;
        display: inline-block;
      }
      &:before {
        height: 64px;
        background: #ED850A;
        transform: rotate(90deg);
        top:auto;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 32px 0 35px 0;
    min-height: 667px;
    h2{
      margin-bottom: 32px;
    }
    .big_tabs_flex{
      gap: 64px;
      flex-direction: column;
      justify-content: space-between;
      .dt1{
        width: 100%;
        .big_tabs_a{
          padding: 8px 0 8px 24px;
          min-height: 28px;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 500;
          line-height: 114.286%;
          letter-spacing: 0.28px;
          &:before{
            left: 6px;
            width: 2px;
            height: 32px;
          }
          &.active{
            &:before{
              height: 12px;
            }
          }
        }
      }
    }
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: linear-gradient(103deg, black, transparent);
      z-index: 0;
    }
  }
}
</style>