<template>
  <div class="newsConcursBlock">
    <container class="container3">
      <div class="newsConcurs">
        <h2 class="div_tit" v-if="dataInfo.title?.value">
          {{ dataInfo.title.value }}
        </h2>
        <swiper
            :modules="modules"
            :slidesPerView="'auto'"
            :spaceBetween="24"
            :navigation="{
              prevEl: prev,
              nextEl: next,
            }"
            class="slide100h"
            v-if="dataInfo.listNews"
        >
          <swiper-slide v-for="(item, key) of dataInfo.listNews" :key="key">
            <div class="slide">
              <div class="dt">
                <div class="img" v-if="item.logo">
                  <img :src="getImage(item.logo)" alt="">
                </div>
                <h4 class="tit hidden_text" v-if="item.name?.value">
                  {{ item.name.value }}
                </h4>
              </div>
              <div class="dt2">
                <div class="title hidden_text" v-if="item.title?.value" v-html="item.title.value"></div>
                <div class="text hidden_text" v-if="item.text?.value" v-html="item.text.value"></div>
                <div class="div_link" v-if="item.link">
                  <a :href="item.link.value" class="btn btn-light" target="_blank">Подробнее</a>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-btns">
          <div ref="prev" class="swiper-btn swiper-button-prev">
            <img src="/img/ico/move-left.svg">
          </div>
          <div ref="next" class="swiper-btn swiper-button-next">
            <img src="/img/ico/move-right2.svg">
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation} from "swiper";
import {ref} from "vue";

export default {
  components: {Container, Swiper, SwiperSlide},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
};
</script>

<style lang="scss" scoped>
.newsConcurs {
  padding: 6.4rem 12rem 3.2rem;
  border-radius: 3.2rem;
  background-color: #17191C;
  overflow: hidden;
  margin-top: 40px;
  margin-bottom: 40px;
  .swiper {
    overflow: visible;
    margin-bottom: 3.2rem;
  }
  .swiper-slide {
    width: 528px;
    max-width: 528px;
  }
  .slide{
    display: flex;
    flex-direction: column;
    background: black;
    padding: 2.4rem;
    border-radius: 1.8rem;
    gap: 2.4rem;
    height: 40rem;
    .dt{
      display: flex;
      display: flex;
      gap: 2.4rem;
      align-items: center;
      .img{
        width: 12.8rem;
        height: 12.8rem;
        border-radius: 1.8rem;
        overflow: hidden;
        flex: none;
        img{
          height: 100%;
          width: 100%;
        }
      }
    }
    .dt2{
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .div_tit {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 3.2rem;
    font-size: 4.8rem;
    font-weight: 500;
  }
  .div_link{
    margin-top: auto;
    .btn{
      min-height: 4rem;
    }
  }
  .hidden_text{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .title{
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
    &.hidden_text{
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }
  }
  .text{
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
    &.hidden_text{
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
  }
  .tit{
    font-size: 2.4rem;
    font-weight: 500;
    &.hidden_text{
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
  }
  .swiper-btns {
    display: flex;
    gap: 6.4rem;
    .swiper-btn {
      position: static;
      &:before {
        content: unset;
      }
    }
    .swiper-button-disabled {
      display: flex;
    }
    img {
      width: 4.8rem;
      height: 4.8rem;
      object-fit: contain;
    }
  }
  @media(max-width: 1240px){
    padding: 60px 32px 32px 32px;
  }
  @media(max-width: 767px){
    padding: 60px 16px 30px 16px;
    .div_tit {
      font-size: 3.2rem;
    }
    .swiper-slide{
      width: 25.2rem;
    }
    .slide{
      gap: .8rem;
      min-height: 30.8rem;
      height: 100%;
      padding: 1.6rem .8rem;
      .dt{
        flex-direction: column;
        align-items: flex-start;
        gap: 1.6rem;
        .img{
          height: 64px;
          width: 64px;
          border-radius: 1rem;
        }
      }
    }
    .tit{
      font-size: 1.4rem;
      margin-bottom: 0;
      &.hidden_text{
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    }
    .text{
      font-size: 1.4rem;
      line-height: 150%;
    }
    .div_link .btn{
      width: 100%;
    }
    .swiper-btns{
      display: none;
    }
    .title{
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 110%;
    }
  }
}
@media(max-width: 991px) and (min-width: 767px){
  .newsConcursBlock{
    padding: 0 4rem;
  }
}
</style>