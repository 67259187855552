<template>
  <div class="block_top rent_requisites_top">
    <container>
<!--      <h1 class="hasSubtit" v-if="dataInfo.title">{{ dataInfo.title.value }}</h1>-->
<!--      <div-->
<!--          v-if="isLoadingFilter"-->
<!--          class="mb-30"-->
<!--      >-->
<!--        <loader-->
<!--            :animation-duration="3000"-->
<!--            :size="60"-->
<!--        ></loader>-->
<!--      </div>-->
<!--      <div-->
<!--          v-else-->
<!--          class="filter_block">-->
<!--        <div class="dt w-100"-->
<!--             v-if="filter.selectOptionsType?.length > 0"-->
<!--        >-->
<!--          <select-and-btn-->
<!--              v-model="filter.selectSelectedType"-->
<!--              :options="filter.selectOptionsType"-->
<!--              :placeholder="filter.nameType"-->
<!--              track-by="id"-->
<!--              label="name"-->
<!--              @selectChange="selectChangeType"-->
<!--          />-->
<!--        </div>-->
<!--        <div class="dt w-100"-->
<!--             v-if="filter.selectOptionsEpoch?.length > 0"-->
<!--        >-->
<!--          <multiselect-->
<!--              v-model="filter.selectSelectedEpoch"-->
<!--              :options="filter.selectOptionsEpoch"-->
<!--              placeholder="Эпоха"-->
<!--              track-by="id"-->
<!--              label="name"-->
<!--              @select="selectChangeEpoch"-->
<!--              :searchable="false"-->
<!--              :allow-empty="false"-->
<!--          ></multiselect>-->
<!--        </div>-->
<!--        <div class="dt w-100"-->
<!--             v-if="filter.selectOptionsMaterial?.length > 0"-->
<!--        >-->
<!--          <select-and-btn-->
<!--              v-model="filter.selectSelectedMaterial"-->
<!--              :options="filter.selectOptionsMaterial"-->
<!--              placeholder="Материал"-->
<!--              track-by="id"-->
<!--              label="name"-->
<!--              @selectChange="selectChangeMaterial"-->
<!--          />-->
<!--        </div>-->
<!--        <div class="dt">-->
<!--          <cl-button @click="searchRent">Найти</cl-button>-->
<!--        </div>-->
<!--      </div>-->
<!--      <row v-if="dataInfo.listCards && dataInfo.listCards.length > 0" class="block_number_text_m">-->
<!--        <column md="4"-->
<!--                v-for="(item, index) in dataInfo.listCards"-->
<!--                :key="item.text?.value"-->
<!--        >-->
<!--          <div class="block_number_text">-->
<!--            <div class="dt dt1 fs2">{{ index + 1 }}.</div>-->
<!--            <div class="dt dt2 fs4n" v-if="item.text" v-html="item.text.value"></div>-->
<!--          </div>-->
<!--        </column>-->
<!--      </row>-->
      <div class="fs3">Всего реквизита: <span class="color-or">{{ data?.total }}</span><span class="icon-info-cl"><img src="/img/ico/info.svg" alt=""><message-left class="message-cl">{{data.total}} уникальных позиций<br>Всего на платформе {{data.totalAll}} штук</message-left></span></div>
    </container>
  </div>

  <container>
    <div class="rent_list">
      <loader
          v-if="isLoading"
          :animation-duration="3000"
          :size="60"
      ></loader>
      <not-info
          v-if="!isLoading && dataList?.length == 0"
          :title="!Object.keys(getParams).length <= 2 ? `Здесь пока ничего нет` : `Ничего не нашлось`"
          :text="!Object.keys(getParams).length <= 2 ? `Нет результатов` : `Нет результатов, удовлетворяющих критериям поиска. Попробуйте сбросить фильтры`"
      >
        <template v-slot:btn v-if="Object.keys(getParams).length > 2">
          <div class="div_btn">
            <cl-button @click="searchRent();">Сбросить фильтры</cl-button>
          </div>
        </template>
      </not-info>
      <row v-if="!isLoading && dataList?.length > 0">
        <column :key='item.guid'
                v-for='item in dataList'
                lg="3"
                md="6">
<!--          <card card-type="rent" @click="$router.push({ query:  {guid: item.guid}})">-->
          <card card-type="rent-requisites linkCard" :route-link="`${$route.path}/item/${item.guid}`">
            <div class="div_img img_proportions_requisites bg_wh" :class="{'not_img': !item.logo}"><img v-if="item.logo" :src="item.logo.imageUrl" alt=""></div>
            <div class="div_content">
              <div class="div_tit fs4n div_tit_recv"> {{ item.name }} </div>
              <div class="div_dop_info">
                <span v-if="item.width && item.height && item.depth && item.width != 0 && item.height != 0 && item.depth != 0"><span class="comma">, </span>{{ item.width }}x{{ item.height }}x{{ item.depth }}см.</span>
                <span v-if="item.materials?.length > 0"><span class="comma">, </span><span v-for="(i, index) in item.materials">{{ i.name + (index == item.materials?.length - 1 ? "" : ", ") }}</span></span>
                <span v-if="item.clothingSize"><span class="comma">, </span>{{ item.clothingSize }}</span>
              </div>
              <div class="div_price fs5n" v-if="item.price">от {{ item.price }}₽/шт</div>
              <div class="div_btn" :class="btnAddShow(item) ? 'btn_show' : ''">
                <div v-if="btnAddShow(item)" class="block_add_count">
                  <div class="bac_btn" @click.prevent="removeProductCart(item, false)"><img src="/img/ico/minus_st.svg" alt=""></div>
                  <div class="bac_num">
                    <div class="bac_top fs16">{{ btnAddShow(item) }}</div>
                    <div class="bac_bot">В наличие {{ item.count }} шт.</div>
                  </div>
                  <div class="bac_btn img_wh" @click.prevent="btnAddShow(item) >= item.count ? '' : addProductCart(item)" :class="{'disabled': btnAddShow(item) >= item.count }"><img src="/img/ico/plus_st.svg" alt=""></div>
                </div>
                <cl-button v-else @click.prevent="addProductCart(item)" class="btn-middle">В корзину</cl-button>
              </div>
            </div>
          </card>
        </column>
      </row>
      <div v-if="(data?.page < data?.pages || isLoadingMore) && !isLoading" class="show_more">
        <loader
            v-if="isLoadingMore"
            :animation-duration="1500"
            :size="55"
            :color="'#ED850A'"
        ></loader>
        <cl-button v-else @click="loadMore" type="light sm-w-100">Показать еще</cl-button>
      </div>
    </div>


  </container>
</template>

<script>
import toogleShoppingCart from "@/mixin/toogleShoppingCart";
import MessageLeft from "@/components/library/message-left.vue";
export default {
  name: "rentRequisitesList",
    components: {MessageLeft},
  mixins: [toogleShoppingCart],
  props: {
    dataFilters:{
      type: Object,
      require: true,
    },
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingMore: false,

      data: false,
      dataList: false,

      getParams: {
        //убрал по задаче 455988
        // havePhoto: true,
        category: this.dataInfo.techCategory.value,
      },
      getParamsStart: {
        // havePhoto: true,
        category: this.dataInfo.techCategory.value,
      },
      getParamsFilter: {
        parent: this.dataInfo.techCategory.value
      },
      controller: null,

      //isLoadingFilter: true,
      /*filter: {
        nameType: "Выберите тип",
        selectSelectedType:  [],
        selectOptionsType:  [],
        selectSelectedEpoch:  [],
        selectOptionsEpoch:  [],
        selectSelectedMaterial:  [],
        selectOptionsMaterial:  [],
      },*/
    }
  },
  watch:{
    dataFilters: {
      handler(){
        //this.getParams = {...this.dataFilters[0]};
        this.getParams = {...this.getParamsStart, ...this.dataFilters[0]};
        this.getParams.page = this.data.page;
        this.getRequisitesItem(false);
      },
      deep: true,
    }
  },
  methods: {
    /*getFilter(){
      this.isLoadingFilter = true;
      return this.axios
          .get(`/api/data/filters/requisite`, {params: this.getParamsFilter, signal: this.controller.signal})
          .then(response => {
            this.filter.selectOptionsType = response.data.subcategory;
            this.filter.selectOptionsEpoch = response.data.timePeriod;
            this.filter.selectOptionsMaterial = response.data.material;


            this.isLoadingFilter = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },*/
    getRequisitesItem(isLoadMore) {
      // если есть query при первой загрузке дублировался поиск
      if (Object.keys(this.$route.query).length > 0) {
        for (const [key, value] of Object.entries(this.$route.query)) {
          if (key == 'company' && !this.getParams.company) {
            return false;
          }
        }
      }
      if (!isLoadMore) {
        delete this.getParams.page;
        this.isLoading = true;
      }
      /*if (Object.keys(this.$route.query).length > 0) {
        for (const [key, value] of Object.entries(this.$route.query)) {
          if (key == 'company') {
            this.getParams.company = value;
            this.$route.query = "";
            var newurl = window.location.href;
            newurl = newurl.replace("company="+value, "");
            window.history.pushState({path:newurl},'',newurl);
          }
        }
      }*/
      return this.axios
          .get(`/api/data/requisite`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            if (isLoadMore) {
              this.dataList = [...this.dataList, ...response.data.records];
            } else {
              this.dataList = response.data.records;
            }
            this.data = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    loadMore() {
      this.isLoadingMore = true;
      this.getParams.page = ++this.data.page;
      this.$nextTick(() => {
        this.getRequisitesItem(true).then(() => {
          this.isLoadingMore = false
        })
      });
    },
    //поиск
    searchRent() {
      this.dataFilters[1].state = true;
    },
    //Тип мебели
    // selectChangeType(val){
    //   if(this.filter.selectSelectedType.length < 1) {
    //     delete this.getParams.subcategory
    //   } else {
    //     this.getParams.subcategory = [...this.filter.selectSelectedType].map(
    //         item => item.id
    //     ).join();
    //   }
    // },
    //Эпоха
    // selectChangeEpoch(val){
    //   if(this.filter.selectSelectedEpoch.length < 1) {
    //     delete this.getParams.timePeriod
    //   } else {
    //     this.getParams.timePeriod = this.filter.selectSelectedEpoch.id;
    //   }
    // },
    //Материал
    // selectChangeMaterial(val){
    //   if(this.filter.selectSelectedMaterial.length < 1) {
    //     delete this.getParams.material
    //   } else {
    //     this.getParams.material = [...this.filter.selectSelectedMaterial].map(
    //         item => item.id
    //     ).join();
    //   }
    // },
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    //this.getFilter();
    this.getRequisitesItem();
    this.$emit("getCategory", this.dataInfo.techCategory.value);
    this.$emit("isComponent", true);

    //какой тип
    /*if(this.dataInfo.techCategory.value == 1315201){
      this.filter.nameType = "Тип мебели";
    } else if(this.dataInfo.techCategory.value == 1315202){
      this.filter.nameType = "Тип форменной одежды";
    }*/

    this.$nextTick(() => {
      helperApp.addBreadcrumbsLink(false, 'breadcrumbs6');
    });
  },
  beforeUnmount() {
    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style lang="scss" scoped>
.row > div {
  position: relative;
}

.card-rent {
  border: 2px solid transparent;
  overflow: visible;
  &:hover {
    z-index: 1;
    border: 2px solid #ED850A;
  }
}

.rent_requisites_top {
  padding: 240px 0 32px 0;

  h1 {
    margin-bottom: 49px;
  }

  .filter_block {
    margin: 0 auto 56px auto;
    position: relative;
    //max-width: 1358px;
    z-index: 1;
  }

  .block_number_text_m {
    margin-bottom: 72px;
  }
  //@media (max-width: 991px) {
  //  padding: 240px 0 32px;
  //}
  //@media (max-width: 767px) {
  //  padding: 100px 0 32px;
  //}
}

.card {
  .btn {
    width: 100%;
  }
  .div_dop_info {
    margin-bottom: 8px;
    color: #ACB0B9;
    font-size: 14px;
    line-height: 114.286%;
    letter-spacing: 0.21px;
    > span:first-child {
      .comma {
        display: none;
      }
    }
  }
  .div_price {
    margin-bottom: 5px;
  }
  .div_btn {
    position: absolute;
    right: -2px;
    left: -2px;
    bottom: 0;
    padding: 0px 16px 16px 16px;
    border-top: transparent;
    flex-grow: 1;
    &.btn_show{
      display: block !important;
    }
  }
  @media (min-width: 992px) {
    &:not(:hover) {
      .div_btn {
        display: none;
      }
    }
  }
}

.div_tit_recv{
  margin-bottom: 8px;
  text-transform: none;
}

.div_text_12{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}
</style>

