<template>
  <div class="radio-holder">
    <h4 v-if="!hiddenLabel" :class="required === true ? 'required' : ''">{{ title }}</h4>
    <input type="hidden" :id="id" :name="name" v-model="outputValue">
    <div class="radio-item" v-for="item of radioData" :key="item.data">
      <label>
        <input type="radio" :data-qa-column="dataQaColumn" @change="radioHandler(item)" :checked="item.isChecked">
        <span class="sp_all"></span>
        <span>{{item.label}}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    radioData: Array,
    name: String,
    id: String,
    hiddenLabel: Boolean,
    title: String,
    required: Boolean,
    dataQaColumn: String
  },
  data: () => ({
    outputValue: ""
  }),
  methods: {
    radioHandler(radio) {
      for (let item of this.radioData) {
        item.isChecked = false
      }
      radio.isChecked = true
      this.outputValue = radio.data
    }
  }
}
</script>

<style scoped>

</style>