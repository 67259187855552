<template>
  <div class="block_wrap metmash_shem">
    <container class="metmash_shem_sel_mob">
      <multiselect
          class="light"
          v-model="activeChildren"
          :options="activeItem.slideListChild"
          label="title"
          :searchable="false"
          :allow-empty="false"
          v-if="listChildrenShow && activeItem.slideListChild && activeItem.slideListChild.length > 0 && widthSite < 768"
      >
        <template v-slot:singleLabel="{ option }">{{ option.title.value }}</template>
        <template v-slot:option="{ option }">{{ option.title.value }}</template>
      </multiselect>
    </container>
    <container class="metmash_shem_cont">
      <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
      <div class="metmash_shem_menu fs4n" v-if="dataInfo.slideList && dataInfo.slideList.length > 0">
        <template
            v-for="(item, index) in dataInfo.slideList"
            :key="item.title?.value"
        >
          <div
              class="div_el"
              @click="levelFirstClick(item)"
              :class="{'active': activeItem == item}"
          >
            {{ item.title?.value }}
          </div>
          <span class="sp_line" v-if="index + 1 < dataInfo.slideList.length">/</span>
        </template>
      </div>
      <transition name="fade">
        <template v-if="listChildrenShow">
          <div class="metmash_shem_flex" v-if="activeItem.slideListChild && activeItem.slideListChild.length > 0">
            <div class="dt1 fs3n">
              <template v-if="widthSite >= 768">
                <div class="metmash_shem_a"
                     v-for="itemChild in activeItem.slideListChild"
                     :key="itemChild.title?.value"
                     @click="activeChildren = itemChild;"
                     :class="{'active': activeChildren == itemChild}"
                >
                  <span v-if="itemChild.title">{{ itemChild.title.value }}</span>
                  <!--                <template v-if="itemChild.number">-->
                  <!--                  <template-->
                  <!--                      v-for="itemPin in itemChild.number.value.split(',')"-->
                  <!--                  >-->
                  <!--                    <div class="metmash_shem_pin metmash_shem_pin" :class="'metmash_shem_pin' + itemPin">-->
                  <!--                      <div class="div_num">{{ itemPin }}</div>-->
                  <!--                      <svg width="48" height="58" viewBox="0 0 48 58" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                  <!--                        <path d="M45.3332 23.6673C45.3332 39.6673 23.9998 55.6673 23.9998 55.6673C23.9998 55.6673 2.6665 39.6673 2.6665 23.6673C2.6665 18.0094 4.91412 12.5831 8.91489 8.58237C12.9157 4.5816 18.3419 2.33398 23.9998 2.33398C29.6578 2.33398 35.084 4.5816 39.0848 8.58237C43.0856 12.5831 45.3332 18.0094 45.3332 23.6673Z" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>-->
                  <!--                      </svg>-->
                  <!--                    </div>-->
                  <!--                  </template>-->
                  <!--                </template>-->

                </div>
              </template>

            </div>
            <div class="dt2">
              <!--              <img class="d-none" :src="getImage(item.image, 'fullHd')" alt="">-->
              <transition name="fade">
                <div class="div_info"
                     v-if="activeChildrenShow"
                >
                  <div class="div_tit fs2" v-if="activeChildren.square" v-html="activeChildren.square.value"></div>
                  <div class="div_tit fs3" v-else-if="activeChildren.bottomTitle">{{activeChildren.bottomTitle.value}}</div>
                  
                  <div class="div_text" :class="!activeChildren.bottomTitle && 'fs3n'" v-if="activeChildren.text" v-html="activeChildren.text?.value"></div>
                  <div class="div_text color-grey-400" v-if="activeChildren.bottomSubtitle">{{activeChildren.bottomSubtitle.value}}</div>
                  <div class="div_btn" v-if="activeChildren.url">
                    <cl-button @click="$router.push({path: activeChildren.url.value})">Узнать подробнее</cl-button>
                  </div>
                  <div class="div_btns" v-if="activeChildren.buttons?.length">
                    <router-link class="btn btn-light" v-for="button of activeChildren.buttons" :to="button.url.value">{{button.text.value}}</router-link>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div v-else-if="activeItem.text || activeItem.square" class="metmash_shem_info">
            <div class="metmash_shem_info_square" v-if="activeItem.square">
              <div class="fs4">Общая площадь</div>
              <div class="fs2 color-wh">{{ activeItem.square.value }}</div>
            </div>
            
            <div class="fs3 metmash_shem_info_text" v-if="activeItem.text" v-html="activeItem.text.value"></div>
          </div>
        </template>
      </transition>

    </container>

    <div class="metmash_shem_pin_wrap">
      <template
          v-for="itemChild in activeItem.slideListChild"
          :key="itemChild.title?.value"
      >
        <template v-if="itemChild.number">
          <template
              v-for="itemPin in itemChild.number.value.split(',')"
          >
            <div class="metmash_shem_pin"
                 :class="[{'active': activeChildren == itemChild}, 'metmash_shem_pin' + itemPin]"
                 @click="activeChildren = itemChild;"
            >
              <div class="div_num">{{ itemPin }}</div>
              <svg width="48" height="58" viewBox="0 0 48 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M45.3332 23.6673C45.3332 39.6673 23.9998 55.6673 23.9998 55.6673C23.9998 55.6673 2.6665 39.6673 2.6665 23.6673C2.6665 18.0094 4.91412 12.5831 8.91489 8.58237C12.9157 4.5816 18.3419 2.33398 23.9998 2.33398C29.6578 2.33398 35.084 4.5816 39.0848 8.58237C43.0856 12.5831 45.3332 18.0094 45.3332 23.6673Z"
                    stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </template>
        </template>
      </template>
      <img class="metmash_shem_img" :src="getImage(dataInfo.image, 'fullHd')" alt="">
      <img class="metmash_shem_img2" v-if="activeChildren.image" :src="getImage(activeChildren.image, 'fullHd')" alt="">
      <transition name="fade">
        <img class="metmash_shem_img2 metmash_shem_img3" v-if="activeItem.image" :src="getImage(activeItem.image, 'fullHd')" alt="">
      </transition>
    </div>


  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Container from "@/components/library/Container.vue";

export default {
  name: "infrastructureMetmash",
  components: {Container},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      activeItem: false,
      listChildrenShow: false,
      activeChildrenShow: true,
      activeChildren: false
    }
  },
  watch: {
    activeItem() {
      this.listChildrenShow = false;
      setTimeout(() => {
        this.listChildrenShow = true;
      }, 100);
    },
    activeChildren() {
      this.activeChildrenShow = false;
      setTimeout(() => {
        this.activeChildrenShow = true;
      }, 100);
    }
  },
  methods: {
    levelFirstClick(item) {
      this.activeItem = item;
      if (this.activeItem.slideListChild) {
        this.activeChildren = this.activeItem.slideListChild[0];
      }
    }
  },
  mounted() {
    if (this.dataInfo.slideList) {
      this.activeItem = this.dataInfo.slideList[0];
      if (this.dataInfo.slideList[0].slideListChild) {
        this.activeChildren = this.dataInfo.slideList[0].slideListChild[0];
      }

    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  }
}
</script>

<style lang="scss" scoped>

.metmash_shem {
  position: relative;

  > .container {
    padding-top: 50px;
    padding-bottom: 77px;
  }

  .metmash_shem_img {
    max-width: 100%;
  }
  .metmash_shem_img2 {
    position: absolute;
    left: 0;
    max-width: 100%;
    top: 0;
    mix-blend-mode: color;
  }
  .metmash_shem_img3 {
    mix-blend-mode: normal;
  }


  .metmash_shem_cont {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 1;

  }

  h2 {
    margin-bottom: 0;
  }

  @media (max-width: 991px) {
    .metmash_shem_cont {
      position: relative;
    }
  }
  @media (max-width: 767px) {
    .metmash_shem_sel_mob {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      position: relative;
      z-index: 15;
      > .multiselect {
        width: auto;
        position: absolute;
        top: 180px;
      }
    }
  }
}

.metmash_shem_menu {
  color: #6B7280;
  text-transform: uppercase;
  margin-bottom: 90px;
  display: flex;
  align-items: center;
  gap: 16px;

  .div_el {
    cursor: pointer;

    &.active {
      color: #ED850A;
      cursor: default;
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 62px;
  }

}

.metmash_shem_flex {
  flex: 1;
  display: flex;
  justify-content: space-between;

  .dt1 {
    padding-right: 60px;
    flex: none;
  }

  .dt2 {
    min-width: 500px;
    width: 60%;
    display: flex;
    align-items: flex-end;
  }

  .div_info {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .div_tit {
      margin-bottom: 8px;
    }

    .div_text {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
    .div_btns {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      .btn {
        min-height: 36px;
        color: #ED850A;
        font-size: 16px;
        padding: 9px 24px;
        &:active {
          color: #fff;
        }
      }
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    .dt2 {
      min-width: 10px;
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .dt1 {
      margin-bottom: 310px;
    }
  }
}

.metmash_shem_info {
  color: #B2B5BB;
  .metmash_shem_info_square {
    margin-bottom: 60px;
  }
  .metmash_shem_info_text {
    p {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 991px) {
    .metmash_shem_info_text {
      p {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}


.metmash_shem_a {
  transition: all .3s ease;
  position: relative;
  padding: 12px 0 12px 88px;
  min-height: 80px;
  color: #B2B5BB;
  display: flex;
  align-items: center;

  &:not(.active) {
    cursor: pointer;
  }

  span {
    transition: transform .3s ease;
  }

  &:before {
    content: "";
    position: absolute;
    left: 34px;
    top: 0;
    height: 100%;
    background: #B2B5BB;
    width: 4px;
    transition: transform .3s ease;
  }

  &.active {
    color: #ffffff;

    span {
      transform: scale(1.3);
      transform-origin: left center;
      display: inline-block;
    }

    &:before {
      height: 64px;
      background: #ED850A;
      transform: rotate(90deg);
      top: auto;
    }
  }
  @media (max-width: 991px) {
    min-height: 1px;
  }
}

.metmash_shem_pin_wrap {
  position: relative;
  display: inline-block;
  margin-bottom: 120px;
  @media (min-width: 1921px) {
    margin-left: calc((100vw - 1920px) / 2);
    min-width: 1920px;
  }
  .metmash_shem_pin {
    position: absolute;
    z-index: 10;
    cursor: pointer;

    &.active {
      cursor: default;

      .div_num {
        color: #fff;
      }

      svg {
        fill: #ED850A;
        stroke: #FFF;
      }
    }

    svg {
      fill: white;
      stroke: #ED850A;
    }

    .div_num {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 116.667% */
      letter-spacing: 0.48px;

      color: #ED850A;
      position: absolute;
      text-align: center;
      top: 10px;
      left: 0;
      right: 0;
    }
  }


  .metmash_shem_pin2 {
    left: 70%;
    top: 22%;
  }

  .metmash_shem_pin30 {
    left: 55%;
    top: 29%;
  }

  .metmash_shem_pin31 {
    display: none;
  }

  .metmash_shem_pin32 {
    left: 49%;
    top: 26%;
  }

  .metmash_shem_pin9 {
    left: 89%;
    top: 22%;
  }

  .metmash_shem_pin6 {
    left: 87%;
    top: 16%;
  }

  .metmash_shem_pin10 {
    left: 83%;
    top: 10%;
  }

  .metmash_shem_pin45 {
    left: 71%;
    top: 12%;
  }

  .metmash_shem_pin12 {
    left: 81%;
    top: 20%;
  }

  .metmash_shem_pin11 {
    left: 67%;
    top: 17%;
  }

  ////
  .metmash_shem_pin19 {
    left: 59%;
    top: 23%;
  }

  .metmash_shem_pin24 {
    left: 38%;
    top: 35%;
  }

  .metmash_shem_pin25 {
    left: 41%;
    top: 24%;
  }
  .metmash_shem_pin8 {
    left: 73%;
    top: 37%;
  }
  .metmash_shem_pin21 {
    left: 81.8%;
    top: 29.3%;
  }
  .metmash_shem_pin46 {
    left: 67.4%;
    top: 30%;
  }
  .metmash_shem_pin11a {
    left: 61.4%;
    top: 62.5%;
  }
  .metmash_shem_pin1 {
    left: 64.5%;
    top: 59%;
  }


  @media (max-width: 991px) {
    position: absolute;
    top: 120px;
  }
  @media (max-width: 991px) {
    left: -140px;
    top: 250px;
    .metmash_shem_pin {
      width: 22px;
      height: 27px;
      .div_num {
        font-size: 10px;
        top: -2px;
      }
    }
  }
}


</style>