<template>
  <div :class="{
      'form-group-file-line': item.options.widget === 'file',
    }" :id="item['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
       :data-name="item['full_name']"
  >
    <div class="file-upload-top">
      <label v-if="!hiddenLabel" class="col-form-label" :class="required === true ? 'required' : ''">{{item.title}} <template v-if="item.options?.demolink">(<a class="demolink" :href="item.options.demolink" target="_blank">образец</a>)</template></label>
    </div>
    <input v-for="input in item.properties"
           :type="input.type"
           :data-needsign="input.attr['data-needsign']"
           :class="input.attr.class"
           :data-old="oldDbData(input.title)"
           :name="input['full_name']"
           :id="input['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
           :data-fileurl="input.attr['data-fileurl']"
           :data-filesize="input.attr['data-filesize']"
           :data-guid="guidData(input.title)"
           :ref="`input${input.title[0].toUpperCase() + input.title.substring(1)}`"
           :value="inputElements[input.title]"
           :data-srcmap="input.attr?.sourceDataMap"
           :data-qa-column="input.attr?.sourceDataMap"
    >
    <template v-if="item.options.widget === 'logo'">
      <div class="logo logoUploadContainer" ref="logoUploadContainer">
        <div class="logo-holder">
          <div class="img">
            <img v-if="logoSrc" :src="logoSrc" alt="">
          </div>
          <div class="upload-button">
            <button :disabled="item.options.readonly" class="btn btn-primary logoUploadButton" ref="logoUploadButton" type="button" >{{logoSrc ? "Редактировать" : "Загрузить"}}</button>
            <span class="small-text">(в формате PNG, JPEG)</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div :id="`holder_${id}`" class="iasFileHolderContainerCls">
        <div class="pluploaderButton plup_podpis_file uploadContainer" :data-qa-column="item.attr?.sourceDataMap" :id="`${id}_dc`" ref="uploadContainer">
          <div v-if="uploader !== 'uploaded'">
            <div v-if="item.attr.description && !item.attr['label-help']" class="form-field-description">
              {{ item.attr.description }}
            </div>
          </div>
          <div class="form-group">
            <template v-if="uploader === 'close'">
              <div class="fileinput-button2 uploadButton" :id="`file_add_show_js_${id}`" ref="uploadButton">
                <input type="hidden" class="uploadInput">
                <div class="fileinput-ico"></div>
                <div class="fileinput-text">
                  Добавить файл
                </div>
              </div>
            </template>
            <template v-if="uploader === 'loading'">
              <div class="file_el">
                <div class="file_td1">
                  <div class="file_ico"></div>
                </div>
                <div class="file_td2">
                  <div class="file_tit plUpFilename">Загрузка документа...</div>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" :aria-valuenow="loadingPercent" aria-valuemin="0" aria-valuemax="100" :style="`width: ${loadingPercent}%;`"></div>
                  </div>
                  <div class="progress-bar-status">Загрузка <span class="progress-bar-status-num">{{ loadingPercent }}%</span></div>
                </div>
              </div>
            </template>
            <div class="plCurrentUploaded plup_podpis_upload" v-if="uploader === 'uploaded'">
              <div class="file_el file_el_new1">
                <div class="file_td1">
                  <div class="file_ico"></div>
                </div>
                <div class="file_td2 file_td2dop file_td2_text">
                  <div class="file_tit plUpFilename">
                    <div class="file_tit_flex">
                      <div class="file_tit_flex_dt1 plUploadedFilename">{{ chosenDocument.name }}</div>
                      <div class="file_tit_flex_dt2 plUpExt2">.{{chosenDocument.format}}</div>
                    </div>
                  </div>
                  <div class="file_text_st">
                  <span class="file_st_green status_hold">
                    <span v-if="chosenDocument.isSigned">Документ подписан</span>
                    <span v-else>Документ загружен</span>
                  </span>
                  </div>
                  <div class="file_text_dl" v-if="item.options.readonly">
                    <a :href="item.options.fileUrl" download target="_blank">Скачать файл</a>
                  </div>
                </div>
                <div class="file_td2 file_td2_del">
                  <div v-if="needToSignNow">
                    <button :id="`sign_button_${id}`" type="button" :data-fileguid="guid" class="btn btn-light plUploadSign btn_podpis_mb" v-show="showSignButton" @click="signButtonHandler">Подписать</button>
                  </div>
                  <span class="krug_del krug_del_img" @click="chosenDocumentClear" v-if="!item.options.readonly"></span>
                </div>
              </div>
            </div>
            <div v-if="needToSignNow" class="podpis_message plup_podpis_message">
              <div class="sign_text_top color_red">{{needSignText}}</div>
              <div class="signData" ref="signData" v-show="showSignData"></div>
            </div>
          </div>
        </div>
        <div class="signUploadBtn" v-show="false" ref="signUploadBtn"></div>
        <div class="signUploadContainer" v-show="false" ref="signUploadContainer"></div>
      </div>
    </template>
    <template v-if="item.attr && item.attr['label-help']">
      <div class="file-informer">{{item.attr['label-help']}}</div>
    </template>
  </div>
</template>

<script>
import {SignPluginBlitz} from "@/plugin/SignPluginBlitz";
import {guidHandler} from "@/main"
import plupload from "plupload";

export default {
  name: "FileUploader",
  props: {
    item: {
      type: Object,
    },
    required: {
      type: Boolean
    },
    id: {
      type: String,
      required: true
    },
    keyUpdate: [String, Number],
    signStep: Boolean,
    hiddenLabel: Boolean
  },
  data() {
    return {
      uploader: 'close', // состояние виджета загрузки файлов. Имеет значения close, open, loading, uploaded
      /*documentsList: [
        {
          id: "0",
          name: "Копии документов, подтверждающих приобретение оборудования, в том числе платежных, и его постановку на баланс субъекта ",
          format: "pdf",
          size: "127.4 KB",
          uploadDate: "19.05.2021",
          isSigned: true
        },
        {
          id: "1",
          name: "Копии документов, подтверждающих приобретение оборудования, в том числе платежных, и его постановку на баланс субъекта ",
          format: "pdf",
          size: "127.4 KB",
          uploadDate: "19.05.2021"
        },
        {
          id: "2",
          name: "Копии документов, подтверждающих приобретение оборудования, в том числе платежных, и его постановку на баланс субъекта ",
          format: "pdf",
          size: "127.4 KB",
          uploadDate: "19.05.2021"
        },
        {
          id: "3",
          name: "Копии документов, подтверждающих приобретение оборудования, в том числе платежных, и его постановку на баланс субъекта ",
          format: "pdf",
          size: "127.4 KB",
          uploadDate: "19.05.2021"
        },
        {
          id: "4",
          name: "Копии документов, подтверждающих приобретение оборудования, в том числе платежных, и его постановку на баланс субъекта ",
          format: "pdf",
          size: "127.4 KB",
          uploadDate: "19.05.2021"
        },
      ],*/
      chosenDocument: {}, // пустой объект под данные добавляемого файла
      guid: "",
      signGuid: "",
      inputElements: {
        guid: "",
        oldSignDbGuid: "",
        oldFileDbGuid: "",
        name: this.item.properties.name && this.item.properties.name.value ? this.item.properties.name.value : "",
        fileDbGuid: this.item.properties.fileDbGuid && this.item.properties.fileDbGuid.value ? this.item.properties.fileDbGuid.value : "",
        signDbGuid: this.item.properties.signDbGuid && this.item.properties.signDbGuid.value ? this.item.properties.signDbGuid.value : "",
        signGuid: ""
      }, // данные, привязывающиеся через v-model к скрытым инпутам
      loadingPercent: 0, // процентаж загрузки, меняется динамически
      needToSignNow: !this.signStep && this.item.properties.signDbGuid?.value === null
        && this.item.properties.fileDbGuid?.value !== null, // необходимость в подписании, по умолчанию равно отсутствию шага подписи
      needSignText: this.signStep ?
        "Добавленный файл имеет объем более 100Мб, его необходимо подписать в данном окне. Файлы размером менее 100МБ будут массово подписываться на последнем шаге заполнения заявки." :
        "Файл необходимо подписать",
      showSignButton: true, // показ кнопки подписи, показана по-умолчанию, потому что поле, его содержащее, скрыто
      showSignData: true, // показывает\скрывает контейнер с текстом-подсказкой
      docUpload: null, // свойство для подключения в него в дальнейшем экземпляра класса подписания файлов
      currentFile: null, // свойство для записи данных текущего файла (более полная версия chosenDocument)
      docUploadSign: null, // свойство под подпись,
      logoSrc: this.item.options.widget === "logo" ? this.item.options.fileUrl : null, // свойство под логотип,
      logoUpload: null, //свойство под загрузчик логотипа
      informerHeight: "auto",
      isBelow: false
    }
  },
  mounted() {
    this.guid = guidHandler(false); // формируем guid по-умолчанию
    if (this.item.options.widget === "logo") {
      this.logoHandler()
    }
    if (this.item.properties.name.value) {
      this.entryFileHandler()
    }
    this.docUploaderHandler()
  },
  methods: {
    /**
     * Функция для отображения входящего файла
     */
    entryFileHandler() {
      this.chosenDocument = {
        name: this.item.properties.name.value,
        format: this.item.properties.name.value.split(".").pop(),
        nameWOExt: this.item.properties.name.value.split(".").shift(),
        isSigned: typeof this.item.properties.signDbGuid !== "undefined" && this.item.properties.signDbGuid.value !== null
      }
      this.currentFile = {}
      this.currentFile[this.item.properties.fileDbGuid.value] = this.item.options.fileUrl
      if (!this.inputElements.signDbGuid && this.item.options.needsign) {
        window.measureSignFileList[this.item.properties.fileDbGuid.value] = this.item.options.fileUrl
        window.measureSignFileInfoList[this.item.properties.fileDbGuid.value] = {
          name: this.inputElements.name,
          fieldName: this.item.title,
          isSigned: false
        }
      }
      this.uploader = "uploaded"
    },
    /**
     * Функция для итерируемых скрытых инпутов
     * @param name - имя итерируемого инпута
     * @returns {string}
     */
    guidData(name) {
      let temp
      switch (name) {
        case "guid":
          temp = this.guid
          break
        case "signGuid":
          temp = this.signGuid
          break
        default:
          temp = null
          break
      }
      return temp
    },
    /**
     * Функция для добавления старых гуидов в атрибут data-old
     * @param name - имя итерируемого инпута
     * @returns {string}
     */
    oldDbData(name) {
      let temp
      switch (name) {
        case "fileDbGuid":
          temp = this.inputElements.oldFileDbGuid
          break
        case "signDbGuid":
          temp = this.inputElements.oldSignDbGuid
          break
        default:
          temp = null
          break
      }
      return temp
    },
    /**
     * Функция для добавления документа из списка (статика)
     * @param id - идентификатор документа в списке
     */
    /*fileListAddHandler(id) {
      this.chosenDocument = this.documentsList[id];
      this.uploader = "uploaded";
    },*/
    /**
     * Функция очистки виджета загрузки документа от всех данных (необходима доработка)
     */
    async chosenDocumentClear() {
      this.uploader = "close";
      if (this.inputElements.fileDbGuid?.length) {
        delete window.measureSignFileList[this.inputElements.fileDbGuid]
        delete window.measureSignFileInfoList[this.inputElements.fileDbGuid]
      } else {
        delete window.measureSignFileList[this.inputElements.guid]
        delete window.measureSignFileInfoList[this.inputElements.guid]
      }
      this.guid = guidHandler(false)
      this.inputElements.oldFileDbGuid = this.inputElements.fileDbGuid
      this.inputElements.oldSignDbGuid = this.inputElements.signDbGuid
      this.inputElements.fileDbGuid = ""
      this.inputElements.signDbGuid = ""
      this.inputElements.name = ""
      this.inputElements.guid = ""
      this.inputElements.signGuid = ""
      this.chosenDocument = null;
      this.needToSignNow = false
      this.$emit('clear', this.inputElements)
      await this.docUploaderHandler()
    },
    /**
     * Функция создания и инициализации загрузчика файла
     */
    async docUploaderHandler() {
      setTimeout(() => {
        // для загружаемых файлов
        if (!this.item.options.readonly) {
          this.docUpload = new plupload.Uploader({
            runtimes: "html5,flash,silverlight,html4",
            browse_button: this.$el.querySelector(".uploadButton"),
            container: this.$el.querySelector(".uploadContainer"),
            filters: {
              max_file_size: "500mb"
            },
            multi_selection: false,
            chunk_size: "128kb",
            url: `${this.params.routes.fileUploadUrl}?guid=${this.guid}`,
            flash_swf_url: "/common/moxie.swf",
            silverlight_xap_url: "/common/moxie.xap",
            dragdrop: true,
            drop_element: this.$el.querySelector(".uploadContainer"),
            init: {
              FilesAdded: (upload, files) => {
                let availableExt = [
                  "doc","docx","pdf","txt","rtf","xls","xlsx","rar","zip","7z","xml","ppt","pptx","jpeg","jpg","png","gif","jfif","tif","tiff"
                ];

                let checkFileOk = true;
                files.forEach(file => {
                  if(!(/^.*\.\w{1,9}$/ui.test(file.name)))
                  {
                    upload.trigger("Error", {code: 400, message: "Проверьте тип файла"});
                    upload.removeFile(file)
                    checkFileOk = false;
                  }
                  const ext = /(?:\.([^.]+))?$/.exec(file.name)[1];
                  if (availableExt.indexOf(ext) === -1)
                  {
                    upload.trigger("Error", {code: 400, message: "Недопустимый тип файла, допустимые типы файлов: " + availableExt.join(', ')});
                    upload.removeFile(file)
                    checkFileOk = false;
                  }
                });

                if (!checkFileOk)
                {
                  return;
                }

                this.guid = guidHandler(false);
                this.inputElements.guid = this.guid
                upload.setOption("url", `${this.params.routes.fileUploadUrl}?guid=${this.guid}`);
                upload.start();

                upload.refresh();
              },
              UploadProgress: (uploader, file) => {
                this.loadingPercent = uploader.total.percent
                this.uploader = "loading";
              },
              Error: (upload, error) => {
                let message = error.message;
                if (error.message === "File size error.") {
                  message = "Максимальный размер файла - 500МБ";
                  if (error.file.size === 0) {
                    message = "Вы пытаетесь загрузить пустой файл";
                  }

                }
                alert("Ошибка загрузки файла" + message)
                this.uploader = "close"
              },
              FileUploaded: (upload, file, response) => {
                if (response.response === "success") {
                  this.uploader = "uploaded"
                  let needAddToMassList
                  if (this.signStep) {
                    needAddToMassList = this.item.options?.needsign;
                  }
                  this.currentFile = file.getNative();
                  if ((file.size > 100300000 || !this.signStep) && this.item.options?.needsign) {
                    this.needToSignNow = true
                    needAddToMassList = false
                  }
                  if (!this.signStep) {
                    this.needSignText = "Необходимо подписать загруженный файл"
                  }
                  this.chosenDocument = {
                    name: file.name,
                    format: file.name.split(".").pop(),
                    nameWOExt: file.name.split(".").shift(),
                    id: file.id,
                    isSigned: false
                  }
                  this.inputElements.fileDbGuid = null
                  this.inputElements.signDbGuid = null
                  this.inputElements.name = this.chosenDocument.name
                  if (needAddToMassList) {
                    window.measureSignFileList[this.guid] = this.currentFile
                    window.measureSignFileInfoList[this.guid] = {
                      name: file.name,
                      fieldName: this.item.title,
                      isSigned: false
                    }
                  }
                  this.guid = guidHandler(false);

                  this.$emit('iasupload', this.inputElements)


                  // Для заявок на вступление в венчурный клуб для создания ОИИ передаем лого в base_64

                  /*var reader = new window.FileReader();
                  reader.readAsDataURL(currentFile);

                  reader.onload = function () {
                    var base64data = reader.result;
                    base64data = base64data.substring(base64data.indexOf(",") + 1);

                    $('<input>').attr({'name': 'venturelogo', 'value': base64data, 'type': 'hidden'}).appendTo('form#measure_ajax_form');
                  };*/
                }
              }
            }
          });
          this.docUpload.init();
        }
        if (this.item.options.needsign) {
          // для подписей
          this.docUploadSign = new plupload.Uploader({
            runtimes: 'html5,flash,silverlight,html4',
            multi_selection: false,
            browse_button: this.$el.querySelector(".signUploadBtn"),
            container: this.$el.querySelector(".signUploadContainer"),
            chunk_size: '128kb',
            url: `${this.params.routes.fileUploadUrl}?guid=${this.guid}`,
            flash_swf_url: '/common/moxie.swf',
            silverlight_xap_url: '/common/moxie.xap',
            init: {
              FilesAdded: (upload, files) => {
                upload.setOption('url', `${this.params.routes.fileUploadUrl}?guid=${this.guid}`);

                upload.start();
                upload.refresh();
              },
              Error: (upload, error) => {
                alert('Ошибка загрузки подписи: ' + error.message)
              },
              FileUploaded: (upload, file, response) => {
                if (response.response === 'success') {
                  this.$emit("iassigned", this.inputElements)
                  return
                }
              }
            }
          })
          this.docUploadSign.init()
        }
      })
    },
    /**
     * Функция для подписания файла
     */
    signButtonHandler() {
      this.showSignButton = false
      this.showSignData = false
      const sign = (currentFile) => {
        const blitz = new SignPluginBlitz(this.$el.querySelector(".signData"), currentFile, result => {
          let sign
          sign = result.signature
          if (this.item.properties.fileDbGuid && this.item.properties.fileDbGuid.value !== null
            && this.item.properties.signDbGuid && this.item.properties.signDbGuid.value === null) {
            sign = result.signatures[this.item.properties.fileDbGuid.value]
          }
          const signature = sign
            .replace(/\r?\n/g, "")
            .replace("-----BEGIN CMS-----", "")
            .replace("-----END CMS-----", "");
          const signBlob = new Blob([signature], {type: 'text/plain'});
          this.chosenDocument.isSigned = true
          this.inputElements.signGuid = this.guid
          this.needToSignNow = false
          this.docUploadSign.addFile(signBlob);
          this.$emit('iasupload', this.inputElements)
        }, () => {
          this.showSignButton = true;
          alert('Не удалось подписать файл ' + error.message)
          //$('#signContainer_{{ id }}').empty().append($('<div/>').text('Не удалось подписать файл'));
        });
        blitz.init();
        this.showSignData = true
      }
      sign(this.currentFile)


      /*if ($(this).data('remote') && $(this).data('url'))
      {
        icHelper.downloadFileByLink($(this).data('url'), sign)
      } else  {
        sign(currentFile);
      }*/
    },
    /**
     * Функция для загрузки логотипа
     */
    async logoHandler() {
      setTimeout(() => {
        this.logoUpload = new plupload.Uploader({
          runtimes: "html5,flash,silverlight,html4",
          browse_button: this.$el.querySelector(".logoUploadButton"),
          container: this.$el.querySelector(".logoUploadContainer"),
          filters: {
            max_file_size: "500mb"
          },
          multi_selection: false,
          chunk_size: "128kb",
          url: `${this.params.routes.fileUploadUrl}?guid=${this.guid}`,
          flash_swf_url: "../assets/common/moxie.swf",
          silverlight_xap_url: "../assets/common/moxie.xap",
          dragdrop: true,
          drop_element: this.$el.querySelector(".logoUploadContainer"),
          init: {
            FilesAdded: (upload, files) => {
              let availableExt = [
                "jpeg","jpg","png"
              ];
              let checkFileOk = true;
              files.forEach(file => {
                if(!(/^.*\.\w{1,9}$/ui.test(file.name)))
                {
                  upload.trigger("Error", {code: 400, message: "Проверьте тип файла"});
                  upload.removeFile(file)
                  checkFileOk = false;
                }
                const ext = /(?:\.([^.]+))?$/.exec(file.name)[1];
                if (availableExt.indexOf(ext) === -1)
                {
                  upload.trigger("Error", {code: 400, message: "Недопустимый тип файла, допустимые типы файлов: " + availableExt.join(', ')});
                  upload.removeFile(file)
                  checkFileOk = false;
                }

              });

              if (!checkFileOk)
              {
                return;
              }
              this.guid = guidHandler(false);
              this.inputElements.guid = this.guid
              upload.setOption("url", `${this.params.routes.fileUploadUrl}?guid=${this.guid}`);
              upload.start();

              upload.refresh();
            },
            Error: (upload, error) => {
              let message = error.message;
              if (error.message === "File size error.") {
                message = "Максимальный размер файла - 500МБ";
                if (error.file.size === 0) {
                  message = "Вы пытаетесь загрузить пустой файл";
                }

              }
              alert("Ошибка загрузки файла" + message)
            },
            FileUploaded: (upload, file, response) => {
              if (response.response === "success") {
                this.currentFile = file.getNative();
                const getFileBase64 = async file => {
                  const reader = new FileReader()
                  reader.readAsDataURL(file)
                  reader.onload = () => {
                    this.logoSrc = reader.result
                  }
                }
                getFileBase64(this.currentFile)
                this.inputElements.name = file.name
                this.inputElements.fileDbGuid = null
                this.inputElements.signDbGuid = null
                this.$emit('iasupload', this.inputElements)
              }
            }
          }
        })
        this.logoUpload.init()
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>