<template>
    <teleport to="#site_header_container">
        <div class="pos_rel">
            <div class="filter_header">
                <container>
                    <div
                            class="filter_header_flex">

                        <div class="td1"
                             :class="{'full_dt': !dataInfo.isButtons?.value, 'full_dt_item': !isFilter, 'td_lite': showInput && widthSite > 991, 'td_lite2': (Object.keys(getParams).length - (filter.selectSelectedFilmType != '' ? 1 : 0) - (filter.nameSearch != '' ? 1 : 0) - (getParams.page ? 1 : 0)) > 0 }"
                             v-if="dataInfo.listFilters.length > 0">
                            <div ref="prev" class="swiper-button-prev"></div>
                            <div ref="next" class="swiper-button-next"></div>
                            <swiper
                                    :modules="modules"
                                    :slides-per-view="'auto'"
                                    :spaceBetween="12"
                                    :navigation="{
                    prevEl: prev,
                    nextEl: next,
                  }"
                                    class="filter_header_slide slide_auto fs16 fw500 filter_recv_slide"
                            >
                                <!--                :loop="filter.selectOptionsFilmType.length > 4 ? true : false"-->
                                <swiper-slide
                                        v-for="filmTypeItem in dataInfo.listFilters"
                                        :key="filmTypeItem.id">
                                    <router-link :to="filmTypeItem.link?.value + (getParams.company ? '?company=' + getParams.company : '')" class="filter_header_slide_item"
                                         :class="{'active': path == filmTypeItem.link?.value}">
                                        <div class="div_img">
                                            <img :src="getImage(filmTypeItem.icon.value)" alt="">
                                        </div>
                                        <div class="div_tit" v-if="filmTypeItem.name">{{ filmTypeItem.name.value }}</div>
                                    </router-link>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <div v-if="dataInfo.isButtons?.value && isFilter" class="td2">
                            <input-search
                                    v-model="filter.nameSearch"
                                    @search="findByText"
                                    @showInputFun="showInputFun"
                                    placeholder="Название"
                                    view="top"
                                    id="topInputFilter"
                            />
                        </div>
                        <div v-if="dataInfo.isButtons?.value && isFilter" class="td3">
                            <cl-button type="light3" class="btn-middle btn-ico-right"
                                       :class="{'btn-text-none': widthSite <= 991}"
                                       img="/img/ico/sliders-horizontal.svg"
                                       @click="btnShowFilter">Фильтры
                                <template v-slot:after>
                                    <div class="circle_or"
                                         v-if="Object.keys(getParams).length - (filter.nameSearch ? 1 : 0) - (techCategory ? 1 : 0)">
                                        {{
                                            Object.keys(getParams).length - (filter.nameSearch ? 1 : 0) - (techCategory ? 1 : 0)
                                        }}
                                    </div>
                                </template>
                            </cl-button>
                        </div>
                    </div>
                </container>
            </div>
        </div>
    </teleport>

    <cl-modal v-model="modalFilterShow" modal-type="center" class="filter_modal modal-scroll">
        <template v-slot:header>
            <div class="text-center">Фильтры</div>
        </template>
        <template v-slot:body>
            <perfect-scrollbar id="perfectUpdate">
                <div class="filter_cont" v-if="filter.selectOptionsEpoch.length">
                      <row class="filter_cont_row1">
                        <column md="12" class="filter_cont_col1" v-if="filter.selectOptionsCompany.length > 0">
                          <div class="filter_tit fs4">Организация</div>
                          <row class="filter_cont_row2">
                            <column md="4" class="align-self-center fw500"
                                    v-for="itemCheckbox in filter.selectOptionsCompany"
                            >
                              <div class='fake_checkbox cursor-pointer'
                                   @click="selectChangeCompany(itemCheckbox.partnerId)"
                                   :class="{'checked': filter.selectSelectedCompany.indexOf(String(itemCheckbox.partnerId)) != -1}"
                              >{{ itemCheckbox.partnerName }}
                              </div>
                            </column>
                          </row>
                        </column>

                        <column md="12" class="filter_cont_col1">
                            <div class="filter_tit fs4">Эпоха</div>
                            <filter-tabs
                                    :tabActive="filter.selectSelectedEpoch"
                                    :tabList="filter.selectOptionsEpoch"
                                    @changeTab="selectChangeEpoch"
                                    :tabSample="1"
                                    label="name"
                                    tabKey="id"
                                    class="filter_tabs_wh filter_tabs_48 filter_tabs_48p"
                            >
                                <template v-slot:before>
                                    <div class="filter_tabs_el">
                                        <button class="btn btn-light btn-tag" :class="{'active': filter.selectSelectedEpoch.length == 0}"
                                                @click="filter.selectSelectedEpoch = []; selectChangeEpoch()">Не выбрано
                                        </button>
                                    </div>
                                </template>
                            </filter-tabs>
                        </column>
                        <!--            <template v-if="filter.selectSelectedCategory.value !== '1315701'"></template>-->
                        <column md="12" class="filter_cont_col1">
                            <div class="filter_tit fs4">{{ filter.nameType }}</div>
                            <row class="filter_cont_row2">
                                <column md="4" class="align-self-center fw500"
                                        v-for="itemCheckbox in filter.selectOptionsType"
                                >
                                    <div class='fake_checkbox cursor-pointer'
                                         @click="selectChangeType(itemCheckbox.id)"
                                         :class="{'checked': filter.selectSelectedType.indexOf(itemCheckbox.id) != -1}"
                                    >{{ itemCheckbox.name }}
                                    </div>
                                </column>
                            </row>
                        </column>

                        <column md="12" class="filter_cont_col1" v-if="filter.selectOptionsMaterial">
                            <div class="filter_tit fs4">Материал</div>
                            <row class="filter_cont_row2">
                                <column md="4" class="align-self-center fw500"
                                        v-for="itemCheckbox in filter.selectOptionsMaterial"
                                >
                                    <div class='fake_checkbox cursor-pointer'
                                         @click="selectChangeMaterial(itemCheckbox.id)"
                                         :class="{'checked': filter.selectSelectedMaterial.indexOf(itemCheckbox.id) != -1}"
                                    >{{ itemCheckbox.name }}
                                    </div>
                                </column>
                            </row>
                        </column>
                    </row>
                </div>
            </perfect-scrollbar>
        </template>
        <template v-slot:footer>
            <cl-button type="light" class="btn-middle color_or" @click="deleteParams">Очистить <span
                    class="d-none d-md-inline">все</span></cl-button>
            <cl-button class="btn-middle" @click="searchRent">Показать <span class="d-none d-md-inline">реквизиты</span>
            </cl-button>
        </template>
    </cl-modal>


</template>

<script>
/*
* deleteParamsStatus - сбрасывает фильтры, нужно для активации сброса вне компонента
* */
import translates from '@/assets/js/resources/translate/rent';
import {ref} from 'vue';
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import {mapGetters} from "vuex";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "arendaRecvizitaTopFilter",
  components: {Swiper, SwiperSlide},
  props: {
    deleteParamsStatus: {
      type: Boolean
    },
    dataInfo: {
      type: Object,
      require: true
    },
      techCategory: {
        type: String,
        require: true
    },
    isFilter: {
        type: Boolean,
    }
  },
  data() {
    return {
      showInput: false,
      modalFilterShow: false,
      path: null,
      clearParam: {state: false},
      isFilterOpen: true,

      ps: null,

      getParams: {
          category: this.techCategory,
      },
      getParamsFilter: {
        parent: null
      },
      controller: null,

      isLoadingFilter: true,
      filter: {
          nameSearch: "",
          nameType: "Выберите тип",
          selectSelectedType:  [],
          selectOptionsType:  [],
          selectSelectedEpoch:  [],
          selectOptionsEpoch:  [],
          selectSelectedMaterial:  [],
          selectOptionsMaterial:  [],
          selectSelectedCompany:  [],
          selectOptionsCompany:  [],
      },
    }
  },
  methods: {
    //передаем параметры на страницу со списком
    emitParamsList() {
      this.getParams.category = this.techCategory;
      this.$emit("searchFun", [{...this.getParams}, this.clearParam, this.isFilter]);
    },
    getFilter() {
      this.isLoadingFilter = true;
      return this.axios
        .get(`/api/data/filters/requisite`, {params: this.getParamsFilter, signal: this.controller.signal})
        .then(response => {
            this.filter.selectOptionsType = response.data.subcategory;
            this.filter.selectOptionsEpoch = response.data.timePeriod;
            this.filter.selectOptionsMaterial = response.data.material;
            this.filter.selectOptionsCompany = response.data.organizations;

          this.isLoadingFilter = false;
        })
        .catch(error => {
          this.showError(error);
        });
    },
    getCompanyQuery(){
      if (Object.keys(this.$route.query).length > 0) {
        for (const [key, value] of Object.entries(this.$route.query)) {
          if (key == 'company') {
            this.selectChangeCompany(value);
            this.$nextTick(() => {
              this.emitParamsList();
            });
          }
        }
      }
    },
    // getFilterType() {
    //   return this.axios
    //     .get(`/api/data/film-type`, {signal: this.controller.signal})
    //     .then(response => {
    //         console.log(response);
    //       this.filter.selectOptionsFilmType = response.data;
    //       this.getFilter();
    //     })
    //     .catch(error => {
    //       this.showError(error);
    //     });
    // },
    translateWord(word) {
      return translates[word] ? translates[word] : 'Значение не найдено';
    },
    //поиск
    searchRent() {
      this.modalFilterShow = false;
      this.emitParamsList();

      if(this.filter.selectSelectedCompany.length < 1) {
        history.pushState({}, "", this.$route.path);
      } else {
        history.pushState({}, "", "?company=" + this.getParams.company);
        //history.pushState({}, "", "?" + new URLSearchParams({company: this.getParams.company}));
      }
    },
    // очистка фильтров в модалке
    // deleteParams(category = false, startSearch = true) {
    //   /*if (Object.keys(this.$route.query).length > 0) {
    //     history.pushState({}, '', this.$route.path);
    //   }*/
    //
    //   this.getParams = {};
    //   if((category && category != '1315701') || !category){
    //     this.filter.selectSelectedCategory = [];
    //     this.filter.selectSelectedDistrict = [];
    //   } else {
    //     this.selectChangeCategory(false, 'result');
    //     this.selectChangePavilions(false, 'result');
    //   }
    //
    //   this.filter.areaFilter = [];
    //   this.filter.ceilingHeight = [];
    //   this.filter.ceilingHeightTrusses = [];
    //   this.filter.length = [];
    //   this.filter.width = [];
    //   this.filter.powerElectricity = [];
    //   this.filter.checkboxAll = [];
    //
    //   this.filter.nameSearch = "";
    //   this.filter.selectSelectedFilmType = "";
    //
    //   if(startSearch) {
    //     this.modalFilterShow = false;
    //     this.emitParamsList();
    //   }
    // },
    // поле типов
    filmTypeFun(item, startSearch = true) {
      if (this.filter.selectSelectedFilmType != item) {
        this.filter.selectSelectedFilmType = item;
        this.getParams.types = item.id;
      } else {
        this.filter.selectSelectedFilmType = "";
        delete this.getParams.types;
      }
      if (startSearch) {
        this.emitParamsList();
      }
    },
    // поле поиска
    findByText(text, startSearch = true) {
      if (text != "") {
        this.getParams.name = text;
      } else {
        delete this.getParams.name;
      }
      //if (startSearch) {
        this.emitParamsList();
      //}
    },
    // разкрытие/закрытие поиска
    showInputFun(status) {
      this.showInput = status;
    },
      // очистка фильтров в модалке
      deleteParams(startSearch = true) {
          this.getParams = {};
          this.filter.selectSelectedType = [];
          this.filter.selectSelectedEpoch = [];
          this.filter.selectSelectedMaterial = [];
          this.filter.selectSelectedCompany = [];
          this.filter.nameSearch = "";
          history.pushState({}, "", this.$route.path);
          if(startSearch) {
              this.modalFilterShow = false;
          }
          this.$nextTick(() => {
            this.emitParamsList();
          });
      },
      //Тип мебели
      selectChangeType(val, eventType = "click"){
          if (eventType == 'click') {
              if (this.filter.selectSelectedType.indexOf(val) != -1) {
                  this.filter.selectSelectedType = [...this.filter.selectSelectedType].filter(item => item != val);
              } else {
                  this.filter.selectSelectedType = [...this.filter.selectSelectedType, val];
              }
          }
          if(this.filter.selectSelectedType.length < 1) {
              delete this.getParams.subcategory
          } else {
              this.getParams.subcategory = [...this.filter.selectSelectedType].join();
          }
      },
      //Эпоха
      selectChangeEpoch(val){
          if(this.filter.selectSelectedEpoch.length < 1) {
              delete this.getParams.timePeriod
          } else {
              this.getParams.timePeriod = this.filter.selectSelectedEpoch[0];
          }
      },
      //Материал
      selectChangeMaterial(val, eventType = "click"){
          if (eventType == 'click') {
              if (this.filter.selectSelectedMaterial.indexOf(val) != -1) {
                  this.filter.selectSelectedMaterial = [...this.filter.selectSelectedMaterial].filter(item => item != val);
              } else {
                  this.filter.selectSelectedMaterial = [...this.filter.selectSelectedMaterial, val];
              }
          }
          if(this.filter.selectSelectedMaterial.length < 1) {
              delete this.getParams.material
          } else {
              this.getParams.material = [...this.filter.selectSelectedMaterial].join();
          }
      },
      selectChangeCompany(val, eventType = "click"){
        const valString = String(val);
        if (eventType == 'click') {
          if (this.filter.selectSelectedCompany.indexOf(valString) != -1) {
            this.filter.selectSelectedCompany = [...this.filter.selectSelectedCompany].filter(item => item != valString);
          } else {
            this.filter.selectSelectedCompany = [...this.filter.selectSelectedCompany, ...valString.split(',')];
          }
        }
        if(this.filter.selectSelectedCompany.length < 1) {
          delete this.getParams.company;
        } else {
          this.getParams.company = [...this.filter.selectSelectedCompany].join();
        }
      },
    // модалка табы
    changeTabEvent(key) {
      if (this.filter[key].length > 0) {
          this.getParams[key] = this.filter[key].join();
      } else {
          delete this.getParams[key];
      }
    },
    // модалка табы кнопка не выбрано
    clearTab(key) {
      this.filter[key] = [];
      delete this.getParams[key];
      if (key == "areaFilter") {
        delete this.getParams.newArea;
      }
    },
    // модалка чекбоксы
    changeCheckbox(value) {
      if (this.filter.checkboxAll.indexOf(value) != -1) {
        this.filter.checkboxAll = [...this.filter.checkboxAll].filter(item => item != value);
      } else {
        this.filter.checkboxAll = [...this.filter.checkboxAll, value];
      }
      if (this.filter.checkboxAll.length < 1) {
        delete this.getParams.checkbox
      } else {
        this.getParams.checkbox = [...this.filter.checkboxAll].join();
      }
    },
    // модалка выбрать все
    chooseAll(itemFilter) {
      const addEl = [...itemFilter].map(item => item.value),
        addElFilter = addEl.filter(item => ![...this.filter.checkboxAll].some(record => record == item));
      this.filter.checkboxAll = [...this.filter.checkboxAll, ...addElFilter];
      this.getParams.checkbox = [...this.filter.checkboxAll].join();
    },
    // отменить все
    cancelAll(itemFilter) {
      const removeEl = [...itemFilter].map(item => item.value);
      this.filter.checkboxAll = [...this.filter.checkboxAll].filter(item => ![...removeEl].some(record => record == item));
      this.getParams.checkbox = [...this.filter.checkboxAll].join();
      if (this.getParams.checkbox == "") {
        delete this.getParams.checkbox
      }
    },
    // показывать кнопку выбрать все
    btnChooseAll(itemFilter) {
      const inStockEl = [...itemFilter].map(item => item.value);
      if (inStockEl.length != [...this.filter.checkboxAll].filter(item => [...inStockEl].some(record => record == item)).length) {
        return true;
      } else {
        return false;
      }
    },
      btnShowFilter(){
          this.modalFilterShow = true;
          setTimeout(() => {
              try {
                  this.ps = new PerfectScrollbar(document.querySelector('#perfectUpdate'));
                  const resizeObserver = new ResizeObserver(() => {
                      this.ps.update();
                  });
                  resizeObserver.observe(document.querySelector('#perfectUpdate').querySelector(".filter_cont"))
              } catch (error){
                  this.ps = null;
              }
          }, 200);
      },
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
      this.path = window.location.pathname;
    document.querySelector('#site_header').setAttribute('fon', '1');
    // this.getFilterType();
    //this.emitParamsList();
    this.getCompanyQuery();
  },
  beforeUnmount() {
    document.querySelector('#site_header').setAttribute('fon', '');
    this.controller.abort();
    this.controller = null;
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  },
  watch: {
    deleteParamsStatus() {
      this.deleteParamsStatus ? this.deleteParams() : '';
    },
      techCategory: {
        handler(){
            this.getParamsFilter.parent = this.techCategory;
            if(this.techCategory == 1315201){
                this.filter.nameType = "Тип мебели";
            } else if(this.techCategory == 1315202){
                this.filter.nameType = "Тип форменной одежды";
            }
            this.getFilter();
        }
    },
      clearParam: {
          handler(){
              if (this.clearParam.state){
                  this.deleteParams();
              }
          },
          deep: true,
      },
      isFilter: {
        handler(){
            this.isFilterOpen = this.isFilter;
        }
      }
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
}
</script>

<style lang="scss">

</style>